import React, { useState } from "react";
import Demographic from "../../components/snippets/Assessment/Demographic";
// import { useRef } from 'react';
import AssessmentFirstPage from "./AssessmentFirstPage";

const Assessment = ({match}) => {
    // const quesWrap = useRef(null)
    const [editorActive, setEditorActive] = useState(false)
    const handleEditor = (editor) =>{
       if(editor){
        setEditorActive(true)
       }else{
        setEditorActive(false)
       }
    }
    return (
        <div className="h-full">
            <div className="h-full flex">
            <Demographic caseId={match.params.caseId} handleEditor={handleEditor} />
                <div className="flex-1 flex">
                    <AssessmentFirstPage asmtId={match.params.assessmentId} caseId={match.params.caseId} screenerId={0} editorActive={editorActive} handleEditor={handleEditor} />
                </div>
            </div>
        </div>
    )
}

export default Assessment