import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useHistory } from 'react-router-dom';
import {useSelector} from 'react-redux'
import FormCard from "../../components/snippets/FormCard"
// import  {FaInfoCircle} from 'react-icons/fa'
import { motion } from 'framer-motion'
import { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
// import { useDispatch, useSelector } from 'react-redux'
import API from '../../axios/API'
import * as Yup from 'yup'
import PageLoader from "../CaseDetails/PageLoader";
// import FieldSelect from "../../components/snippets/FieldSelect"
import { useDispatch } from "react-redux";
import { setAlert } from "../../store/theme/actions";
import { useTranslation } from "react-i18next"
import axios from "axios"
import Dialog from "../../components/snippets/Dialog";
import DavServiceDialog from "../Dialogs/DavServiceDialog";
import ReassessDialog from "../../components/snippets/ReassesDialog";
import DAVDialog from "../../components/snippets/DAVDialog";


createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

// const initialFormValues = {
//     otherUsefullInfo:{
//         availabilityFamily: '',
//         formalServices: '',
//         specialCircumstances: '',
//         summFour: '',
//         summOne: '',
//         summThree: '',
//         summTwo: ''
//     }
// }

const ScoreSummary = ({ details,assessmentId,caseId }) => {

    // const [editForm] = useState(false)
    const [loading, setLoading] = useState(true)
    // const [formLoading,setFormLoading] = useState(false)
    // const [dataLoaded, setDataLoaded] = useState(true)
    const [summary, setSummary] = useState([false]);
    // const [firstIcon, setFirstIcon] = useState(false);
    // const [secondIcon, setSecondIcon] = useState(false);
    // const [thirdIcon, setThirdIcon] = useState(false);
    // const [fourthIcon, setFourthIcon] = useState(false);
    const userDetails = useSelector(state => state.auth.userDetails)
    const organization = useSelector(state => state.auth.organization)
    const {t} = useTranslation()
    // const question1 = [{text:t('Yes'),value:1}, {text:t('No,care recipient has higher needs'),value:2}, {text:t('No,care recipient has lower needs'),value:3}].map(c => ({ text: c.text, value: c.value }))
    // const question2 = [{text:t('Yes'),value:1}, {text:t('No'),value:2}].map(c => ({ text: c.text, value: c.value }))
    // const question3 = [{text:t('Yes'),value:1}, {text:t('No'),value:2}].map(c => ({ text: c.text, value: c.value }))
    // const question4 = [{text:t('Yes'),value:1}, {text:t('Possibly'),value:2}, {text:t('No'),value:3}].map(c => ({ text: c.text, value: c.value}))
    let orgId = localStorage.getItem('orgid')
    const [disable,setDisable] = useState(false)
    let history = useHistory();
    const dispatch = useDispatch()
    let davService = localStorage.getItem('military')
    const [davServiceEnable,setDavServiceEnable] = useState(false)
    useEffect(() => {
        // setDataLoaded(false)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/assessments/${assessmentId}/summary`,{
            headers: {
                'Authorization': localStorage.getItem('token'), 
                'Accept': 'application/json'
              }
        }).then(res => {
            if (res.status === 200) {
                setSummary(res.data.body)
                setLoading(false)
            }
        }).catch(e => {
            console.log(e)
        })
        // .finally(() => setDataLoaded(true))
    }, [])// eslint-disable-line

    const profileFormSchema = Yup.object().shape({
        otherUsefullInfo: Yup.object({
            // availabilityFamily: Yup.string().required(),
            // formalServices: Yup.string().required(),
            // specialCircumstances: Yup.string().required(),
            summFour: Yup.string().required(t("required field")).nullable(),
            summOne: Yup.string().required(t("required field")).nullable(),
            summThree: Yup.string().required(t("required field")).nullable(),
            summTwo: Yup.string().required(t("required field")).nullable()
        })
    })
    const profileForm = useFormik({
        initialValues: summary,
        validationSchema:profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            // setFormLoading(true)
            saveSummary()
            // setFormLoading(false)
        }
    })

    const saveSummary = () =>{
        setDisable(true)
        // API.post(`organizations/${orgId}/assessments/${assessmentId}/summary`,profileForm.values.otherUsefullInfo).then((res)=>{
        //     if(res.status === 200){
            // if(davService === 'true' && summary.davMilitaryEnable){
            //     setDavServiceEnable(true)
            // }else 
            if((userDetails.orgId === parseInt(organization) && userDetails.role.includes('caremanager'))||(userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_cm'))){
                    let details ={
                        "screener":0
                    }
                    API.post(`organizations/${orgId}/cases/${caseId}/assessments`,details).then(res => {
                        if (res.status === 200) {
                            history.push(`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}`)
                            dispatch(setAlert({ title: t('Success'), subtitle: t('Post Assessment created successfully'), type: 'success', active: true }))
                        }
                    }).catch(err => {
                        if (err.response.status === 406) {
                            dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                            history.push(`/casedata/${caseId}`)
                           
                        }
                        console.log(err)
                    })
                }else{
                    history.push(`/casedata/${profileForm.values.caseId}`);
                }
            }
    //     }).catch(err => {
    //         if (err.response.status === 406) {
    //             dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
    //             history.push(`/casedata/${caseId}`)
               
    //         }
    //         console.log(err)
    //     })
    // }
    // const ForceAssment = () =>{
    //     API.post(`assessments/${assessmentId}/summary`,profileForm.values.otherUsefullInfo).then((res)=>{
    //         if(res.status === 200){
    //             if(userDetails.role.includes('caremanager')){
    //                 API.post(`cases/${caseId}/assessments`).then(res => {
    //                     if (res.status === 200) {
    //                         history.push({pathname:`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}`,state:{caseId:res.data.body.caseId,editMode:true}})
    //                         dispatch(setAlert({ title: 'Success', subtitle: 'Post Assessment created successfully', type: 'success', active: true }))
    //                     }
    //                 }).catch(err => {
    //                     console.log(err)
    //                 })
    //             }else{
    //                 history.push(`/casedata/${profileForm.values.caseId}`);
    //             }
    //         }
    //     }).catch((err)=>{
    //         console.log(err)
    //     })
    // }

    const movewatch =() =>{
        setDisable(true)
        API.post(`assessments/${assessmentId}/watchlist`).then((res)=>{
            if(res.status === 200){
                history.push(`/watch_list`)
            }
        }).catch(err => {
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                history.push(`/casedata/${caseId}`)
               
            }
            console.log(err)
        })
    }

    const onCloseDav=()=>{
        setDavServiceEnable(false)
        setDisable(false)
    }
    return (
        <div className={'flex-1'}>
             {
                loading ? <PageLoader/>:
                <div className="pl-3">
                 
                     <div>
                        <FormCard>
                            <div class="text-xl"><h1>{t("Here are your TCARE Caregiver Assessment results")}:</h1> </div>
                               <motion.div><br></br><br></br>
                                <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-2 px-32">
                                    <div class="flex mb-4">
                                        <div title={summary.cgEmotionalNeedsMsg.cgRelationshipBurdenMsg} class="w-1/2">{t("Relationship Burden")}:</div>
                                        <div class="w-1/2">
                                            {
                                                summary.cgEmotionalNeeds.cgRelationBurden === "1" ? <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgRelationBurden === "2" ? <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgRelationBurden === "3"? <div className=" answer-3">{t("High")}</div> : ''
                                            }
                                        </div>
                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgObjectiveBurdenMsg} class="w-1/2">{t("Objective Burden")}:</div>
                                        <div class="w-1/2">
                                            {
                                                summary.cgEmotionalNeeds.cgObjectiveBurden === "1" ? <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgObjectiveBurden === "2" ? <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgObjectiveBurden === "3" ? <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>

                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgStressBurdenMsg} class="w-1/2">{t("Stress Burden")}:</div>
                                        <div class="w-1/2 ">
                                            {
                                                summary.cgEmotionalNeeds.cgStressBurden === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgStressBurden === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgStressBurden === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>

                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgUpliftMsg} class="w-1/2">{t("Uplifts")}:</div>
                                        <div class="w-1/2 ">
                                            {
                                                summary.cgEmotionalNeeds.cgUplifts === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgUplifts === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgUplifts === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>

                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgDepressionMsg} class="w-1/2">{t("Depression")}:</div>
                                        <div class="w-1/2">
                                            {
                                                summary.cgEmotionalNeeds.cgDepression === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgDepression === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgDepression === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>
                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgIdentityDiscrepMsg} class="w-1/2">{t("Identity Discrepancy")}:</div>
                                        <div class="w-1/2">
                                            {
                                                summary.cgEmotionalNeeds.cgIdentityDiscrep === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgIdentityDiscrep === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgIdentityDiscrep === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>
                                        
                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgMsg.intentionPlaceMsg} class="w-1/2">{t("Intension To Place")}:</div>
                                       <div  class="w-1/2">
                                       {
                                            summary.cgStatus.intentionToPlace === "0" ?
                                            <div className="answer-4">{t('No')}</div>:
                                            summary.cgStatus.intentionToPlace === "1" ?
                                            <div className="answer-3">{t("Yes")}</div>:''
                                        }
                                       </div>

                                    </div>
                                   
                                </div>
                            </motion.div><br></br>
                            
                      
                        {/* <FormCard title={t("Questionnaire")} >
                            {/* <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-1"> */}
                                {/* <div>
                                    <div> {t("1. Does caregiver accurately understand care receiver's level of need?")} <button onClick={()=>setFirstIcon(!firstIcon)} ><FaInfoCircle size="20px"/></button></div><br></br>
                                    <div>
                                    {
                                        firstIcon ? <span >{t("Questions to consider when answering")}
                                        <ul><br></br>
                                            <li>{t("Does caregiver have accurate knowledge about the care receiver’s medical condition?")}</li>
                                            <li>{t("Does caregiver accurately attribute symptoms to disease?")}</li>
                                            <li>{t("Does caregiver understand type and amount of help or supervision needed?")}</li>
                                        </ul></span> :''
                                    }
                                    </div>
                                    <FieldSelect loading={formLoading} label="" fieldMeta={profileForm.getFieldMeta('otherUsefullInfo.summOne')} fieldHelper={profileForm.getFieldHelpers('otherUsefullInfo.summOne')} fieldProps={profileForm.getFieldProps('otherUsefullInfo.summOne')} edit={true} options={question1} position="top" dataLoaded={dataLoaded} />
                                </div>&nbsp;
                                <div>
                                    <div>{t("2. Currently is caregiver able to provide necessary care in a safe manner?")} <button onClick={()=>setSecondIcon(!secondIcon)} ><FaInfoCircle size="20px"/></button></div><br></br>
                                    <div>
                                    {
                                        secondIcon ? <span >{t("Is the caregiver physically, emotionally and financially able to provide adequate and safe care?")} &nbsp;
                                        <ul>&nbsp;
                                            <li>{t("How long has he/she been providing care?")}</li>
                                            <li>{t("Does caregiver accurately attribute symptoms to disease?")}</li>
                                            <li>{t("His/her medical condition?")}</li>
                                            <li>{t("What other obligations does he/she have? Employment? Other dependents?")}</li>    
                                            <li>{t("Evidence of depression?")}</li>
                                            <li>{t("Evidence of or concerns about substance abuse?")}</li>
                                            <li>{t("Concerns about or evidence of abuse?")}</li>
                                             <li>{t("Did he/she express concerns about relationship?")}</li>
                                             <li>{t("Concerns about personal safety?")}</li>  
                                             <li>{t("Does he/she have necessary knowledge and skills to provide care?")}</li>     
                                             <li>{t("Does he/she have confidence or sense of mastery?")}</li>
                                         </ul></span>:''
                                    }
                                    </div>
                                    <FieldSelect loading={formLoading} label="" fieldMeta={profileForm.getFieldMeta('otherUsefullInfo.summTwo')} fieldHelper={profileForm.getFieldHelpers('otherUsefullInfo.summTwo')} fieldProps={profileForm.getFieldProps('otherUsefullInfo.summTwo')} edit={true} options={question2} position="top" dataLoaded={dataLoaded} />
                                </div>&nbsp;
                                <div>
                                    <div>{t("3. Are there services/resources available to the caregiver that would enable the caregiver to provide care in a safe manner?")} <button onClick={()=>setThirdIcon(!thirdIcon)}><FaInfoCircle size="20px"/></button></div><br></br>
                                    <div>
                                  {
                                      thirdIcon ?   <span >{t("What personal strengths and resources does the caregiver have to draw upon to assist with care responsibilities?")}

                                      <ul><br></br>
                                          <li>{t("Are there educational programs or services available to enhance the skills or knowledge of the caregiver?")}</li>
                                          <li>{t("Is he or she likely to seek and accept help from informal sources?")}</li>
                                          <li>{t("Is he or she likely to seek or accept help from formal sources?")}</li>
                                          <li>{t("Can he/she afford to purchase necessary help?")}</li>
                                          <li>{t("Is he/she likely to be eligible for means-tested services or resources that are available through public or private programs?")}</li>
                                      </ul></span>:''
                                  }
                                    </div>
                                    <FieldSelect loading={formLoading} label="" fieldMeta={profileForm.getFieldMeta('otherUsefullInfo.summThree')} fieldHelper={profileForm.getFieldHelpers('otherUsefullInfo.summThree')} fieldProps={profileForm.getFieldProps('otherUsefullInfo.summThree')} edit={true} options={question3} position="top" dataLoaded={dataLoaded} />
                                </div>&nbsp;
                                <div>
                                    <div>{t("4. Is there evidence that would indicate that the caregiver should be encouraged to seek help from a health professional for his/her health condition?")} <button onClick={()=>setFourthIcon(!fourthIcon)}><FaInfoCircle size="20px"/></button></div><br></br>
                                    <div>
                                    {
                                        fourthIcon ? <span >{t("What personal strengths and resources does the caregiver have to draw upon to assist with care responsibilities?")}
                                        <ul><br></br>
                                            <li>{t("Are there educational programs or services available to enhance the skills or knowledge of the caregiver?")}</li>
                                            <li>{t("Is he or she likely to seek and accept help from informal sources?")}</li>
                                            <li>{t("Is he or she likely to seek or accept help from formal sources?")}</li>
                                            <li>{t("Can he/she afford to purchase necessary help?")}</li>
                                            <li>{t("Is he/she likely to be eligible for means-tested services or resources that are available through public or private programs?")}</li>
                                        </ul></span>:''
                                    }
                                    </div>
                                    <FieldSelect loading={formLoading} label="" fieldMeta={profileForm.getFieldMeta('otherUsefullInfo.summFour')} fieldHelper={profileForm.getFieldHelpers('otherUsefullInfo.summFour')} fieldProps={profileForm.getFieldProps('otherUsefullInfo.summFour')}position="top"  edit={true} options={question4}  dataLoaded={dataLoaded}  />
                                </div> */}
                            {/* </div> */}
                        {/* </FormCard> */} 
                        
                            
                            
                            {/* <div>
                            <p class="text-center text-xl">Thanks For being a Caregiver...</p>
                            </div><br></br><br></br>
                            <div>
                            <p class="text-center text-base">A family caregiver specialist from colorado will be reachingout to</p>
                            <p class="text-center text-base">you regarding next steps in getting you the tailored resources you</p>
                            <p class="text-center text-base">need.</p>
                            </div><br></br>
                            <div class="flex justify-center ...">
                                <button className="answer-5">
                                    LEARN ABOUT TCARE
                                </button>
                            </div>                      */}

                             { (summary.cgScore !== 'High'  &&  ((userDetails.role.includes('caremanager') && userDetails.caremanagerId === summary.careManagerId)||(userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_cm') && userDetails.caremanagerId === summary.careManagerId))) ? 
                             <div className="flex justify-center mt-7 mx-5">
                            <button disabled={disable} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-blue-500  text-black" onClick={()=> movewatch()}>{t("Move to Watchlist")}</button>
                            <button disabled={disable} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() =>saveSummary()}>{t("Force Full Assessment")}</button>
                            {/* {(summary.cgScore === 'High'&& summary.careManagerId !== 0) ? <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => saveSummary()}>{t("Continue to Full Assessment")}</button>:''}
                            {(userDetails.role.includes('screener')) ?<button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-blue-500  text-white" onClick={()=>history.push(`/casedata/${caseId}`)}>{t("Cancel")}</button>:''}
                           {(userDetails.role.includes('screener')) ?  <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Save Score")}</button>:''} */}
                        </div>:(summary.cgScore === 'High' && ((userDetails.role.includes('caremanager') && userDetails.caremanagerId === summary.careManagerId)||(userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_cm') && userDetails.caremanagerId === summary.careManagerId))) ? 
                            <div className="flex justify-center mt-7 mx-5">
                              <button disabled={disable} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => saveSummary()}>{t("Continue to Full Assessment")}</button>
                            </div>: (userDetails.role.includes('screener'))?
                            <div className="flex justify-center mt-7 mx-5">
                                {/* <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-blue-500  text-white" onClick={()=>history.push(`/casedata/${caseId}`)}>{t("Case Details")}</button> */}
                                {/* <button disabled={disable} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Save Score")}</button> */}
                                </div>:''}
                        </FormCard>

                    </div>
                    
                       
                </div>
                  
            }

                    <DAVDialog showDialog={davServiceEnable} title={t('DAV Military Service')} onClose={() =>  onCloseDav()}>
                        <DavServiceDialog props={caseId} cgId={summary.caregiverId} crId={summary.careReceiverId} onClose={() => onCloseDav()} />
                    </DAVDialog>
        </div>
           
    )
}

export default ScoreSummary