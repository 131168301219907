import { useCallback, useMemo, useState, useEffect } from "react"
// import { AnimatePresence, motion } from 'framer-motion'
import { setAlert } from "../../store/theme/actions"
import { useDispatch, useSelector } from "react-redux"
import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import DataTable, { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import API from "../../axios/API"
import { useTranslation } from "react-i18next"
// import FieldLoader from "../../components/snippets/FieldLoader"
// import {RiInboxArchiveFill} from 'react-icons/ri'
// import { RiDirectionFill } from 'react-icons/ri'
// import Dialog from '../../components/snippets/Dialog'
// import ClosedCaseDialog from '../Dialogs/ClosedCaseDialog'
import 'moment-timezone';
// import ReferrerCaseDialog from "../Dialogs/ReferrerCaseDialog"
// import { PopInAnim } from '../../commons/anims'
// import { FaLayerGroup } from 'react-icons/fa'
// import PopoverMenu from '../../components/snippets/PopoverMenu'
// import {setTransferCaseRender} from '../../store/auth/actions'
import {setChildOrgRender} from '../../store/auth/actions'



createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const TransferOrgs = ({ onClose, caseIds }) => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [organizaitonList, setOrganizationList] = useState([]);
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(false);
    const {userDetails} = useSelector(state => state.auth)
    const render = useSelector(state => state.auth.childOrgTransferRender)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')

    useEffect(() => {
        setLoading(true);
        API.get(`organizations/${orgId}/organizations/child:list`).then((res) => {
            setOrganizationList(res.data.filter(c=>c.organizationId !==userDetails.orgId));
        }).catch(e => {
            // console.log(e)
        }).finally(() => setLoading(false))
    }, [])// eslint-disable-line
   
    const childOrgtransfer = (id) =>{
        let details = {
            "orgId": orgId,
            "caseId": caseIds,
            "childOrgId": id
          }
          API.post(`organizations/cases:transfer`,details).then(res =>{
              dispatch(setAlert({title:t('Success'),subtitle:t('Case transferred to the child org successfully'),type:'success',active:true}));
              dispatch(setChildOrgRender(render+1));
              onClose();
          }).catch(e=>{
              console.log(e)
          }).finally(()=>onClose);
    }



    const colums = useMemo(() => {
        return [
            { name: t('Organization Name'), selector: 'organizationName', grow: 1, sortable: true },
            // { name: 'City', selector: 'organizationAddress.city', grow: 1, sortable: true },
            // { name: 'Phone', selector: 'phone', grow: 1, sortable: true },
            {
                name: t('Action'),button:true, cell: row =><div className={`flex items-center rounded-lg text-white px-3 py-1 bg-tcolor`} style={{cursor:'pointer'}} onClick={()=>childOrgtransfer(row.organizationId)}> <span className="mr-2 text-sm font-medium">{t("Select")}</span></div>, sortable: true, hide: 'sm' }
 
            // {name: 'Action', button: true, cell: row => <Link to={`/caregiver/${row.id}`}><FaEye size="20px" className="text-gray-600 dark:text-gray-300" /></Link>}
        ]
    }, [])// eslint-disable-line
    const SubHeader = useMemo(() => (
        <div className="flex items-center">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return organizaitonList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [organizaitonList,filter])// eslint-disable-line
    const ExpandComponent = useCallback(row => {
        return (

            <div className="bg-bluegray-300 dark:bg-ldark" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>

        )
    }, [])
    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })

    return (
        <div>
            {
                loading ?
                    <DatatableLoader /> :
                    <div className=" mt-4 p-2 bg-bluegray-200 dark:bg-ddark rounded-lg">
                        <DataTable title="casedetails" columns={colums} data={filteredcasedetails} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} noDataComponent={t('There are no childorg to display')} />
                    </div>
            }
        </div>
    )
}
// (onClose,caseIds) => {

//     const dispatch = useDispatch()
//     const { t } = useTranslation()
//     const [filter, setFilter] = useState('')
//     const [subFilter,setSubFilter] = useState('')
//     const [loading, setLoading] = useState(true)
//     const [page, setPage] = useState(1)
//     const [columnsSelected, setColumnsSelected] = useState([])
//     const { screenSize, themeMode } = useSelector(state => state.theme)
//     const caseTable = useRef(null)
//     const [openMultiAction, setOpenMultiAction] = useState(false)
//     const [activeQuickAction] = useState({ position: 'bottom', active: null })
//     const organization = useSelector(state => state.auth.organization)
//     const [pendingCrList, setPendingCrList] = useState([])
//     const [fetchingFollowupFor, setFetchingFollowupFor] = useState(null)
//     const [followupFetchedList] = useState([])
//     const language = useSelector(state=>state.theme.language)
//     const [selectRows] = useState([])
//     const userDetails = useSelector(state =>state.auth.userDetails)
//     const [closeCaseModal, setCloseCaseModal] = useState(false)
//     const [reassignreferrerCaseModal,setReassignReferrerCaseModal] = useState(false)
//     const closecaseRender = useSelector(state => state.auth.closeCaseRender)
//     const render = useSelector(state =>state.auth.transferCaseRender)
//     const [caseId, setCaseId] = useState('')
//     // const [statusId,setStatusId] = useState('')
//     const [followupList,setFollowupList] = useState([])
//     let orgId = localStorage.getItem('orgid')
//     const [currentRow,setCurrentRow] = useState(null)
//     useEffect(() => {
//         setLoading(true)
//         API.get(`organizations/${orgId}/organizations`).then(res => {
//             if (res.status === 200) {
//                     setPendingCrList(res.data)
//                 setLoading(false)
//             }
//         })
//     }, [language,closecaseRender,render, organization])// eslint-disable-line



//     console.log(caseIds)
//     const childOrgtransfer = (id) =>{
//         let details = {
//             "orgId": orgId,
//             "caseId": caseIds,
//             "childOrgId": id
//           }
//           API.post(`organizations/cases:transfer`,details).then(res =>{
//               dispatch(setAlert({title:t('Success'),subtitle:t('Case transferred to the child org successfully'),type:'success',active:true}));
//               dispatch(setChildOrgRender(render+1));
//               onClose();
//           }).catch(e=>{
//               console.log(e)
//           }).finally(()=>onClose);
//     }


   

//     const colums = useMemo(() => {
//         return [
//             {
//                 name:  t('OrganizationName'), selector: 'organizationName', sortable: true,wrap: true},
//                 { name: t('Action'),button:true, cell: row =><div className={`flex items-center rounded-lg text-white px-3 py-1 bg-tcolor`} style={{cursor:'pointer'}} onClick={()=>childOrgtransfer(row.organizationId)}> <span className="mr-2 text-sm font-medium">{t("Select")}</span></div>, sortable: true, hide: 'sm' }
              
//         ].map(c => {
//             if (c.name !== undefined && columnsSelected.find(e => e.name === c.name)) c.omit = columnsSelected.find(e => e.name === c.name).active
//             return c
//         })
//     }, [activeQuickAction, columnsSelected, pendingCrList]) // eslint-disable-line
//     useLayoutEffect(() => {
//         setColumnsSelected(colums.filter(c => c.name !== undefined && c.optionalColumn !== false).map(c => ({ name: c.name, active: c.initialActive || false })))
//     }, []) // eslint-disable-line    
//     const SubHeader = useMemo(() => (
//         <div className="flex items-center relative">
//             <InlineTextField label={t("Search")} value={filter} setValue={setFilter} />
//         </div>
//     ), [filter, columnsSelected]) // eslint-disable-line
//     const filteredpendingCrList = useMemo(() => {
//         let filterSplit = filter.toString().toLowerCase().split(' ')
//         return pendingCrList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
//     }, [pendingCrList, filter])


//     const SubHeaderSub = useMemo(() => (
//         <div className="flex items-center relative">
//             <InlineTextField label={t("Search")} autoFocus={subFilter.toString().length > 0 ? true : false} value={subFilter} setValue={setSubFilter} />
//         </div>
//     ), [subFilter,followupList])//eslint-disable-line

//     const filteredFollowupList = useMemo(()=> {
//         let filterSplit = subFilter.toString().toLowerCase().split(' ')
//         return followupList.filter(c => subFilter === '' || subFilter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
//     },[followupList,subFilter])

//     const RowComponent = useCallback(row => {
//         return (
//             <div className="bg-bluegray-300 dark:bg-ldark py-2 px-3" key={row.data.caseId}>
//                 {
//                     Object.keys(row.data).map(c => (
//                         <div className="flex flex-col my-1">
//                             <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
//                             <span className="text-sm" >{row.data[c]}</span>
//                         </div>
//                     ))
//                 }
//             </div>
//         )
//     }, [])
 

//     // const fetchFollowupFor = (caseId) => {
//     //     setFetchingFollowupFor(caseId)
//     //     API.get(`cases/${caseId}/follow-ups`)
//     //         .then(res => {
//     //             if (res.data.status) {
//     //                 setPendingCrList(pendingCrList.map(c => {
//     //                     if (c.caseId === caseId) c.followupList = res.data.data
//     //                     return c
//     //                 }))
//     //             } else dispatch(setAlert({ title: t('Failed to get Assessment'), subtitle: res.data.errors.join(', '), active: true, type: 'error' }))
//     //         })
//     //         .catch(err => {
//     //             console.log(err)
//     //             dispatch(setAlert({ title: t('Failed to get Assessment'), subtitle: '', active: true, type: 'error' }))
//     //         }).finally(() => setFetchingFollowupFor(null))
//     // }

//     // const followdate =(value) =>{
//     //     let timestamp = new Date(value).getTime();
//     //     let tz = moment.tz.guess()
//     //     let tzabbr = moment.tz.zone(tz).abbr(timestamp)
//     //    return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
       
//     // }

 
   
//         // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,11)}, ${followupdate.toUTCString().slice(11,16)} ${value.slice(11,19)}`
    
//     //  const updatedate =(value) => {
//     //     let timestamp = new Date(value).getTime();
//     //     let tz = moment.tz.guess()
//     //     let tzabbr = moment.tz.zone(tz).abbr(timestamp)
//     //     let updatedates = new Date(value)
//     //     // updatedates = (updatedates.toUTCString().slice(4,21))
//     //     return `${updatedates.toUTCString().slice(8,11)}${updatedates.toUTCString().slice(4,7)}, ${updatedates.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
//     //  }

//      const transferCase = (id, caseIds) => {
//         let details ={
//             "newCareManagerId": id,
//             // "caseId": caseIds,
//             "orgId": organization
//         }
//         API.post(`cases:transfer`,details).then(res=>{
//             dispatch(setAlert({title:t('Success'),subtitle:t('Case Transferred Successfully'),type:'success',active:true}))
//             dispatch(setTransferCaseRender(render+1))
//             onClose();
//         }).catch(e=>{
//             console.log(e)
//         }).finally(()=>onClose)
//     }

//     const followupColumns = useMemo(() => {
//         return [
//             // { name: t('F ollow-Up Id'), center: true, selector: row=>row.rcfId, sortable: true },
//             { name: t('CareManager Name'), center: true, selector: row => `${row.firstName} ${row.lastName}`,sortable:true},
//             { name: t('Select'),button:true, cell: row =><div className={`flex items-center rounded-lg text-white px-3 py-1 bg-tcolor`} style={{cursor:'pointer'}} onClick={()=>transferCase(row.pplId)}> <span className="mr-2 text-sm font-medium">{t("Select")}</span></div>, sortable: true, hide: 'sm' }
//         ]
//     }, [activeQuickAction,followupFetchedList]) // eslint-disable-line
//     const rowExpanded = (data, row) => {
//         console.log(data, row)
//         setCurrentRow(row.organizationId)
//         setSubFilter('')
//             API.get(`organizations/${orgId}/care-managers`)
//                 .then(res => {
//                     if (res.status === 200) {
//                         setPendingCrList(pendingCrList.map(c => {
//                             console.log(c)
//                             if (c.caseId === row.caseId) c.followupList = res.data.body
//                             return c
//                         }))
//                     } else dispatch(setAlert({ title: t('Failed to get followup'), subtitle: res.data.errors.join(', '), active: true, type: 'error' }))
//                 })
//                 .catch(err => {
//                     console.log(err)
//                     dispatch(setAlert({ title: t('Failed to get followup'), subtitle: '', active: true, type: 'error' }))
//                 }).finally(() => setFetchingFollowupFor(null))
        
//     }

//     const NoFollowup = () => (
//         <div className="my-6 text-sm text-gray-500 dark:text-gray-400">
//             {t('No Follow-Up to display')}
//         </div>
//     )   
//     const ExpandComponent = useCallback(({ data }) => {
//         return (
//             <div className="p-4 bg-bluegray-300 dark:bg-ldark">
//                 {fetchingFollowupFor === data.caseId && <FieldLoader />}
//                 {data.followupList && setFollowupList(data.followupList)}
//                 <DataTable columns={followupColumns} data={filteredFollowupList} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader highlightOnHover persistTableHead
                
//                 selectableRowsHighlight  subHeader subHeaderComponent={SubHeaderSub} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<RowComponent />}  noDataComponent={<NoFollowup />}  />
//             </div>
//         )
//     }, [activeQuickAction, themeMode, fetchingFollowupFor,filteredFollowupList]) // eslint-disable-line

//     const openCloseCaseModal = (c) => {
//         if (c !== undefined)
//             setCaseId([c])
//         setCloseCaseModal(true)
//     }
//     const openReassignReferrerModal = (c) => {
//         if (c !== undefined)
//         setCaseId([c])
//         setReassignReferrerCaseModal(true)
//     }


//     return (
//         <div className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg relative" ref={caseTable}>
//             {
//                 loading ?
//                     <DatatableLoader /> :
//                     <DataTable title="pendingCrList" columns={colums} data={filteredpendingCrList} 
//                     theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader  
//                      selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} 
//                      paginationDefaultPage={page} paginationComponentOptions={{ rowsPerPageText: 'Items per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }} 
//                       expandableRowExpanded={(row) => (row.organizationId === currentRow)} expandableRowsComponent={<ExpandComponent />} onRowExpandToggled={rowExpanded}
//                      noDataComponent={t('There are no records to display')} />
//             }
//              <AnimatePresence>
//                 {
//                     selectRows.length > 0 &&
//                     <motion.button variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" className="flex items-center absolute bottom-6 ml-4 rounded text-white text-xs hover:bg-opacity-80" onClick={() => setOpenMultiAction(true)}>
//                         <FaLayerGroup size="18px" className="mr-1 text-black dark:text-white" />
//                         {selectRows.length > 0 &&
//                             <span className="absolute -top-3 left-3/4 bg-tcolor rounded-lg py-0.5 px-1.5 text-[9px]">{selectRows.length}</span>
//                         }
//                         <PopoverMenu title={`${t('Action')} (${selectRows.length})`} origin="topright" active={openMultiAction} onClose={() => setOpenMultiAction(false)}>
//                            {( selectRows[0].statusId !== 11) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openCloseCaseModal()}>
//                                 <RiInboxArchiveFill size="16px" className="mr-2" />
//                                 {t("Close Referrals")}
//                             </div> :''}
//                             {/* {selectRows[0].caseStatus !== 'PENDING_REFERRER' ?: ''} */}
                           
//                              {
//                                 ((userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && selectRows.every(i => i.referredByName !== null)) ||(userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && selectRows.every(i => i.referredByName !== null))) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openReassignReferrerModal()}>
//                                     <RiDirectionFill size="16px" className="mr-2" />
//                                     {t("Reassign Referrer")}
//                                 </div> : ''
//                             }
                           
//                         </PopoverMenu>
//                     </motion.button>
//                 }
//             </AnimatePresence>

// <Dialog title={t("Close Referrals")} showDialog={closeCaseModal} onClose={() => setCloseCaseModal(false)}>
//                 <ClosedCaseDialog onClose={() => setCloseCaseModal(false)} caseIds={caseId} />
//             </Dialog>
//             <Dialog title={t("Reassign Referrer")} showDialog={reassignreferrerCaseModal} onClose={() => setReassignReferrerCaseModal(false)}>
//                 <ReferrerCaseDialog onClose={() => setReassignReferrerCaseModal(false)} caseIds={caseId} />
//             </Dialog>
//         </div>
//     )
// }

export default TransferOrgs