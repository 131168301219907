import { AnimatePresence, motion, useDragControls } from "framer-motion"
import { wrapAnim, wrapPopAnim } from "../../commons/anims"
import { IoMdClose } from "react-icons/io"
import { VscChromeMaximize, VscChromeMinimize, VscChromeRestore} from "react-icons/vsc"
import { useEffect, useRef, useState } from "react"

const Dialognotes = ({ children, showDialog, title, onClose, injectClass='', isDraggableEditor = false, onResize, isMaximize }) => {
    const controls = useDragControls()
    const constraintsRef = useRef(null)
    const divRef = useRef(null);
    const [isMinimize, setIsMinimize] = useState(false);
    useEffect(() => {
        const handleResizeDiv = (entries) => {
            for (const entry of entries) {
                const element = document.querySelector('div.ql-toolbar')
                const qlEditor = document.querySelectorAll('div.ql-editor')
                if (element || qlEditor) {
                    qlEditor && qlEditor.forEach(e => {
                        if (!isMaximize) {
                            const ql_toolbar_height = element.clientHeight
                            const reduceHeight = ql_toolbar_height + 160;
                            e.style.minHeight = `calc(${entry.contentRect.height}px - ${reduceHeight}px)`
                            e.style.maxHeight = `calc(${entry.contentRect.height}px - ${reduceHeight}px)`

                        } else {
                            e.style.minHeight = `calc(${entry.contentRect.height}px - 200px)`
                            e.style.maxHeight = `calc(${entry.contentRect.height}px - 200px)`
                        }
                    })
                }
            }
        };

        if (isDraggableEditor && divRef.current) {
            const resizeObserver = new ResizeObserver(handleResizeDiv);
            resizeObserver.observe(divRef.current);

            return () => {
                resizeObserver.unobserve(divRef.current);
            };
        }
    }, [isDraggableEditor, isMaximize]);

    function startDrag(event) {
        controls.start(event, 0)
    }
    const close = () => {
        if(typeof onClose === 'function') onClose()
        setIsMinimize(false)
    }
    const resize = () => {
        if(typeof onResize === 'function') onResize()
        setIsMinimize(false)
    }
    const handleMinimize = () => {
        setIsMinimize(!isMinimize)
        if(!isMinimize && isMaximize){
            setIsMinimize(true)
            onResize()
        }
    }

    return (
        <AnimatePresence>
            {showDialog &&
            <>
                {isDraggableEditor && <div onPointerDown={startDrag} />}
                <motion.div variants={wrapAnim} initial="hidden" animate="visible" exit="hidden" className={`flex ${isMinimize ? 'items-end justify-start':'items-center justify-center'} fixed top-0 bottom-0 left-0 right-0 ${isDraggableEditor ? 'bg-transparent pointer-events-none' : 'bg-black'} text-gray-700 dark:text-white bg-opacity-60 z-[900]`} ref={constraintsRef} >
                    <motion.div variants={wrapPopAnim} className={`${isDraggableEditor && isMaximize || isMinimize ? 'no-transform':''} ${isDraggableEditor ? 'resize_div relative pointer-events-auto bg-bluegray-50' : ''} bg-bluegray-200 dark:bg-ldark px-4 pt-2 pb-4 min-w-[100px] min-h-[100px] rounded-lg shadow-xl flex flex-col max-h-[90vh] ${injectClass}`} 
                    {...(isDraggableEditor && {
                        drag: !isMaximize && !isMinimize ? true : false,
                        dragControls: controls,
                        dragConstraints: constraintsRef,
                        style:{
                            width: isMaximize ? '100vw' : (isMinimize ? '320px' : ''),
                            height: isMaximize ? '100vh' : (isMinimize ? '60px' : ''),
                            maxWidth: isMinimize ? '320px' : '',
                            minHeight: isMinimize ? '60px' : '',
                            maxHeight: isMaximize ? 'none' : '',
                            cursor: isMinimize || isMaximize ? '' : 'move',
                        }, 
                                        
                    })}
                    > 
                        <div
                            ref={divRef}
                            {
                            ...(isDraggableEditor && {
                                style: {
                                    marginRight: '-16px',
                                    marginBottom: '-16px',
                                    paddingRight: '20px',
                                    resize: isMaximize ? '': 'both',
                                    overflow: 'auto',
                                    maxWidth: isMaximize ? '' : isMinimize ? '320px': '900px',
                                    minHeight:'300px',
                                    minWidth:'300px',
                                    width:isMaximize ? '100%' : '',
                                    height:isMaximize ? '100%':'',
                                },
                                onPointerDownCapture: (e) => {
                                    e.stopPropagation()
                                },
                            })
                            }
                    >
                        <motion.div className={`flex justify-between items-center ${isDraggableEditor ? 'z-50' : ''}`}>
                            <span className="text-lg font-medium text-gray-600 dark:text-white">
                                {title}
                            </span>
                            <div className={`flex items-center ${isDraggableEditor ? 'z-50' : ''}`}>
                                {isDraggableEditor && 
                                (
                                    <>
                                        {!isMinimize ? <VscChromeMinimize size="20px" className="cursor-pointer mr-1" onClick={handleMinimize}/> :''}
                                        {isMaximize ? <VscChromeRestore size="20px" className="cursor-pointer" onClick={resize} /> : <VscChromeMaximize size="20px" className="cursor-pointer" onClick={resize} />}
                                    </>
                                )}
                                <IoMdClose size="20px" className="ml-1 cursor-pointer" onClick={close} />
                            </div>
                        </motion.div>
                        <div className="flex mt-4 overflow-auto">
                            {children}
                        </div>
                    </div>
                    </motion.div>
                </motion.div>
            </>
            }
        </AnimatePresence>
    )
}

export default Dialognotes