import { useState } from "react"
import Tab from "../../components/snippets/Tabs/Tab"
import TabGroup from "../../components/snippets/Tabs/TabGroup"
import TabButtonGroup from "../../components/snippets/Tabs/TabButtonGroup"
import TabButton from "../../components/snippets/Tabs/TabButton"
// import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'
import Userlist from "./Userlist"
import RemovedUserlist from "./Removedlist"



const UserInfo = () => {

    const [tab, setTab] = useState(0)
    // const userDetails = useSelector(state => state.auth.userDetails)
    const { t } = useTranslation()
    return (
        <div>
            <div className="text-center">
                <TabButtonGroup>
                    <TabButton index={0} value={tab} setValue={setTab}>
                       {t("User List")}
                    </TabButton>
                    <TabButton index={1} value={tab} setValue={setTab}>
                       {t("Removed User List")}
                    </TabButton>
                </TabButtonGroup>
            </div>
            <TabGroup value={tab}>
                <Tab index={0} value={tab}>
                    <Userlist />
                </Tab>
                <Tab index={1} value={tab}>
                    <RemovedUserlist />
                </Tab>
               
            </TabGroup>
        </div>
    )
}

export default UserInfo