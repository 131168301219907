// import Myproject from "../images/Myproject.png"
// import blackvip from "../images/blackvip.png"

const { useSelector } = require("react-redux")

const Empty = ({ size= '21px', size1='', injectClass, style }) => {

    const themeMode = useSelector(state => state.theme.themeMode)

    return (
        <div className={`${injectClass}`}>
        <img width={ size } height={ size1 } style={ style } src={themeMode === 'light' ? themeMode === 'dark' ? '' :  '' : '' } alt="" />
        </div>
    )
} 

export default Empty