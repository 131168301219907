
import axios from 'axios';
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { setAlert } from '../store/theme/actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
// import API from '../axios/API'
import * as Yup from 'yup';

import FieldInput from '../components/snippets/FieldInput'
const initialFormValues = {
    userName: ''
}
const ForgotPasswordModal = ({onClose}) => {

    const [dataLoaded] = useState(true)
    const [loading] = useState(false)
    const [edit] = useState(true)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    // useEffect(() => {
    //     API.get('menus/close-case-reasons').then(res => {
    //         setCloseReason(res.data.body.map(c => ({ text: c.name, value: c.value })));
    //         setDataLoaded(true)
    //     }).catch(e => {
    //         console.log(e)
    //     })
    // }, [])

    const profileFormSchema = Yup.object().shape({
        userName: Yup.string().required()
    })

    const profileForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            axios.post(`${process.env.REACT_APP_API_BASE_URL}users/${profileForm.values.userName}:forgot-password`).then(res=>{
                if(res.data.status === false){
                    dispatch(setAlert({title:'Error',subtitle:res.data.errorMessage,active:true,type:'error'}))
                    onClose()
                }else{
                    dispatch(setAlert({title:'Success',subtitle:'Success! Check Email to reset your password',active:true,type:'success'}))
                    onClose()
                }
            })
        }
    })

   

    return (
        <div className="py-2 w-full">
            <div className="px-4 mt-2">
            <FieldInput ariaLabel="Enter Username" loading={loading} type="text" label="Please Enter your Username" autoComplete='off' fieldMeta={profileForm.getFieldMeta('userName')} fieldProps={profileForm.getFieldProps('userName')} edit={edit} dataLoaded={dataLoaded}/>
            </div>
            <div className="flex justify-between mt-8 mx-2">
                <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t("Cancel")}</button>
                <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Reset Password")}</button>
            </div>
        </div>
    )

}

export default ForgotPasswordModal