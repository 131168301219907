const TabButton = ({children, index, value, setValue,id =''}) => {

    const handleClick = () => {
        if(typeof setValue === 'function') setValue(index)
    }

    return (
        <button className={`mx-2 py-0.5 font-medium rounded transition-all duration-150 ${index === value ? 'bg-tcolor text-black dark:bg-ldark dark:text-white px-4 text-sm' : 'text-gray-600 dark:text-gray-200 text-xs'} outline-none focus:outline-none`} onClick={handleClick} id = {id}>
            {children}
        </button>
    )
}

export default TabButton