const AlertsLoader = () => {
    return (
        <div>
            {
                [1,2,3,4].map(e => (
                    <div key={e} className="mt-3 w-full rounded-lg bg-gray-200 p-2 flex items-center">
                        <div className="h-10 w-12 rounded-full animate-pulse bg-gray-400"></div>
                        <div className="w-full ml-4">
                            <div className="h-3 w-full mt-3 rounded-lg animate-pulse bg-gray-400"></div>
                            <div className="h-3 w-1/2 mt-3 rounded-lg animate-pulse bg-gray-400"></div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default AlertsLoader