
import { motion } from "framer-motion"
import { TextAnimateY } from "../../commons/anims"
import Moment from 'react-moment';
import 'moment-timezone';
// import moment from 'moment';
import { useHistory } from 'react-router-dom';

const Reminder = ({ alert }) => {
    let history = useHistory();
    // const lastdat =(value)=>
    // {if(value !== null && value){
    //     let timestamp = new Date(value).getTime();
    //     let tz = moment.tz.guess()
    //     let tzabbr = moment.tz.zone(tz).abbr(timestamp)
    //          let followupdate = new Date(value)
    //      return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
    //      }else{
    //          return ''
    //      }
    //   }

      const caseDetails = (values)=>{
        setTimeout(()=>{
            if(document.getElementById("reminder")){
                document.getElementById("reminder").click();
            }
        },500)
        history.push(`/casedata/${values}?reminder=true`)
        }
    return (
        <motion.div variants={TextAnimateY} className={`group my-3 p-2 rounded-xl transition-all duration-100 cursor-pointer hover:ring-2 text-tBlack dark:text-gray-200 `}>
            <div className={`flex justify-between items-center mr-2`}>
                <div className="flex items-center">
                    {/* <div className="group-hover:animate-bounce">
                    <FaExclamationTriangle size="25px" />
                    </div> */}
                    <div className="ml-3">
                        <div> <span className="font-bold text-sm hover:underline decoration-2" onClick={()=>caseDetails(alert.id)}>{alert.id}</span></div>
                        <div style={{ overflow: 'hidden' ,maxHeight:'65px' }}  className="text-red-700 dark:text-red-300 text-xs"><p>{alert.status}</p></div>
                    </div>
                </div>
                <div className="text-xs font-bold">
                 <Moment format="MMM D,YYYY">{alert.time}</Moment>
                </div>
            </div>
        </motion.div>
    )
}

export default Reminder