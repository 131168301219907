import React from "react";
import Demographic from "../../components/snippets/Assessment/Demographic";
import ScoreSummary from "./ScoreSummary";



const Score = ({match}) => {
    // const quesWrap = useRef(null)
    return (
        <div className="h-full">
            <div className="flex h-full">
                <Demographic caseId={match.params.caseId} />
                <div className="flex-1">
                    <ScoreSummary assessmentId={match.params.assessmentId} caseId={match.params.caseId} />
                </div>
            </div>
        </div>
    )
}

export default Score