import { MdAccountBox } from 'react-icons/md'
import { FaUserFriends, FaBuffer, FaVoteYea, FaUserMinus, FaUserShield, FaCloudUploadAlt,FaCreativeCommons } from 'react-icons/fa'
import {FcTodoList} from 'react-icons/fc'
import { ImUserPlus } from 'react-icons/im'
import { CgList, CgOrganisation } from 'react-icons/cg'
import { ImHome,ImStack } from 'react-icons/im'
import { CaregiverRoutes } from './CaregiverRoutes'
import { CarereceiverRoutes } from './CarereceiverRoutes'
import CaseList from '../../pages/CaseDetails/CaseList'
import UnassignedCase from '../../pages/CaseDetails/UnassignedCase'
import WatchList from '../../pages/CaseDetails/WatchList'
import Assessment from '../../pages/Assessment/Assessment'
import Profile from '../../pages/Profile/Profile'
import CaseData from '../../pages/CaseDetails/CaseData'
import Dashboard from '../../pages/Dashboard/Dashboard'
import Addorganization from '../../pages/Profile/Addorganization'
import EditOrganization from '../../pages/Profile/EditOrganization'
import Adduser from '../../pages/Profile/Adduser'
import Organizationlist from '../../pages/Profile/Organization'
import Associatedorgs from '../../pages/Profile/Associatedorgs'
import Adminlist from '../../pages/Profile/Adminlist'
import EditUser from '../../pages/Profile/EditUser'
import UploadsFile from '../../pages/Profile/Uploads'
import RemovedAdminList from '../../pages/Profile/RemovedAdminList'
import Summary from '../../pages/Summary/summary'
import Careplan from '../../pages/Careplan/Careplan'
import ReleaseNotes from '../../pages/Reports&Release/ReleaseNotes'
import PendingCarereceivers from '../../pages/CaseDetails/PendingCarereceivers'
import CreateCase from '../../pages/Case/CreateCase'
import Score from '../../pages/ScreenerScore/Score'
import SummaryReadonly from '../../pages/ReadOnly/Summary/SummaryReadonly'
import ResourceUtilization from '../../pages/Resource/ResourceUtilization'
import AssessmentData from '../../pages/ReadOnly/Assessment/AssessmentData'
import careplan from '../../pages/ReadOnly/Careplan/Careplan'
import AddCaseDemographic from '../../pages/Case/AddCaseDemographic'
import NewCase from '../../pages/Case/NewCase'
import TodoList from '../../pages/CaseDetails/TodoCases'
import ClosedCase from '../../pages/CaseDetails/ClosedCase'
import Clienlist from '../../pages/Client/Client'
import ClientEdit from '../../pages/Client/Client-Edit'
import UserInfo from '../../pages/Profile/UserInfo'
import {AiFillFileAdd} from 'react-icons/ai'
import { FaUserPlus } from 'react-icons/fa'
import ScoreReadonly from '../../pages/ReadOnly/Score/ScoreReadonly'
import { SiSwagger } from 'react-icons/si'
import Swagger from '../../pages/Swagger/Swagger'
import { SubscriberSearch } from '../../pages/SubscriberDetails/SubscriberSearch'
import { CarereceiverList } from '../../pages/Carereceiver/CarereceiverList'
import AddServiceUsage from '../../pages/Case/AddServiceUsage'
import { BsFileEarmarkPlusFill } from "react-icons/bs";
export const DashRoutes = [
    {
        title: 'Dashboard',
        ItemIcon: ImHome,
        link: '/',
        component: Dashboard
    },
    {
        title: 'New Case',
        link: '/addCase',
        component: NewCase
    },
    {
        title: 'Release Notes',
        link: '/releasenotes',
        component:ReleaseNotes
    },
    
]

export const ReferralRoutes =[
    {
        title:'Create Referral',
        ItemIcon:AiFillFileAdd,
        link:'/referrer',
        menu: 'Add New',
        component:AddCaseDemographic,
        roles:['referrer','caremanager,referrer','dynamic_ref']
    },
    {
        title: 'Enroll Caregiver',
        ItemIcon:FaUserPlus,
        link: '/addCase',
        menu: 'Add New',
        component: NewCase,
        roles:['caremanager','dynamic_cm']
    },
    {
        title: 'Add Service Usage Data',
        ItemIcon:BsFileEarmarkPlusFill,
        link: '/addServiceUsage',
        menu: 'Add New',
        component: AddServiceUsage,
        roles:['caremanager','dynamic_cm','admin','dynamic_admin']
    },
]

export const CaseRoutes = [
    {
        title:'To Do',
        ItemIcon: FcTodoList,
        link:'/todo',
        menu : 'Cases',
        component:TodoList,
        roles : ['caremanager','screener','dynamic_cm']
    },
    {
        title:'Pending Referrals',
        ItemIcon: ImStack,
        link:'/pendingRefferals',
        menu : 'Cases',
        component:PendingCarereceivers,
        roles : ['caremanager','referrer','admin','dynamic_cm','dynamic_admin','dynamic_ref']
    },
    {
        title: 'Case Details',
        link: '/casedata/:caselist_id',
        component: CaseData,
    },
    {
        title: 'Unassigned Cases',
        ItemIcon: FaBuffer,
        link: '/unassigned_list',
        menu: 'Cases',
        component: UnassignedCase,
        roles: ['admin' ,'admin,caremanager','caremanager','screener','dynamic_admin','dynamic_cm']
    },
    {
        title: 'Watch List',
        ItemIcon: FaVoteYea,
        link: '/watch_list',
        menu: 'Cases',
        component: WatchList,
        roles: ['admin' ,'admin,caremanager','caremanager','screener','dynamic_admin','dynamic_cm']
    },
    {
        title: 'All Cases',
        ItemIcon: CgList,
        link: '/case_list',
        menu: 'Cases',
        component: CaseList,
        roles: ['admin' ,'admin,caremanager','caremanager','screener','referrer']
    },
    {
        title: 'Closed Cases',
        ItemIcon: FaCreativeCommons,
        link: '/closed_list',
        menu: 'Cases',
        component: ClosedCase,
        roles: ['admin' ,'admin,caremanager','caremanager','screener']
    },
    {
        title: 'Clients',
        ItemIcon: FaUserFriends,
        link: '/client',
        menu: 'Cases',
        component: Clienlist,
        roles:['caremanager','admin,caremanager','screener','admin','referrer'],
        saveData: true,
        multiWindow: true
    },
    {
        title: 'Subscriber ID Details',
        ItemIcon: ImUserPlus,
        link: '/subscriberId',
        menu: 'Cases',
        component: SubscriberSearch,
        roles: ['screener','referrer','dynamic_scr','dynamic_ref']
    },
    {
        title: 'Care Receivers',
        ItemIcon: FaUserFriends,
        link: '/careReceivers',
        menu: 'Cases',
        component: CarereceiverList,
        roles: ['screener','referrer','dynamic_scr','dynamic_ref']
    },
    
]

// export const ReportsRoutes =[
//     {
//         title: 'Help&Support',
//         ItemIcon: MdHelpOutline,
//         menu: 'Info',
//         link: '/releasenot',
//         component: ReleaseNotes,
//         saveData: true,
//         multiWindow: false
//     },
//     {
//         title: 'Release Notes',
//         ItemIcon: MdSpeakerNotes,
//         menu: 'Info',
//         link: '/releasenotes',
//         component: ReleaseNotes,
//         saveData: true,
//         multiWindow: false
//     },
//     {
//         title: 'Child Org Report',
//         ItemIcon: MdReport,
//         menu: 'Info',
//         link: '/releasenote',
//         component: ReleaseNotes,
//         saveData: true,
//         multiWindow: false
//     },

// ]

export const SettingsRoutes = [
    {
        title: 'My Profile',
        ItemIcon: MdAccountBox,
        link: '/my-profile',
        menu: 'Details',
        component: Profile,
        saveData: true,
        multiWindow: false
    },
    // {
    //     title: 'Clients',
    //     ItemIcon: FaUserFriends,
    //     link: '/client',
    //     menu: 'Details',
    //     component: Clienlist,
    //     roles:['caremanager','admin,caremanager','screener','admin'],
    //     saveData: true,
    //     multiWindow: true
    // },
    // {
    //     title: 'Caregivers List',
    //     ItemIcon: FaUserFriends,
    //     link: '/caregivers',
    //     menu: 'Details',
    //     component: Caregivers,
    //     roles:['caremanager','admin,caremanager','screener'],
    //     saveData: true,
    //     multiWindow: true
    // },
    // {
    //     title: 'Carereceiver List',
    //     ItemIcon: FaUserFriends,
    //     link: '/carereceiver',
    //     menu: 'Details',
    //     component: Carereceiver,
    //     roles:['caremanager','admin,caremanager','screener'],
    //     saveData: true,
    //     multiWindow: true
    // },
    {
        title: 'Add New Organization',
        link: '/addneworganization',
        component: Addorganization
    },
    {
        title: 'Modify Organization',
        link: '/editOrganization/:orgId',
        component: EditOrganization
    }, {
        title: 'Add New User',
        link: '/addnewuser',
        component: Adduser
    }, {
        title: 'Modify User',
        link: '/editUser/:userId',
        component: EditUser,
        roles: ['superadmin']
    },
    {
        title: 'User List',
        ItemIcon: FaUserFriends,
        link: '/userList',
        component: UserInfo,
        menu: 'Details',
        roles: ['admin','dynamic_admin']
    },
    // {
    //     title: 'Removed User List',
    //     ItemIcon: FaUserMinus,
    //     link: '/removedUser',
    //     component: RemovedUserlist,
    //     menu: 'Details',
    //     roles: ['admin']
    // },
    {
        title: 'Organization Details',
        ItemIcon: CgOrganisation,
        link: '/editOrganization',
        component: EditOrganization,
        menu: 'Details',
        roles: ['admin','dynamic_admin']
    },
    {
        title: 'Admin List',
        ItemIcon: FaUserShield,
        link: '/adminList',
        component: Adminlist,
        menu: 'Details',
        roles: ['superadmin']
    },
    {
        title: 'Removed Admin List',
        ItemIcon: FaUserMinus,
        link : '/removedAdmin',
        component:RemovedAdminList,
        menu:'Details',
        roles:['superadmin']
    },
    {
        title: 'Organization List',
        ItemIcon: CgOrganisation,
        link: '/organization',
        component: Organizationlist,
        menu: 'Details',
        roles: ['superadmin']
    },
    {
        title: 'Uploads',
        ItemIcon: FaCloudUploadAlt,
        link: '/uploads',
        component: UploadsFile,
        menu: 'Details',
        roles: ['admin','dynamic_admin']
    },
    {
        title: 'Associated Orgs',
        ItemIcon: CgOrganisation,
        link: '/associatedOrgs',
        component: Associatedorgs,
        menu: 'Details',
        roles: ['admin','dynamic_admin']
    },
    {
        title:'Assessment',
        link : '/case/:caseId/assessment/:assessmentId',
        component:Assessment
    },
    {
        title:'Assessment',
        link : '/case/:caseId/readonly-assessment/:assessmentId',
        component:AssessmentData
    },
    {
        title:'Summary',
        link : '/case/:caseId/summary/:assessmentId',
        component:Summary
    },
    {
        title:'Summary Results',
        link : '/case/:caseId/readonly-summary/:assessmentId',
        component:SummaryReadonly
    },
    {
        title:'Score',
        link : '/case/:caseId/score/:assessmentId',
        component:Score
    },
    {
        title:'Score Results',
        link : '/case/:caseId/readonly-score/:assessmentId',
        component:ScoreReadonly
    },
    {
        title:'Care Plan',
        link:'/case/:caseId/assessment/:assessmentId/careplan/:careplanId/:queue',
        component:Careplan,
    },
    {
        title:'Referral',
        link:'/createCase/:caseId/carereceiver/:careReceiverId/caregiver/:careGiverId/:careManagerId',
        component:CreateCase
    },
    {
        title:'Resource Utilization',
        link:'/case/:caseId/resourceUtlization',
        component:ResourceUtilization
    },
    {
        title:'Care Plan',
        link:'/case/:caseId/assessment/:assessmentId/readonly-careplan/:careplanId/:queue',
        component:careplan,
    },
    {
        title:'New Referral',
        link:'/referrer',
        component:AddCaseDemographic
    },
    {
        title: 'Client',
        link: '/client/:carereceiver_id',
        component: ClientEdit,
    }
]

const SwaggerRoutes = [
    {
        title: 'Swagger Docs',
        ItemIcon: SiSwagger,
        link: '/swagger',
        menu: 'Swagger',
        component: Swagger,
        saveData: false,
        multiWindow: false
    }
]

export const CombinedRoutes = [...CaseRoutes, ...SettingsRoutes, ...DashRoutes,...ReferralRoutes,...CaregiverRoutes, ...CarereceiverRoutes, ...SwaggerRoutes]