import { withRouter } from "react-router"
import FieldInput from '../../components/snippets/FieldInput'
import FieldSelect from "../../components/snippets/FieldSelect"
import * as Yup from 'yup'
import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import DemographicLoader from '../Case/DemographicLoader'
import axios from "axios"
import Button from '../../components/snippets/Button'
import LogoImg from '../../components/LogoImg';
import { motion } from 'framer-motion'
import { TextAnimateXRev } from '../../commons/anims'
import DarkModeSwitch from "../../components/snippets/DarkModeSwitch"
import FieldMultiSelect from '../../components/snippets/FieldMultiSelect'
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next"

const initialFormValues = {
    caregiver: {
        language: '',
        firstName: '',
        lastName: '',
        preferredName: '',
        phoneNumber: '',
        email: '',
        dateOfBirth: '',
        address: {
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            city: '',
            postalCode: '',
            state: ''
        },
        gender: '',
        race: []
    },
    // careReceiver: {
    //     firstName: '',
    //     lastName: '',
    //     dateOfBirth: '',
    //     relationshipId: '',
    //     address: {
    //         addressLine1: '',
    //         addressLine2: '',
    //         addressLine3: '',
    //         city: '',
    //         postalCode: '',
    //         state: ''
    //     },
    //     subscriberId: ''
    // }
}



const Screener = () => {

    const [loading] = useState(false)
    const [formLoading] = useState(false)
    const [dataLoaded] = useState(true)
    const [states, setStates] = useState([])
    const [gender, setGender] = useState([])
    const [raceEthnicity, setRaceEthnicity] = useState([])
    // const [languages, setLanguage] = useState([])
    // const [relationShip, setRelationship] = useState([])
    const history = useHistory()
    const {t} = useTranslation()
    let lang = localStorage.getItem('language')
    const screenerFormSchema = Yup.object().shape({
        caregiver: Yup.object({
            language: Yup.string(),
            firstName: Yup.string().nullable().max(50).required("FirstName is required field"),
            lastName: Yup.string().nullable().max(50).required("LastName is required field"),
            preferredName: Yup.string(),
            phoneNumber: Yup.string().required("Phone number is required field"),
            email: Yup.string(),
            dateOfBirth: Yup.date(),
            address: Yup.object({
                addressLine1: Yup.string(),
                addressLine2: Yup.string(),
                addressLine3: Yup.string(),
                city: Yup.string(),
                postalCode: Yup.string(),
                state: Yup.string()
            }),
            gender: Yup.string(),
            race: Yup.array()
        }),
        // careReceiver: Yup.object({
        //     firstName: Yup.string().required("FirstName is the required field"),
        //     lastName: Yup.string().required("Lastname is required field"),
        //     dateOfBirth: Yup.string().required("Date of birth is required field"),
        //     relationshipId: Yup.string().required("Relationship is required field"),
        //     address: Yup.object({ postalCode: Yup.string().required("PostalCode is required field") }),
        //     subscriberId: Yup.string().required("Subscriber id is a required field")
        // })
    })


    const screenerForm = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: screenerFormSchema,
        onSubmit: values => {
            createScreenerCase()
        }
    })

    useEffect(() => {
        axios.all([axios.get(`${process.env.REACT_APP_API_BASE_URL}menus/states?lang=${lang}`), axios.get(`${process.env.REACT_APP_API_BASE_URL}menus/genders?lang=${lang}`), axios.get(`${process.env.REACT_APP_API_BASE_URL}menus/races?lang=${lang}`), axios.get(`${process.env.REACT_APP_API_BASE_URL}menus/languages?lang=${lang}`), axios.get(`${process.env.REACT_APP_API_BASE_URL}menus/relationships?lang=${lang}`)]).then(axios.spread((statesResp, gendersResp,
            raceResp, languageResp, relationshipResp) => {
            setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
            // setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key })))
            // setRelationship(relationshipResp.data.body.map(c => ({ text: c.name, value: c.value })))
        }))
    }, [])

    const createScreenerCase = () => {
        if (screenerForm.values.caregiver.dateOfBirth) {
            let date = screenerForm.values.caregiver.dateOfBirth.split('/')
            screenerForm.values.caregiver.dobDay = parseInt(date[2])
            screenerForm.values.caregiver.dobMonth = parseInt(date[1])
            screenerForm.values.caregiver.dobYear = parseInt(date[0])
        }
        // if (screenerForm.values.careReceiver.dateOfBirth) {
        //     let date = screenerForm.values.careReceiver.dateOfBirth.split('/')
        //     screenerForm.values.careReceiver.dobDay = parseInt(date[2])
        //     screenerForm.values.careReceiver.dobMonth = parseInt(date[1])
        //     screenerForm.values.careReceiver.dobYear = parseInt(date[0])
        // }
        let details = {
            caregiver: screenerForm.values.caregiver,
            // organizationId:10065 //sandbox
            organizationId:2 
            // careReceiver: [screenerForm.values.careReceiver]
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}intake`, details).then(res => {
            if (res.status === 200) {
                // history.push(`/screener/intake/assessment/${res.data.body.assessmentId}/case/${res.data.body.caseId}`)
                history.push({ pathname: '/screener/intake', state: { assessmentId: res.data.body.assessmentId, caseId: res.data.body.caseId } })
            }
        })
    }
    return (
        <div className="min-h-screen flex justify-center items-center flex-col bg-blue-50 dark:bg-ddark overflow-hidden">
            <motion.div className="fixed left-8 top-5" variants={TextAnimateXRev} initial="hidden" animate="visible">
                <LogoImg size="200px" />
            </motion.div>
            <motion.div className="fixed right-8 top-5" variants={TextAnimateXRev} initial="hidden" animate="visible">
                <DarkModeSwitch />
            </motion.div>
            {
                loading ?
                    <DemographicLoader />
                    :
                    <>
                        <div className="text-black dark:text-white text-left">{t('Caregiver')} {t('Information')}</div>
                        <div className="text-black dark:text-white text-left mt-8 grid grid-cols-2 2xl:grid-cols-2 gap-4">
                            {/* <FieldSelect loading={formLoading} label={'Language'} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.language')} fieldProps={screenerForm.getFieldProps('caregiver.language')} fieldHelper={screenerForm.getFieldHelpers('caregiver.language')} options={languages} edit={true} dataLoaded={dataLoaded} />
                            <div></div> */}
                            <FieldInput loading={formLoading} label={t('First Name')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.firstName')} fieldProps={screenerForm.getFieldProps('caregiver.firstName')} fieldHelper={screenerForm.getFieldHelpers('caregiver.firstName')} edit={true} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} label={t('Last Name')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.lastName')} fieldProps={screenerForm.getFieldProps('caregiver.lastName')} fieldHelper={screenerForm.getFieldHelpers('caregiver.lastName')} edit={true} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} label={t('Preferred Name')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.preferredName')} fieldProps={screenerForm.getFieldProps('caregiver.preferredName')} fieldHelper={screenerForm.getFieldHelpers('caregiver.preferredName')} edit={true} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} type="phone" label={t('Phone Number')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.phoneNumber')} fieldProps={screenerForm.getFieldProps('caregiver.phoneNumber')} fieldHelper={screenerForm.getFieldHelpers('caregiver.phoneNumber')} edit={true} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} label={t('Email')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.email')} fieldProps={screenerForm.getFieldProps('caregiver.email')} fieldHelper={screenerForm.getFieldHelpers('caregiver.email')} edit={true} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} type="date" maxDate={new Date()} label={t('Date Of Birth')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.dateOfBirth')} fieldProps={screenerForm.getFieldProps('caregiver.dateOfBirth')} fieldHelper={screenerForm.getFieldHelpers('caregiver.dateOfBirth')} edit={true} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} label={t('Address')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.address.addressLine1')} fieldProps={screenerForm.getFieldProps('caregiver.address.addressLine1')} fieldHelper={screenerForm.getFieldHelpers('caregiver.address.addressLine1')} edit={true} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} label={t('City')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.address.city')} fieldProps={screenerForm.getFieldProps('caregiver.address.city')} fieldHelper={screenerForm.getFieldHelpers('caregiver.address.city')} edit={true} dataLoaded={dataLoaded} />
                            <FieldSelect loading={formLoading} label={t('State')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.address.state')} fieldProps={screenerForm.getFieldProps('caregiver.address.state')} fieldHelper={screenerForm.getFieldHelpers('caregiver.address.state')} options={states} edit={true} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} label={t('Zip')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.address.postalCode')} fieldProps={screenerForm.getFieldProps('caregiver.address.postalCode')} fieldHelper={screenerForm.getFieldHelpers('caregiver.address.postalCode')} edit={true} dataLoaded={dataLoaded} ml={true} length={'5'}/>
                            <FieldSelect loading={formLoading} label={t('Gender')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.gender')} fieldProps={screenerForm.getFieldProps('caregiver.gender')} fieldHelper={screenerForm.getFieldHelpers('caregiver.gender')} options={gender} edit={true} dataLoaded={dataLoaded} />
                            <FieldMultiSelect loading={formLoading} label={t('Race/Ethnicity')} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('caregiver.race')} fieldProps={screenerForm.getFieldProps('caregiver.race')} fieldHelper={screenerForm.getFieldHelpers('caregiver.race')} options={raceEthnicity} edit={true} dataLoaded={dataLoaded} />
                        </div>
                        <div className="text-center">
                            <Button injectClass="mt-10 py-1 py-2" onClick={() => screenerForm.submitForm()}>{t("Submit")}</Button>
                        </div>
                    </>
            }
        </div>
    )
}

export default withRouter(Screener)