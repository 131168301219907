import { useState } from 'react'
import { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'
import FieldInput from "../../components/snippets/FieldInput"
import { useFormik } from "formik"
import * as Yup from 'yup'
import API from '../../axios/API'
import { setAlert } from '../../store/theme/actions'
import { useTranslation } from "react-i18next"

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const initialFormValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    followup: '',
    notes: ''
}

Yup.addMethod(Yup.string, 'validateName', function () {
    return this.test('onlyAlphabet and numbers are allowed', 'Only Alphabets and numbers allowed', (value) => {
        if (value === undefined || value === '') return true
        let r = /^[^-\s]/g
        return r.test(value)
    })
})

Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
    return this.test('validPhone', "Not a Valid Phone", (value) => {
        if (value === undefined || value === '') return true
        return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
    })
})

Yup.addMethod(Yup.string, 'validateEmail', function () {
    return this.test('validEmail', "Not valid", (value) => {
        if (value === undefined || value === '') return true
        let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
        return r.test(value)
    })
})
const AddReferral = ({ onClose }) => {
    const [formLoading, setFormLoading] = useState(false)
    const [editForm] = useState(true)
    const [dataLoaded] = useState(true)
    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.auth.userDetails)
    const organization = useSelector(state => state.auth.organization)
    const {t} = useTranslation()


    const profileFormSchema = Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required("Firstname is required"),
        lastName: Yup.string().nullable().max(50).required("Lastname is required"),
        phoneNumber: Yup.string().validatePhoneLength().required("Phone number is required"),
        email: Yup.string().validateEmail().required("Email is required"),
        notes: Yup.string(),
        followup: Yup.string()
    })

    const profileForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            submitForm()
            setFormLoading(false)
        }
    })


    const submitForm = () => {
        let split = profileForm.values.followup.split(' ')
        let dateSplit = split[0].split('/')
        let timeSplit = split[1].split(':')
        let date = dateSplit[2]
        let month = dateSplit[1]
        let year = dateSplit[0]
        let hour = timeSplit[0]
        let minute = timeSplit[1]
        let concatStr = year+'-'+month+'-'+date+'T'+hour+':'+minute+':00Z'
        let details = {
            careReceiver:
            {
                address: {
                    state: '',
                    addressLine1: '',
                    addressLine2: '',
                    city: '',
                    postalCode: ''
                },
                personId: userDetails.caremanagerId,
                firstName: profileForm.values.firstName,
                lastName: profileForm.values.lastName,
                emailAddress: profileForm.values.email,
                phone: profileForm.values.phoneNumber,
                followup: concatStr,
                relationshipId: 2
            },
            referrerCaseFollowup:
            {
            notes:profileForm.values.notes,
            followupDate: concatStr,
            },
            organizationId: organization
        }
        API.post(`referrals`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: 'Success', subtitle: 'Carereceiver added successfully', type: 'success', active: true }))
                onClose()
            } else {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something Went Wrong', type: 'error', active: true }))
                onClose()
            }
        }).catch(err => {
            dispatch(setAlert({ title: 'Error', subtitle: 'Something Went Wrong', type: 'error', active: true }))
            onClose()
        })
    }

    return (
        <div className="p-2 w-full text-left">
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-2">
                <FieldInput loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('firstName')} fieldProps={profileForm.getFieldProps('firstName')} edit={editForm} dataLoaded={dataLoaded} />
                <FieldInput loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('lastName')} fieldProps={profileForm.getFieldProps('lastName')} edit={editForm} dataLoaded={dataLoaded} />
                <FieldInput loading={formLoading} type={t("phone")} label="Phone Number" autoComplete='off' fieldMeta={profileForm.getFieldMeta('phoneNumber')} fieldProps={profileForm.getFieldProps('phoneNumber')} edit={editForm} dataLoaded={dataLoaded} />
                <FieldInput loading={formLoading} label={t("Email")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('email')} fieldProps={profileForm.getFieldProps('email')} edit={editForm} dataLoaded={dataLoaded} />
                <FieldInput type="datetime" loading={false} label={t('Followup Date')} autoComplete='off' fieldMeta={profileForm.getFieldMeta('followup')} fieldProps={profileForm.getFieldProps('followup')} fieldHelper={profileForm.getFieldHelpers('followup')} edit={editForm} dataLoaded={dataLoaded} />
            </div>
            <div className="mt-2">
                <FieldInput type="textarea" label={t("Type a notes")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('notes')} fieldProps={profileForm.getFieldProps('notes')} edit={true} dataLoaded={dataLoaded} />
            </div>
            <div className="flex justify-between mt-8 mx-2">
                <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t("Cancel")}</button>
                <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Save")}</button>
            </div>
        </div>
    )
}

export default AddReferral