import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { MdCheck } from 'react-icons/md'
import { BsBuilding } from 'react-icons/bs'
import { AnimatePresence, motion } from 'framer-motion'
import { setOrganization } from '../store/auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import API from  '../axios/API'
import { useHistory } from 'react-router'
import { useTranslation } from "react-i18next"

const OrganizationSelector = ({injectPopupClass, originX, originY, onFocus, selectCallBack, closeOrg}) => {

    const [showSelector, setShowSelector] = useState(false)
    const { organization, isLoggedIn,userDetails } = useSelector(state => state.auth)
    const [organizations, setOrganizations] = useState([])
    const [filter, setFilter] = useState('')
    const dispatch = useDispatch()
    const hideShowSelector = () => setShowSelector(false)
    const initialOrg = JSON.parse(localStorage.getItem('userDetails'))
    const history = useHistory()
    const {t} = useTranslation()
    useEffect(()=>{
        if(isLoggedIn) {
            API.get(`organizations/${userDetails.orgId}/organizations`).then(res=>{
                if(res.status === 200){
                    setOrganizations(res.data.map(c=>({orgId:c.organizationId,title:c.organizationName,code:c.organizationName.substring(0,2).toLowerCase()})))
                }
            })
        }
    }, [isLoggedIn]) // eslint-disable-line
    const menuAnim = useMemo(() => ({
        hidden: {
            scale: 0,
            opacity: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            originX: originX,
            originY: originY
        }
    }), [originX, originY]) // eslint-disable-line
    const orgCode = useMemo(() => {
        let l = organizations.filter(c => c.orgId === parseInt(organization))
        return l.length > 0 ? l[0].code : initialOrg ? initialOrg.orgName.substring(0,2).toLowerCase() :''
    }, [organization,organizations]) // eslint-disable-line
    useLayoutEffect(() => {
        if(showSelector) {
            setTimeout(() => {
                window.addEventListener('click', hideShowSelector)
            }, 100)
        } else window.removeEventListener('click', hideShowSelector)

        return () => window.removeEventListener('click', hideShowSelector)
    }, [showSelector]) // eslint-disable-line
    const filteredOrgs = useMemo(() => {
        return organizations.filter(c => filter.trim() === '' || c.title.toLowerCase().includes(filter.toLowerCase())).sort((a, b) => a.title - b.title)
    }, [organizations, filter])

    const changeOrganization = (value)=>{
        dispatch(setOrganization(value))
        localStorage.setItem('orgid',value)
        history.push('/')
        setShowSelector(false)
    }
    const accessibilityFixs = (data) => {
        changeOrganization(data);
        setShowSelector(false)
    }

    useEffect(()=>{
        if(showSelector){
            if(typeof selectCallBack == 'function') selectCallBack('organization')
        }
    },[showSelector])

    useEffect(()=>{
        if(closeOrg){
            setShowSelector(false)
        }
    },[closeOrg])
    return (
        <div className="relative">
            <div className="cursor-pointer" tabIndex="0" onBlur={() => {!showSelector && setShowSelector(false)}} onKeyDown={(event) => {event.key === 'Enter' && setShowSelector(!showSelector)}} onClick={() => setShowSelector(true)} onFocus={onFocus} title={t('Select Organization')}>
                <span className="absolute text-[9px] leading-none -top-3 -right-2.5 bg-tcolor text-black px-1 py-1 rounded-full">{orgCode}</span>
                <BsBuilding size="20px" className="text-gray-800 dark:text-white" />
            </div>
            <AnimatePresence>
                { showSelector &&
                    <motion.div variants={ menuAnim } initial="hidden" animate="visible" exit="hidden" className={`absolute bg-bluegray-500 dark:bg-ldark py-2 w-48 rounded-lg text-left ${injectPopupClass}`} onClick={ev => ev.stopPropagation()}>
                        <div className="text-xs text-gray-200 pl-2">{t("Suborganization")}</div>
                        <div className={`relative ring-1 focus-within:ring-2 ring-gray-300 dark:ring-gray-600 focus-within:ring-tcolor dark:focus-within:ring-tcolor px-2 py-0 rounded hover:cursor-text flex items-center mx-2 mt-2`}>
                            <input onChange={ev => setFilter(ev.target.value)} placeholder={t("Filter")} className="w-[80%] text-sm outline-none flex-grow text-white font-medium rounded px-1  bg-transparent" />
                        </div>
                        <div className="pt-2 max-h-[180px] overflow-auto">
                            {
                                filteredOrgs.map(c => (
                                    <div key={c.orgId} tabIndex="0" className="py-0.5 pl-4 pr-2 hover:bg-bluegray-600 text-white text-sm cursor-pointer flex items-center justify-between"  onKeyDown={(event) =>  {event.key === 'Enter' && accessibilityFixs(c.orgId)}} onClick={() => changeOrganization(c.orgId)} onFocus={onFocus}>
                                        <span className="text-[10px]" >{c.title}</span>
                                        { parseInt(organization) === c.orgId && <MdCheck className="text-green-300 flex-shrink-0" /> }
                                    </div>
                                ))
                            }
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
            {/* <Dialog title='Select Organization' showDialog={showSelector} onClose={()=>setShowSelector(false)}>
                <OrganizationSelectorDialog onClose={()=>setShowSelector(false)}/>
            </Dialog> */}
        </div>
    )
}

export default OrganizationSelector