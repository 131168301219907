import { FormikProvider, useFormik } from "formik"
import API from '../../axios/API'
import {setAlert} from '../../store/theme/actions'
import FieldInput from "../../components/snippets/FieldInput"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from 'yup'
import { useState, useMemo } from "react"
import { setNotesAction } from "../../store/auth/actions"
import { useTranslation } from "react-i18next"
import { QuillEditor } from "../../components/snippets/QuillEditor"
import FieldDate from "../../components/snippets/FieldDate"

const initialFormValues = {
    description : '',
    date:''
}


const DemoNotes = ({ onClose, caseId, type, isMaximize = false, isDraggableEditor = false }) =>{
    const dispatch = useDispatch()
    const [dataLoaded] = useState(true)
    const notesAction= useSelector(state =>state.auth.notesAction)
    const [initialData,setInitialData] = useState()
    const [saveNote, setSaveNote] = useState(false)
    const {t} = useTranslation()
    const userDetails = useSelector(state=>state.auth.userDetails)
    let orgId = localStorage.getItem('orgid')
    const formData = useMemo(()=>{
        if(type === 'update'){
            setInitialData(caseId.notes)
            return ({...initialData})
        }else{
            return ({...initialFormValues})
        }
    },[type]) // eslint-disable-line

    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            // let r = /^[^-\s]/g
            // finding html tag contains the content
            let r = /<[^>]+>\s*[^<\s\r\n][^<]*<\/[^>]+>/
            if(value === '<p><br></p>') return false
            return r.test(value)
        })
    })

    const notesFormSchema = Yup.object().shape({
        description : Yup.string().validateDescription().required(t("Notes is a required field")),
        date:(userDetails.role.includes('admin') || userDetails.role.includes('dynamic_admin') || userDetails.role.includes('caremanager') || userDetails.role.includes('dynamic_cm')) ? Yup.string().required("Date is required") : Yup.string().nullable()
    })

    const notesForm = useFormik({
        initialValues : formData,
        validationSchema : notesFormSchema,
        enableReinitialize : true,
        validateOnMount : true,
        validateOnChange:true,
        validateOnBlur:true,
        onSubmit : values=>{
            (type === 'create') ? submitForm() : updateForm()
        }
    })
    
    const submitForm = () => {
        setSaveNote(true)
        let details =
        {
            "notes": notesForm.values.description,
            "date":notesForm.values.date && notesForm.values.date.toString().length > 0 ?  notesForm.values.date.toString().replace('/','-').replace('/','-').slice(0,10) + " " + new Date().toString().slice(16,24) : new Date().toLocaleDateString('en-GB').split('/').reverse().join('-')+ " " + new Date().toString().slice(16,24)
        }
        API.post(`cases/${caseId}/notes?orgId=${orgId}`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t("Notes saved successfully"), type: "success", active: true }))
                dispatch(setNotesAction(notesAction+1))
                onClose()
            }
        }).catch(e => {
            console.log(e)
        })
    }
    
    const updateForm = () =>{
        setSaveNote(true)
       let details = {
            "notes": notesForm.values.description,
            "date":notesForm.values.date && notesForm.values.date.toString().length > 0 ?  notesForm.values.date.toString().replace('/','-').replace('/','-').slice(0,10) + " " + new Date().toString().slice(16,24) : new Date().toLocaleDateString('en-GB').split('/').reverse().join('-')+ " " + new Date().toString().slice(16,24)
            // "notesId": caseId.notes.notesId
          }
          API.post(`cases/${caseId}/notes?orgId=${orgId}`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t("Notes saved successfully"), type: "success", active: true }))
                onClose();
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            } else {

            }
        }).catch(e => {
            console.log(e)
        }).finally(() => {
            notesForm.resetForm()
        })
    }
    return (
        <div className={`py-2 ${isDraggableEditor ? 'w-full': ''}`}>
            <div className={`${isDraggableEditor ? 'mt-1 px-1 z-50' : 'px-4'} ${isMaximize ? 'min-w-[850px]' : ''} cursor-auto editor_area`} onPointerDownCapture={e => e.stopPropagation()}>
            {/* <FieldInput  type="textarea" label={t("Type a note")} autoComplete='off' fieldMeta={notesForm.getFieldMeta('description')} fieldProps={notesForm.getFieldProps('description')} edit={true} dataLoaded={dataLoaded} /> */}
                <FormikProvider value={notesForm}>
                    {(userDetails.role.includes('admin') || userDetails.role.includes('dynamic_admin') || userDetails.role.includes('caremanager') || userDetails.role.includes('dynamic_cm')) &&  <div className="cursor-pointer w-full flex z-10 flex-row justify-end my-1"><FieldDate injectClass={'w-64'} type="date" ariaLabel={'Notes Calendar'} label={"Calendar"} fieldMeta={notesForm.getFieldMeta('date')} fieldHelper={notesForm.getFieldHelpers('date')} fieldProps={notesForm.getFieldProps('date')} edit={true} dataLoaded={true} isDraggableEditor={isDraggableEditor}/></div>}
                    <QuillEditor name="description" ariaLabel={'Type a Note'} type="textarea" label={t("Type a note")} autocomplete='off' fieldMeta={notesForm.getFieldMeta('description')} fieldProps={notesForm.getFieldProps('description')} edit={true} dataLoaded={dataLoaded} isDraggableEditor={isDraggableEditor} isMaximize={isMaximize} />
                </FormikProvider>
            </div>
            <div className={`flex justify-between ${isDraggableEditor ? 'mt-4': 'mt-8'} mx-2 editor_action`}>
                <button className={`${isDraggableEditor ? 'z-50' : ''} flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500`} onClick={onClose}>{t("Cancel")}</button>
                <button disabled={saveNote ? true : false} className={`${isDraggableEditor ? 'z-50' : ''} flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white`} onClick={() => notesForm.submitForm()}>{t("Save")}</button>
            </div>
        </div>
    )
}

export default DemoNotes