import { useLayoutEffect, useRef } from "react"
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_am4charts from "@amcharts/amcharts4/themes/amcharts"
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'

am4core.useTheme(am4themes_animated);

const Cases = ({ cases ,totalCases }) => {

    const chartRef = useRef(null)
    const { themeMode } = useSelector(state => state.theme)
    const { t } = useTranslation()

    useLayoutEffect(() => {
        if (themeMode === 'dark') {
            am4core.unuseTheme(am4themes_am4charts)
            am4core.useTheme(am4themes_dark)
        } else {
            am4core.unuseTheme(am4themes_dark)
            am4core.useTheme(am4themes_am4charts)
        }

        // Create chart instance
        var chart = am4core.create("chartdivCases", am4charts.PieChart);
        chart.logo.disabled = true;
        // Generate data
        // chart.data = ["All Cases", "InProgress Cases", "Closed Cases", "followUp Cases","Unassignied Cases","Referral Cases"].map(c => console.log(cases[c]))
        chart.data = cases.map(c=>({name:c.status,count:c.id}))
        chart.innerRadius = 50;
        var label = chart.seriesContainer.createChild(am4core.Label);
        label.text = totalCases
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fontSize = 40;
        // console.log(label)
        
        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "count";
        pieSeries.dataFields.category = "name";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.role = "none";

        pieSeries.ticks.template.disabled = true;
        pieSeries.alignLabels = false;
        pieSeries.labels.template.disabled = true;
        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;  
    
        chart.hiddenState.properties.radius = am4core.percent(0);
        
        chartRef.current = chart
        // console.log(chartRef.current.role)
        // chart.role="menu"
        return () => {
            chartRef.current.dispose();
        };
    }, [cases, themeMode]) // eslint-disable-line

    return (
        <div>
            <div  id="chartdivCases" className="h-[200px]"></div>
            <div className="text-black dark:text-white">
                <span>{t('All Cases')}</span>
            </div>
        </div>
    )
}

export default Cases