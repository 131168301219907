import { FaUserAlt } from 'react-icons/fa'
import CaregiverEdit from '../../pages/Caregivers/Caregiver-Edit'

export const CaregiverRoutes = [
    {
        title: 'Caregiver',
        ItemIcon: FaUserAlt,
        link: '/caregiver/:caregiver_id',
        component: CaregiverEdit,
        saveData: true,
        multiWindow: false
    },
    {
        title:'CareGiver-Edit',
        link:'/caregiver-edit',
        component: CaregiverEdit,

    },
]