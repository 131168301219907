
import FormCard from '../../components/snippets/FormCard'
import FieldInput from '../../components/snippets/FieldInput'
import FavFormButton from '../../components/snippets/FavFormButton'
// import { Link } from 'react-router-dom'
import { useEffect, useState} from 'react'
import API from '../../axios/API'
import FieldSelect from '../../components/snippets/FieldSelect'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'
import { useDispatch } from "react-redux"
import { setAlert } from "../../store/theme/actions"
import PageLoader from './PageLoader'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import axios from 'axios'
import AssessmentFavFormButton from '../../components/snippets/AssessmentFavFormButton'




const initialFormValues = {
    careGiver: {
        address: {
            country: '',
            timeZone: '',
            state: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            postalCode: '',
            addressLine3: ''
        },
        relationship: '',
        language: '',
        personId: 0,
        firstName: '',
        lastName: '',
        emailAddress: '',
        phone: '',
        areaCode: '',
        subscriberId: '',
        preferredName: '',
        hispanic: false,
        nativeAmerican: false,
        dateOfBirth: '',
        dobDay: 0,
        phoneType: '',
        pacificIslander: false,
        otherRace: false,
        multiRacial: false,
        dobYear: 0,
        dobMonth: 0,
        uninsured: false,
        refused: false,
        medicaid: false,
        medicare: false,
        tricare: false,
        otherInsurer: false,
        maritalStat: '',
        phoneAuthorized: false,
        pronouns: '',
        samsId: '',
        ssn: '',
        homePhone: '',
        gender: '',
        employmentId: 0,
        educationId: 0,
        incomeId: 0,
        white: false,
        black: false,
        asian: false,
        middleEastern: false,
        middleName: '',
        race: [],
        pgName: '',
        pcspDate: '',
        relationshipId: '',
        otherRelationship: '',
    }
    // "programName": "",
    // "relationship": '',
    // "otherRelationship":'',
    // "personId": '',
    // "firstName": "",
    // "lastName": "",
    // "subscriberId": ""
}

const initialFormValues1 = {
    careReceiver:{
        address: {
            state: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            county: '',
            postalCode: '',
        },
        language: '',
        disability: '',
        personId: 0,
        firstName: '',
        lastName: '',
        relationshipId: '',
        otherRelationship: '',
        emailAddress: '',
        phone: '',
        areaCode: '',
        subscriberId: '',
        preferredName: '',
        hispanic: false,
        nativeAmerican: false,
        dateOfBirth: '',
        dobDay: 0,
        phoneType: '',
        pacificIslander: false,
        otherRace: false,
        refused: false,
        medicaid: false,
        medicare: false,
        tricare: false,
        otherInsurer: false,
        multiRacial: false,
        dobYear: 0,
        dobMonth: 0,
        uninsured: false,
        maritalStat: '',
        phoneAuthorized: false,
        pronouns: 0,
        samsId: '',
        ssn: '',
        homePhone: '',
        gender: '',
        employmentId: 0,
        educationId: 0,
        incomeId: 0,
        white: false,
        black: false,
        asian: false,
        middleEastern: false,
        middleName: '',
        relationship: '',
        race: [],
        assessType: '',
        tcareProtocol:''
    }

    // "programName": "",
    // "relationship": '',
    // "otherRelationship":'',
    // "personId": '',
    // "firstName": "",
    // "lastName": "",
    // "subscriberId": ""
}


const AssociatedCases = ({ props ,CmId}) => {
    const dispatch = useDispatch()
    const [associatedCase, setAssociatedCase] = useState('')
    const [loading, setLoading] = useState(true);
    const [newCareReceiver, setNewCareReceiver] = useState(false);
    const [newCareGiver, setNewCaregiver] = useState(false);
    const [relationShip, setRelationship] = useState([])
    const [programName, setProgramName] = useState([])
    const [states, setStates] = useState([])
    const [formLoading, setFormLoading] = useState(false)
    const [dataLoaded] = useState(true)
    const [editForm, setEditForm] = useState(true)
    // const [addUser,setAddUser] = useState(false)
    const [associatecount,setAssociatecount] = useState(0)
    const userDetails = useSelector(state =>state.auth.userDetails)
    const organization = useSelector(state => state.auth.organization)
    const [inputEnable, setInputEnable] = useState(false)
    const [inputEnable1, setInputEnable1] = useState(false)
    const [zipEnable, setZipEnable] = useState(false)
    const [cgRefused, setCgRefused] = useState(false)
    const  history = useHistory()
    const {t} = useTranslation()
    const [fromCase,setFromCase] = useState(false)
    const [years, setYears] = useState([])
    let prgName = localStorage.getItem('prgname')
    let pcsp = localStorage.getItem('pcsp')
    let birthyear = localStorage.getItem('birthyear')
    let disableAssociateCr = localStorage.getItem('disableAssociateCr')
    let orgId = localStorage.getItem('orgid')
    const [birthyear1, setBirthYear1] = useState(false)
    const [programName1, setProgramName1] = useState(false)
    const [datePscp, setDatePscp] = useState(false)
    const [addCare, setAddcare] = useState(false)
    const [disable,setDisable] = useState(false)
    let lang= localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))
    const [protocol, setProtocol] = useState([])
    let protocolType = localStorage.getItem('tcareProtocolEnabled')

    Yup.addMethod(Yup.string, 'otherRelation', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[a-zA-Z'_^-\s]*$/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'EmptySpace', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    useEffect(() => {
        for (let i = 1900; i <= new Date().getFullYear(); i++) {
            years.push(i)
        }
    }, []) //eslint-disable-line
    useEffect(() => {
        if (birthyear === 'true') {
            setBirthYear1(true)
        }
        if(prgName === 'true') {
            setProgramName1(true)
        } if (pcsp === 'true') {
            setDatePscp(true)
        }
        if(disableAssociateCr === 'true'){
            setAddcare(false)
          }
          else{
              setAddcare(true)
          }
       
        setLoading(true)
        API.get(`organizations/${orgId}/cases/${props}/associatedCase`).then(response => {
            if (response.status === 200) {
                setAssociatedCase(response.data)
                setLoading(false)
              
            }
        }).catch(e => {
            console.log(e)
        }).finally()
    }, [associatecount]) //eslint-disable-line

    useEffect(() => {
        if(history.location !== undefined && history.location.state !== undefined){
            setFromCase(history.location.state.fromNewCase)
            // if(history.location.state.cgNew === true){
            //     // console.log(history.location.state.cgNew === true);
            //     addCaregiver()
            // }
        }
        
        setFormLoading(true)
        if(menuItems && menuItems !== null && menuItems !== '' && menuItems !== undefined){
            if (menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined) {
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            } else {
                API.get(`menus/states?lang=${lang}`).then((statesResp) => {
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if (menuItems.relationShip && menuItems.relationShip.length > 0 && menuItems.relationShip !== undefined) {
                setRelationship(menuItems.relationShip.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/relationships?lang=${lang}`).then((relationshipResp) => {
                    setRelationship(relationshipResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.programName && menuItems.programName.length > 0 && menuItems.programName !== undefined) {
                setProgramName(menuItems.programName.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/case-programName?lang=${lang}`).then((programNameId) => {
                    setProgramName(programNameId.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            if (menuItems.protocol && menuItems.protocol.length > 0 && menuItems.protocol !== undefined) {
                setProtocol(menuItems.protocol.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/protocol?lang=${lang}`).then((protocolResp) => {
                    setProtocol(protocolResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
            setFormLoading(false)
        }else{
            axios.all([API.get(`menus/states?lang=${lang}`), API.get(`menus/relationships?lang=${lang}`), API.get(`menus/case-programName?lang=${lang}`)]).then(axios.spread((statesResp, relationshipResp, programNameId) => {
                setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                setRelationship(relationshipResp.data.body.map(c => ({ text: c.name, value: c.value })))
                setProgramName(programNameId.data.body.map(c => ({ text: c.name, value: c.value })))
                setFormLoading(false)
            }))
        }
        setYears(years.map(c => ({ text: c, value: c })))
        // API.get('menus/relationships').then(res => {
        //     setRelationship(res.data.body.map(c => ({ text: c.name, value: c.value })))
        //     setFormLoading(false)
        // })
    }, [])// eslint-disable-line
    const careReceiverSchema = Yup.object({
        careReceiver: Yup.object({
            // address: Yup.object({
            //     addressLine1: Yup.string().nullable().required(t("Address is required")),
            //     state: Yup.string().nullable().required(t("State is a required field")),
            //     postalCode: Yup.string().nullable().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t("ZIP Code is a required field")),
            // }),
            language: Yup.string().nullable(),
            firstName: Yup.string().nullable().max(50).validateName().required(t("First Name is a required field")),
            relationship: Yup.string().required(t("Relationship is a required field")),
            otherRelationship: inputEnable ? Yup.string().nullable().min(3, t("Too Short!")).EmptySpace().otherRelation().required(t("Other Relationship is required")) : Yup.string().nullable().min(3, t("Too Short!")).EmptySpace().otherRelation(),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Last Name is a required field")),
            tcareProtocol: protocolType === 'true' ? Yup.string().nullable().required(t("Protocol Type is a required field")) : Yup.string().nullable(),
            // emailAddress: Yup.string().nullable().validateEmail(),
            // phone: Yup.string().nullable().validatePhoneLength().required(t("Mobile Number is a required field")),
            // homePhone: Yup.string().nullable().validatePhoneLength(),
            // subscriberId: Yup.string().nullable().EmptySpace().length(9, t("It should have nine alpha - numeric values")).matches(/^[a-zA-Z0-9_]*$/, t("Subscriber Id should be alpha-numeric")),
            // preferredName: Yup.string().nullable(),
            // dateOfBirth: !cgRefused ? Yup.date().required(t("Please provide the required details")) : Yup.date(),
            // dobDay: Yup.number(),
            // dobYear: Yup.number(),
            // dobMonth: Yup.number(),
            // pgName: programName1 ? Yup.string().required(t("Program Name is a required field")) : Yup.string().nullable(),
            // pcspDate: datePscp ? Yup.date().required(t("Date Of Associated PCSP is a required field")) : Yup.date(),
            // maritalStat: Yup.string().nullable(),
            // pronouns: Yup.string().nullable(),
            // ssn: Yup.string().nullable().length(4, t("SSN code should be 4 digits")).matches(/^[0-9]*$/, t("SSN code should be numeric value")),
            // gender: Yup.string().nullable(),
            // middleName: Yup.string().nullable().validateName().length(1),
            // phoneAuthorized: Yup.bool(),
            // employmentId: Yup.string().nullable(),
            // educationId: Yup.string().nullable(),
            // incomeId: Yup.string().nullable(),
            // race: Yup.array(),
            // disability: Yup.string().nullable()
        })
    })
    // inputEnable ? Yup.object().shape({
    //     relationship: Yup.string().required(t("Relationship is a required field")),
    //     firstName: Yup.string().EmptySpace().required(t("First Name is a required field")),
    //     lastName: Yup.string().EmptySpace().required(t("Last Name is a required field")),
    //     otherRelationship: Yup.string().required(t("Other Relationship is required")).EmptySpace().otherRelation().nullable().min(3, t("Too Short!"))
    // }):Yup.object().shape({
    //     relationship: Yup.string().required(t("Relationship is a required field")),
    //     firstName: Yup.string().EmptySpace().required(t("First Name is a required field")),
    //     lastName: Yup.string().EmptySpace().required(t("Last Name is a required field")),
    //     otherRelationship: Yup.string().EmptySpace().otherRelation().min(3, t("Too Short!")).nullable()
    // })

    const careGiverSchema =Yup.object({
        careGiver: Yup.object({
            address: Yup.object({
                addressLine1: Yup.string().EmptySpace().nullable().required(t("Address is required")),
                state: Yup.string().nullable().required(t("State is a required field")),
                postalCode: Yup.string().nullable().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t("ZIP Code is a required field")),
            }),
            language: Yup.string().nullable(),
            firstName: Yup.string().nullable().max(50).validateName().required(t("First Name is a required field")),
            relationship: Yup.string().required(t("Relationship is a required field")),
            otherRelationship: inputEnable1 ? Yup.string().nullable().min(3, t("Too Short!")).EmptySpace().otherRelation().required(t("Other Relationship is required")) : Yup.string().nullable().min(3, t("Too Short!")).EmptySpace().otherRelation(),
            lastName: Yup.string().nullable().max(50).validateName().required(t("Last Name is a required field")),
            // emailAddress: Yup.string().nullable().validateEmail(),
            phone: Yup.string().nullable().validatePhoneLength().required(t("Mobile Number is a required field")),
            // homePhone: Yup.string().nullable().validatePhoneLength(),
            // subscriberId: Yup.string().nullable().EmptySpace().length(9, t("It should have nine alpha - numeric values")).matches(/^[a-zA-Z0-9_]*$/, t("Subscriber Id should be alpha-numeric")),
            preferredName: Yup.string().nullable(),
            dateOfBirth: !cgRefused ? Yup.date().required(t("Please provide the required details")) : Yup.date(),
            dobDay: Yup.number(),
            dobYear: Yup.number(),
            dobMonth: Yup.number(),
            pgName: programName1 ? Yup.string().required(t("Program Name is a required field")).nullable() : Yup.string(),
            pcspDate: datePscp ? Yup.date().required(t("Date Of Associated PCSP is a required field")) : Yup.date(),
            // maritalStat: Yup.string().nullable(),
            // pronouns: Yup.string().nullable(),
            // ssn: Yup.string().nullable().length(4, t("SSN code should be 4 digits")).matches(/^[0-9]*$/, t("SSN code should be numeric value")),
            // gender: Yup.string().nullable(),
            // middleName: Yup.string().nullable().validateName().length(1),
            // phoneAuthorized: Yup.bool(),
            // employmentId: Yup.string().nullable(),
            // educationId: Yup.string().nullable(),
            // incomeId: Yup.string().nullable(),
            // race: Yup.array(),
            // disability: Yup.string().nullable()
        })
    })
        // inputEnable1 ? Yup.object().shape({
        //     relationship: Yup.string().required(t("Relationship is a required field")),
        //     firstName: Yup.string().EmptySpace().required(t("First Name is a required field")),
        //     lastName: Yup.string().EmptySpace().required(t("Last Name is a required field")),
        //     otherRelationship: Yup.string().EmptySpace().otherRelation().nullable().required(t("Other Relationship is required")).min(3, t("Too Short!")) 
        // }):Yup.object().shape({
        //     relationship: Yup.string().required(t("Relationship is a required field")),
        //     firstName: Yup.string().EmptySpace().required(t("First Name is a required field")),
        //     lastName: Yup.string().EmptySpace().required(t("Last Name is a required field")),
        //     otherRelationship: Yup.string().EmptySpace().otherRelation().nullable().min(3, t("Too Short!"))
        // })
    
    
    const careReceiverForm = useFormik({
        initialValues: initialFormValues1,
        validationSchema: careReceiverSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            addNewCareReceiver()
        }
    })

    const careGiverForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: careGiverSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            addNewCareGiver()
        }
    })
    const addCareReceivers = () => {
        setNewCareReceiver(!newCareReceiver)
        setEditForm(true)
        // setAddUser(true)
        setInputEnable(false)
        careReceiverForm.resetForm()
    }
   
    const addCaregiver =() => {
        setNewCaregiver(!newCareGiver)
        // setAddUser(false)
        setEditForm(true)
        setInputEnable1(false)
        careGiverForm.resetForm()
    }
    // const toggleFormEdit = () => {
    //     if (editForm) careReceiverForm.submitForm()
    //     if (careReceiverForm.isValid || !editForm) setEditForm(!editForm)
    // }

    const otherValue = (values) => {
        if (values === 14) {
            setInputEnable(true)
        } else {
            setInputEnable(false)
            // careReceiverForm.setValues({...profileForm,relationshipId:values,otherRelationship :''})
           
        }
    }
    const getAddress = (value, field, i) => {
        if (value.length === 5) {
            API.get(`/intake/zones?zipCode=${value}`).then((res) => {
                if (res.status === 200) {
                    setZipEnable(true)
                    if (field === 'cg') {
                        careGiverForm.setValues({ ...careGiverForm.values, careGiver: { ...careGiverForm.values.careGiver, address: { ...careGiverForm.values.careGiver.address, state: res.data.body.state, city: res.data.body.city, country: res.data.body.country, timeZone: res.data.body.timeZone } } })
                    }
                    // else if (field === 'cr') {
                    //     console.log(careGiverForm.setValues({ ...careGiverForm.values, careReceiver: { ...careGiverForm.values.careReceiver[i], address: { ...careGiverForm.values.careReceiver[i].address, state: res.data.body.state, city: res.data.body.city,country: res.data.body.country } } })
                    //     )
                    //     careGiverForm.setValues({ ...careGiverForm.values, careReceiver: { ...careGiverForm.values.careReceiver[i], address: { ...careGiverForm.values.careReceiver[i].address, state: res.data.body.state, city: res.data.body.city,country: res.data.body.country } } })
                    // }
                }
            })
        } else if (value.length === 0) {
            setZipEnable(false)
            careGiverForm.setValues({ ...careGiverForm.values, careGiver: { ...careGiverForm.values.careGiver, address: { ...careGiverForm.values.careGiver.address, state: "", city: "", country: "", timeZone: "" } } })
        }
    }


    const setRefused = (side, value) => {
        if (side === 'cg') {
            setCgRefused(value)
            // setDateRefused(true)
        }
    }
  
    const otherValue1 = (values) => {
        if (values === 14) {
            setInputEnable1(true)
        } else {
            setInputEnable1(false)
            // careReceiverForm.setValues({...profileForm,relationshipId:values,otherRelationship :''})
           
        }
    }
    const addNewCareReceiver = () => {
        setDisable(true)
        let details = {
            personId : associatedCase.personInfo.caregiverId,
            careReceiver:{
                "address": { "state": "", "addressLine1": "", "addressLine2": "", "city": "", "county": "", "postalCode": "" },
                "language": "",
                "firstName": careReceiverForm.values.careReceiver.firstName.trim(),
                "lastName": careReceiverForm.values.careReceiver.lastName.trim(),
                "relationshipId": careReceiverForm.values.careReceiver.relationship,
                "otherRelationship": careReceiverForm.values.careReceiver.otherRelationship,
                "emailAddress": "",
                "phone": "",
                "areaCode": "",
                "subscriberId": "",
                "preferredName": "",
                "hispanic": false,
                "nativeAmerican": false,
                "dateOfBirth": '',
                "dobDay": 0,
                "phoneType": "",
                "pacificIslander": false,
                "otherRace": false,
                "refused": false,
                "medicaid": false,
                "medicare": false,
                "tricare": false,
                "otherInsurer": false,
                "multiRacial": false,
                "dobYear": 0,
                "dobMonth": 0,
                "uninsured": false,
                "maritalStat": "",
                "phoneAuthorized": false,
                "pronouns": null,
                "samsId": "",
                "ssn": "",
                "homePhone": "",
                "gender": "",
                "employmentId": 0,
                "educationId": 0,
                "incomeId": 0,
                "white": false,
                "black": false,
                "asian": false,
                "middleEastern": false,
                "middleName": "",
                "relationship": "",
                "race": [],
                "disability": "",
                "assessType": "",
                "tcareProtocol":careReceiverForm.values.careReceiver.tcareProtocol
            
            }
            // "personId": associatedCase.personInfo.caregiverId,
            // "firstName": careReceiverForm.values.firstName,
            // "lastName": careReceiverForm.values.lastName,
            // "relationship": careReceiverForm.values.relationship,
            // "otherRelationship":careReceiverForm.values.otherRelationship
            // // "subscriberId": careReceiverForm.values.subscriberId
        }
        
             API.post(`organizations/${organization}/cases/${props}?queue=associateCareReceiver`,details).then(res=>{
                if (res.status === 200){
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Add CareReceiver successfully'), active: true, type: 'success' }))
                    careReceiverForm.resetForm()
                    careGiverForm.resetForm()
                    setNewCareReceiver(!newCareReceiver)
                    setNewCaregiver(false)
                    setAssociatecount(associatecount+1)
                    setInputEnable(false)
                    setDisable(false)
                }
                else{
                    dispatch(setAlert({ title: t('NotCreated'), subtitle: t('Not Created successfully'), active: true, type: 'failure' }))
                    setDisable(false)
                }
            }).catch(e=>{
                console.log(e)
            })
    }
    const addNewCareGiver = () => {
        setDisable(true)
        if (careGiverForm.values.careGiver.dateOfBirth && birthyear1) {
            careGiverForm.values.date = careGiverForm.values.careGiver.dateOfBirth.split('/');
            careGiverForm.values.day = careGiverForm.values.date[2]
            careGiverForm.values.month = careGiverForm.values.date[1]
            careGiverForm.values.year = careGiverForm.values.date[0]
        }
        let details = {
            personId : associatedCase.personInfo.careReceiverId,
            pcspDate: careGiverForm.values.careGiver.pcspDate ? careGiverForm.values.careGiver.pcspDate.toString().replace('/','-').replace('/','-').slice(0,10) : null,
            caregiver: {
                address: {
                    timeZone: careGiverForm.values.careGiver.address.timeZone,
                    country: careGiverForm.values.careGiver.address.country,
                    state: careGiverForm.values.careGiver.address.state,
                    addressLine1: careGiverForm.values.careGiver.address.addressLine1,
                    addressLine2: careGiverForm.values.careGiver.address.addressLine2,
                    city: careGiverForm.values.careGiver.address.city,
                    postalCode: careGiverForm.values.careGiver.address.postalCode,
                    addressLine3: careGiverForm.values.careGiver.address.addressLine3
                },
                language: careGiverForm.values.careGiver.language ? careGiverForm.values.careGiver.language : null,
                firstName: careGiverForm.values.careGiver.firstName.trim(),
                lastName: careGiverForm.values.careGiver.lastName.trim(),
                emailAddress: careGiverForm.values.careGiver.emailAddress,
                phone: careGiverForm.values.careGiver.phone,
                subscriberId: careGiverForm.values.careGiver.subscriberId,
                preferredName: careGiverForm.values.careGiver.preferredName,
                dobDay: cgRefused || careGiverForm.values.careGiver.dateOfBirth === 0 ? 0 : birthyear1 ? parseInt(careGiverForm.values.day) : parseInt(careGiverForm.values.careGiver.dobDay ? careGiverForm.values.careGiver.dobDay : 1),
                dobYear: cgRefused ? 0 : birthyear1 ? parseInt(careGiverForm.values.year) : parseInt(careGiverForm.values.careGiver.dateOfBirth ? careGiverForm.values.careGiver.dateOfBirth : 0),
                dobMonth: cgRefused || careGiverForm.values.careGiver.dateOfBirth === 0 ? 0 : birthyear1 ? parseInt(careGiverForm.values.month) : parseInt(careGiverForm.values.careGiver.dobMonth ? careGiverForm.values.careGiver.dobMonth : 1),
                maritalStat: careGiverForm.values.careGiver.maritalStat ? careGiverForm.values.careGiver.maritalStat : null,
                pronouns: careGiverForm.values.careGiver.pronouns ? careGiverForm.values.careGiver.pronouns : null,
                ssn: careGiverForm.values.careGiver.ssn,
                homePhone: careGiverForm.values.careGiver.homePhone,
                gender: careGiverForm.values.careGiver.gender ? careGiverForm.values.careGiver.gender : null,
                employmentId: careGiverForm.values.careGiver.employment ? careGiverForm.values.careGiver.employment : 0,
                educationId: careGiverForm.values.careGiver.education ? careGiverForm.values.careGiver.education : 0,
                incomeId: careGiverForm.values.careGiver.income ? careGiverForm.values.careGiver.income : 0,
                middleName: careGiverForm.values.careGiver.middleName,
                white: careGiverForm.values.careGiver.race !== undefined && careGiverForm.values.careGiver.race.includes(1),
                black: careGiverForm.values.careGiver.race !== undefined && careGiverForm.values.careGiver.race.includes(2),
                asian: careGiverForm.values.careGiver.race !== undefined && careGiverForm.values.careGiver.race.includes(4),
                middleEastern: careGiverForm.values.careGiver.race !== undefined && careGiverForm.values.careGiver.race.includes(7),
                hispanic: careGiverForm.values.careGiver.race !== undefined && careGiverForm.values.careGiver.race.includes(3),
                nativeAmerican: careGiverForm.values.careGiver.race !== undefined && careGiverForm.values.careGiver.race.includes(5),
                pacificIslander: careGiverForm.values.careGiver.race !== undefined && careGiverForm.values.careGiver.race.includes(6),
                otherRace: careGiverForm.values.careGiver.race !== undefined && careGiverForm.values.careGiver.race.includes(8),
                multiRacial: false,
                uninsured: careGiverForm.values.careGiver.uninsured,
                refused: careGiverForm.values.careGiver.race !== undefined && careGiverForm.values.careGiver.race.includes(9),
                medicaid: careGiverForm.values.careGiver.medicaid,
                medicare: careGiverForm.values.careGiver.medicare,
                tricare: careGiverForm.values.careGiver.tricare,
                otherInsurer: careGiverForm.values.careGiver.otherInsurer,
                phoneAuthorized:careGiverForm.values.careGiver.phoneAuthorized,
                disability: careGiverForm.values.careGiver.disability ? careGiverForm.values.careGiver.disability : null,
                pgName: careGiverForm.values.careGiver.pgName ? careGiverForm.values.careGiver.pgName : 0,
                relationshipId: careGiverForm.values.careGiver.relationship,
                otherRelationship:careGiverForm.values.careGiver.otherRelationship
            }}
        API.post(`organizations/${organization}/cases/${props}?queue=associateCaregiver`,details).then(res=>{
                if (res.status === 200){
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Add CareGiver successfully'), active: true, type: 'success' }))
                    careGiverForm.resetForm()
                    careReceiverForm.resetForm()
                    setNewCaregiver(!newCareGiver)
                    setNewCareReceiver(false)
                    setAssociatecount(associatecount+1)
                    setEditForm(false)
                    setInputEnable1(false)
                    setDisable(false)
                }
                else{
                    dispatch(setAlert({ title: t('NotCreated'), subtitle: t('Not Created successfully'), active: true, type: 'failure' }))
                    setDisable(false)
                }
            }).catch(e=>{
                console.log(e)
            })
    }
    const caseDetails = (values)=>{
      
    document.getElementById("newCase").click();
        history.push(`/casedata/${values}`)
    }
    return (
        <div>
            {
                loading ? <PageLoader/>: <div>
                    <FormCard>
                        <h1 className='text-gray-600 dark:text-tcolor' style={{ fontSize: '20px' }}>{t("Associated Cases for")} <span className='text-gray-600 dark:text-tcolor' style={{ fontSize: '20px', fontWeight: 'bold' }}>{associatedCase.personInfo.caregiverName}</span></h1>
                        {/* <h1 style={{ color: '#797979', fontWeight: '500', fontSize: '14px', lineHeight: '18px' }}>{t("You have one or more unscheduled cases for")} {associatedCase.personInfo.careGiverName}.{t("Please schedule these cases or click on the Status button to take action on this case")} </h1> */}
                        {
                        associatedCase.cgAssociatedCase.map((values, id) => {
                            return (
                                <FormCard title={t(`${t('CASE ID')} ${values.caseId}`)} onClick={()=>associatedCase.cgAssociatedCase[id].careManagerId === userDetails.caremanagerId || userDetails.role.includes('admin') || (userDetails.orgId === parseInt(organization) && userDetails.role.includes('screener')) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_scr'))? caseDetails(values.caseId) :''} injectClass="mt-12">
                                    <h1><span className='text-gray-600 dark:text-tcolor' style={{ fontSize: '20px' }} title={t("Caregiver")} data-private="lipsum">{values.careGiverName === 'null null'? "(----)" :values.careGiverName }</span>&nbsp;<span className='text-black dark:text-white' style={{ fontSize: '20px'}}>{t("taking care of")} </span>&nbsp;<span className='text-gray-600 dark:text-tcolor' style={{ fontSize: '20px' }} title={t("Carereceiver")} data-private="lipsum">{values.carerecieverName === 'null null' ? "(----)":values.carerecieverName}</span></h1>
                                    &nbsp;
                                    <div className='grid grid-cols-4 gap-4'>
                                        <div className='text-gray-600 dark:text-gray-200'>{t("Status")}</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '15px' }}>{values.status}</div>
                                        <div className='text-gray-600 dark:text-gray-200'>{t("Protocol")}</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '15px' }}>{values.protocolType}</div>
                                        {/* <>{values.schedulingEnabled ? <div style={{ color: '#A9A9A9' }}>{t("Scheduled for")}</div> : ''}</>
                                        <>{(values.schedulingEnabled && values.status !== "Closed") ?
                                            <div>{(values.scheduledAt != null) ? <FieldInput edit={false}></FieldInput> : <Link style={{ fontSize: '15px', fontWeight: 'bold' }}>{t("Click to schedule")}</Link>}</div> : ''}</> */}
                                    </div>
                                </FormCard>
                            )
                        })
                    }
                    {newCareReceiver ? <FormCard title={t("Add Care Receiver")}>
                        <FavFormButton injectClass="absolute -top-5 right-5" disabled={disable} edit={editForm} handleClick={()=>careReceiverForm.submitForm()} />
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                            <FieldSelect ariaLabel={'Relationship Dropdown'} loading={formLoading} label={t("Relationship")} fieldMeta={careReceiverForm.getFieldMeta('careReceiver.relationship')} fieldHelper={careReceiverForm.getFieldHelpers('careReceiver.relationship')} fieldProps={careReceiverForm.getFieldProps('careReceiver.relationship')} edit={editForm} options={relationShip} onChange={otherValue} position="top" dataLoaded={dataLoaded} />
                            { inputEnable && <FieldInput ariaLabel={'Other Relationship'} loading={formLoading} label={t("Other Relation")} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`careReceiver.otherRelationship`)} fieldProps={careReceiverForm.getFieldProps(`careReceiver.otherRelationship`)} edit={editForm} dataLoaded={dataLoaded} />}
                            <FieldInput ariaLabel={'Care Receiver First Name'} hideData={true} loading={formLoading} label={t("First Name")}autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta('careReceiver.firstName')} fieldProps={careReceiverForm.getFieldProps('careReceiver.firstName')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Care Reciever Last Name'} hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta('careReceiver.lastName')} fieldProps={careReceiverForm.getFieldProps('careReceiver.lastName')} edit={editForm} dataLoaded={dataLoaded} />
                           {protocolType === 'true' ? <FieldSelect ariaLabel={'RCare Reciever Protocol Type'} loading={formLoading} label={t("Protocol Type")} fieldMeta={careReceiverForm.getFieldMeta('careReceiver.tcareProtocol')} fieldHelper={careReceiverForm.getFieldHelpers('careReceiver.tcareProtocol')} fieldProps={careReceiverForm.getFieldProps('careReceiver.tcareProtocol')} edit={editForm} options={protocol} onChange={otherValue} position="top" dataLoaded={dataLoaded} />:''}
                            {/* <FieldInput loading={formLoading} label="Subscriber Id" autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`subscriberId`)} fieldProps={careReceiverForm.getFieldProps(`subscriberId`)} edit={editForm} dataLoaded={dataLoaded} /> */}
                        </div>
                    </FormCard> : ''}
                    <div className="row flex">
                    <div className="col-md-1 col-xs-1 col-sm-1">
                        { 
                            (((userDetails.role.length === 1) && ((userDetails.role[0]==='admin' || userDetails.role[0] === 'screener' || associatedCase.personInfo.careManagerId === 0))) || (associatedCase.personInfo.careManagerId === 0 && organization !== userDetails.orgId)) ? '' : 
                                associatedCase.cgAssociatedCase.length >= 0 && !fromCase ? <div className="mt-5" > 
                                    {((organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('caremanager') && (userDetails.caremanagerId === CmId)) || (organization && userDetails && userDetails.orgId !== parseInt(organization) && ((userDetails.caremanagerId === CmId) && userDetails.role.includes('dynamic_cm'))))&& addCare ? !newCareReceiver ? <span title={t("+ Add Care Receiver")}><AssessmentFavFormButton title={t("+ Add Care Receiver")} onClick={() => addCareReceivers()} /></span> : <span title={t("Remove Care Receiver")}><AssessmentFavFormButton title={t("Remove Care Receiver")} onClick={() => addCareReceivers()}/></span>  : ''}
                                </div> : '' 
                        }
                        {
                            ((organization && userDetails && userDetails.orgId === parseInt(organization) && ((userDetails.caremanagerId === CmId) && (userDetails.role[0] !== 'screener'))) || (organization && userDetails && organization !== userDetails.orgId && ((userDetails.caremanagerId === CmId) && userDetails.role.includes('dynamic_cm'))))&& addCare && fromCase  ? !newCareReceiver ? <span title={t("+ Add Care Receiver")}><AssessmentFavFormButton title={t("+ Add Care Receiver")} onClick={() => addCareReceivers()} /></span> : <span title={t("Remove Care Receiver")}><AssessmentFavFormButton title={t("Remove Care Receiver")} onClick={() => addCareReceivers()}/></span>  : ''
                        }
                            </div>
                    </div>
                    </FormCard>
                  
                   
                    <FormCard>
                        <h1 className='text-gray-600 dark:text-tcolor' style={{ fontSize: '20px' }}>{t("Associated Cases for")} <span className='text-gray-600 dark:text-tcolor' style={{ fontSize: '20px', fontWeight: 'bold' }}>{associatedCase.personInfo.careReceiverName}</span></h1>
                        {/* <h1 style={{ color: '#797979', fontWeight: '500', fontSize: '14px', lineHeight: '18px' }}>{t("You have one or more unscheduled cases for")} {associatedCase.personInfo.careRecipientName}.{t("Please schedule these cases or click on the Status button to take action on this case")} </h1> */}
                        {
                        associatedCase.crAssociatedCase.map((values, id) => {
                            return (
                                <FormCard title={`${t('CASE ID')} ${values.caseId}`} onClick={()=>((associatedCase.crAssociatedCase[id].careManagerId === userDetails.caremanagerId) ||(userDetails.orgId === parseInt(organization) && userDetails.role.includes('screener')) || userDetails.role.includes('admin') || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_scr'))) ? caseDetails(values.caseId) :''} injectClass="mt-12">
                                    <h1><span data-private="lipsum" className='text-gray-600 dark:text-tcolor' style={{ fontSize: '20px' }} title={t("Caregiver")} >{values.careGiverName === 'null null' ? "(----)" :values.careGiverName}</span>&nbsp;<span className='text-black dark:text-white' style={{ fontSize: '20px'}}>{t("taking care of")} </span>&nbsp;<span className='text-gray-600 dark:text-tcolor' style={{ fontSize: '20px' }} title={t("Carereceiver")} data-private="lipsum">{values.carerecieverName === 'null null' ? "(----)" :values.carerecieverName}</span></h1>
                                    &nbsp;
                                    <div className='grid grid-cols-4 gap-4'>
                                        <div className='text-gray-600 dark:text-gray-200'>{t("Status")}</div>
                                        <div style={{ fontWeight: 'bold', fontSize: '15px' }}>{values.status}</div>
                                        {/* <>{values.schedulingEnabled ? <div style={{ color: '#A9A9A9' }}>{t("Scheduled for")}</div> : ''}</>
                                        <>{(values.schedulingEnabled && values.status !== "Closed") ?
                                            <div>{(values.scheduledAt != null) ? <FieldInput edit={false}></FieldInput> : <Link style={{ fontSize: '15px', fontWeight: 'bold' }}>{t("Click to schedule")}</Link>}</div> : ''}</> */}
                                    </div>
                                </FormCard>
                            )
                        })
                    }
                     {newCareGiver ? <FormCard title={t("Add Caregiver")}>
                        <FavFormButton injectClass="absolute -top-5 right-5" disabled={disable} edit={editForm} handleClick={()=> careGiverForm.submitForm()} />
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                            <FieldSelect ariaLabel={'Relationship Dropdown'} loading={formLoading} label={t("Relationship")} fieldMeta={careGiverForm.getFieldMeta('careGiver.relationship')} fieldHelper={careGiverForm.getFieldHelpers('careGiver.relationship')} fieldProps={careGiverForm.getFieldProps('careGiver.relationship')} edit={editForm} options={relationShip} position="top" dataLoaded={dataLoaded} onChange={otherValue1}/>
                            { inputEnable1 && <FieldInput ariaLabel={'Other Relationship'} loading={formLoading} label={t("Other Relation")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta(`careGiver.otherRelationship`)} fieldProps={careGiverForm.getFieldProps(`careGiver.otherRelationship`)} edit={editForm}  dataLoaded={dataLoaded} />}
                            <FieldInput ariaLabel={'Caregiver First Name'} hideData={true} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta('careGiver.firstName')} fieldProps={careGiverForm.getFieldProps('careGiver.firstName')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Caregiver Last Name'} hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta('careGiver.lastName')} fieldProps={careGiverForm.getFieldProps('careGiver.lastName')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Caregiver Mobile Number'} hideData={true} loading={formLoading} type="phone" label={t("Mobile Number")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta('careGiver.phone')} fieldProps={careGiverForm.getFieldProps('careGiver.phone')} edit={editForm} dataLoaded={dataLoaded} maxlength={10} />
                            {birthyear1 ?
                                            <FieldInput ariaLabel={' CaregiverDate of Birth'} disabled={cgRefused ? true : false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={careGiverForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={careGiverForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} />
                                            : <FieldSelect ariaLabel={'Caregiver Birth Year Dropdown'} disabled={cgRefused ? true : false} loading={formLoading} label={t('Birth Year')} fieldMeta={careGiverForm.getFieldMeta(`careGiver.dateOfBirth`)} fieldProps={careGiverForm.getFieldProps(`careGiver.dateOfBirth`)} fieldHelper={careGiverForm.getFieldHelpers(`careGiver.dateOfBirth`)} options={years} edit={true} dataLoaded={dataLoaded} position="bottom" />

                                        }
                                        <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative w-96">
                                            {/* <FieldInput  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={careGiverForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={careGiverForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> */}
                                            {cgRefused && <button style={{ height: 'fit-content', width: 'fit-content' }} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={() => setRefused('cg', false)}>{t('Refused')}</button>}
                                            {!cgRefused && <button style={{ height: 'fit-content', width: 'fit-content' }} className="ring-2 rounded-lg p-1 bg-tgray" onClick={() => setRefused('cg', true)}>{t('Refused')}</button>}
                                        </div>
                            {programName1 ? <FieldSelect ariaLabel={'Caregiver Program Name Dropdown'}  loading={formLoading} label={t('Program Name')} fieldMeta={careGiverForm.getFieldMeta('careGiver.pgName')} fieldHelper={careGiverForm.getFieldHelpers('careGiver.pgName')} fieldProps={careGiverForm.getFieldProps('careGiver.pgName')} edit={editForm} options={programName} position="bottom" dataLoaded={dataLoaded} /> : ''}
                            {datePscp ? <FieldInput ariaLabel={'Caregiver Date of Associated PCSP'} loading={formLoading} type="date" label={t("Date of Associated PCSP")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta('careGiver.pcspDate')} fieldProps={careGiverForm.getFieldProps('careGiver.pcspDate')} fieldHelper={careGiverForm.getFieldHelpers('careGiver.pcspDate')} edit={editForm} dataLoaded={dataLoaded} /> : ''}
                            <FieldInput ariaLabel={'Caregiver Zip Code'}  loading={formLoading} label={t("ZIP Code")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta('careGiver.address.postalCode')} fieldProps={careGiverForm.getFieldProps('careGiver.address.postalCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev) => getAddress(ev, 'cg')} />
                            {zipEnable ? <FieldInput ariaLabel={'Caregiver Address Line 1'} loading={formLoading} label={t("Address Line 1")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta('careGiver.address.addressLine1')} fieldProps={careGiverForm.getFieldProps('careGiver.address.addressLine1')} edit={editForm} dataLoaded={dataLoaded} /> : ''}
                            {zipEnable ? <FieldInput ariaLabel={'Caregiver City'} loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={careGiverForm.getFieldMeta('careGiver.address.city')} fieldProps={careGiverForm.getFieldProps('careGiver.address.city')} edit={editForm} dataLoaded={dataLoaded} /> : ''}
                            {zipEnable ? <FieldSelect ariaLabel={'Caregiver State'} loading={formLoading} label={t('State')} fieldMeta={careGiverForm.getFieldMeta('careGiver.address.state')} fieldHelper={careGiverForm.getFieldHelpers('careGiver.address.state')} fieldProps={careGiverForm.getFieldProps('careGiver.address.state')} edit={editForm} options={states} position="bottom" dataLoaded={dataLoaded} /> : ''}
                            {/* <FieldInput loading={formLoading} label="Subscriber Id" autoComplete='off' fieldMeta={careReceiverForm.getFieldMeta(`subscriberId`)} fieldProps={careReceiverForm.getFieldProps(`subscriberId`)} edit={editForm} dataLoaded={dataLoaded} /> */}
                        </div>
                    </FormCard> : ''}&nbsp;
                    <div className="row flex">
                        <div className="col-md-1 col-xs-1 col-sm-1">
                    {
                        (((userDetails.role.length === 1) && ((userDetails.role[0]==='admin'))) || (associatedCase.personInfo.careManagerId === 0 && organization !== `${userDetails.orgId}`) || ((userDetails.role.includes('screener')))) ? ''
                        :associatedCase.crAssociatedCase.length >= 0 && !fromCase ? <div className="mt-5">

                        {((organization && userDetails && userDetails.orgId === parseInt(organization) && (((userDetails.caremanagerId === CmId) &&(userDetails.role.includes('caremanager'))))) || ( userDetails && organization && userDetails.orgId !== parseInt(organization)  && ((userDetails.caremanagerId === CmId) && userDetails.role.includes('dynamic_cm')))) ? !newCareGiver ? <span title={t("+ Add Caregiver")}><AssessmentFavFormButton title={t("+ Add Caregiver")} onClick={() => addCaregiver()}  /></span> : <span title={t("Remove Caregiver")}><AssessmentFavFormButton title={t("Remove Caregiver")} onClick={() => addCaregiver()} /></span> : ''} 
                     </div> : '' 
                    }

                    {
                        (
                            (organization && userDetails && userDetails.orgId === parseInt(organization) && 
                                ((userDetails.caremanagerId === CmId) || (userDetails.role.includes('screener')) || userDetails.role.includes('caremanager'))) 
                            || 
                            (userDetails && organization && userDetails.orgId !== parseInt(organization) && 
                                ((userDetails.caremanagerId === CmId) && userDetails.role.includes('dynamic_cm')) || (userDetails.role.includes('dynamic_scr'))
                            )
                        ) 
                        && fromCase ? !newCareGiver ? <span title={t("+ Add Caregiver")}><AssessmentFavFormButton title={t("+ Add Caregiver")} onClick={() => addCaregiver()}  /></span> : <span title={t("Remove Caregiver")}><AssessmentFavFormButton title={t("Remove Caregiver")} onClick={() => addCaregiver()} /></span> : ''
                    }
                     {
                        ((organization && userDetails && userDetails.orgId === parseInt(organization) && ((userDetails.caremanagerId === 0) ||(userDetails.role.includes('screener')))) || (userDetails && organization && userDetails.orgId !== parseInt(organization) && ((userDetails.caremanagerId === 0)&&(userDetails.role.includes('dynamic_cm')) || (userDetails.role.includes('dynamic_scr'))))) && !fromCase ? !newCareGiver ? <span title={t("+ Add Caregiver")}><AssessmentFavFormButton title={t("+ Add Caregiver")} onClick={() => addCaregiver()}  /></span> : <span title={t("Remove Caregiver")}><AssessmentFavFormButton title={t("Remove Caregiver")} onClick={() => addCaregiver()} /></span> : ''
                    }
                  
                    </div>
                    </div>
                    </FormCard>
                   
                </div>
            }
        </div>
    )
}

export default AssociatedCases