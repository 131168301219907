import { useEffect, useState } from "react"
// import { useTranslation } from "react-i18next"
// import { useDispatch } from "react-redux"
import Cases from "./Cases"
import InProgress from "./InProgress"
import PendingReferral from "./PendingReferral"
import { AnimatePresence, motion } from 'framer-motion'

const Chart = ({details}) => {

    const [loaded, setLoaded] = useState(false)
    const [pendingReferral, setPendingReferral] = useState([{ text: '1 day', value: 0 }, { text: '2-3 days', value: 0 }, { text: '4-7 days', value: 0 }, { text: '2 weeks', value: 0 }, { text: '3 weeks', value: 0 }, { text: '4 weeks', value: 0 }, { text: '> 1 month', value: 0 }])
    const [totalPendingReferral, setTotalPendingReferral] = useState(0)
    const [totalinprogress, setTotalInprogress] = useState(0)
    const [inProgress, setInProgress] = useState(details.progressCount)
    // const [pendingReferrer,setPendingReferrer] = useState(details.referrerCount)
    const [cases, setCases] = useState(details.allCasesCount)
    const [totalCases,setTotalCases] = useState()

    // const dispatch = useDispatch()
    // const { t } = useTranslation()

    // const diff_weeks = (dt2, dt1) => {
    //     var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    //     let week = diff / (60 * 60 * 24 * 7);
    //     let day = diff / (60 * 60 * 24);
    //     return {day: Math.abs(Math.round(day)), week: Math.abs(Math.round(week))};
    // }

    useEffect(() => {
        // API.get(`v1/dashboardChart`)
        //     .then(res => {
        //         if (res.data && res.data.status) {
                    
        //         } else {
        //             dispatch(setAlert({ title: t('no_records_found'), subtitle: res.data.errors.join(', '), active: true, type: 'error' }))
        //         }
        //     })
        //     .catch(err => console.log(err))
        // let now = new Date()
        //             now.setHours(0,0,0,0);
        //             let ref = {'1 day': 0, '2-3 days': 0, '4-7 days': 0, '2 weeks': 0, '3 weeks': 0, '4 weeks': 0, '> 1 month': 0}
        setTotalCases(details.allCases)
        setTotalPendingReferral(details.allCasesCount.length > 0 ? details.allCasesCount[4].id:0)
        setTotalInprogress(details.allCasesCount.length > 0 ? details.allCasesCount[0].id:0)
                    // pendingReferrer.forEach(c => {
                    //     let cDate = new Date(c.created_at.split('T')[0])
                    //     cDate.setHours(0,0,0,0);
                    //     let diff = diff_weeks(cDate, now)
                    //     if(diff.day <= 1) ref['1 day'] += 1
                    //     else if(diff.day > 1 && diff.day <= 3) ref['2-3 days'] += 1
                    //     else if(diff.day > 3 && diff.day <= 7) ref['4-7 days'] += 1
                    //     else if(diff.week <= 2) ref['2 weeks'] += 1
                    //     else if(diff.week > 2 && diff.week <= 3) ref['3 weeks'] += 1
                    //     else if(diff.week > 3 && diff.week <= 4) ref['4 weeks'] += 1
                    //     else if(diff.week > 4) ref['> 1 month'] += 1
                    // })
                    setPendingReferral(details.referrerCount)
                    setInProgress(details.progressCount.map(c=>({name:c.status,count:c.id})))
                    setCases(details.allCasesCount)
                    setLoaded(true)

    }, [details]) // eslint-disable-line
    
    return (
        <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-7">
                <motion.div aria-label="All Cases" role="" className="bg-bluegray-200 dark:bg-ldark rounded-2xl relative shadow-lg overflow-hidden p-4 text-white font-bold text-center text-xs py-2">
                    { loaded && <Cases cases={cases} totalCases={totalCases} /> }
                </motion.div>
                <div className="bg-bluegray-200 dark:bg-ldark rounded-2xl relative shadow-lg overflow-hidden p-4 text-white font-bold text-center text-xs py-2">
                    { loaded && <PendingReferral pendingReferral={pendingReferral} totalPendingReferral={totalPendingReferral} /> }
                </div>
                <div className="bg-bluegray-200 dark:bg-ldark rounded-2xl relative shadow-lg overflow-hidden p-4 text-white font-bold text-center text-xs py-2">
                    { loaded && <InProgress inProgress={inProgress} totalinprogress={totalinprogress} /> }
                </div>
            </div>
        </div>
    )
}

export default Chart