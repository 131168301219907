
import { useFormik } from "formik"
import { useState, useEffect } from "react"
import * as Yup from 'yup'
import { useMemo } from "react"
import FavFormButton from "../../components/snippets/FavFormButton"
import FieldInput from "../../components/snippets/FieldInput"
import FieldSelect from "../../components/snippets/FieldSelect"
import FormCard from "../../components/snippets/FormCard"
import { useTranslation } from 'react-i18next'

// import { useHistory } from "react-router-dom"
import { FaArrowLeft } from 'react-icons/fa'
import { useDispatch, useSelector } from "react-redux"
import FieldCheckbox from '../../components/snippets/FieldCheckbox'
import API from "../../axios/API"
import axios from 'axios'
import { setAlert } from '../../store/theme/actions'
import Tab from "../../components/snippets/Tabs/Tab"
import TabGroup from "../../components/snippets/Tabs/TabGroup"
import TabButtonGroup from "../../components/snippets/Tabs/TabButtonGroup"
import TabButton from "../../components/snippets/Tabs/TabButton"
import ServiceUsage from "./ServiceUsage"
import FieldMultiSelect from '../../components/snippets/FieldMultiSelect'

Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
    return this.test('validPhone', "Not a Valid Phone", (value) => {
        if (value === undefined || value === '') return true
        return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
    })
})
Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
    return this.test('onlyAlphabet', "Only Alphabets Allowed", (value) => {
        if (value === undefined || value === '') return true
        let r = /^[a-zA-Z ]*$/g
        return r.test(value)
    })
})

Yup.addMethod(Yup.string, 'validateEmail', function () {
    return this.test('validEmail', "Not valid", (value) => {
        if (value === undefined || value === '' || value === null) return true
        let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
        return r.test(value)
    })
})

Yup.addMethod(Yup.string, 'validateName', function () {
    return this.test('onlyAlphabet and numbers are allowed', 'Only Alphabets and numbers allowed', (value) => {
        if (value === undefined || value === '') return true
        let r = /^[^-\s]/g
        return r.test(value)
    })
})

const initialFormValues = {
    date: ''
}

const CarereceiverEdit = ({ match ,history}) => {

    // let history = useHistory()
    const { t } = useTranslation()

    const [editForm, setEditForm] = useState(false)
    const [formLoading, setFormLoading] = useState(false)
    const dispatch = useDispatch()
    const [dataLoaded, setDataLoad] = useState(false)
    const [initialData, setInitialData] = useState([])
    useEffect(() => {
        setFormLoading(true)
        API.get(`care-receivers/${match?.params?.carereceiver_id}`).then(res => {
            setInitialData(res.data);
            setFormLoading(false)
            setDataLoad(true)
        }).catch(e => {
            console.log(e);
        }).finally()
    }, [])// eslint-disable-line

    const formData = useMemo(() => {
        if (initialData.dobMonth && initialData.dobDay && initialData.dobYear) {
            initialData.date = initialData.dobYear + '/' + initialData.dobMonth + '/' + initialData.dobDay 
        }
        if (initialData.homePhone === null) {
            initialData.homePhone = ''
        }
        if (initialData.phone === null) {
            initialData.phone = ''
        }
        initialData.race =[]
        if (initialData.white === true) {
            initialData.race.push(1)
        }  if (initialData.black === true) {
            initialData.race.push(2)
        }  if (initialData.hispanic === true) {
            initialData.race.push(3)
        }  if (initialData.asian === true) {
            initialData.race.push(4)
        }  if (initialData.nativeAmerican === true) {
            initialData.race.push(5)
        }  if (initialData.pacificIslander === true) {
            initialData.race.push(6)
        }  if (initialData.middleEastern === true) {
            initialData.race.push(7)
        }  if (initialData.otherRace === true) {
            initialData.race.push(8)
        }  if (initialData.refusedRace === true) {
            initialData.race.push(9)
        } 
        initialData.emailAddress = initialData.emailAddress ? initialData.emailAddress : ''
        return { ...initialFormValues, ...initialData }
    }, [initialData])
    const userDetails = useSelector(state => state.auth.userDetails)
    const [states, setStates] = useState([])
    const [gender, setGender] = useState([])
    const [employment, setEmployment] = useState([])
    const [pronouns, setPronouns] = useState([])
    const [raceEthnicity, setRaceEthnicity] = useState([])
    const [language, setLanguage] = useState([])
    const [maritalStatus, setMarital] = useState([])
    const [education, setEducation] = useState([])
    const [Income, setIncome] = useState([])
    const [tab, setTab] = useState(0)
    const [cgRefused,setCgRefused] = useState(false)
    const [years,setYears] = useState([])
    let lang= localStorage.getItem('language')

    useEffect(()=>{
        for(let i = 1900; i <= new Date().getFullYear(); i++){
            years.push(i)
        }
    },[years])

    useEffect(() => {
        axios.all([API.get(`menus/states?lang=${lang}`), API.get(`menus/genders?lang=${lang}`), API.get(`menus/employments?lang=${lang}`),
        API.get(`menus/pronouns?lang=${lang}`), API.get(`menus/races?lang=${lang}`), API.get(`menus/marital-status?lang=${lang}`), API.get(`menus/educations?lang=${lang}`), API.get(`menus/languages?lang=${lang}`),
        API.get(`menus/incomes?lang=${lang}`)]).then(axios.spread((statesResp, gendersResp, employmentResp, pronounsResp,
            raceResp, maritalResp, educationResp, languageResp, incomeResp) => {
            setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setEmployment(employmentResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setPronouns(pronounsResp.data.body.map(c => ({ text: c.name, value: c.name })))
            setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setMarital(maritalResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setEducation(educationResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setIncome(incomeResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setYears(years.map(c=>({text:c,value:c})))
        }))
    }, [years])
    const profileFormSchema = Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required("FirstName is a required field"),
        middleName: Yup.string().validateName().nullable(),
        lastName: Yup.string().nullable().max(50).validateName().required("Last name is a required field"),
        preferredName: Yup.string().validateName().nullable(),
        address: Yup.object({
            addressLine1: Yup.string().nullable(),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().nullable().validateName(),
            state: Yup.string().required("State is a required field").nullable(),
            postalCode: Yup.string().length(5, 'ZIP code must be a 5-digit number').matches(/^[0-9]*$/, "ZIP Code Field Only Numerics...").required("ZIP Code Field is a required field"),
        }),
        date: Yup.date().required("Date is a required field"),
        homePhone: Yup.string().validatePhoneLength(),
        phone: Yup.string().validatePhoneLength().required("Phone is a required field"),
        emailAddress: Yup.string().validateEmail().nullable(),
        ssn: Yup.string().length(4, "SSN number must be exactly 4 digits").matches( /^[0-9]*$/,"Only Numerics ...").nullable(),
        language: Yup.string().nullable(),
        gender: Yup.string().nullable(),
        maritalStat: Yup.string().nullable(),
        relationShip: Yup.string().nullable(),
        employmentId: Yup.string(),
        educationId: Yup.string(),
        incomeId: Yup.string(),
        dobDay: Yup.number(),
        dobMonth: Yup.number(),
        dobYear: Yup.number(),
        subscriberId: Yup.string().nullable()
    })

    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            submitForm()
            setFormLoading(false)
        }
    })

    const getAddress = (value,field) => {
        if(value.length ===5){
            API.get(`/intake/zones?zipCode=${value}`).then((res)=>{
                if(res.status === 200){
                    if(field === 'cg'){
                        profileForm.setValues({...profileForm.values,...profileForm.values,address:{...profileForm.values.address,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country}})
                    }
                }
            })
        }
    }

    const setRefused = (side,value) =>{
        if(side === 'cg'){
            setCgRefused(value)
        }
    }


    const toggleFormEdit = () => {
        if (editForm) profileForm.submitForm()
        if (profileForm.isValid || !editForm) setEditForm(!editForm)
    }
    const changeRacevalues = ((val) => {
        if (val === 1) {
            profileForm.values.white = !profileForm.values.white;
        } else if (val === 2) {
            profileForm.values.black = !profileForm.values.black;
        } else if (val === 3) {
            profileForm.values.hispanic = !profileForm.values.hispanic;
        } else if (val === 4) {
            profileForm.values.asian = !profileForm.values.asian;
        } else if (val === 5) {
            profileForm.values.nativeAmerican = !profileForm.values.nativeAmerican;
        } else if (val === 7) {
            profileForm.values.middleEastern = !profileForm.values.middleEastern;
        } else if (val === 6) {
            profileForm.values.pacificIslander = !profileForm.values.pacificIslander;
        } else if (val === 9) {
            profileForm.values.refusedRace = !profileForm.values.refusedRace;
        } else if (val === 8) {
            profileForm.values.otherRace = !profileForm.values.otherRace;
        } else { }
    })

    const submitForm = () => {
        // if (profileForm.values.date) {
        //     profileForm.values.date = profileForm.values.date?.split('/')
        //     profileForm.values.dobDay = profileForm.values.date[2]
        //     profileForm.values.dobMonth = profileForm.values.date[1]
        //     profileForm.values.dobYear = profileForm.values.date[0]
        // }
        let details = {
            address: {
                state: profileForm.values.address.state,
                country: profileForm.values.address.country,
                addressLine1: profileForm.values.address.addressLine1,
                addressLine2: profileForm.values.address.addressLine2,
                city: profileForm.values.address.city,
                postalCode: profileForm.values.address.postalCode,
            },
            language: profileForm.values.language ? profileForm.values.language : null,
            areaCode: profileForm.values.areaCode,
            phone: profileForm.values.phone,
            careManagerId: userDetails.caremanagerId,
            personId: initialData.personId,
            emailAddress: (profileForm.values.emailAddress && profileForm.values.emailAddress !== "") ? profileForm.values.emailAddress : null,
            firstName: profileForm.values.firstName,
            lastName: profileForm.values.lastName,
            subscriberId: profileForm.values.subscriberId,
            dobDay:cgRefused ? 0 : parseInt(profileForm.values.dobDay ?profileForm.values.dobDay:1),
            hispanic: profileForm.values.race.includes(3),
            dobMonth:cgRefused ? 0 : parseInt(profileForm.values.dobMonth ? profileForm.values.dobMonth:1),
            dobYear:cgRefused ? 0 : parseInt(profileForm.values.dobYear ? profileForm.values.dobYear:0),
            uninsured: profileForm.values.uninsured,
            maritalStat: profileForm.values.maritalStat ? profileForm.values.maritalStat : null,
            relationshipId:profileForm.values.relationShip ? profileForm.values.relationShip : null,
            caseId:history.location.state.caseId,
            pronouns: profileForm.values.pronouns ? profileForm.values.pronouns : null,
            nativeAmerican: profileForm.values.race.includes(5),
            pacificIslander: profileForm.values.race.includes(6),
            multiRacial: profileForm.values.multiRacial,
            preferredName: profileForm.values.preferredName,
            ssn: profileForm.values.ssn,
            homePhone: profileForm.values.homePhone,
            gender: profileForm.values.gender ? profileForm.values.gender : null,
            employmentId: profileForm.values.employmentId ? profileForm.values.employmentId : 0,
            educationId: profileForm.values.educationId ? profileForm.values.educationId : 0,
            incomeId: profileForm.values.incomeId ? profileForm.values.incomeId : 0,
            white: profileForm.values.race.includes(1),
            black: profileForm.values.race.includes(2),
            asian: profileForm.values.race.includes(4),
            middleEastern: profileForm.values.race.includes(7),
            middleName: profileForm.values.middleName,
            concentToContat: profileForm.values.phoneAuthorized,
            medicaid: profileForm.values.medicaid,
            medicare: profileForm.values.medicare,
            tricare: profileForm.values.tricare,
            otherInsurer: profileForm.values.otherInsurer,
            otherRace:  profileForm.values.race.includes(8),
            refused:  profileForm.values.race.includes(9)
        }
        API.patch('/persons', details).then(res => {
            dispatch(setAlert({ title: 'Success', subtitle: 'User Updated Successfully', type: 'success', active: true }))
        }).catch(e => {
            console.log(e)
        }).finally(() => history.goBack())
    }
    return (
        <div class="pb-8">
            <button className="flex mt-3 bg-tcolor px-5 py-1 rounded-lg items-center shadow-lg text-white ring-offset-2 hover:ring-2 hover:ring-tcolor dark:ring-offset-black whitespace-nowrap" onClick={() => history.goBack()}>
                <FaArrowLeft className="mr-3" /> {t("Back")}
            </button>
            <div className="text-center">
                <TabButtonGroup>
                    <TabButton index={0} value={tab} setValue={setTab}>
                       {t("Profile")}
                    </TabButton>
                    {(userDetails.role.length === 1 && userDetails.role[0]==='screener')  ? '' : <TabButton index={1} value={tab} setValue={setTab}>
                        {t("Service Usage")}
                    </TabButton>}
                </TabButtonGroup>
            </div>
            <TabGroup value={tab}>
                <Tab index={0} value={tab}>
                    <FormCard title={t("Basic Information")}>
                        <FavFormButton injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={() => setEditForm(false)} />
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldInput loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('firstName')} fieldProps={profileForm.getFieldProps('firstName')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} label={t("Middle Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('middleName')} fieldProps={profileForm.getFieldProps('middleName')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'1'} />
                            <FieldInput loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('lastName')} fieldProps={profileForm.getFieldProps('lastName')} edit={editForm} dataLoaded={dataLoaded} />
                            {/* <FieldInput loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('date')} fieldProps={profileForm.getFieldProps('date')} fieldHelper={profileForm.getFieldHelpers('date')} edit={editForm} dataLoaded={dataLoaded} /> */}
                            <div className="flex items-center gap-2 relative">
                              <FieldSelect disabled={cgRefused ? true:false} loading={formLoading} label={t('Year')} fieldMeta={profileForm.getFieldMeta(`dobYear`)} fieldProps={profileForm.getFieldProps(`dobYear`)} fieldHelper={profileForm.getFieldHelpers(`dobYear`)} options={years} edit={editForm} dataLoaded={dataLoaded} position="bottom" />
                               {/* <FieldInput  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={profileForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> */}
                               {cgRefused && <button style={{height:'fit-content',width:'fit-content',color:'white'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cg',false)}>Refused</button>}
                               {!cgRefused && <button style={{height:'fit-content',width:'fit-content',color:'black'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cg',true)}>Refused</button>}
                            </div>
                            <FieldInput loading={formLoading} label={t("Preferred Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('preferredName')} fieldProps={profileForm.getFieldProps('preferredName')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} label={t("SSN(Last 4 digit)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('ssn')} fieldProps={profileForm.getFieldProps('ssn')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'4'} />
                            <FieldInput loading={formLoading} label={t("Subscriber Id")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('subscriberId')} fieldProps={profileForm.getFieldProps('subscriberId')} edit={editForm} dataLoaded={dataLoaded} />
                        </div>
                    </FormCard>
                    <FormCard title={t("Contact Information")} injectClass="mt-12">

                        <div>Select caregiver’s most commonly used method of communication and enter below.</div>
                        &nbsp;
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldInput loading={formLoading} type="phone" label={t("Mobile Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('phone')} fieldProps={profileForm.getFieldProps('phone')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} type="phone" label={t("Home Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('homePhone')} fieldProps={profileForm.getFieldProps('homePhone')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} label={t("Email")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('emailAddress')} fieldProps={profileForm.getFieldProps('emailAddress')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Consented to be contacted")} autoComplete='off' fieldHelper={profileForm.getFieldHelpers('phoneAuthorized')} fieldMeta={profileForm.getFieldMeta('phoneAuthorized')} fieldProps={profileForm.getFieldProps('phoneAuthorized')} edit={editForm} dataLoaded={dataLoaded} />
                        </div>
                    </FormCard>
                    <FormCard title={t("Additional Information")} injectClass="mt-12">

                        <div><h1>This helps identify the best resources for the caregiver — not for eligibility requirements</h1></div>
                        &nbsp;
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldSelect loading={formLoading} label={t("Gender")} fieldMeta={profileForm.getFieldMeta('gender')} fieldHelper={profileForm.getFieldHelpers('gender')} fieldProps={profileForm.getFieldProps('gender')} edit={editForm} options={gender} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect loading={formLoading} label={t("Education Level")} fieldMeta={profileForm.getFieldMeta('educationId')} fieldHelper={profileForm.getFieldHelpers('educationId')} fieldProps={profileForm.getFieldProps('educationId')} edit={editForm} options={education} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect loading={formLoading} label={t("Preferred Language")} fieldMeta={profileForm.getFieldMeta('language')} fieldHelper={profileForm.getFieldHelpers('language')} fieldProps={profileForm.getFieldProps('language')} edit={editForm} options={language} position="top" dataLoaded={dataLoaded} />
                            <FieldMultiSelect loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta('race')} fieldHelper={profileForm.getFieldHelpers('race')} fieldProps={profileForm.getFieldProps('race')} edit={editForm} options={raceEthnicity} position="bottom" dataLoaded={dataLoaded} onChange={changeRacevalues(profileForm.getFieldProps('race'))} />
                            <FieldSelect loading={formLoading} label={t("Total Household Income")} fieldMeta={profileForm.getFieldMeta('incomeId')} fieldHelper={profileForm.getFieldHelpers('incomeId')} fieldProps={profileForm.getFieldProps('incomeId')} edit={editForm} options={Income} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect loading={formLoading} label={t("Marital Status")} fieldMeta={profileForm.getFieldMeta('maritalStat')} fieldHelper={profileForm.getFieldHelpers('maritalStat')} fieldProps={profileForm.getFieldProps('maritalStat')} edit={editForm} options={maritalStatus} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect loading={formLoading} label={t("Employment Status")} fieldMeta={profileForm.getFieldMeta('employmentId')} fieldHelper={profileForm.getFieldHelpers('employmentId')} fieldProps={profileForm.getFieldProps('employmentId')} edit={editForm} options={employment} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect loading={formLoading} label={t("Pronouns")} fieldMeta={profileForm.getFieldMeta('pronouns')} fieldHelper={profileForm.getFieldHelpers('pronouns')} fieldProps={profileForm.getFieldProps('pronouns')} edit={editForm} options={pronouns} position="bottom" dataLoaded={dataLoaded} />

                        </div>
                    </FormCard>

                    <FormCard title={t("Primary Address")} injectClass="mt-12">
                        <div>The primary address will be used as the default location for caregiver resources</div>
                        &nbsp;
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                        <FieldInput loading={formLoading} label={t("ZIP Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.postalCode')} fieldProps={profileForm.getFieldProps('address.postalCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'cg')}/>
                            <FieldInput loading={formLoading} label={t("Address Line 1")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine1')} fieldProps={profileForm.getFieldProps('address.addressLine1')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput loading={formLoading} label={t("Address Line 2")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine2')} fieldProps={profileForm.getFieldProps('address.addressLine2')} edit={editForm} dataLoaded={dataLoaded} />
                            {/* <FieldInput loading={formLoading} label={t("Address Line 3")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine3')} fieldProps={profileForm.getFieldProps('address.addressLine3')} edit={editForm} dataLoaded={dataLoaded} /> */}
                            <FieldInput loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.city')} fieldProps={profileForm.getFieldProps('address.city')} edit={editForm} dataLoaded={dataLoaded} />
                            {/* <FieldInput loading={formLoading} label={t("Country")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.country')} fieldProps={profileForm.getFieldProps('address.country')} edit={editForm} dataLoaded={dataLoaded} /> */}
                            <FieldSelect loading={formLoading} label={t("State")} fieldMeta={profileForm.getFieldMeta('address.state')} fieldHelper={profileForm.getFieldHelpers('address.state')} fieldProps={profileForm.getFieldProps('address.state')} edit={editForm} options={states} position="top" dataLoaded={dataLoaded} />
                            
                        </div>
                    </FormCard>

                    <FormCard title={t("Insurance")} injectClass="mt-12">

                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldCheckbox loading={formLoading} label={t("Medicaid")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('medicaid')} fieldProps={profileForm.getFieldProps('medicaid')} fieldHelper={profileForm.getFieldHelpers('medicaid')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Medicare")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('medicare')} fieldProps={profileForm.getFieldProps('medicare')} fieldHelper={profileForm.getFieldHelpers('medicare')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Tricare")} fieldMeta={profileForm.getFieldMeta('tricare')} fieldProps={profileForm.getFieldProps('tricare')} fieldHelper={profileForm.getFieldHelpers('tricare')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Other Insurer")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('otherInsurer')} fieldProps={profileForm.getFieldProps('otherInsurer')} fieldHelper={profileForm.getFieldHelpers('otherInsurer')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldCheckbox loading={formLoading} label={t("Uninsured")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('uninsured')} fieldProps={profileForm.getFieldProps('uninsured')} fieldHelper={profileForm.getFieldHelpers('uninsured')} edit={editForm} dataLoaded={dataLoaded} />
                        </div>
                    </FormCard>
                    {editForm &&
                        <div className="flex justify-center mt-7 mx-5">
                            <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-500 hover:bg-opacity-80 text-white" onClick={() => setEditForm(false)}>{t("Cancel")}</button>
                            <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Save")}</button>
                        </div>
                    }
                </Tab>
                <Tab index={1} value={tab}>
                    <ServiceUsage carereceiverId={match?.params?.carereceiver_id} />
                </Tab>
            </TabGroup>

        </div>
    )
}

export default CarereceiverEdit