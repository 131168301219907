import { SET_ALERT, SET_ANIMATE, SET_LOADING, SET_PAGE_TITLE, SET_SIDEBAR, SET_THEME, SET_LANGUAGE ,SET_HIDE} from "./constants"

export const setTheme = value => {
    localStorage.setItem('theme', value)
    if(value === 'dark') document.documentElement.classList.add('dark')
    else document.documentElement.classList.remove('dark')
    return {
        type: SET_THEME,
        payload: value
    }
}

export const setLanguages = value =>{
    localStorage.setItem('language', value) 
    return {type: SET_LANGUAGE, payload: value}
}
    



export const setAlert = value => ({type: SET_ALERT, payload: value})
export const setPageTitle = value => {
    document.title = value
    return {type: SET_PAGE_TITLE, payload: value}
}
export const setLoading = value => ({type: SET_LOADING, payload: value})
export const setSidebar = value => ({type: SET_SIDEBAR, payload: value})
export const setAnimate = value => {
    localStorage.setItem('animate', value)
    return {type: SET_ANIMATE, payload: value || value === 'true'}
}
export const setHide = value => ({type: SET_HIDE, payload: value})