
import { useEffect, useState } from "react"


import { motion } from 'framer-motion'
import { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'

import PageLoader from "../CaseDetails/PageLoader";
import ScreenerDemographic from '../../pages/External Screener/ScreenerDemographic'
import { useTranslation } from "react-i18next"



createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)


const ScreenerScore = ({history }) => {

    // const [editForm] = useState(false)
    const [loading, setLoading] = useState(true)
    const [summary, setSummary] = useState([false]);
    const { t } = useTranslation()
    useEffect(() => {
        setSummary(history.location.state)
        setLoading(false)
    }, [])// eslint-disable-line


    return (
        <div className="min-h-screen flex flex-col bg-blue-50 dark:bg-ddark overflow-hidden p-10">
        <div className="h-full">
            <div className="flex">
            <ScreenerDemographic caseId={history.location.state.caseId}/>
            <div className={'flex-1'}>
             {
                loading ? <PageLoader/>:
                <div className="min-h-screen justify-center flex flex-1 flex-col bg-blue-50 dark:bg-ddark text-black dark:text-white overflow-hidden">
                     {/* <motion.div className="fixed left-8 top-5" variants={TextAnimateXRev} initial="hidden" animate="visible">
                <LogoImg size="200px" />
            </motion.div>
            <motion.div className="fixed right-8 top-5" variants={TextAnimateXRev} initial="hidden" animate="visible">
                <DarkModeSwitch />
            </motion.div> */}
                     <div>
                        {/* <FormCard> */}
                            <div class="text-xl"><h1>{t("Here are your TCARE Caregiver Assessment results")}:</h1> </div>
                               <motion.div><br></br><br></br>
                                <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-2 px-32">
                                    <div class="flex mb-4">
                                        <div title={summary.cgEmotionalNeedsMsg.cgRelationshipBurdenMsg} class="w-1/2">{t("Relationship Burden")}:</div>
                                        <div class="w-1/2">
                                            {
                                                summary.cgEmotionalNeeds.cgRelationBurden === "1" ? <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgRelationBurden === "2" ? <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgRelationBurden === "3"? <div className=" answer-3">{t("High")}</div> : ''
                                            }
                                        </div>
                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgObjectiveBurdenMsg} class="w-1/2">{t("Objective Burden")}:</div>
                                        <div class="w-1/2">
                                            {
                                                summary.cgEmotionalNeeds.cgObjectiveBurden === "1" ? <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgObjectiveBurden === "2" ? <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgObjectiveBurden === "3" ? <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>

                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgStressBurdenMsg} class="w-1/2">{t("Stress Burden")}:</div>
                                        <div class="w-1/2 ">
                                            {
                                                summary.cgEmotionalNeeds.cgStressBurden === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgStressBurden === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgStressBurden === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>

                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgUpliftMsg} class="w-1/2">{t("Intension To Place")}:</div>
                                        <div class="w-1/2">
                                            {
                                                summary.cgEmotionalNeeds.cgUplifts === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgUplifts === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgUplifts === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>
                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgDepressionMsg} class="w-1/2">{t("Depression")}:</div>
                                        <div class="w-1/2">
                                            {
                                                summary.cgEmotionalNeeds.cgDepression === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgDepression === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgDepression === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>
                                    </div>
                                    <div class="flex mb-4">
                                    <div title={summary.cgEmotionalNeedsMsg.cgIdentityDiscrepMsg} class="w-1/2">{t("Identity Discrepancy")}:</div>
                                        <div class="w-1/2">
                                            {
                                                summary.cgEmotionalNeeds.cgIdentityDiscrep === "1" ?
                                                    <div className="answer-4">{t("Low")}</div> :
                                                    summary.cgEmotionalNeeds.cgIdentityDiscrep === "2" ?
                                                        <div className="answer-2">{t("Medium")}</div> :
                                                        summary.cgEmotionalNeeds.cgIdentityDiscrep === "3" ?
                                                            <div className="answer-3">{t("High")}</div> : ''
                                            }
                                        </div>
                                        
                                    </div>
                                   
                                </div>
                            </motion.div>
                            <div>
                            <p class="text-center text-xl">{t("Thanks For being a Caregiver...")}</p>
                            </div><br></br><br></br>
                            <div>
                            <p class="text-center text-base">{t("A family caregiver specialist from Colorado will be reaching out to")}</p>
                            <p class="text-center text-base">{t("you regarding next steps in getting you the tailored resources you")}</p>
                            <p class="text-center text-base">{t("need.")}</p>
                            </div><br></br>
                            <div class="flex justify-center ...">
                                <a className="answer-5" href="https://tcare.ai/testimonials/" target="_blank" rel="noopener noreferrer">
                                    LEARN ABOUT TCARE
                                </a>
                            </div>                     
                        {/* </FormCard> */}

                    </div>
                    
                       
                </div>
                  
            }
        </div>
            </div>
        </div>
        </div>
    )
}

export default ScreenerScore