import { motion } from 'framer-motion'
import { PopInAnim, TextAnimateXRev,TextAnimateX } from '../commons/anims'
import { GoSignOut } from 'react-icons/go'
import {  FaUserAlt } from 'react-icons/fa'
import { BiMenuAltLeft } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { setLoggedIn,setLoadAllCase, setTriggerSubidSearch} from '../store/auth/actions'
import FlowText from './snippets/FlowText'
import { useEffect, useState } from 'react'
import { setSidebar } from '../store/theme/actions'
import { useTranslation } from 'react-i18next'
import { useMsal } from "@azure/msal-react";
import {FiSearch} from 'react-icons/fi'
import {AiFillCloseCircle} from 'react-icons/ai'
import API from '../axios/API'
import { useHistory } from 'react-router-dom'


const Topbar = () => {

    const { userDetails, isLoggedIn } =  useSelector(state => state.auth)
    const {pageTitle} = useSelector(state => state.theme)
    const [showTitle, setShowTitle] = useState(true)
    const [title, setTitle] = useState('')
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const username =JSON.parse(localStorage.getItem('userDetails'))
    const [openSearch, setOpenSearch] = useState(false)
    const { instance } = useMsal();
    const [subScriberId,setSubscriberId] = useState('')
    const organization = useSelector(state => state.auth.organization)
    const history = useHistory()
    const [subError,setSubError] = useState(false)
    const roles = useSelector(state => state.auth.userDetails?.role)
    // const allSearch = useSelector(state => state.auth.enableAllSearch)
    const subSearch = useSelector(state => state.auth.triggersubSearch)
    const userDetail = useSelector(state => state.auth.userDetails)
    const [search, setSearch] = useState(true)   
    const logout = () => {
        if(userDetails.authType === 'sso') {
            instance.logoutRedirect({
                // postLogoutRedirectUri: "/",
                // mainWindowRedirectUri: "/"
            });
            localStorage.clear()
        }
        dispatch(setLoggedIn(false))
    }
    const viewSidebar = () => dispatch(setSidebar(true))
    
    useEffect(() => {
        const pathname = history.location.pathname;
        if(pathname === '/subscriberId') {    
            setSearch(false)
        }else {
            setSearch(true)
        }
        if(isLoggedIn) {
            setShowTitle(false)
            setTitle(pageTitle)
            setTimeout(() => {
                setShowTitle(true)
            }, 100)
        }       
    }, [pageTitle, isLoggedIn]) // eslint-disable-line

    const callSubscriberId = ()=>{
        let regex = /^[a-zA-Z0-9_]*$/
        if((subScriberId.length === 9 || subScriberId.length === 14) && regex.test(subScriberId)){
            API.get(`organizations/${organization}/cases:search?subId="${subScriberId}"`).then((res)=>{
                if(res.status === 200){
                    dispatch(setLoadAllCase(false))
                    dispatch(setTriggerSubidSearch(!subSearch))
                    history.push('/case_list',{state:{list:res.data.body,from:"GeneralSearch",subId:subScriberId}})               
                }
            })
        }else{
            setSubError(true)
            setTimeout(()=>{
                setSubError(false)
            },3000)
        }
    }

    const setValue=(value)=>{
        if(value.target.value.length > 0){
            if(value.keyCode === 13){
                setSubscriberId(value.target.value)
                callSubscriberId()
            }else{
                setSubscriberId(value.target.value)
            }
        }else{
            dispatch(setLoadAllCase(true))
            enableSearch(false)
        }
    }

    const enableSearch=(value)=>{
        setOpenSearch(value)
        if(!value){
            setSubscriberId('')
            // dispatch(setEnableSearch(!allSearch))
            dispatch(setLoadAllCase(!value))
             dispatch(setTriggerSubidSearch(!subSearch))
            history.replace()
        }
    }

    return (
        <div className="w-full flex px-4 py-2 justify-between items-center h-16">
            <motion.span variants={ TextAnimateXRev } initial="hidden" animate="visible" className="flex items-center">
                <BiMenuAltLeft size="25px" className="cursor-pointer lg:hidden text-tcolor dark:text-white" onClick={viewSidebar} />
                { showTitle && <span className="ml-7 text-xl text-tcolor dark:text-gray-300 font-bold hidden sm:inline-block"><FlowText text={ t(title) || '' } /></span> }
            </motion.span>
            <div className="flex justify-end items-center">
                {search && roles && ((userDetail.orgId === parseInt(organization) && userDetail.parentRole.includes('screener')) || (userDetail.orgId !== parseInt(organization) && userDetail.childRole.includes('dynamic_scr'))) && <motion.div className="relative mr-5 ml-5 cursor-pointer flex flex-1">
                    {subError && <motion.div className="text-red-500 m-2 text-sm">{t("Please provide a valid subscriber id")}</motion.div>}
                    {openSearch  && <motion.div variants={TextAnimateX}  initial="hidden" animate="visible" className={`bg-transparent flex flex-1 ring-1 dark:ring-white rounded-full`}><input maxLength={userDetails.env ==='MOLINA'?'14':'9'} className={'focus:outline-none focus:ring-0 bg-transparent text-gray-600 dark:text-white px-5 py-1'}  type="text" id="subscriber" placeholder={t("Search Subscriber Id")} onKeyUp={(ev)=> setValue(ev)} onInput={(ev)=> setValue(ev)} onChange={(event) => {
                        const modifiedValue = event.target.value.replace(/\s/g, '');
                        event.target.value = modifiedValue;
                    }} />
                    <span className="relative right-2 top-1 text-xl flex">
                            <FiSearch className="text-tcolor dark:text-white ml-2" onClick={()=>callSubscriberId(subScriberId)}/>
                        </span></motion.div>}
                    {openSearch ? <AiFillCloseCircle className={'text-tcolor dark:text-white m-2'} onClick={()=>enableSearch(false)}/> : <FiSearch className={'text-tcolor dark:text-white m-2'} onClick={()=>enableSearch(true)}/>}
                </motion.div>}
                <motion.div variants={ PopInAnim } initial="hidden" animate="visible" className={ `bg-tcolor flex px-6 py-0.5 mr-3 rounded-tl-xl rounded-br-xl  items-center text-sm` }>
                    <FaUserAlt className={ `mr-3 text-black` } />
                    <span className="text-black font-bold">
                        <FlowText text={ username.cmfirstName } />
                    </span>
                </motion.div>
                {/* <FieldSelect  fieldMeta={'Intulogic & co'} fieldHelper={'Intulogic & co'} fieldProps={'Intulogic & co'} edit={true} options={organization} position="bottom" /> */}
                <motion.button variants={ PopInAnim } initial="hidden" animate="visible" className={ `group bg-gray-300 dark:bg-gray-300 hover:bg-indigo-500 dark:hover:bg-indigo-500 text-gray-600 dark:text-gray-600 hover:text-white dark:hover:text-white font-bold transition-all duration-300 px-3 py-0.5 rounded-lg shadow-xl cursor-pointer mr-4 text-sm flex items-center` } onClick={ logout }>
                   {t("Logout")}
                    <GoSignOut className={ `text-indigo-500 group-hover:text-white transition-all duration-300 ml-3 mt-0.5 text-base` } />
                </motion.button>
            </div>
        </div>
    )
} 

export default Topbar