export const summaryData = {
    "orgId": 1,
    "caseId": 543,
    "careManagerId": 9,
    "caregiverId": 1148,
    "careReceiverId": 1149,
    "cmFirstName": "Vinoth",
    "cmLastName": "Xavier",
    "cgFirstName": "robert",
    "cgLastName": "tony",
    "crFirstName": "steve",
    "crLastName": "rogers",
    "relationship": "Domestic partner",
    "status": "Summary in Progress",
    "dob": "31",
    "gender": null,
    "maritialStatus": null,
    "race": null,
    "education": "Attended high school, did not graduate",
    "employment": "Work part-time",
    "income": "$25,000 - $34,999",
    "cgPhoneNumber": "942-751-85555",
    "careManagerEmail": "vijay.p@intulogic.in",
    "specialCircumstances": null,
    "caseNotes": [],
    "cgStatus": {
        "caregiverPhase": "Phase 1",
        "phaseId": "1",
        "intentionToPlace": "No"
    },
    "cgMsg": {
        "caregiverType": "Stressfull Snapdragons",
        "caregiverMsgType": "May have multiple sources of stress.  Stress is usually not related to direct care responsibilities. Stress often tied to concerns about the future.  May stem from other sources, such as a job or relationships with people other than the care receiver.  Can be helped by introducing general stress management programs and techniques.",
        "intentionPlaceMsg": "Acting as relative almost all the time.",
        "caregiverPhaseMsg": "1"
    },
    "cgEmotionalNeeds": {
        "cgRelationBurden": "1",
        "cgObjectiveBurden": "1",
        "cgStressBurden": "2",
        "cgUplifts": "1",
        "cgDepression": "1",
        "cgIdentityDiscrep": "1"
    },
    "cgEmotionalNeedsMsg": {
        "cgRelationshipBurdenMsg": "The extent to which the care receiver makes demands for care and attention over and above the level that the caregiver perceives is warranted by the care receiver’s condition. The perception that the care receiver is making excessive demands creates a negative psychological state for the caregiver that is directly linked to the caregiver’s unique relationship with the care receiver.",
        "cgObjectiveBurdenMsg": "A negative psychological state that results from the perception that caregiving activities and responsibilities are infringing on other aspects of the caregiver’s life including time and energy to address other family obligations, leisure activities and personal needs.",
        "cgStressBurdenMsg": "A generalized form of negative affect that results from caregiving. It is not the result of any specific event, task, or interaction.",
        "cgUpliftMsg": "A positive psychological outcome associated with caregiving. It includes such things as the direct enjoyment from caregiving tasks, an improved relationship with the care receiver, and generalized positive affect.",
        "cgDepressionMsg": "An affective disorder characterized by negative affect. This measure is a shortened version of the Center for Epidemiological Studies - Depression Scale (CESD). This measure captures four underlying dimensions: dysphoria, somatic complaints, positive affect, and interpersonal distress.",
        "cgIdentityDiscrepMsg": "A negative psychological state that accrues when the activities and responsibilities that a caregiver assumes with regard to the care receiver are inconsistent with the caregiver’s expectations or personal norms concerning these activities and responsibilities."
    },
    "crNeeds": {
        "crAdl": 1,
        "crIadl": 1,
        "crProblemBehavior": 3,
        "memoryIssue": "Yes"
    },
    "crNeedsMsg": {
        "crAdlMsg": "The care receiver’s ability to perform essential personal care activities such as eating, bathing, dressing, and grooming.",
        "crIadlMsg": "The care receiver’s ability to perform\ntasks that are instrumental for living independently, such as preparing meals, doing laundry, housework, opening and sorting mail, and paying bills.",
        "crProblemBehaviourMsg": "Words, gestures, or actions of the care receiver that may be upsetting to others and negatively impact either the care receiver or caregiver’s life."
    },
    "cgObligations": {
        "personalCareHrs": 0,
        "pcHr": 1,
        "personalCareLevel": null,
        "householdCareHrs": 0,
        "householdCareLevel": null,
        "hcHr": 1,
        "otherAssistanceHrs": 0,
        "otherAssistanceLevel": null,
        "oaHr": 1,
        "totalDependents": 34,
        "adultDependents": 22,
        "childDependents": 12
    },
    "otherUsefullInfo": {
        "availabilityFamily": null,
        "formalServices": null,
        "specialCircumstances": null,
        "summOne": null,
        "summTwo": null,
        "summThree": null,
        "summFour": null,
        "formalServicesAssess": "869"
    },
    "type": "At risk",
    "enableCall": 1,
    "enableSchedule": 1
}