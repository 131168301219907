import { useCallback, useMemo, useState, useEffect } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'

import { datatableTheme } from '../../data/dataTableTheme'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from "react-router-dom"
import { FaUserEdit, FaCheck, FaTimesCircle, FaUserMinus, FaInfoCircle,FaPhoneAlt,FaEnvelope } from 'react-icons/fa'
import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import AddButton from '../../components/snippets/AddButton'
import API from '../../axios/API'
import { setAlert } from '../../store/theme/actions'
import Dialog from '../../components/snippets/Dialog'
import FlowText from '../../components/snippets/FlowText'
import { useTranslation } from 'react-i18next'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const Adminlist = ({onClose}) => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [adminList, setAdminList] = useState([]);
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(false);
    const [removeuser, setRemoveUser] = useState([])
    const [activateuser, setActivateUser] = useState([])
    const [deactivateuser, setDeactivateUser] = useState([])
    const [deactivatePopup, setDeactivatePopup] = useState(false)
    const [activatePopup, setActivatePopup] = useState(false)
    const [removePopup, setRemovePopup] = useState(false)
    const [pplId, setPplId] = useState()
    const organization = useSelector(state => state.auth.organization)
    const dispatch = useDispatch();
    const { t } = useTranslation()
    let orgId = localStorage.getItem('orgid')
    
    useEffect(() => {
        setLoading(true);
        API.get(`user?roles=admin`).then((res) => {
            setAdminList(res.data.body);
        }).catch(e => {
            console.log(e)
        }).finally(() => setLoading(false))
    }, [removeuser, activateuser, deactivateuser,organization])// eslint-disable-line

    const removeUser = () => {
        API.delete(`users/${pplId}?orgId=${orgId}`).then(res => {
            setRemoveUser(res)
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('User Removed Successfully'), type: 'success', active: true }))
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Invalid Id'), type: 'error', active: true }))
            } else if (res.status === 404) {
                dispatch(setAlert({ title: t('Error'), subtitle:t('User Not found'), type: 'error', active: true }))
            } else { }
        }).catch(e => {
            console.log(e)
        }).finally(()=>setRemovePopup(false))
    }


    const deactivateUser = () => {
        API.patch(`users/${pplId}:deactivate?orgId=${orgId}`).then(res => {
            setDeactivateUser(res)
            if (res.status === 200) {
                dispatch(setAlert({ title:t('Success'), subtitle: t('User Deactivated Successfully'), type: 'success', active: true }))
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Invalid Id'), type: 'error', active: true }))
            } else if (res.status === 404) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('User Not Found'), type: 'error', active: true }))
            } else { }
        }).catch(e => {
            console.log(e);
        }).finally(()=>setDeactivatePopup(false))
    }

    const activateUser = () => {
        API.patch(`users/${pplId}:reactivate?orgId=${orgId}`).then(res => {
            setActivateUser(res)
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('User Activated Successfully'), type: 'success', active: true }))
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Invalid Id'), type: 'error', active: true }))
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('User Not Found'), type: 'error', active: true }))
            } else { }
        }).catch(e => {
            console.log(e)
        }).finally(()=>setActivatePopup(false))
    }

    const openDeactivatePopup = (value) => {
        setPplId(value)
        setDeactivatePopup(true)
    }

    const openActivatePopup = (value) => {
        setPplId(value)
        setActivatePopup(true)
    }

    const openRemovePopup = (value) => {
        setPplId(value)
        setRemovePopup(true)
    }
    const colums = useMemo(() => {
        return [
            { name: t('Name'), selector:row => `${row.firstName} ${row.lastName}`, grow: 1, sortable: true },
            { name: t('User Name'), selector: 'userName', grow: 1, sortable: true },
            // { name: t('Phone'), selector: 'phone', grow: 1, sortable: true },
            // { name: t('E-Mail'), selector: 'email', grow: 1, sortable: true },
            {name:t('Phone'),cell:row=>{return <div style={{display:'flex',fontSize:'13px',marginTop:'15px'}}><a href={`tel: +1${row.phone && row.phone.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor p-2 rounded-lg text-white" title={'Call'}>
            <FaPhoneAlt size="10px" style={{margin:'auto'}}/></a>&nbsp;&nbsp;<span>{row.phone}</span></div>},grow:1,sortable: true},
            // { name: t('E-Mail'), selector: 'email',grow:1, sortable: true },
            {name:t('E-Mail'),cell:row=>{return <div style={{display:'flex',fontSize:'13px',marginTop:'15px'}}><a href={`mailto:${row.email}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={'Mail'}>
            <FaEnvelope size="10px" style={{margin:'auto'}} />
        </a>&nbsp;&nbsp;<span>{row.email}</span> </div>},grow:1,sortable: true},
            { name: t('Organization Name'), selector: 'organizationName', grow: 1, sortable: true },
            {
                name: t('Active'),
                button: true, grow: 1, cell: row => <>
                    {(row.active === false && row.status === "PENDING_CERTIFICATION") ? <button ><FaInfoCircle size="20px" title={'Pending Certification'} className="text-blue-600 dark:text-blue-300" /></button> :
                        (row.active === true && row.status === "ACTIVE") ? <button onClick={() => openDeactivatePopup(row.pplId)}> <FaCheck size="20px" title={'Click to deactivate'} className="text-green-600 dark:text-green-300" /></button>:
                            (row.active === false && row.status === "DEACTIVATED") ? <button onClick={() => openActivatePopup(row.pplId)}><FaTimesCircle title={'Click to activate'} size="20px" className="text-yellow-600 dark:text-yellow-300" /></button> : ''}
                </>, sortable: true,
            },
            {
                name: t('Action'), button: true, grow: 1, cell: row => <>
                    {(row.active === false && row.status === "PENDING_CERTIFICATION") ? <><Link to={`editUser/${row.pplId}`}><FaUserEdit size="20px" title={'Edit'} className="text-gray-600 dark:text-blue-300" /></Link>&nbsp;&nbsp;
                        <button onClick={() => openRemovePopup(row.pplId)}><FaUserMinus size="20px" title={'Remove'} className="text-red-600 dark:text-red-300" /></button></> : (row.active === false && row.status === "DEACTIVATED") ? <button onClick={() => openRemovePopup(row.pplId)}><FaUserMinus size="20px" title={'Remove'} className="text-red-600 dark:text-red-300" /></button> :
                        (row.active === true && row.status === "ACTIVE") ? <Link to={`editUser/${row.pplId}`}><FaUserEdit size="20px" title={'Edit'} className="text-gray-600 dark:text-blue-300" /></Link> : ''}
                </>,
            },
        ]
    }, [])// eslint-disable-line
    const SubHeader = useMemo(() => (
        <div className="flex items-center">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} />
            <AddButton to="/addnewuser" title={t("Add New Admin")} />
        </div>
    ), [filter]) // eslint-disable-line
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return adminList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [adminList, filter])// eslint-disable-line
    const ExpandComponent = useCallback(row => {
        return (

            <div className="bg-bluegray-300 dark:bg-ldark" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>

        )
    }, [])
    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })

    return (
        <div>
            {
                loading ?
                    <DatatableLoader /> :
                    <div className=" mt-4 p-2 bg-bluegray-200 dark:bg-ddark rounded-lg">
                        <DataTable title="casedetails" columns={colums} data={filteredcasedetails} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} noDataComponent={t('There are no records to display')} />
                    </div>
                    // <div className=" mt-4 p-2 bg-bluegray-200 dark:bg-ddark rounded-lg">
                    // <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>
                    // </div>
            }

            {/* Deactivate user */}
            <Dialog title="Deactivate User" showDialog={deactivatePopup} onClose={() => setDeactivatePopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t(`Are you sure you want to deactivate this admin `)} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setDeactivatePopup(false)}>No</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => deactivateUser()}>Yes</button>
                    </div>
                </div>
            </Dialog>

            {/* Activate user */}
            <Dialog title="Activate User" showDialog={activatePopup} onClose={() => setActivatePopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t(`Are you sure you want to activate this admin `)} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setActivatePopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => activateUser()}>{t("Yes")}</button>
                    </div>
                </div>
            </Dialog>

            {/* remove admin */}
            <Dialog title="Remove User" showDialog={removePopup} onClose={() => setRemovePopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t(`Are you sure you want to remove this admin `)} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setRemovePopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => removeUser()}>{t("Yes")}</button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default Adminlist