import { Route, Switch } from "react-router"
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Login from "../pages/Login"
import Auth from "./Auth"
import HomeRoot from "./HomeRoot"
import { setAlert, setAnimate, setLoading, setTheme, setLanguages } from "../store/theme/actions"
import Alert from "../components/Alert"
import Loading from "../components/snippets/Loading"
import API from "../axios/API"
import ResetPassword from "../pages/ResetPassword"
import Screener from '../pages/External Screener/Screener'
import IntakeScreener from '../pages/External Screener/IntakeScreener'
import ScreenerScore from "../pages/External Screener/ScreenerScore"
import { withRouter } from "react-router-dom"
import {useTranslation} from 'react-i18next'
import {setLoggedIn } from "../store/auth/actions"
import { useMsal } from "@azure/msal-react"
import { useLayoutEffect } from "react"


const Root = ({history}) => {

    const {themeMode, alert, loading} = useSelector(state => state.theme)

    const dispatch = useDispatch()
    const {t} = useTranslation()
    

    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'



    const setHideAlert = () => dispatch(setAlert({active: false}))
    const languages = useSelector(state => state.theme.languages)


    
    useEffect(() => {
        let localTheme = localStorage.getItem('theme')
        if(localTheme && localTheme !== themeMode) {
            if(localTheme === 'light' || localTheme === 'dark') {
                dispatch(setTheme(localTheme))
                return
            }
            
        }
        dispatch(setTheme(prefersDarkMode))
        // animate
        let animate = localStorage.getItem('animate')
        if(animate === 'false') dispatch(setAnimate(false))
    }, [prefersDarkMode]) // eslint-disable-line
    useEffect(() => {
        let lang = localStorage.getItem('language')
        if(lang !== undefined && languages.find(c => c.code === lang)!== undefined) {
            dispatch(setLanguages(lang))
        }else{
            dispatch(setLanguages('en_US'))
        }
    },[]) //eslint-disable-line
    useEffect(() => {
        // adding interceptors to api
        API.interceptors.request.use(request => {
            dispatch(setLoading(true))
            return request
        })
        API.interceptors.response.use(
            // response => {
            //     dispatch(setLoading(false))
            //     return response
            // },
            // error => {
            //     dispatch(setLoading(false))
            //     return Promise.reject(error)
            // }
            response => {
                dispatch(setLoading(false))
                return response
            }, error => {
                if (error.response && error.response.status === 401) {
                    dispatch(setAlert({ title: t('unauthorized'), subtitle: t('Unauthorized to proceed'), active: true, type: 'error', force: true }))
                    dispatch(setLoggedIn(false))
                    history.push('/login')
                }
                dispatch(setLoading(false))
                return Promise.reject(error)
            }
        )
    }, []) // eslint-disable-line

    const { instance } = useMsal();
    useLayoutEffect(() => {
        instance.handleRedirectPromise()
          .then((s) => {
            if (s !== null && s.account != null)
              //        currentAccount.setCurrentAccount(s.account.username, s.account.homeAccountId, s.account.tenantId);
              console.log('success');
          })
          .catch((a) => {
            console.log('err', a);
          });
    }, []); //eslint-disable-line

  

   
    // useEffect(()=>{
    //     if(userDetails && userDetails.sub){
    //         const user = {
    //             email: userDetails.email,
    //             recordFrontendLogging: false,
    //             recordFrontendNetworkCalls: false,
    //             recordFrontendUI: true,
    //             associateFrontendLogsWithUser: true,
    //           }

    //           if (!user || !user.recordFrontendUI) {
    //             // Record nothing
    //             setRecordUI(false)
    //           } else {
    //             setRecordUI(true)
    //           }
          
            
               
    // if (user && userDetails.email) {
    //     LogRocket.init('uibbsf/tcare5', {
    //       network: {
    //         isEnabled: true,
    //       }
    //     })
    //   } else {
    //     LogRocket.init('uibbsf/tcare5', {
    //       network: {
    //         isEnabled: false,
    //       }
    //     })
    //   }
  
            
          
    //           if (user && user.email) {
    //             LogRocket.identify(userDetails.email, {
    //               name:userDetails.sub,
    //               email:userDetails.email
    //               // Any additional fields
    //             })
    //           }
          
    //     }
    // },[])
    const element = document.getElementById('jsd-widget')
    element.setAttribute('aria-label', 'Tcare Help')
    
    return (
        <Auth>
            <div className="h-full">
                { loading && <Loading /> }
                <Alert title={ alert.title } subtitle={ alert.subtitle } active={ alert.active } setActive={ setHideAlert } type={alert.type}/>
                <Switch>
                    <Route path="/login" exact component={ Login } />
                    <Route path="/reset/:token" exact component={ ResetPassword } />
                    <Route path="/molina/molina-tx" exact component={ Screener } />
                    <Route path="/screener/intake" exact component={ IntakeScreener}/>
                    <Route path="/screener/score" exact component={ ScreenerScore } />
                    <Route path="" component={ HomeRoot } />
                </Switch>
            </div>
        </Auth>
    )
}

export default withRouter(Root)