import * as Yup from 'yup'
import FormCard from "../../components/snippets/FormCard"
import FieldInput from '../../components/snippets/FieldInput'
import { useFormik } from 'formik'
import { useState,useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setAlert } from '../../store/theme/actions'
import API from '../../axios/API'
import { useTranslation } from 'react-i18next'

const initialFormValues = {
    password: '',
    confirmPassword: ''
}

Yup.addMethod(Yup.string, 'validatePassword', function () {
    return this.test('Password must be atleast 8 characters with 1 uppercase, 1 numeric character & 1 special character', "Password must be atleast 8 characters with 1 uppercase, 1 numeric character & 1 special character", (value) => {
        if (value === undefined || value === '') return true //eslint-disable-next-line
        let r = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ -/:-@\[-`{-~]).{8,64}$/g
        return r.test(value)
    })
})

const ChangePassword = () => {

    const [formLoading, setFormLoading] = useState(false)
    const [profile,setProfile] = useState()
    const [dataLoaded,setDataLoaded] = useState(false)
    const { userDetails } = useSelector(state => state.auth)
    const dispatch = useDispatch()
    const { t } = useTranslation()

    useEffect(() => {
        API.get(`/users`).then(res => {
            if (res.data.body) {
                setProfile(res.data.body)
                setFormLoading(false)
                setDataLoaded(true)
            }
        }).catch(e => {
            // console.log(e);
        }).finally()
    }, [])// eslint-disable-line

    const formSchema = Yup.object().shape({
        password: Yup.string().validatePassword().required("password is required"),
        confirmPassword: Yup.string().validatePassword().required("confirm password is required")
    })
    const passwordForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: formSchema,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            if(values.password !== values.confirmPassword) {
                dispatch(setAlert({title: "Password not match", subtitle: "Password and Confirm Password should match", active: true, type:'error'}))
            }else{
                submitForm()
            }
            setFormLoading(false)
        }
    })

    const submitForm = () =>{
        let details = {
            password : passwordForm.values.password,
            userName : profile.userName,
            personId : profile.pplId
        }
        API.patch(`users/${userDetails.caremanagerId}/password`,details).then(res=>{
            if(res.status === 200){
                dispatch(setAlert({title:"Success",subtitle:"Password changed successfully", type:'success',active:true}))
            }else if(res.status === 400){
                dispatch(setAlert({title:"Error",subtitle:"Invalid password", type:'error',active:true}))
            }else if(res.status === 404){
                dispatch(setAlert({title:"Error",subtitle:"User not found", type:'error',active:true}))
            }else{}
        }).catch(e =>{
            console.log(e)
        }).finally(()=>passwordForm.resetForm())
    }

    return (
        <div className="max-w-lg">
            <FormCard title={t("Change Password")}>
                <div className="grid gap-5">
                    <FieldInput ariaLabel={'Password'}  type="password" loading={formLoading} label={t("Password")} autoComplete='off' fieldMeta={passwordForm.getFieldMeta('password')} fieldProps={passwordForm.getFieldProps('password')} edit={true} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={'Confirm Password'} type="password" loading={formLoading} label={t("Confirm Password")} autoComplete='off' fieldMeta={passwordForm.getFieldMeta('confirmPassword')} fieldProps={passwordForm.getFieldProps('confirmPassword')} edit={true} dataLoaded={dataLoaded} />
                    <div className="flex justify-end mt-2 mx-5">
                        <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={passwordForm.submitForm}>{t("Save")}</button>
                    </div>
                </div>
            </FormCard>
        </div>
    )
}

export default ChangePassword