import { useCallback, useMemo, useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";

import { datatableTheme } from "../../data/dataTableTheme";
import { useSelector } from "react-redux";
// import InlineTextField from "../../components/snippets/InlineTextField";
import DatatableLoader from "../../components/snippets/DatatableLoader";
import { useTranslation } from 'react-i18next'
import 'moment-timezone';
import moment from 'moment';

import API from '../../axios/API'
import FlowText from '../../components/snippets/FlowText'

createTheme('defaultLight', datatableTheme.default);
createTheme('defaultDark', datatableTheme.dark);

const Communications = ({ props }) => {

    const [filter] = useState('')
    const [page, setPage] = useState(1)
    const [communicationDetails, setCommunicationDetails] = useState([])
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(true)
    const { t } = useTranslation()

    useEffect(() => {
        setLoading(true);
        API.get(`cases/${props}/emailHistory`).then(res => {
            setCommunicationDetails(res.data.body);
        }).catch(e => {
            console.log(e)
        }).finally(() => setLoading(false))
    }, [props])

    const lastdat =(value)=>
       {if(value !== null && value){
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
        // let followupdate = new Date(value)
        //     return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
            }else{
                return ''
            }
         }

    const colums = useMemo(() => {
        return [
            { name: t('CaseId'), selector: 'caseId', sortable: true },
            { name: t('CreatedBy'), selector:row => `${row.fname} ${row.lname}`, grow: 1, sortable: true },
            { name: t('Type'), selector: 'type', sortable: true },
            { name: t('Email/Phone'), selector: 'communicationId', sortable: true },
            { name: t('CreatedAt'), selector: row => `${lastdat(row.createdAt)}`, sortable: true },
            { name: t('Notes'), selector: 'notes', sortable: true },
            { name: t('Roles'), selector: 'roles', sortable: true },
            { name: t('Status'), selector: 'status' , sortable: true},
            // { name: 'Call End Time', selector: 'endTime' , sortable: true}
        ]
    }, [t])
    //  const SubHeader = useMemo(() => (
    //     <div className="flex items-center">
    //         <InlineTextField label="Search" value={filter} setValue={setFilter} />
           
    //     </div>
    // ), [filter]) // eslint-disable-line
    const filteredcommunicationDetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return communicationDetails.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [communicationDetails, filter])// eslint-disable-line

    const ExpandComponent = useCallback(row => {
        return (
            <div className="bg-bluegray-300 dark:bg-ldark py-2 px-3" >
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>
        )
    }, [])
    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })

    return (
        <div className="p-2 mt-2 bg-bluegray-200 dark:bg-ddark rounded-lg">
            {
                loading ?
                    <DatatableLoader />
                    :filteredcommunicationDetails.length>0?
                    <DataTable title={t("Communication")} columns={colums} data={filteredcommunicationDetails}  theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination highlightOnHover  selectableRowsHighlight persistTableHead onChangePage={val => setPage(val)}   paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} noDataComponent={t('There are no records to display')} />
                   :<div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>

            }
        </div>
    )
}

export default Communications