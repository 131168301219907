const authStore = {
    isLoggedIn: true,
    userDetails: {
    },
    user:{
        
    },
    organization: 'TCARE',
    closeCaseRender : 0,
    transferCaseRender : 0,
    childOrgTransferRender : 0,
    assignCaseRender : 0,
    notesAction: 0,
    relationShipCount : 0,
    changerelationShipCount : 0,
    reopencaseCount : 0,
    associatecount : 0,
    demographiccount: 0,
    profileCount : 0,
    languagespan: 0,
    onlyNumber:false,
    onlyNumber1:false,
    userRoles: null,
    loadAllCase:false,
    enableAllSearch:false,
    triggersubSearch:false,
    serviceUsage:0
}

export default authStore