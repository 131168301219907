
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import * as Yup from 'yup'
import FavFormButton from "../../components/snippets/FavFormButton"
import FieldInput from "../../components/snippets/FieldInput"
import FieldSelect from "../../components/snippets/FieldSelect"
import FormCard from "../../components/snippets/FormCard"

import { useDispatch, useSelector } from 'react-redux'
import { FaArrowLeft } from 'react-icons/fa'
import API from '../../axios/API'
import axios from 'axios'
import Tab from "../../components/snippets/Tabs/Tab"
import TabGroup from "../../components/snippets/Tabs/TabGroup"
import TabButtonGroup from "../../components/snippets/Tabs/TabButtonGroup"
import TabButton from "../../components/snippets/Tabs/TabButton"
import Notifications from "./Notifications"
import { setAlert } from "../../store/theme/actions"
import { useHistory } from "react-router-dom"
import { motion } from "framer-motion"
import AssessmentOptionField from '../../components/snippets/Assessment/AssessmentOptionField'
import { useTranslation } from 'react-i18next'
import UserMultiSelect from "../../components/snippets/UserMultiSelect"





const EditUser = ({ match }) => {

    const history = useHistory()
    const [initialData, setInitialData] = useState()
    const userDetails = useSelector(state => state.auth.userDetails)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [editForm, setEditForm] = useState(true)
    const [states, setStates] = useState([])
    const [gender, setGender] = useState([])
    const [employment, setEmployment] = useState([])
    const [maritalStatus, setMarital] = useState([])
    const [education, setEducation] = useState([])
    const [income, setIncome] = useState([])
    const [raceEthnicity, setRaceEthnicity] = useState([])
    const [formLoading, setFormLoading] = useState(false)
    const [tab, setTab] = useState(0)
    const [Role, setRole] = useState([])
    const dispatch = useDispatch()
    const [organization,setOrganization] = useState([])
    // const [tempUserName,setTempUserName] = useState('')
    const [tempMail,setTempMail] = useState('')
    const { t } = useTranslation()
    const orgId = useSelector(state => state.auth.organization)
    const [cgRefused,setCgRefused] = useState(false)
    const [years,setYears] = useState([])
    // const [zipEnable,setZipEnable] = useState(false)
    
    let orgid = localStorage.getItem('orgid')
    let birthyear = localStorage.getItem('birthyear')
    const [birthyear1, setBirthYear1] = useState(false)
    let lang = localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test('validPhone', t("Not a Valid Phone"), (value) => {
            if (value === undefined || value === '' || value === null) return true
            return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
        })
    })
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test('onlyAlphabet', t("Only Alphabets Allowed"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[a-zA-Z ]*$/g
            return r.test(value)
        })
    })
    
    Yup.addMethod(Yup.string, 'validateEmail', function () {
        return this.test('validEmail', t("Not valid"), (value) => {
            if (value === undefined || value === '') return true
            let r = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'EmptySpace', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    
    // const phoneRegExp = (/(\\d{3})-(\\d{3})-(\\d{4})/)
    
    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test('onlyAlphabet and numbers are allowed', t('Only Alphabets and numbers allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    useEffect(()=>{
        for(let i = 1900; i <= new Date().getFullYear(); i++){
            years.push(i)
        }
    },[years])
    useEffect(() => {
        if(birthyear === 'true'){
            setBirthYear1(true)
        }
        if(menuItems !== undefined && menuItems !== null && menuItems !== ''){
            if(menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined){
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            }else{
                API.get(`menus/states?lang=${lang}`).then((statesResp)=>{
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if(menuItems.gender && menuItems.gender.length > 0 && menuItems.gender !== undefined){
                setGender(menuItems.gender.map((c)=>({text:c.name,value:c.key})))
            }else{
                API.get(`menus/genders?lang=${lang}`).then((gendersResp)=>{
                    setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
            if(menuItems.employment && menuItems.employment.length > 0 && menuItems.employment !== undefined){
                setEmployment(menuItems.employment.map((c)=>({text: c.name, value: c.value })))
            }else{
                API.get(`menus/employments?lang=${lang}`).then((employmentResp)=>{
                    setEmployment(employmentResp.data.body.map(c => ({ text: c.name, value: c.value })))
                })
            }
            if(menuItems.education && menuItems.education.length > 0 && menuItems.education !== undefined){
                setEducation(menuItems.education.map((c)=>({text: c.name, value: c.value })))
            }else{
                API.get(`menus/educations?lang=${lang}`).then((educationResp)=>{
                    setEducation(educationResp.data.body.map((c)=>({text: c.name, value: c.value })))
                })
            }
            if(menuItems.income && menuItems.income.length > 0 && menuItems.income !== undefined){
                setIncome(menuItems.income.map((c)=>({text:c.name,value:c.value})))
            }else{
                API.get(`menus/incomes?lang=${lang}`).then((incomeResp)=>{
                    setIncome(incomeResp.data.body.map((c)=>({text:c.name,value:c.key})))
                })
            }
            if(menuItems.maritalStat && menuItems.maritalStat.length > 0 && menuItems.maritalStat !== undefined){
                setMarital(menuItems.maritalStat.map((c)=>({text:c.name,value:c.key})))
            }else{
                API.get(`menus/marital-status?lang=${lang}`).then((maritalResp)=>{
                    setMarital(maritalResp.data.body.map((c)=>({text:c.name,value:c.key})))
                })
            }
            if(menuItems.roles && menuItems.roles.length > 0 && menuItems.roles !== undefined){
                setMarital(menuItems.roles.map((c)=>({text:c.name,value:c.key})))
            }else{
                API.get(`menus/roles/org/${orgId}?lang=${lang}`).then((rolesResp)=>{
                    if (userDetails.role[0] === 'superadmin') {
                        setRole([{value:1,name:'admin'}].map(c => ({ answerLabel:c.name,answerId:c.value,selected:false,key:"2" })))
                        API.get('organizations').then(organizationResp => {
                            if (organizationResp.status === 200) {
                                setOrganization(organizationResp.data.map(c => ({ text: c.organizationName, value: c.organizationId })))
                            }
                        })
                    }else{
                        setRole(rolesResp.data.body.map(c => ({ answerLabel:c.name,answerId:c.value,selected:false ,key:c.key})))
                    }
                   
                })
            }
            if(menuItems.race && menuItems.race.length > 0 && menuItems.race !== undefined){
                setRaceEthnicity(menuItems.race.map((c)=>({ text: c.name, value: c.value})))
            }else{
                API.get(`menus/races?lang=${lang}`).then((raceResp)=>{
                    setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
                })
            }
        }else{
        axios.all([API.get(`menus/states?lang=${lang}`), API.get(`menus/genders?lang=${lang}`), API.get(`menus/employments?lang=${lang}`),
        API.get(`menus/marital-status?lang=${lang}`), API.get(`menus/educations?lang=${lang}`), API.get(`menus/incomes?lang=${lang}`), API.get(`menus/races?lang=${lang}`)]).then(axios.spread((statesResp, gendersResp, employmentResp, maritalResp, educationResp, incomeResp, raceResp) => {
            setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setEmployment(employmentResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setMarital(maritalResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setEducation(educationResp.data.body.map(c => ({ text: c.name, value: c.value })))
            // setLanguage(languageResp.data.body.map(c=>({text:c.name,value:c.key})))
            setIncome(incomeResp.data.body.map(c => ({ text: c.name, value: c.value })))
            setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
            // setRelationship(relationshipResp.data.body.map(c=>({text:c.name,value:c.value})))
            // setOrganizations(orgResp.data.map(c=>({text:c.organizationName,value:c.organizationId})))
            if (userDetails.role[0] === 'superadmin') {
                API.get('organizations').then(organizationResp => {
                    if (organizationResp.status === 200) {
                        setOrganization(organizationResp.data.map(c => ({ text: c.organizationName, value: c.organizationId })))
                    }
                })
            }
            setYears(years.map(c=>({text:c,value:c})))

        }))
    }
    }, [])// eslint-disable-line  

    useEffect(() => {
        API.get(`users/${match.params.userId}`).then(res => {
            if (res.status === 200) {
                let userdata = res.data.body
                // setTempUserName(userdata.userName)
                setTempMail(userdata.email)
                if(birthyear === 'true' && userdata.year !== 0){
                    userdata.date = userdata.year + "/" + userdata.month + "/" + userdata.day 
                }else if (userdata.year !== 0){
                    userdata.date = userdata.year
                }else {
                    userdata.date = ''
                }
                if(userdata.year === 0){
                    setCgRefused(true)
                }
                userdata.race = []
                if (userdata.white === true) {
                    userdata.race.push(1)
                } 
                 if (userdata.black === true) {
                    userdata.race.push(2)
                } 
                 if (userdata.hispanicOrLatino === true) {
                    userdata.race.push(3)
                } 
                 if (userdata.asian === true) {
                    userdata.race.push(4)
                } 
                 if (userdata.americanIndian === true) {
                    userdata.race.push(5)
                } 
                 if (userdata.nativeHawaiian === true) {
                    userdata.race.push(6)
                } 
                 if (userdata.middleEastern === true) {
                    userdata.race.push(7)
                } 
                 if (userdata.otherRace === true) {
                    userdata.race.push(8)
                } 
                 if (userdata.refused === true) {
                    userdata.race.push(9)
                } 
                API.get(`menus/roles/org/${orgid}`).then(rolesResp=>{
                    setRole(rolesResp.data.body.map(c => ({ answerLabel:c.name,answerId:c.value,selected:false,key:c.key })))
                    let tempRoles = rolesResp.data.body.map(c => ({ answerLabel:c.name,answerId:c.value,selected:false,key:c.key }))
                    let count = 0
                    tempRoles.map(e=>{
                        userdata.roles.map(c=>{
                            if(e.answerLabel === c){
                                count = count +1
                                e.selected = true
                                setRole(tempRoles)
                            }
                            return null;
                        })
                        return null;
                    })
                    setInitialData(userdata)
                })
                setFormLoading(false)
                setDataLoaded(true)
            }
        }).catch(e => {
            console.log(e)
        }).finally()
    }, [])//eslint-disable-line


    const profileFormSchema = cgRefused ? Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
        middleName: Yup.string().nullable(),
        lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
        prefferedName: Yup.string().validateName().nullable(),
        ssn: Yup.string().length(4, t("SSN number must be exactly 4 digits")).matches(/^[0-9]*$/, t("SSN number must be exactly 4 digits")).nullable(),
        userName: Yup.string().nullable(),
        roles: Yup.array().of(Yup.string().required(t("Role is required"))),
        date:  Yup.date().nullable(),
        phone: Yup.string().nullable().validatePhoneLength(),
        homePhone: Yup.string().nullable().validatePhoneLength(),
        email: Yup.string().validateEmail().required(t("Email is required")),
        gender: Yup.string().nullable(),
        maritalStatus: Yup.string().nullable(),
        educationId: Yup.string(),
        employmentId: Yup.string(),
        incomeId: Yup.string(),
        address: Yup.object({
            state: Yup.string().required(t("State is a required field")),
            addressLine1: Yup.string().EmptySpace().nullable(),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().trim(t('Remove Trailing WhiteSpace')).strict().nullable(),
            postalCode:Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numerics")).nullable(),
        }),
        day: Yup.number(),
        month: Yup.number(),
        year: Yup.number(),
        race : Yup.array()
    }): Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("Firstname is required field")),
        middleName: Yup.string().nullable(),
        lastName: Yup.string().nullable().max(50).validateName().required(t("Lastname is a required field")),
        prefferedName: Yup.string().validateName().nullable(),
        ssn: Yup.string().length(4, t("SSN number must be exactly 4 digits")).matches(/^[0-9]*$/, t("SSN number must be exactly 4 digits")).nullable(),
        userName: Yup.string().nullable(),
        roles: Yup.array().of(Yup.string().required(t("Role is required"))),
        date:  Yup.date().nullable(),
        phone: Yup.string().nullable().validatePhoneLength(),
        homePhone: Yup.string().nullable().validatePhoneLength(),
        email: Yup.string().validateEmail().required(t("Email is required")),
        gender: Yup.string().nullable(),
        maritalStatus: Yup.string().nullable(),
        educationId: Yup.string(),
        employmentId: Yup.string(),
        incomeId: Yup.string(),
        address: Yup.object({
            state: Yup.string().required(t("State is a required field")),
            addressLine1: Yup.string().EmptySpace().nullable(),
            addressLine2: Yup.string().nullable(),
            city: Yup.string().trim('Remove Trailing WhiteSpace').strict().nullable(),
            postalCode:Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numerics")).nullable(),
        }),
        day: Yup.number(),
        month: Yup.number(),
        year: Yup.number(),
        race : Yup.array()
    })


    const profileForm = useFormik({
        initialValues: initialData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            
            setFormLoading(true)
            submitForm()
            setFormLoading(false)
        }
    })

    const getAddress = (value,field) => {
        if(value.length ===5){
            API.get(`/intake/zones?zipCode=${value}`).then((res)=>{
                // setZipEnable(true)
                if(res.status === 200){
                    if(field === 'cg'){
                        profileForm.setValues({...profileForm.values,...profileForm.values,address:{...profileForm.values.address,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country}})
                    }
                }
            })
        }else if(value.length === 0){
            // setZipEnable(false)
            profileForm.setValues({...profileForm.values,...profileForm.values,address:{...profileForm.values.address,state:"",city:"",country:""}})
        }
    }

    const setRefused = (side,value) =>{
        if(side === 'cg'){
            setCgRefused(value)
        }
    }
    const setFieldValue = (val)=>{
        setRole(val)
    }
    const toggleFormEdit = () => {
        if (editForm) profileForm.submitForm()
        if (!editForm) setEditForm(!editForm)
        // {
        //     setEditForm(!editForm)
        //     setDataLoaded(dataLoaded)
        //     setFormLoading(false)
        // }
    }

    const togglecancel =()=>{
        profileForm.resetForm()
            setCgRefused(false)
        setEditForm(false)
    }

    const changeRacevalues = ((val) => {
        if (val === 1) {
            profileForm.values.white = !profileForm.values.white;
        } else if (val === 2) {
            profileForm.values.black = !profileForm.values.black;
        } else if (val === 3) {
            profileForm.values.hispanic = !profileForm.values.hispanic;
        } else if (val === 4) {
            profileForm.values.asian = !profileForm.values.asian;
        } else if (val === 5) {
            profileForm.values.nativeAmerican = !profileForm.values.nativeAmerican;
        } else if (val === 7) {
            profileForm.values.middleEastern = !profileForm.values.middleEastern;
        } else if (val === 6) {
            profileForm.values.pacificIslander = !profileForm.values.pacificIslander;
        } else if (val === 9) {
            profileForm.values.refusedRace = !profileForm.values.refusedRace;
        } else if (val === 8) {
            profileForm.values.otherRace = !profileForm.values.otherRace;
        } else { }
    })

    const submitForm = () => {
        profileForm.values.role=[]
        Role.map(c=>{
            if(c.selected){
                profileForm.values.role.push(c.answerLabel)
            } return null;
        })
        let RolesId=[]
        Role.map(c=>{
            if(c.selected){
                RolesId.push(c.key)
            } return null;
        })
        if (profileForm.values.date && birthyear1) {
            let date =  profileForm.values.date
            date = date?.split('/')
            profileForm.values.day = date[2]
            profileForm.values.month = date[1]
            profileForm.values.year = date[0]
        }
        let details = {
            month: cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.month) : parseInt(profileForm.values.month ? profileForm.values.month:1),
            day: cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.day) : parseInt(profileForm.values.day ? profileForm.values.day:1),
            year:cgRefused ? 0 : birthyear1 ? parseInt(profileForm.values.year) : parseInt(profileForm.values.date ? profileForm.values.date:0),
            pplId: initialData.pplId,
            userId: initialData.userId,
            userName: profileForm.values.userName.trim(),
            address: {
                state: profileForm.values.address.state,
                addressLine1: profileForm.values.address.addressLine1,
                addressLine2: profileForm.values.address.addressLine2,
                city: profileForm.values.address.city,
                postalCode: profileForm.values.address.postalCode,
                addressLine3: profileForm.values.address.addressLine3
            },
            roles: profileForm.values.role,
            rolesId:RolesId,
            email: profileForm.values.email,
            firstName: profileForm.values.firstName.trim(),
            lastName: profileForm.values.lastName.trim(),
            organizationName: userDetails.orgName,
            phone: profileForm.values.phone,
            middleName: profileForm.values.middleName,
            prefferedName: profileForm.values.prefferedName,
            ssn: profileForm.values.ssn,
            homePhone: profileForm.values.homePhone,
            gender: profileForm.values.gender ? profileForm.values.gender : null,
            employmentId: profileForm.values.employmentId ? profileForm.values.employmentId : 0,
            educationId: profileForm.values.educationId ? profileForm.values.educationId : 0,
            incomeId: profileForm.values.incomeId ? profileForm.values.incomeId : 0,
            maritalStatus: profileForm.values.maritalStatus ? profileForm.values.maritalStatus : null,
            consentToContact: true,
            white: profileForm.values.race.includes(1),
            black: profileForm.values.race.includes(2),
            hispanicOrLatino: profileForm.values.race.includes(3),
            asian: profileForm.values.race.includes(4),
            americanIndian: profileForm.values.race.includes(6),
            nativeHawaiian: profileForm.values.race.includes(5),
            middleEastern: profileForm.values.race.includes(7),
            refused: profileForm.values.race.includes(9),
            otherRace: profileForm.values.race.includes(8)
        }
        if(tempMail === profileForm.values.email) {
            if(profileForm.values.role?.length === 0){
                dispatch(setAlert({title:t("Warning"), subtitle: t("Please select a role"),type:'warning',active: true}))
                setEditForm(true)
            }else{
                API.patch(`organizations/${userDetails.orgId}/users`, details).then(res => {
                    if (res.status === 200 && res.data.params === true) {
                        dispatch(setAlert({ title: t('Success'), subtitle:t('User updated successfully'), active: true, type: 'success' }))
                        history.goBack();
                        setTimeout(() => window.location.reload(), 2000);
                    }else if(res.status === 200 && res.data.status === false){
                        dispatch(setAlert({title: t("Warning"), subtitle: res.data.errorMessage,type:'warning',active: true}))
                        // history.goBack()
                        setEditForm(true)
                    }
                }).catch(e => {
                    console.log(e)
                })
            }
        }
        // else if(tempMail !==profileForm.values.email){
        //     axios.all([API.post('users/-/email:check-availability', { email: profileForm.values.email }),
        // ]).then((mailResp)=>{
        //     if(mailResp.data.params === false){
        //         dispatch(setAlert({ title: 'Error', subtitle: 'Email already exists', type: 'error', active: true }))
        //     }
        //     // else if(checkResp.data.params === false){
        //     //     dispatch(setAlert({ title: 'Error', subtitle: 'Username already exists', type: 'error', active: true }))
        //     // }
        //     // else if(mailResp.data.params === false){
        //     //     dispatch(setAlert({ title: 'Error', subtitle: 'Email already exists', type: 'error', active: true }))
        //     // }
        //     else if(mailResp.data.params === true){
        //         if(profileForm.values.role?.length === 0){
        //             dispatch(setAlert({title: "Warning", subtitle: "Please select a role",type:'warning',active: true}))
        //         }else{
        //             API.patch(`organizations/${userDetails.orgId}/users`, details).then(res => {
        //                 if (res.status === 200) {
        //                     dispatch(setAlert({ title: 'Success', subtitle: 'User updated successfully', active: true, type: 'success' }))
        //                     history.goBack()
        //                 }
        //             }).catch(e => {
        //                 console.log(e)
        //             })
        //         }
        //     }
        // })
        // }
        // else if(tempUserName !== profileForm.values.userName && tempMail === profileForm.values.email){
        //     API.post('users/-/username:check-availability', { userName: profileForm.values.userName }).then(res=>{
        //         if(res.data.params === false){
        //             dispatch(setAlert({ title: 'Error', subtitle: 'Username already exists', type: 'error', active: true })) 
        //         }else if(res.data.params === true){
        //             API.patch(`organizations/${userDetails.orgId}/users`, details).then(res => {
        //                 if (res.status === 200) {
        //                     dispatch(setAlert({ title: 'Success', subtitle: 'User updated successfully', active: true, type: 'success' }))
        //                     history.goBack()
        //                 }
        //             }).catch(e => {
        //                 console.log(e)
        //             })
        //         }
        //     })
        // }
        else if(tempMail !== profileForm.values.email){
            API.post('users/-/email:check-availability', { email: profileForm.values.email }).then(res=>{
                if(res.data.params ===  false){
                    dispatch(setAlert({ title: t('Error'), subtitle: t('Email already exists'), type: 'error', active: true }))
                }else if(res.data.params === true){
                    API.patch(`organizations/${userDetails.orgId}/users`, details).then(res => {
                        if (res.status === 200) {
                            dispatch(setAlert({ title: t('Success'), subtitle: t('User updated successfully'), active: true, type: 'success' }))
                            history.goBack()
                        }
                    }).catch(e => {
                        console.log(e)
                    })
                }
            })
        }
    }


    return (
        <div class="pb-8">
            <button className="flex mt-3 bg-tcolor px-6 py-2 rounded-xl items-center shadow-lg text-black ring-offset-2 hover:ring-2 hover:ring-tcolor dark:ring-offset-black whitespace-nowrap" onClick={() => history.goBack()}>
                <FaArrowLeft className="mr-3" /> {t("Back")}
            </button>
            <div className="text-center">
                <TabButtonGroup>
                    <TabButton index={0} value={tab} setValue={setTab}>
                        {t("Profile")}
                    </TabButton>
                    <TabButton index={1} value={tab} setValue={setTab}>
                        {t("Notification")}
                    </TabButton>
                </TabButtonGroup>
            </div>
            <TabGroup value={tab}>
                <Tab index={0} value={tab}>
                    <FormCard title={t("Basic Info")} injectClass="mt-12">
                        {(orgId && userDetails && userDetails.orgId === parseInt(orgId)) ?<FavFormButton injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={togglecancel} />:''}
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldInput ariaLabel={'First Name'} hideData={true} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('firstName')} fieldProps={profileForm.getFieldProps('firstName')} edit={editForm} dataLoaded={dataLoaded} />
                            {/* <FieldInput loading={formLoading} label={t("Middle Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('middleName')} fieldProps={profileForm.getFieldProps('middleName')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'1'} /> */}
                            <FieldInput ariaLabel={'Last Name'} hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('lastName')} fieldProps={profileForm.getFieldProps('lastName')} edit={editForm} dataLoaded={dataLoaded} />
                           { birthyear1 ? <FieldInput ariaLabel={'Date od Birth'} disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('date')} fieldProps={profileForm.getFieldProps('date')} fieldHelper={profileForm.getFieldHelpers('date')} edit={editForm} dataLoaded={dataLoaded} />:
                             <FieldSelect ariaLabel={'Date Of Birth Dropdown'} disabled={cgRefused ? true:false} loading={formLoading} label={t('Birth Year')} fieldMeta={profileForm.getFieldMeta('date')} fieldProps={profileForm.getFieldProps('date')} fieldHelper={profileForm.getFieldHelpers('date')} options={years} edit={editForm} dataLoaded={dataLoaded} position="bottom" />
                            }
                            <div className="flex items-center grid-cols-2 grid 2x:grid-cols-2 gap-4 relative ">
                             {/* <FieldInput  disabled={cgRefused ? true:false} loading={formLoading} type="date" maxDate={new Date()} label={t("Date of Birth")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careGiver.dateOfBirth')} fieldProps={profileForm.getFieldProps('careGiver.dateOfBirth')} fieldHelper={profileForm.getFieldHelpers('careGiver.dateOfBirth')} edit={editForm} dataLoaded={dataLoaded} /> */}
                                 {editForm && cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tcolor" onClick={()=>setRefused('cg',false)}>{t('Refused')}</button>}
                                 {editForm && !cgRefused && <button style={{height:'fit-content',width:'fit-content'}} className="ring-2 rounded-lg p-1 bg-tgray" onClick={()=>setRefused('cg',true)}>{t('Refused')}</button>}
                             </div>
                            <FieldInput ariaLabel={'Preffered Name'} loading={formLoading} label={t("Preferred Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('prefferedName')} fieldProps={profileForm.getFieldProps('prefferedName')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'SSN'} loading={formLoading} label={t("SSN (Last 4 Digit)")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('ssn')} fieldProps={profileForm.getFieldProps('ssn')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'4'} />
                            {/* <FieldInput loading={formLoading} label={t("User Name")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('userName')} fieldProps={profileForm.getFieldProps('userName')} edit={editForm} dataLoaded={dataLoaded} /> */}
                            { userDetails.role.includes('superadmin') ? <FieldSelect ariaLabel={'Organization Dropdown'} loading={formLoading} label={t("Organization")} fieldMeta={profileForm.getFieldMeta('organizationId')} fieldHelper={profileForm.getFieldHelpers('organizationId')} fieldProps={profileForm.getFieldProps('organizationId')} edit={editForm} options={organization} position="top" dataLoaded={dataLoaded} /> : ''}
                        </div>
                    </FormCard>
                    <motion.div initial='hidden' animate='visible' exit='hidden' className={`mt-3 form-wrap py-4`}>
                        <div className="w-11/12 text-sm">
                            {((userDetails.role[0] === 'superadmin'))? t('Roles (Non Editable)'):t('Roles (You can select multiple roles)')}
                        </div>
                        <div className="mt-4 pb-3">
                            {((userDetails.role[0] === 'superadmin') && userDetails.role.length === 1) ? <AssessmentOptionField edit={false} type={'multi_option'} value={Role} options={Role} setValue={val => setFieldValue(val)} /> : <AssessmentOptionField edit={editForm} type={'multi_option'} value={Role} options={Role} setValue={val => setFieldValue(val)} />}
                        </div>
                    </motion.div>
                    <FormCard title={t("Contact Information")}>

                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldInput ariaLabel={'Mobile Number'} hideData={true} loading={formLoading} type="phone" label={t("Mobile Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('phone')} fieldProps={profileForm.getFieldProps('phone')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Home Phone Number'} hideData={true} loading={formLoading} type="phone" label={t("Home Phone Number")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('homePhone')} fieldProps={profileForm.getFieldProps('homePhone')} edit={editForm} dataLoaded={dataLoaded} />
                            <FieldInput ariaLabel={'Email'} hideData={true} loading={formLoading} label={t("Email")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('email')} fieldProps={profileForm.getFieldProps('email')} edit={editForm} dataLoaded={dataLoaded} />
                            {/* <FieldInput loading={formLoading} type="checkbox" label={"Consented to be contacted"} autoComplete='off' fieldMeta={profileForm.getFieldMeta('phoneNumber')} fieldProps={profileForm.getFieldProps('phoneNumber')} edit={editForm} /> */}
                        </div>
                    </FormCard>
                    <FormCard title={t("Additional Information")}>
                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                            <FieldSelect ariaLabel={'Gender Dropdown'} loading={formLoading} label={t("Gender")} fieldMeta={profileForm.getFieldMeta('gender')} fieldHelper={profileForm.getFieldHelpers('gender')} fieldProps={profileForm.getFieldProps('gender')} edit={editForm} options={gender} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Education Dropdown'} loading={formLoading} label={t("Education")} fieldMeta={profileForm.getFieldMeta('educationId')} fieldHelper={profileForm.getFieldHelpers('educationId')} fieldProps={profileForm.getFieldProps('educationId')} edit={editForm} options={education} position="bottom" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Marital Status Dropdown'} loading={formLoading} label={t("Marital Status")} fieldMeta={profileForm.getFieldMeta('maritalStatus')} fieldHelper={profileForm.getFieldHelpers('maritalStatus')} fieldProps={profileForm.getFieldProps('maritalStatus')} edit={editForm} options={maritalStatus} position="top" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Total Household Income Dropdown'} loading={formLoading} label={t("Total Household Income")} fieldMeta={profileForm.getFieldMeta('incomeId')} fieldHelper={profileForm.getFieldHelpers('incomeId')} fieldProps={profileForm.getFieldProps('incomeId')} edit={editForm} options={income} position="top" dataLoaded={dataLoaded} />
                            <FieldSelect ariaLabel={'Employment Status Dropdown'} loading={formLoading} label={t("Employment Status")} fieldMeta={profileForm.getFieldMeta('employmentId')} fieldHelper={profileForm.getFieldHelpers('employmentId')} fieldProps={profileForm.getFieldProps('employmentId')} edit={editForm} options={employment} position="top" dataLoaded={dataLoaded} />
                            <UserMultiSelect ariaLabel={'Race/Ethnicity Dropdown'} loading={formLoading} label={t("Race/Ethnicity")} fieldMeta={profileForm.getFieldMeta('race')} fieldHelper={profileForm.getFieldHelpers('race')} fieldProps={profileForm.getFieldProps('race')} edit={editForm} options={raceEthnicity} position="bottom" dataLoaded={dataLoaded} onChange={changeRacevalues(profileForm.getFieldProps('race'))} />
                        </div>
                    </FormCard>
                    <FormCard title={t("Primary Address")}>

                        <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                        <FieldInput ariaLabel={'Zip Code'} loading={formLoading} label={t("Zip Code")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.postalCode')} fieldProps={profileForm.getFieldProps('address.postalCode')} fieldHelper={profileForm.getFieldHelpers('address.postalCode')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'cg')} />
                           <FieldInput ariaLabel={'Address Line 1'} loading={formLoading} label={t("Address Line 1")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine1')} fieldProps={profileForm.getFieldProps('address.addressLine1')} fieldHelper={profileForm.getFieldHelpers('address.addressLine1')} edit={editForm} dataLoaded={dataLoaded} />  
                            <FieldInput ariaLabel={'Address Line 2'} loading={formLoading} label={t("Address Line 2")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine2')} fieldProps={profileForm.getFieldProps('address.addressLine2')} fieldHelper={profileForm.getFieldHelpers('address.addressLine2')} edit={editForm} dataLoaded={dataLoaded} /> 
                            {/* <FieldInput loading={formLoading} label={"Address Line 3"} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.addressLine3')} fieldProps={profileForm.getFieldProps('address.addressLine3')} fieldHelper={profileForm.getFieldHelpers('address.addressLine3')} edit={editForm} dataLoaded={dataLoaded} /> */}
                         <FieldInput ariaLabel={'City'} loading={formLoading} label={t("City")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('address.city')} fieldProps={profileForm.getFieldProps('address.city')} fieldHelper={profileForm.getFieldHelpers('address.city')} edit={editForm} dataLoaded={dataLoaded} />    
                         <FieldSelect ariaLabel={'State Dropdown'} loading={formLoading} label={t("State")} fieldMeta={profileForm.getFieldMeta('address.state')} fieldHelper={profileForm.getFieldHelpers('address.state')} fieldProps={profileForm.getFieldProps('address.state')} edit={editForm} options={states} position="top" dataLoaded={dataLoaded} ml={true} length={'5'} />    
                            
                        </div>
                    </FormCard>
                    {editForm &&
                        <div className="flex justify-center mt-7 mx-5">
                            <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white" onClick={() => setEditForm(false)}>{t("Cancel")}</button>
                            <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => profileForm.submitForm()}>{t("Save")}</button>
                        </div>
                    }
                </Tab>
                <Tab index={1} value={tab}>
                    <Notifications props={match.params.userId} />
                </Tab>
            </TabGroup>
        </div>
    )
}

export default EditUser