// import FlowText from "../../components/snippets/FlowText"
// import { useTranslation } from 'react-i18next'
const TopRowData = ({ loaded, caseNeedAction, caregiverRisk, timeToAssess,timeToCases } ) => {
    // const {t} = useTranslation()
    return (
        // <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-7">
        //     <div className="bg-gradient-to-tr relative shadow-lg overflow-hidden from-purple-300 to-purple-500 rounded-2xl ring-1 ring-purple-500 p-4 text-white font-bold text-center py-12">
        //         <div className="text-4xl">
        //             { loaded ? <FlowText force={true} text={`${caseNeedAction}`} /> : <div className="w-28 h-8 rounded-xl bg-gray-200 animate-pulse inline-block"></div> }
        //         </div>
        //         <div className="my-3 text-xl text-gray-100"><FlowText direction="x" text={t("Cases Need Action")} /></div>
        //         <div className="absolute h-28 w-28 rounded-full -left-8 -bottom-12 bg-gradient-to-tr from-purple-600 to-purple-300"></div>
        //         <div className="absolute h-28 w-28 rounded-full -right-6 -top-12 bg-gradient-to-bl from-purple-200 to-purple-500"></div>
        //     </div>
        //     <div className="bg-gradient-to-tr relative shadow-lg overflow-hidden from-red-300 to-red-500 rounded-2xl ring-1 ring-red-500 p-4 text-white font-bold text-center py-12">
        //         <div className="text-4xl">
        //             { loaded ? <FlowText force={true} text={`${caregiverRisk}`} /> : <div className="w-28 h-8 rounded-xl bg-gray-200 animate-pulse inline-block"></div> }
        //         </div>
        //         <div className="my-3 text-xl text-gray-100"><FlowText direction="x" text={t("Caregivers At Risk")} /></div>
        //         <div className="absolute h-28 w-28 rounded-full -left-8 -bottom-12 bg-gradient-to-tr from-red-600 to-red-300"></div>
        //         <div className="absolute h-28 w-28 rounded-full -right-6 -top-12 bg-gradient-to-bl from-red-200 to-red-500"></div>
        //     </div>
        //     <div className="bg-gradient-to-tr relative shadow-lg overflow-hidden from-blue-300 to-blue-500 rounded-2xl ring-1 ring-blue-500 p-4 text-white font-bold text-center py-12">
        //         <div className="text-4xl">
        //             { loaded ? <FlowText force={true} text={`${timeToAssess}`} /> : <div className="w-28 h-8 rounded-xl bg-gray-200 animate-pulse inline-block"></div> }
        //         </div>
        //         <div className="my-3 text-xl text-gray-100"><FlowText direction="x" text={t("Time to Assessment")} /></div>
        //         <div className="absolute h-28 w-28 rounded-full -left-8 -bottom-12 bg-gradient-to-tr from-blue-600 to-blue-300"></div>
        //         <div className="absolute h-28 w-28 rounded-full -right-6 -top-12 bg-gradient-to-bl from-blue-200 to-blue-500"></div>
        //     </div>
        //     <div className="bg-gradient-to-tr relative shadow-lg overflow-hidden from-green-300 to-green-500 rounded-2xl ring-1 ring-green-500 p-4 text-white font-bold text-center py-12">
        //         <div className="text-4xl">
        //             { loaded ? <FlowText force={true} text={`${timeToCases}`}/> : <div className="w-28 h-8 rounded-xl bg-gray-200 animate-pulse inline-block"></div> }
        //         </div>
        //         <div className="my-3 text-xl text-gray-100"><FlowText direction="x" text={t("Time to Care Plan")} /></div>
        //         <div className="absolute h-28 w-28 rounded-full -left-8 -bottom-12 bg-gradient-to-tr from-green-600 to-green-300"></div>
        //         <div className="absolute h-28 w-28 rounded-full -right-6 -top-12 bg-gradient-to-bl from-green-200 to-green-500"></div>
        //     </div>
        // </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-7">
                <div className="bg-bluegray-200 dark:bg-ldark rounded-2xl relative shadow-lg overflow-hidden p-4 text-white font-bold text-center py-2">
                   <div className="rounded-full animate-pulse bg-gray-300 text-center" style={{height:'200px',width:'200px',margin:'auto'}}></div>
                </div>
                <div className="bg-bluegray-200 dark:bg-ldark rounded-2xl relative shadow-lg overflow-hidden p-4 text-white font-bold text-center py-2">
                <div className="rounded-full animate-pulse bg-gray-300 text-center" style={{height:'200px',width:'200px',margin:'auto'}}></div>
                </div>
                <div className="bg-bluegray-200 dark:bg-ldark rounded-2xl relative shadow-lg overflow-hidden p-4 text-white font-bold text-center py-2">
                <div className="rounded-full animate-pulse bg-gray-300 text-center" style={{height:'200px',width:'200px',margin:'auto'}}></div>
                </div>
            </div>
    )
}

export default TopRowData