import blueLogo from '../images/blueLogo.png'
import blueSymbol from '../images/blueLogoSymbol.png'
import whiteLogo from '../images/whiteLogo.png'
import whiteSymbol from '../images/whiteLogoSymbol.png'
const { useSelector } = require("react-redux")

const LogoImg = ({ size= '120px', symbol= false }) => {

    const themeMode = useSelector(state => state.theme.themeMode)

    return (
        <img width={ size } src={themeMode === 'light' ? symbol ? blueSymbol : blueLogo : symbol ? whiteSymbol : whiteLogo } alt="TCARE Logo" aria-label='TCARE Logo' role="presentation"/>
    )
}

export default LogoImg