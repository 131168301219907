const VipCard = ({injectClass, children, title,onClick}) => {

    return (
        <div className={`form-wrap ${injectClass}`}>
            {title ? <div className={`ml-3 form-wrap-heading ${onClick ? 'cursor-pointer' : ''}`} onClick={ () => onClick ? onClick() : null }>{title}</div>:''}
            {children}
        </div>
    )
}

export default VipCard