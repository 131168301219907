export const SET_LOGGEDIN = 'SET_LOGGEDIN'
export const SET_USER_DETAILS = 'SET_USER_DETAILS'
export const SET_USER = 'SET_USER'
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const SET_CLOSECASERENDER = 'SET_CLOSECASERENDER'
export const SET_TRANSFERCASERENDER = 'SET_TRANSFERCASERENDER'
export const SET_CHILDORGTRANSFERRENDER = 'SET_CHILDORGTRANSFERRENDER'
export const SET_NOTESACTION = 'SET_NOTESACTION'
export const SET_RELATIONSHIPCOUNT = 'SET_RELATIONSHIPCOUNT'
export const SET_CHANGERELATIONSHIPCOUNT ='SET_CHANGERELATIONSHIPCOUNT'
export const SET_REOPENCASECOUNT = 'SET_REOPENCASECOUNT'
export const SET_ASSOCIATECOUNT = ' SET_ASSOCIATECOUNT'
export const SET_DEMOGRAPHIC  =  ' SET_DEMOGRAPHIC'
export const SET_PROFILE = ' SET_PROFILE '
export const SET_SPANISH  = 'SET_SPANISH'
export const SET_ASSIGNCASERENDER = 'SET_ASSIGNCASERENDER'
export const SET_ONLY_NUMBER = 'SET_ONLY_NUMBER'
export const SET_ONLY_NUMBER1 = 'SET_ONLY_NUMBER1'
export const SET_USERROLES = 'SET_USERROLES'
export const SET_LOAD_ALL_CASE = 'SET_LOAD_ALL_CASE'
export const SET_ENABLE_ALL_SEARCH = 'SET_ENABLE_ALL_SEARCH' 
export const SET_TRIGGER_SUBID_SEARCH = 'SET_TRIGGER_SUBID_SEARCH'
export const SET_REFRESH = 'SET_REFRESH'
export const SET_SERVICEUSAGE = 'SET_SERVICEUSAGE'