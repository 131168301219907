
const a = [1,2,3,4]
const b = [1,2]

const DemographicLoader = () => {

    return (
        <div className="flex h-full justify-between items-center w-full">
            <div className="flex-1 flex flex-col items-center">
                <div className="h-2.5 w-96 animate-pulse bg-gray-400 rounded-lg"></div>
                <div className="mt-16 flex flex-col gap-5">
                    {
                        a.map(c => (
                            <div key={c} className="flex justify-between gap-4">
                                <div>
                                    <div className="h-2 w-80 animate-pulse bg-gray-400 rounded-lg"></div>
                                    <div className="h-2 w-52 mt-2 animate-pulse bg-gray-400 rounded-lg"></div>
                                </div>
                                <div>
                                    <div className="h-2 w-80 animate-pulse bg-gray-400 rounded-lg"></div>
                                    <div className="h-2 self-end w-52 mt-2 animate-pulse bg-gray-400 rounded-lg"></div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="flex-1 flex flex-col items-center">
                <div className="h-2.5 w-96 animate-pulse bg-gray-400 rounded-lg"></div>
                <div className="mt-16 flex flex-col gap-5">
                    {
                        b.map(c => (
                            <div key={c} className="flex justify-between gap-4">
                                <div>
                                    <div className="h-2 w-80 animate-pulse bg-gray-400 rounded-lg"></div>
                                    <div className="h-2 w-52 mt-2 animate-pulse bg-gray-400 rounded-lg"></div>
                                </div>
                                <div>
                                    <div className="h-2 w-80 animate-pulse bg-gray-400 rounded-lg"></div>
                                    <div className="h-2 self-end w-52 mt-2 animate-pulse bg-gray-400 rounded-lg"></div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default DemographicLoader