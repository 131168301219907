
import { useState} from "react"
import { motion } from "framer-motion"
import API from '../../../axios/API'
import { useEffect } from "react"
import { FaCheck,FaChevronUp,FaChevronDown } from "react-icons/fa"
import AssessmentTextField from "../../../components/snippets/Assessment/AssessmentTextField"
import AssessmentOptionField from "../../../components/snippets/Assessment/AssessmentOptionField"
import { AiFillPrinter } from "react-icons/ai"
import AssessmentLoader from "../../Assessment/AssessmentLoader"
import { createPortal } from "react-dom"
import Dialog from "../../../components/snippets/Dialog"
import FlowText from "../../../components/snippets/FlowText"
import { useTranslation } from "react-i18next"
import 'moment-timezone';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux"
const AssessmentReadOnly = ({ asmtId}) => {

    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(true)
    const [downloadPopup,setDownloadPopup] = useState(false)
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')
    let history = useHistory();
    const userDetails = useSelector(state => state.auth.userDetails)
    useEffect(() => {
        setLoading(true)
        if(history?.location?.state?.caseType === "SCR"){
        API.get(`organizations/${orgId}/assessments/${asmtId}/pages/0/questions?screener=1&currentPage=0`).then(res => {
            if (res.status === 200) {
                res.data.body.question.map(c=>{
                    if(c.questionId === 148 || c.questionId === 202){
                        c.answers.map(e=>{
                            if((e.answerId === 604|| e.answerId === 1470 || e.answerId === 1729 )&& e.selected){
                                let otherText = e.otherText !== null && e.otherText.split(',')
                                otherText !== null && otherText.length > 0 && otherText.map(e=>{
                                    c.answers.push({answerId:e,answerLabel:e,questionId:c.questionId,selected:true})
                                    return null;
                                })
                            }
                            return e
                        })
                        return c
                    } return null;
                })
                setQuestions(res.data.body)
                setLoading(false)
            }
        })
        }else{
        API.get(`organizations/${orgId}/assessments/${asmtId}/pages/0/questions?screener=0&currentPage=0`).then(res => {
            if (res.status === 200) {
                res.data.body.question.map(c=>{
                    c.expansionPanel = false
                    if(c.questionId === 148 || c.questionId === 202 || c.questionId === 257){
                        c.answers.map(e=>{
                            if((e.answerId === 604 ||e.answerId === 1470 || e.answerId === 1729) && e.selected){
                                let otherText = e.otherText !== null && e.otherText.split(',')
                                otherText !== null && otherText.length > 0 && otherText.map(e=>{
                                    c.answers.push({answerId:e,answerLabel:e,questionId:c.questionId,selected:true})
                                    return null;
                                })
                            }
                            return e
                        })
                        return c
                    } return null;
                })
                setQuestions(res.data.body)
                setLoading(false)
            }
        })
        }
    }, []) // eslint-disable-line


    const printAssessment=()=>{
        let timestamp = new Date().getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        if(history?.location?.state?.caseType === "SCR" && userDetails.env === "UHC"){
        API.get(`organizations/${orgId}/assessments/${asmtId}:printnew?timezone=${tzabbr}&caseType=${history?.location?.state?.caseType}`).then(res=>{
            if(res.status === 200){
             const linkSource = `data:application/pdf;base64,${res.data.body}`;
             const downloadLink = document.createElement("a");
             const fileName = "TCE-Assessment.pdf";
             downloadLink.href = linkSource;
             downloadLink.download = fileName;
             downloadLink.click();
             setDownloadPopup(false)
            }
         })
    }else{
        API.get(`organizations/${orgId}/assessments/${asmtId}:print?timezone=${tzabbr}`).then(res=>{
            if(res.status === 200){
             const linkSource = `data:application/pdf;base64,${res.data.body}`;
             const downloadLink = document.createElement("a");
             const fileName = "TCE-Assessment.pdf";
             downloadLink.href = linkSource;
             downloadLink.download = fileName;
             downloadLink.click();
             setDownloadPopup(false)
            }
         })
    }
    }

    // const toggleExpansion = (value)=>{
    //     questions.question.map((e,i)=>{
    //         if(e.questionId === value.questionId){
    //             console.log(e)
    //             e.expansionPanel = !e.expansionPanel
    //         }
    //         return e
    //     })
    // }
    return (
        <div className={'flex-1'}>
            {
                loading ? <AssessmentLoader /> : <div className="flex-1 pl-5 h-full">
                   <div className={'flex flex-1 justify-between'}>
                   <div className="flex gap-1 justify-end items-center bg-gradient-to-tr from-tcolor to-blue-400 px-2 py-0.5 rounded text-[10px] cursor-pointer hover:ring-2 hover:ring-offset-2 hover:ring-tcolor hover:ring-offset-gray-200 dark:hover:ring-offset-ldark text-white" onClick={() =>setDownloadPopup(true)}>
                        <AiFillPrinter size="12px" /><span>{t("Print")}</span>
                    </div>
                       </div>
                       {
                                questions.question.map((c, i) => {
                                    if (c.questionType === "radio") {
                                        return <><motion.section tabIndex={0} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={`flex justify-between items-center`}>
                                                <div className="w-11/12 text-sm">
                                                    {c.questionLabel}
                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                            {/* {c.conditionalQUestionEnabled ? c.expansionPanel ? <FaChevronUp  className="text-[red] font-bold cursor-pointer"  onClick={() => toggleExpansion(c)}/>: <FaChevronDown className="text-[red] font-bold cursor-pointer" onClick={() => toggleExpansion(c)}  /> : ''} */}
                                            </div>
                                            {
                                                c.questionId === 110 ? <div className="mt-4 pb-3">
                                                    <AssessmentOptionField edit={false} type={'option'} value={c.answers.answerValue} options={c.answers} />
                                                </div> : <div className="mt-4 pb-3">
                                                    <AssessmentOptionField edit={false} type={'option'} value={c.answers.answerValue} options={c.answers} />
                                                </div>
                                            }
                                            {
                                                (c.questionId === 175 && c.answers[0].selected) ? <AssessmentTextField edit={false} type={'number'} value={c.answers[0].otherText} ml={true} length={'2'} /> : ''
                                            }
                                            {
                                                (c.questionId === 178 && c.answers[0].selected) ? <AssessmentTextField edit={false} type={'number'} value={c.answers[0].otherText} ml={true} length={'2'} /> : ''
                                            }
                                            {
                                                (c.questionId === 336 && c.answers[0].selected) ? <AssessmentTextField edit={false} value={c.answers[0].otherText} ml={true} /> : ''
                                            }
                                            {
                                                (c.questionId === 356 && c.answers[0].selected) ? <AssessmentTextField edit={false} type={'number'} value={c.answers[0].otherText} ml={true} length={'2'} /> : ''
                                            }
                                            {
                                                (c.questionId === 325 && c.answers[0].selected) ? <AssessmentTextField edit={false} type={'number'} value={c.answers[0].otherText} ml={true} length={'2'} /> : ''
                                            }
                                             {
                                                (c.questionId === 364 && c.answers[3].selected && c.answers[3].otherText) ? <AssessmentTextField edit={false} value={c.answers[3].otherText} ml={true} /> : ''
                                            }
                                        </motion.section>
                                         {
                                                c.conditionalQUestionEnabled && (c.answers[0].answerValue === '1' && c.answers[0].selected) && <div className="mt-1 overflow-y-auto min-h-fit max-h-72 height_less_topbar -mr-8 pr-8">
                                                    {
                                                        c.conditionalQuestions.map((e, i) => {
                                                            if (e.questionType === "radio") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className="flex flex-row w-full">
                                                                        <div className={`flex justify-between flex-row w-full items-center`}>
                                                                            <div className="w-11/12 text-sm">
                                                                                {e.questionLabel}
                                                                            </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        <AssessmentOptionField edit={true} type={'option'} value={e.answers.answerValue} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === "text") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm">
                                                                            {e.questionLabel}
                                                                        </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        {
                                                                            // e.questionId == 20 && <AssessmentTextField edit={true} type={'number'} value={e.textBox !== '0'? e.textBox :''} setValue={val => setFieldValue(e.questionId, val)} ml={true} length={'4'} />
                                                                            e.questionId === 20 && <FieldSelect ariaLabel={t("Year")} label={t("Year")} fieldMeta={yearsForm.getFieldMeta('year')} fieldProps={yearsForm.getFieldProps('year')} fieldHelper={yearsForm.getFieldHelpers('year')} options={years} onChange={(ev) => setFieldValue(e.questionId, ev)} edit={true} position="bottom" dataLoaded={true} />
                                                                        }

                                                                        {
                                                                            (e.questionId === 156 || e.questionId === 157 || e.questionId === 158 || e.questionId === 159 || e.questionId === 160 || e.questionId === 161) && e.questionId !== 20 ? <AssessmentTextField edit={true} type={'number'} value={e.textBox} setValue={val => setFieldValue(e.questionId, val)} ml={true} length={'3'} /> :
                                                                                (e.questionId !== 156 || e.questionId !== 157 || e.questionId !== 158 || e.questionId !== 159 || e.questionId !== 160 || e.questionId !== 161) && e.questionId !== 20 && <AssessmentTextField edit={true} type={'number'} value={e.textBox} setValue={val => setFieldValue(e.questionId, val)} ml={true} length={'2'} />

                                                                        }

                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === "select") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm">
                                                                            {e.questionLabel}
                                                                        </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        <AssessmentOptionField edit={true} type={'option'} value={e.answers.answerValue} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === "multi") {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm">
                                                                            {e.questionLabel}
                                                                        </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                    </div>
                                                                    <div className="mt-4 pb-3">
                                                                        <AssessmentOptionField edit={true} type={'multi_option'} value={e.answers} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                    </div>
                                                                    {(multiSelect && (e.questionId === 148 || e.questionId === 202)) ? <div className="mt-4 pb-3">
                                                                        <AssessmentOptionChip ariaLabel={t('Disease')} edit={true} label={t('Disease')} type={'option'} fieldHelper={diseaseForm.getFieldHelpers('disease')} fieldMeta={diseaseForm.getFieldMeta('disease')} fieldProps={diseaseForm.getFieldProps('disease')} dataLoaded={true} options={diseases} setAnswer={val => setField(c, val)} />
                                                                    </div> : ''}
                                                                </motion.section>
                                                            } else if (e.questionType === 'header') {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div className={`flex justify-between items-center`}>
                                                                        <div className="w-11/12 text-sm" style={{ fontWeight: 'bold' }}>
                                                                            {e.questionLabel}
                                                                        </div>
                                                                    </div>
                                                                </motion.section>
                                                            } else if (e.questionType === 'survey') {
                                                                return <motion.section aria-label={e.questionLabel} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${e.questionType === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                                    <div>
                                                                        <div className={'flex flex-col justify-start'}>
                                                                            <div className={'flex justy-between items-center'} style={{ width: '35%' }}>
                                                                                <div className={'w-11/12 text-sm'}>
                                                                                    {e.questionLabel}
                                                                                </div><span>{e.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                                            </div>
                                                                            <div className={'flex flex-1'}>
                                                                                <AssessmentOptionField edit={true} type={'option'} value={e.answers.answerValue} options={e.answers} setValue={val => setExpansionPanelFieldValue(c.questionId,e.questionId, val)} />
                                                                            </div>
                                                                        </div>
                                                                        {/* <hr className={'mt-5'} style={(themeMode.themeMode === 'light') ? { border: '1px solid grey' } : {}} /> */}
                                                                    </div>
                                                                </motion.section>
                                                            } return null;
                                                        })
                                                    }
                                                </div>
                                            }
                                      
                                        </>
                                    } else if (c.questionType === "text") {
                                        return <motion.section tabIndex={0} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={`flex justify-between items-center`}>
                                                <div className="w-11/12 text-sm">
                                                    {c.questionLabel}
                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                            </div>
                                            <div className="mt-4 pb-3">
                                                {/* { <div className={`relative ring-1 focus-within:ring-2 'ring-gray-300 dark:ring-gray-700' focus-within:ring-tcolor dark:focus-within:ring-tcolor px-2 py-1 rounded 'hover:cursor-wait' flex items-center 'animate-pulse' }`}>
                                                    <input className={`outline-none flex-grow text-gray-600 dark:text-white font-medium rounded px-3 py-1 focus:text-black bg-transparent`}  value={c.textBox} />    
                                                </div>} */}
                                               { <AssessmentTextField edit={false} value={c.textBox} ml={true} length={'2'} />}
                                            </div>
                                        </motion.section>
                                    } else if (c.questionType === "select") {
                                        return <motion.section tabIndex={0} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={`flex justify-between items-center`}>
                                                <div className="w-11/12 text-sm">
                                                    {c.questionLabel}
                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                            </div>
                                            <div className="mt-4 pb-3">
                                                <AssessmentOptionField edit={false} type={'option'} value={c.answers.answerValue} options={c.answers} />
                                            </div>
                                        </motion.section>
                                    } else if (c.questionType === "multi") {
                                        return <motion.section tabIndex={0} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={`flex justify-between items-center`}>
                                                <div className="w-11/12 text-sm">
                                                    {c.questionLabel}
                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                            </div>
                                            <div className="mt-4 pb-3">
                                                <AssessmentOptionField edit={false} type={'multi_option'} value={c.answers} options={c.answers} />
                                            </div>
                                            {
                                                (c.questionId === 362 && c.answers[8].selected && c.answers[8].otherText) ? <AssessmentTextField edit={false} value={c.answers[8].otherText} ml={true} /> : ''
                                            }
                                        </motion.section>
                                    } else if (c.questionType === 'header') {
                                        return <motion.section tabIndex={0} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={`flex justify-between items-center`}>
                                                <div className="w-11/12 text-sm" style={{ fontWeight: 'bold' }}>
                                                    {c.questionLabel}
                                                </div>
                                            </div>

                                        </motion.section>
                                    } else if (c.questionType === 'survey') {
                                        return <motion.section tabIndex={0} initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.questionType === 'heading' ? '' : 'form-wrap py-4'}`}>
                                            <div className={'flex justy-between items-center'}>
                                                <div className={'w-11/12 text-sm'}>
                                                    {c.questionLabel}
                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                            </div>
                                            <div className={'mt-4 pb-3'}>
                                                <AssessmentOptionField edit={false} type={'option'} value={c.answers.answerValue} options={c.answers} />
                                            </div>
                                        </motion.section>
                                    }
                                    return c
                                })
                            }
                </div>
            }
            {
                createPortal(<Dialog title={t("Download File")} showDialog={downloadPopup} onClose={() => setDownloadPopup(false)}>
                     <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Are you sure you want to download this  Assessment form?")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-700 dark:text-red-300 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setDownloadPopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => printAssessment()}>{t("Yes")}</button>
                    </div>
                </div>
                </Dialog>, document.body)
            }
        </div>
    )
}

export default AssessmentReadOnly