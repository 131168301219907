import { useCallback, useMemo, useState, useEffect } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'

import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'

import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"

import API from '../../axios/API'

import {setAlert} from '../../store/theme/actions'
import {setChildOrgRender} from '../../store/auth/actions'
import { useTranslation } from "react-i18next"
import Dialog from '../../components/snippets/Dialog'
import FlowText from '../../components/snippets/FlowText'
import { createPortal } from "react-dom"

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const ChildOrgTransferDailog = ({ onClose, caseIds }) => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [organizaitonList, setOrganizationList] = useState([]);
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(false);
    const {userDetails} = useSelector(state => state.auth)
    const render = useSelector(state => state.auth.childOrgTransferRender)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')
    const [alertPopupEnable,setAlertPopupEnable] = useState(false)
    const [message,setMessage] = useState()
    const [childId,setChildId] = useState()

    useEffect(() => {
        setLoading(true);
        API.get(`organizations/${orgId}/organizations`).then((res) => {
            setOrganizationList(res.data.filter(c=>c.organizationId !==userDetails.orgId));
        }).catch(e => {
            // console.log(e)
        }).finally(() => setLoading(false))
    }, [])// eslint-disable-line
   
    const childOrgtransfer = (id) =>{
        setChildId(id)
        let details = {
            "orgId": orgId,
            "caseId": caseIds,
            "childOrgId": id
          }
          API.post(`organizations/cases:transfer`,details).then(res =>{
            if(res.data.status === false){
                setAlertPopupEnable(true)
                setMessage(res.data.errorMessage)
            }else{
              dispatch(setAlert({title:t('Success'),subtitle:t('Case transferred to the child org successfully'),type:'success',active:true}));
              dispatch(setChildOrgRender(render+1));
              onClose();
            }
          }).catch(e=>{
              console.log(e)
          }).finally(()=>onClose);
    }


    const ProtocolEnable=()=>{
        let details = {
            "orgId": orgId,
            "caseId": caseIds,
            "childOrgId": childId,
            "initiateTransfer":true
          }
          API.post(`organizations/cases:transfer`,details).then(res =>{
              dispatch(setAlert({title:t('Success'),subtitle:t('Case transferred to the child org successfully'),type:'success',active:true}));
              dispatch(setChildOrgRender(render+1));
              setAlertPopupEnable(false)
              onClose();
          }).catch(e=>{
              console.log(e)
          }).finally(()=>onClose);
  
    }

    const CloseProtocol =()=>{
        dispatch(setChildOrgRender(render+1));
        setAlertPopupEnable(false)
        onClose();
    }
    const colums = useMemo(() => {
        return [
            { name: t('Organization Name'), selector: 'organizationName', grow: 1, sortable: true },
            // { name: 'City', selector: 'organizationAddress.city', grow: 1, sortable: true },
            // { name: 'Phone', selector: 'phone', grow: 1, sortable: true },
            {
                name: t('Action'),button:true, cell: row =><div className={`flex items-center rounded-lg text-white px-3 py-1 bg-tcolor`} style={{cursor:'pointer'}} onClick={()=>childOrgtransfer(row.organizationId)}> <span className="mr-2 text-sm font-medium">{t("Select")}</span></div>, sortable: true, hide: 'sm' }
 
            // {name: 'Action', button: true, cell: row => <Link to={`/caregiver/${row.id}`}><FaEye size="20px" className="text-gray-600 dark:text-gray-300" /></Link>}
        ]
    }, [])// eslint-disable-line
    const SubHeader = useMemo(() => (
        <div className="flex items-center">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return organizaitonList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [organizaitonList,filter])// eslint-disable-line
    const ExpandComponent = useCallback(row => {
        return (

            <div className="bg-bluegray-300 dark:bg-ldark" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>

        )
    }, [])
    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })

    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })

    return (
        <>
            {
                loading ?
                    <DatatableLoader /> :
                    <div className=" mt-1 p-2 bg-bluegray-200 dark:bg-ddark rounded-lg max-h-[300px] overflow-auto">
                        <DataTable title="casedetails" columns={colums} data={filteredcasedetails} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} noDataComponent={t('There are no childorg to display')} />
                    </div>
            }
              <Dialog title={t("")} showDialog={alertPopupEnable} onClose={() => setAlertPopupEnable(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={message} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => CloseProtocol()}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() =>ProtocolEnable()} >{t("Yes")}</button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default ChildOrgTransferDailog