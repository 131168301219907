import React, { useRef } from 'react'
import { useTranslation } from "react-i18next"

const AssessmentTextField = ({ edit, loading, type, value, setValue, ml, length,ariaLabel }) => {

    const inputElem = useRef(null)
    const { t} = useTranslation()
    const chgValue = (e) => {
        if(typeof setValue === 'function' && edit) setValue(e)
    }
    const focusInput = () => inputElem.current.focus()
    return (
        <div className={`relative ring-1 focus-within:ring-2 ${edit ? 'ring-gray-400 dark:ring-gray-600' : 'ring-gray-300 dark:ring-gray-700'} focus-within:ring-tcolor dark:focus-within:ring-tcolor px-2 py-1 rounded ${loading ? 'hover:cursor-wait' : 'hover:cursor-pointer'} flex items-center ${loading ? 'animate-pulse' : ''}`} onClick={ focusInput }>
            <input disabled={loading || !edit} ref={ inputElem } className={`outline-none flex-grow text-gray-600 dark:text-white font-medium rounded px-3 py-1 focus:text-black bg-transparent ${loading ? 'hover:cursor-wait' : ''}`} placeholder="..." type="text" value={ value } onChange={(e) => chgValue(e.target.value) } maxLength={ml ? `${length}` : ''} aria-label={ariaLabel}/>
            { value !== '' && /^[^-\s]/.test(value) === false ? <span className="text-red-500 text-xs">{t("Space at beginning is not allowed")}</span>:
             type === 'number' && /^\d*$/.test(value) === false && value !== null ? <span className="text-red-500 text-xs">{t("Only Numbers")}</span>: 
             value > 168 && type === 'number' && value !== null && edit && <span className="text-red-500 text-xs">{t("max limit 168 hrs.")}</span>}
            
        </div>
    )
}

export default AssessmentTextField
