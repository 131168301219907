export const careplan = {
    "caseGoals": [
        {
            "goalId": 20,
            "goalName": "Health Goal",
            "goalDetails": [
                {
                    "strategyId": 26,
                    "strategyName": "Reduce generalized stress",
                    "desiredOutcome": null,
                    "strategyDetails": [
                        {
                            "categoryName": "Medical or Behavioral Health Related Services",
                            "caregoryId": 17,
                            "highlight": 1,
                            "goalTypeDetails": [
                                {
                                    "services": [
                                        {
                                            "selectedServiceId": 682,
                                            "serviceId": 800,
                                            "rdbId": 1573967,
                                            "keyword": "Other",
                                            "info": "Intulogic\nchennai,fordia,AK,fordia,75001,(964) 584-9116\n",
                                            "webLink": "http://intulogic.com",
                                            "comment": null,
                                            "adhocTemplate": null,
                                            "adhocContent": null,
                                            "deleted": false
                                        },
                                        {
                                            "selectedServiceId": 683,
                                            "serviceId": 800,
                                            "rdbId": 1605814,
                                            "keyword": "Other",
                                            "info": "tets\ntst,test,AL,test,75001,(123) 456-7890\n",
                                            "webLink": null,
                                            "comment": null,
                                            "adhocTemplate": null,
                                            "adhocContent": null,
                                            "deleted": false
                                        },
                                        {
                                            "selectedServiceId": 684,
                                            "serviceId": 800,
                                            "rdbId": 1605815,
                                            "keyword": "Other",
                                            "info": "test\nts,test,AZ,test,75001,(123) 456-7890\n",
                                            "webLink": null,
                                            "comment": null,
                                            "adhocTemplate": null,
                                            "adhocContent": null,
                                            "deleted": false
                                        },
                                        {
                                            "selectedServiceId": 685,
                                            "serviceId": 800,
                                            "rdbId": 0,
                                            "keyword": "Other",
                                            "info": "Otherservice\ntest,test,undefined,undefined,12345,123-456-7890 \n",
                                            "webLink": "",
                                            "comment": null,
                                            "adhocTemplate": null,
                                            "adhocContent": null,
                                            "deleted": false
                                        }
                                    ],
                                    "serviceId": 800,
                                    "servieType": 100,
                                    "carePlanId": 100,
                                    "categoryId": 17,
                                    "categoryName": "Medical or Behavioral Health Related Services",
                                    "caseId": 61,
                                    "goalId": 20,
                                    "goalType": 0,
                                    "goalTypeName": "Health Goal",
                                    "special": 1,
                                    "serviceTypeName": "Other"
                                },
                                {
                                    "services": [],
                                    "serviceId": 801,
                                    "servieType": 101,
                                    "carePlanId": 100,
                                    "categoryId": 17,
                                    "categoryName": "Medical or Behavioral Health Related Services",
                                    "caseId": 61,
                                    "goalId": 20,
                                    "goalType": 0,
                                    "goalTypeName": "Health Goal",
                                    "special": 1,
                                    "serviceTypeName": "Wellness programs"
                                }
                            ]
                        }
                    ]
                },
                {
                    "strategyId": 27,
                    "strategyName": "Improve overall health",
                    "desiredOutcome": null,
                    "strategyDetails": [
                        {
                            "categoryName": "Medical or Behavioral Health Related Services",
                            "caregoryId": 17,
                            "highlight": 1,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 802,
                                    "servieType": 29,
                                    "carePlanId": 100,
                                    "categoryId": 17,
                                    "categoryName": "Medical or Behavioral Health Related Services",
                                    "caseId": 61,
                                    "goalId": 20,
                                    "goalType": 0,
                                    "goalTypeName": "Health Goal",
                                    "special": 0,
                                    "serviceTypeName": "Alcohol and other drug abuse (AODA) evaluation"
                                },
                                {
                                    "services": [
                                        {
                                            "selectedServiceId": 686,
                                            "serviceId": 803,
                                            "rdbId": 0,
                                            "keyword": "Continence Clinic",
                                            "info": "clinical service\ntest,test,undefined,undefined,12345,123-456-7890 \n",
                                            "webLink": "",
                                            "comment": null,
                                            "adhocTemplate": null,
                                            "adhocContent": null,
                                            "deleted": false
                                        }
                                    ],
                                    "serviceId": 803,
                                    "servieType": 68,
                                    "carePlanId": 100,
                                    "categoryId": 17,
                                    "categoryName": "Medical or Behavioral Health Related Services",
                                    "caseId": 61,
                                    "goalId": 20,
                                    "goalType": 0,
                                    "goalTypeName": "Health Goal",
                                    "special": 0,
                                    "serviceTypeName": "Medical evaluation"
                                },
                                {
                                    "services": [],
                                    "serviceId": 804,
                                    "servieType": 69,
                                    "carePlanId": 100,
                                    "categoryId": 17,
                                    "categoryName": "Medical or Behavioral Health Related Services",
                                    "caseId": 61,
                                    "goalId": 20,
                                    "goalType": 0,
                                    "goalTypeName": "Health Goal",
                                    "special": 0,
                                    "serviceTypeName": "Mental health evaluation"
                                }
                            ]
                        }
                    ]
                }
            ],
            "priority": null
        },
        {
            "goalId": 29,
            "goalName": "Embrace Caregiver Identity",
            "goalDetails": [
                {
                    "strategyId": 23,
                    "strategyName": "Change personal rules for care",
                    "desiredOutcome": null,
                    "strategyDetails": [
                        {
                            "categoryName": "Counseling  or CG Education psycho-social",
                            "caregoryId": 3,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1218,
                                    "servieType": 30,
                                    "carePlanId": 100,
                                    "categoryId": 3,
                                    "categoryName": "Counseling  or CG Education psycho-social",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 1,
                                    "serviceTypeName": "Alternative ways to express anger and frustration"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1220,
                                    "servieType": 36,
                                    "carePlanId": 100,
                                    "categoryId": 3,
                                    "categoryName": "Counseling  or CG Education psycho-social",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Caregiver journey/identity change"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1217,
                                    "servieType": 42,
                                    "carePlanId": 100,
                                    "categoryId": 3,
                                    "categoryName": "Counseling  or CG Education psycho-social",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 1,
                                    "serviceTypeName": "Develop new way of viewing current situation (Cognitive Reframing)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1222,
                                    "servieType": 52,
                                    "carePlanId": 100,
                                    "categoryId": 3,
                                    "categoryName": "Counseling  or CG Education psycho-social",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Family communication and relationships"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1219,
                                    "servieType": 65,
                                    "carePlanId": 100,
                                    "categoryId": 3,
                                    "categoryName": "Counseling  or CG Education psycho-social",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 1,
                                    "serviceTypeName": "Increase level of mastery or confidence"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1221,
                                    "servieType": 94,
                                    "carePlanId": 100,
                                    "categoryId": 3,
                                    "categoryName": "Counseling  or CG Education psycho-social",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Understanding and coping with guilt"
                                }
                            ]
                        },
                        {
                            "categoryName": "Education for caregiver to obtain information about services and assist with planning for the future",
                            "caregoryId": 4,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1225,
                                    "servieType": 34,
                                    "carePlanId": 100,
                                    "categoryId": 4,
                                    "categoryName": "Education for caregiver to obtain information about services and assist with planning for the future",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Available support services and how to obtain them"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1223,
                                    "servieType": 44,
                                    "carePlanId": 100,
                                    "categoryId": 4,
                                    "categoryName": "Education for caregiver to obtain information about services and assist with planning for the future",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Disease and disease processes (provide basis for accurate assessment of care needs)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1224,
                                    "servieType": 67,
                                    "carePlanId": 100,
                                    "categoryId": 4,
                                    "categoryName": "Education for caregiver to obtain information about services and assist with planning for the future",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Legal, financial and/or health care planning"
                                }
                            ]
                        },
                        {
                            "categoryName": "Support Groups Expand and sustain networks of support",
                            "caregoryId": 15,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1226,
                                    "servieType": 39,
                                    "carePlanId": 100,
                                    "categoryId": 15,
                                    "categoryName": "Support Groups Expand and sustain networks of support",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Condition or disease focused (including early stage groups for care receiver)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1227,
                                    "servieType": 46,
                                    "carePlanId": 100,
                                    "categoryId": 15,
                                    "categoryName": "Support Groups Expand and sustain networks of support",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Emotional support/release"
                                }
                            ]
                        }
                    ]
                },
                {
                    "strategyId": 24,
                    "strategyName": "Reduce or minimize work load",
                    "desiredOutcome": null,
                    "strategyDetails": [
                        {
                            "categoryName": "Adult Day Services Experience time away from care responsibilities",
                            "caregoryId": 1,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1232,
                                    "servieType": 55,
                                    "carePlanId": 100,
                                    "categoryId": 1,
                                    "categoryName": "Adult Day Services Experience time away from care responsibilities",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Health model"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1233,
                                    "servieType": 89,
                                    "carePlanId": 100,
                                    "categoryId": 1,
                                    "categoryName": "Adult Day Services Experience time away from care responsibilities",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Social model"
                                }
                            ]
                        },
                        {
                            "categoryName": "Assistive Technologies Promote safety and functional abilities of care receiver",
                            "caregoryId": 2,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1257,
                                    "servieType": 32,
                                    "carePlanId": 100,
                                    "categoryId": 2,
                                    "categoryName": "Assistive Technologies Promote safety and functional abilities of care receiver",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Assistive devices and care supplies (e.g. low beds, mobility devices, commodes, protective garments)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1254,
                                    "servieType": 45,
                                    "carePlanId": 100,
                                    "categoryId": 2,
                                    "categoryName": "Assistive Technologies Promote safety and functional abilities of care receiver",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Emergency response system (e.g. medical alert, in home monitoring)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1255,
                                    "servieType": 56,
                                    "carePlanId": 100,
                                    "categoryId": 2,
                                    "categoryName": "Assistive Technologies Promote safety and functional abilities of care receiver",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Home Modifications (e.g. ramps, walk in showers, grab-bars)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1256,
                                    "servieType": 60,
                                    "carePlanId": 100,
                                    "categoryId": 2,
                                    "categoryName": "Assistive Technologies Promote safety and functional abilities of care receiver",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Home safety features (e.g. lighting, locks, exit door alarms)"
                                }
                            ]
                        },
                        {
                            "categoryName": "Education to build caregiving skills eg direct care and communication",
                            "caregoryId": 6,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1258,
                                    "servieType": 43,
                                    "carePlanId": 100,
                                    "categoryId": 6,
                                    "categoryName": "Education to build caregiving skills eg direct care and communication",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Direct care skills (e.g. bathing, dressing, transfer)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1259,
                                    "servieType": 63,
                                    "carePlanId": 100,
                                    "categoryId": 6,
                                    "categoryName": "Education to build caregiving skills eg direct care and communication",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "How to ask for help from informal sources (e.g. family, friends, neighbors)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1261,
                                    "servieType": 85,
                                    "carePlanId": 100,
                                    "categoryId": 6,
                                    "categoryName": "Education to build caregiving skills eg direct care and communication",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Skills for responding to mood and behavior changes"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1260,
                                    "servieType": 86,
                                    "carePlanId": 100,
                                    "categoryId": 6,
                                    "categoryName": "Education to build caregiving skills eg direct care and communication",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Skills to communicate with care reciever"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1262,
                                    "servieType": 87,
                                    "carePlanId": 100,
                                    "categoryId": 6,
                                    "categoryName": "Education to build caregiving skills eg direct care and communication",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Skills to communicate with service providers"
                                }
                            ]
                        },
                        {
                            "categoryName": "Financial and/or Legal Services and Protection Obtain assistance or counsel",
                            "caregoryId": 8,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1234,
                                    "servieType": 33,
                                    "carePlanId": 100,
                                    "categoryId": 8,
                                    "categoryName": "Financial and/or Legal Services and Protection Obtain assistance or counsel",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Automatic bill pay (e.g. utility, rent, mortgage)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1238,
                                    "servieType": 35,
                                    "carePlanId": 100,
                                    "categoryId": 8,
                                    "categoryName": "Financial and/or Legal Services and Protection Obtain assistance or counsel",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Benefit entitlement programs and/or health insurance plans (e.g. Medicaid, Medicare, LTC Insurance)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1237,
                                    "servieType": 40,
                                    "carePlanId": 100,
                                    "categoryId": 8,
                                    "categoryName": "Financial and/or Legal Services and Protection Obtain assistance or counsel",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Consumer advocacy and protection services (e.g. adult protective services)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1235,
                                    "servieType": 53,
                                    "carePlanId": 100,
                                    "categoryId": 8,
                                    "categoryName": "Financial and/or Legal Services and Protection Obtain assistance or counsel",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Financial assistance or voucher programs (e.g. prescriptions, care supplies, services, housing)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1236,
                                    "servieType": 66,
                                    "carePlanId": 100,
                                    "categoryId": 8,
                                    "categoryName": "Financial and/or Legal Services and Protection Obtain assistance or counsel",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Legal services (e.g. estate planning, legal counsel, elder law attorneys)"
                                }
                            ]
                        },
                        {
                            "categoryName": "Informal Help Network Enlist or increase current amount of help",
                            "caregoryId": 9,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1242,
                                    "servieType": 38,
                                    "carePlanId": 100,
                                    "categoryId": 9,
                                    "categoryName": "Informal Help Network Enlist or increase current amount of help",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Civic or fraternal organizations (e.g. Rotary Club, Lions Club, Jaycees)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1241,
                                    "servieType": 48,
                                    "carePlanId": 100,
                                    "categoryId": 9,
                                    "categoryName": "Informal Help Network Enlist or increase current amount of help",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Ethnic/Cultural social clubs"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1239,
                                    "servieType": 51,
                                    "carePlanId": 100,
                                    "categoryId": 9,
                                    "categoryName": "Informal Help Network Enlist or increase current amount of help",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Family and friends (includes family meetings)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1240,
                                    "servieType": 78,
                                    "carePlanId": 100,
                                    "categoryId": 9,
                                    "categoryName": "Informal Help Network Enlist or increase current amount of help",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Religious affiliation groups"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1243,
                                    "servieType": 92,
                                    "carePlanId": 100,
                                    "categoryId": 9,
                                    "categoryName": "Informal Help Network Enlist or increase current amount of help",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Student group/organizations (e.g. high schools, universities, fraternities)"
                                }
                            ]
                        },
                        {
                            "categoryName": "In-home Supports and Services Reduce responsibility or workload",
                            "caregoryId": 10,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1244,
                                    "servieType": 37,
                                    "carePlanId": 100,
                                    "categoryId": 10,
                                    "categoryName": "In-home Supports and Services Reduce responsibility or workload",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Chore/Homemaker services"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1245,
                                    "servieType": 57,
                                    "carePlanId": 100,
                                    "categoryId": 10,
                                    "categoryName": "In-home Supports and Services Reduce responsibility or workload",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Home delivery of meals/groceries"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1246,
                                    "servieType": 58,
                                    "carePlanId": 100,
                                    "categoryId": 10,
                                    "categoryName": "In-home Supports and Services Reduce responsibility or workload",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Home health care (e.g. nursing, care attendants)"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1247,
                                    "servieType": 73,
                                    "carePlanId": 100,
                                    "categoryId": 10,
                                    "categoryName": "In-home Supports and Services Reduce responsibility or workload",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Personal care"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1248,
                                    "servieType": 74,
                                    "carePlanId": 100,
                                    "categoryId": 10,
                                    "categoryName": "In-home Supports and Services Reduce responsibility or workload",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Pharmacy delivery"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1249,
                                    "servieType": 83,
                                    "carePlanId": 100,
                                    "categoryId": 10,
                                    "categoryName": "In-home Supports and Services Reduce responsibility or workload",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Sitter/Companion services"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1250,
                                    "servieType": 98,
                                    "carePlanId": 100,
                                    "categoryId": 10,
                                    "categoryName": "In-home Supports and Services Reduce responsibility or workload",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Volunteer/Friendly visitor services"
                                }
                            ]
                        },
                        {
                            "categoryName": "Overnight Respite Services Experience time away from care responsibilities",
                            "caregoryId": 12,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1263,
                                    "servieType": 50,
                                    "carePlanId": 100,
                                    "categoryId": 12,
                                    "categoryName": "Overnight Respite Services Experience time away from care responsibilities",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Facility-based respite"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1265,
                                    "servieType": 59,
                                    "carePlanId": 100,
                                    "categoryId": 12,
                                    "categoryName": "Overnight Respite Services Experience time away from care responsibilities",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Home of another family member or friend"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1264,
                                    "servieType": 62,
                                    "carePlanId": 100,
                                    "categoryId": 12,
                                    "categoryName": "Overnight Respite Services Experience time away from care responsibilities",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Home-based respite"
                                }
                            ]
                        },
                        {
                            "categoryName": "Transportation Introduce alternate source of transportation",
                            "caregoryId": 16,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1251,
                                    "servieType": 93,
                                    "carePlanId": 100,
                                    "categoryId": 16,
                                    "categoryName": "Transportation Introduce alternate source of transportation",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Transport Services"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1252,
                                    "servieType": 97,
                                    "carePlanId": 100,
                                    "categoryId": 16,
                                    "categoryName": "Transportation Introduce alternate source of transportation",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Volunteer/Escort Services"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1253,
                                    "servieType": 99,
                                    "carePlanId": 100,
                                    "categoryId": 16,
                                    "categoryName": "Transportation Introduce alternate source of transportation",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Voucher Programs"
                                }
                            ]
                        }
                    ]
                },
                {
                    "strategyId": 25,
                    "strategyName": "Support positive self-appraisal",
                    "desiredOutcome": null,
                    "strategyDetails": [
                        {
                            "categoryName": "Counseling  or CG Education psycho-social",
                            "caregoryId": 3,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1230,
                                    "servieType": 96,
                                    "carePlanId": 100,
                                    "categoryId": 3,
                                    "categoryName": "Counseling  or CG Education psycho-social",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Valuing positive aspects of caregiving"
                                }
                            ]
                        },
                        {
                            "categoryName": "Support Groups Expand and sustain networks of support",
                            "caregoryId": 15,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1231,
                                    "servieType": 54,
                                    "carePlanId": 100,
                                    "categoryId": 15,
                                    "categoryName": "Support Groups Expand and sustain networks of support",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Friendship/Peer support"
                                }
                            ]
                        }
                    ]
                },
                {
                    "strategyId": 26,
                    "strategyName": "Reduce generalized stress",
                    "desiredOutcome": null,
                    "strategyDetails": [
                        {
                            "categoryName": "Counseling  or CG Education psycho-social",
                            "caregoryId": 3,
                            "highlight": 0,
                            "goalTypeDetails": [
                                {
                                    "services": [],
                                    "serviceId": 1270,
                                    "servieType": 41,
                                    "carePlanId": 100,
                                    "categoryId": 3,
                                    "categoryName": "Counseling  or CG Education psycho-social",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Coping skills"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1268,
                                    "servieType": 82,
                                    "carePlanId": 100,
                                    "categoryId": 3,
                                    "categoryName": "Counseling  or CG Education psycho-social",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Self care techniques"
                                },
                                {
                                    "services": [],
                                    "serviceId": 1269,
                                    "servieType": 91,
                                    "carePlanId": 100,
                                    "categoryId": 3,
                                    "categoryName": "Counseling  or CG Education psycho-social",
                                    "caseId": 61,
                                    "goalId": 29,
                                    "goalType": 0,
                                    "goalTypeName": "Embrace Caregiver Identity",
                                    "special": 0,
                                    "serviceTypeName": "Stress management techniques"
                                }
                            ]
                        }
                    ]
                }
            ],
            "priority": null
        }
    ],
    "edited": true,
    "existing": false,
    "cmResponsibility": null,
    "cgResponsibility": null
}