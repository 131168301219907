import { motion } from "framer-motion";
import { useEffect, useState } from "react"
import AssessmentLoader from '../Assessment/AssessmentLoader';
import ResourceOptionField from "./ResourceOptionField";
import FieldInput from "../../components/snippets/FieldInput";
import { useFormik } from "formik";
import * as Yup from 'yup'
import FlowText from "../../components/snippets/FlowText";
import { AiOutlineStar, AiTwotoneStar } from 'react-icons/ai'
import { AnimatePresence } from "framer-motion";
import { assessmentOptionAnim } from "../../commons/anims";
import Demographic from "../../components/snippets/Assessment/Demographic";
import API from "../../axios/API";
import { setAlert } from "../../store/theme/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next"
// import ReassessDialog from "../../components/snippets/ReassesDialog";
// import Reassessments from "../Dialogs/Reassessment";

const initialFormValues = [{
    notes: '',
    date: ''
}]

const ResourceUtilization = ({ match }) => {

    const [loading, setLoading] = useState(true)
    const [questions, setQuestions] = useState([])
    const [finalAnswers, setFinalAnswers] = useState([])
    // const [rating, setRating] = useState([{ id: 1, selected: false }, { id: 2, selected: false }, { id: 3, selected: false }, { id: 4, selected: false }, { id: 5, selected: false }])
    // const organization = useSelector(state => state.auth.organization)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [answeredCount, setAnsweredCount] = useState(0)
    const [questionLength, setQuestionLength] = useState(0)
    let orgId = localStorage.getItem('orgid')
    // let reason = localStorage.getItem('reassess')
    // const [reassessDialogModal1, setReassessDialogModal1] = useState(false)
    // const [assessmentTypeId,setAssesmentTypeId] = useState()
    // let Create = localStorage.getItem('createScreen')
    const [disable,setDisable] =useState(false)

    const history = useHistory()
    useEffect(() => {
        setLoading(true)
        setAnsweredCount(0)
        setQuestionLength(0)
        API.get(`cases/${match.params.caseId}/resource-utilization`).then(res => {
            setQuestions(res.data.body.resourceUtilization.map(c => {
                c.answers = [{ answerLable: t('Yes'), answerId: 1, selected: false, answerValue: '' }, { answerLable: t('No'), answerId: 0, selected: false, answerValue: '' }]
                c.ratings = [{ id: 1, selected: false }, { id: 2, selected: false }, { id: 3, selected: false }, { id: 4, selected: false }, { id: 5, selected: false }]
                return c
            }))
            setQuestionLength(res.data.body.resourceUtilization.length);
            setLoading(false)
        })
    }, [])// eslint-disable-line

    const resourceFormSchema = Yup.array().of(
        Yup.object().shape({
            notes: Yup.string(),
            date: Yup.string()
        })
    )

    const resourceForm = useFormik({
        initialValues: initialFormValues,
        validateOnMount: true,
        validationSchema: resourceFormSchema,
        enableReinitialize: true,
        onSubmit: values => {
        }
    })

    const setFieldValue = (c, val) => {
        setQuestions(questions.map(e => {
            if (c.used !== null) {
                if (e.selectedServiceId === c.selectedServiceId) {
                    e.used = val
                    if(val === 0){
                        e.ratings = [{ id: 1, selected: false }, { id: 2, selected: false }, { id: 3, selected: false }, { id: 4, selected: false }, { id: 5, selected: false }]
                    }
                }
            } else {
                if (e.selectedServiceId === c.selectedServiceId) {
                    e.used = val
                    setAnsweredCount(answeredCount + 1)
                }
            }
            return e
        }))
        setFinalAnswers(questions)
    }
    const setRatingValue = (c, i) => {
        setQuestions(questions.map(e => {
            if (e.selectedServiceId === c.selectedServiceId) {
                e.ratings = [{ id: 1, selected: false }, { id: 2, selected: false }, { id: 3, selected: false }, { id: 4, selected: false }, { id: 5, selected: false }]
                e.rating = i + 1
                for(var j =0 ;j<=i;j++){
                    if(j<=i){
                        e.ratings[j].selected = true
                    }else{
                        e.ratings[j].selected = false
                    }
                }
            }
            return e
        }))
        setFinalAnswers(questions)
        // setRating(rating.map((f, j) => {
        //     if (j <= i) {
        //         f.selected = true
        //     } else {
        //         f.selected = false
        //     }
        //     return f
        // }))
    }



    const submitResourceUtilization = () => {
        setDisable(true)
        setLoading(true)
        setFinalAnswers(questions.map((e, i) => {
            resourceForm.values.map((f, j) => {
                if(f !== undefined && f.date !== undefined && f.date !== null && f.date.length>0){
                    if (i === j) {
                        e.notes = f.notes;
                        e.startDate = new Date(f.date).toISOString();
                        delete e.answers
                    }
                }
                
                return f
            })
            return e
        }))
        API.post(`cases/${match.params.caseId}/resource-utilization`,finalAnswers).then(res=>{
            if(res.status === 200){
                dispatch(setAlert({title:t('Success'),subtitle:t('Resource utilization saved successfully'),active:true,type:'success'}))
                let details ={
                    "screener":0
                }
                API.post(`organizations/${orgId}/cases/${match.params.caseId}/assessments`,details).then(res => {
                    if (res.status === 200) {
                        // if(reason === "true" && Create === 'true'){
                        //     setAssesmentTypeId(res.data.body.assessmentId)
                        //     setReassessDialogModal1(true)
                        // }else{
                        history.push(`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}`)
                        dispatch(setAlert({ title:t ('Success'), subtitle: t('New Follow Up Assessment created successfully'), type: 'success', active: true }))
                    }
                }).catch(err => {
                    console.log(err)
                })
            }
        }).catch(err => {
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                history.push(`/casedata/${match.params.caseId}`)
               
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            }
        })
    }
    return (
        <div className="h-full">
            <div className="h-full flex">
                <Demographic caseId={match.params.caseId} />
                <div className="flex-1">
                    <div className="flex-1 flex">
                        {
                            loading ? <AssessmentLoader /> : <div className="flex-1 pl-5 h-full">
                               {t("Did you use the following resources?")}
                                {
                                    questions.map((c, i) => {
                                        return <motion.div initial="hidden" animate="visible" exit="hidden" className={'mt-3 form-wrap py-4'}>
                                            <div className={'flex items-center'}>
                                                <div className={'w-11/12 text-sm'}>
                                                    {c.facilityId}
                                                </div>
                                            </div>
                                            <div className={'flex justify-start items-center mt-2 mb-2'}>
                                                <div className={'text-sm mr-5 ml-5'}>
                                                    <ResourceOptionField edit={true} type={'option'} value={c.used} options={c.answers} setValue={val => setFieldValue(c, val)} />
                                                </div>
                                                {
                                                    c.used === 1 ?
                                                        <>
                                                            <div className={'mr-5 ml-5'}>
                                                                <div className={'flex justify-between'}>
                                                                    <FlowText text={t('Rating')} /><>{
                                                                        c.ratings.map((e, i) => {
                                                                            return <div className={`flex items-center text-sm py-1 mr-2 ml-2 px-3  ring-gray-400 dark:ring-gray-600 hover:cursor-pointer`} onClick={() => setRatingValue(c, i)}>
                                                                                <AnimatePresence>
                                                                                    {e.selected &&
                                                                                        <motion.span className="overflow-hidden" variants={assessmentOptionAnim} initial="hidden" animate="visible" exit="hidden">
                                                                                            <AiTwotoneStar size="20px" className="pr-1 ml-1 -mt-1 -mb-1" />
                                                                                        </motion.span>
                                                                                    }
                                                                                    {
                                                                                        !e.selected &&
                                                                                        <motion.span className="overflow-hidden" variants={assessmentOptionAnim} initial="hidden" animate="visible" exit="hidden">
                                                                                            <AiOutlineStar size="20px" className="pr-1 ml-1 -mt-1 -mb-1" />
                                                                                        </motion.span>
                                                                                    }
                                                                                </AnimatePresence>
                                                                            </div>
                                                                        })
                                                                    }</>
                                                                </div>
                                                            </div>
                                                            <div className={'text-sm mr-5 ml-5'}>
                                                                <FieldInput type="date" label={t("Date")} maxDate={new Date()} fieldMeta={resourceForm.getFieldMeta(`${i}.date`)} fieldProps={resourceForm.getFieldProps(`${i}.date`)} fieldHelper={resourceForm.getFieldHelpers(`${i}.date`)} edit={true} dataLoaded={true} />
                                                            </div>
                                                        </> : ''
                                                }
                                            </div>
                                            <FieldInput type="textarea" ariaLabel={t('Type a note')}  fieldMeta={resourceForm.getFieldMeta(`${i}.notes`)} fieldHelper={resourceForm.getFieldHelpers(`${i}.notes`)} fieldProps={resourceForm.getFieldProps(`${i}.notes`)} label={t('Type a note')} edit={true} dataLoaded={true} />
                                        </motion.div>
                                    })
                                }
                            </div>
                        }
                    </div>
                    <div className="flex justify-center mt-7 mx-5">
                        {/* <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-500 hover:bg-opacity-80 text-white" onClick={() => history.push(`/casedata/${caseId}`)}>Cancel</button> */}
                        {/* <button disabled={true} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => submitResourceUtilization()}>{t("Continue")}</button> */}
                    <div className={`flex items-center rounded-lg text-white px-3 py-1 bg-green-700 ${((answeredCount === questionLength)) ? 'cursor-pointer hover:ring-2 hover:ring-tcolor hover:ring-offset-2 hover:ring-offset-bluegray-300 dark:hover:ring-offset-ldark' : 'cursor-not-allowed opacity-50'} ml-2 mr-2`} onClick={((answeredCount === questionLength) && (!disable)) ? submitResourceUtilization : ''}>
                        <span className="mr-2 text-sm font-medium">{t("Continue")}</span>
                    </div>
                    </div>
                </div>
            </div>
            {/* <ReassessDialog title={t("Assessment Reason")} showDialog={reassessDialogModal1} onClose={() => setReassessDialogModal1(false)}>
                <Reassessments onClose={() => setReassessDialogModal1(false)} props={match.params.caseId} assess ={assessmentTypeId} />
            </ReassessDialog> */}
        </div>
    )
}

export default ResourceUtilization