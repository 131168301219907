const themeStore = {
    themeMode: null,
    alert: {title: '', subtitle: '', active: false, type: 'error'},
    pageTitle: '',
    loading: false,
    sideBar: false,
    animate: false,
    language: 'en',
    languages :[
        {
            title: 'English',
            code: 'en_US',
            key: 'en'
        },
        {
            title: 'Espanol',
            code: 'es_US',
            key: 'es'
        },
        {
            title: '普通话',
            code: 'zh_CN',
            key: 'zh'
        },
        {
            title: '廣東話',
            code: 'zh_HK',
            key: 'hk'
        },
        {
            title: '日本語 (にほんご',
            code: 'ja_JP',
            key: 'ja'
        },
        {
            title: '한국어',
            code: 'ko_KR',
            key: 'ko'
        },
        {
            title: 'русский',
            code: 'ru_RU',
            key: 'ru'
        }
    ],
    hide:true
    
}
export default themeStore