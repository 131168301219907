import { useCallback, useEffect, useMemo, useState } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'

import InlineTextField from '../../components/snippets/InlineTextField'
import API from '../../axios/API'
import DatatableLoader from '../../components/snippets/DatatableLoader'
import {setAlert} from '../../store/theme/actions'
import {setTransferCaseRender} from '../../store/auth/actions'
import { useTranslation } from "react-i18next"
createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const TransferCaseDialog = ({ onClose, caseIds }) => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const organization = useSelector(state => state.auth.organization);
    const [caremanagerList, setCareManagerList] = useState([])
    const [loading,setLoading]= useState(true)
    const render = useSelector(state =>state.auth.transferCaseRender)
    const {t} = useTranslation()
    // const [selectRows, setSelectedRows] = useState([])
    const dispatch = useDispatch()
    useEffect(() => {
        setLoading(true)
        API.get(`organizations/${organization}/care-managers`).then(res => {
            if (res.status === 200) {
                setCareManagerList(res.data.body)
                setLoading(false)
            }
        }).catch(e => {
            console.log(e)
        })
    }, [])// eslint-disable-line

    const transferCase = (id) => {
        let details ={
            "newCareManagerId": id,
            "caseId": caseIds,
            "orgId": organization
        }
        API.post(`cases:transfer`,details).then(res=>{
            dispatch(setAlert({title:t('Success'),subtitle:t('Case Transferred Successfully'),type:'success',active:true}))
            dispatch(setTransferCaseRender(render+1))
            onClose();
        }).catch(e=>{
            console.log(e)
        }).finally(()=>onClose)
    }

    const colums = useMemo(() => {
        return [
            { name: t('CareManager'),selector: row => `${ row.firstName } ${ row.lastName }`, sortable: true },
            { name: t('Select'),button:true, cell: row =><div className={`flex items-center rounded-lg text-white px-3 py-1 bg-tcolor`} style={{cursor:'pointer'}} onClick={()=>transferCase(row.pplId)}> <span className="mr-2 text-sm font-medium">{t("Select")}</span></div>, sortable: true, hide: 'sm' }
        ]
    }, [])// eslint-disable-line

    const SubHeader = useMemo(() => (
        <div className="flex items-center">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} />
        </div>

    ), [filter]) // eslint-disable-line
    const filteredCaregivers = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return caremanagerList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [caremanagerList,filter]) // eslint-disable-line
    const ExpandComponent = useCallback(row => {
        return (
            <div className="bg-bluegray-300 dark:bg-ldark py-2 px-3" key={row.data.id}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm">{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>
        )
    }, [])
    const rowsSelected = useCallback(({ selectedRows }) => {
        transferCase(selectedRows[0].pplId)
    }, []) // eslint-disable-line
    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })

    return (
        <div className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg max-h-[300px] overflow-y-auto">
            {
                loading ? 
                <DatatableLoader/>
                :
                <DataTable title="Caregivers" columns={colums} data={filteredCaregivers} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader highlightOnHover persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} onSelectedRowsChange={rowsSelected}/>
            }
        </div>
    )
}

export default TransferCaseDialog