import { useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdTranslate, MdCheck } from 'react-icons/md'
import { AnimatePresence, motion } from 'framer-motion'
import {setLanguages} from '../store/theme/actions'
import { useDispatch, useSelector } from 'react-redux'




const LanguageSelector = ({injectPopupClass, originX, originY}) => {

    const [showSelector, setShowSelector] = useState(false)
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    // const language = useSelector(state => state.theme.language)
    // const language = useSelector(state =>state.auth.setSpanish)
    const languages = useSelector(state => state.theme.languages)
    const setLanguage = lang => {
        dispatch(setLanguages(lang))
        i18n.changeLanguage(lang)
        // API.post(`language/${lang}`).then(res => {
        //     dispatch(setLanguages(language+1))
        // }).catch(e => {
        //     console.log(e)
        // })
    }
    const hideShowSelector = () => setShowSelector(false)
    const menuAnim = useMemo(() => ({
        hidden: {
            scale: 0,
            opacity: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            originX: originX,
            originY: originY
        }
    }), [originX, originY])
    const langCode = useMemo(() => {
        let l = languages.filter(c => c.code === i18n.language)
        return l.length > 0 ? l[0].key : languages[0].key
    }, [i18n.language]) //eslint-disable-line
    useLayoutEffect(() => {
        if(showSelector) {
            setTimeout(() => {
                window.addEventListener('click', hideShowSelector)
            }, 100)
        } else window.removeEventListener('click', hideShowSelector)

        return () => window.removeEventListener('click', hideShowSelector)
    }, [showSelector]) //eslint-disable-line

    return (
        <div className="relative">
            <div className="cursor-pointer relative" onClick={() => setShowSelector(true)} title={t('Select Language')}>
                <span className="absolute text-[10px] leading-none -top-3 -right-2.5 bg-tcolor text-white px-1 py-1 rounded-full">{langCode}</span>
                <MdTranslate size="20px" className="text-gray-800 dark:text-white" />
            </div>
            <AnimatePresence>
                { showSelector &&
                    <motion.div variants={ menuAnim } initial="hidden" animate="visible" exit="hidden" className={`absolute bg-bluegray-500 dark:bg-ldark py-2 w-48 rounded-lg text-left ${injectPopupClass}`}>
                        <div className="text-xs text-gray-300 pl-2">Language</div>
                        <div className="pt-2">
                            {
                                languages.map(c => (
                                    <div key={c.code} className="py-1 pl-4 pr-2 hover:bg-bluegray-600 text-white text-sm cursor-pointer flex items-center justify-between" onClick={() => setLanguage(c.code)}>
                                        <span>{c.title}</span>
                                        { i18n.language === c.code && <MdCheck className="text-green-300" /> }
                                    </div>
                                ))
                            }
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}

export default LanguageSelector