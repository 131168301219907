import { useState, useMemo,useEffect, useLayoutEffect } from 'react'
import { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import FormCard from '../../components/snippets/FormCard'
import { useFormik } from 'formik'
import { FaDownload,FaTrashAlt } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import * as Yup from 'yup'
import FieldInput from "../../components/snippets/FieldInput"
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import { setAlert } from '../../store/theme/actions'
import DatatableLoader from '../../components/snippets/DatatableLoader'
import API from '../../axios/API'
import { createPortal } from "react-dom"
import FlowText from '../../components/snippets/FlowText'
import Dialog from '../../components/snippets/Dialog'
import 'moment-timezone';
import moment from 'moment';

const initialFormValues = {
    file: '',
    fileName: '',
    details: ''
}


createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const UploadsFile = ({props,CmId}) => {
    const [filter] = useState('')
    const dispatch = useDispatch()
    const [formLoading, setFormLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    // const [formData,setFormData] = useState()
    const [file,setFile] = useState()
    const [fileName,setFileName] = useState()
    const editForm = true
    const [uploadList ,setUploadsList] = useState([])
    const organization = useSelector(state =>state.auth.organization)
    const [render,setRender] = useState(0)
    const [downloadFile,setDownloadFile] = useState()
    const [downloadFileName,setDownloadFileName] = useState()
    const [downloadPopup,setDownloadPopup] = useState(false)
    const [deletePopup,setDeletePopup] = useState(false)
    const [fileId,setFileId] = useState()
    const [activeQuickAction] = useState({ position: 'bottom', active: null })
    const [columnsSelected, setColumnsSelected] = useState([])
    // const uploadTable = useRef(null)
    const [uploadButton,setUploadButton] = useState(false)
    const [page, setPage] = useState(1)
    const { t } = useTranslation()
    const userDetails = useSelector(state=>state.auth.userDetails)
    Yup.addMethod(Yup.string, 'EmptySpace', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })


    useEffect(() => {
        setLoading(true);
        API.get(`cases/${props}/files`).then((res) => {
            setUploadsList(res.data.params);
            setLoading(false)
        }).catch(e => {
            console.log(e)
        }).finally()
    }, [render]) // eslint-disable-line

    const followdate =(value) => {
        if(value !== null && value){
            let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
        //     let followupdate = new Date(value)
        // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
        }else{
            return ''
        }
     }
    const profileFormSchema = Yup.object().shape({
        file: Yup.string(),
        details: Yup.string().EmptySpace()
    })
    const formData = useMemo(() => {
        return { ...initialFormValues, }
    },[])
    

    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            setFormLoading(true)
            submitForm()
            setFormLoading(false)
        }
    })

    // const QuickActionComp = useCallback((row) => {
    //     let options = [
    //         {icon:MdFileDownload,title:'Download File',link:'',onClick:()=> downloadPDF(row.fileId,row.fileName)},
    //         {icon:MdDelete,title:'Delete File',link:'',onClick:()=>deletePdf(row.fileId)}
    //     ]
    //     return (
    //         <QuickAction id={row.fileId} active={activeQuickAction} setActive={id => setActiveQuickAction(id)} table={uploadTable} options={options} />
    //     )
    // }, [activeQuickAction, uploadList])// eslint-disable-line

    const validateFileType = (size) => {
        var fileName = size;
        if (fileName > 15728640) {
            dispatch(setAlert({ title: t('Warning'), subtitle: t('Only Accept Below 15 Mb'), type: 'warning', active: true }))
            profileForm.resetForm();
            setUploadButton(false)
            return false;
        }
    }
    const colums = useMemo(() => {
        return [
            // {
            //     cell: row => QuickActionComp(row),
            //     allowOverflow: true,
            //     button: true,
            //     width: '56px', // custom width for icon button
            // },
            {
                name: t('File Name'),
                selector: 'fileName', sortable: true
            },
            {
                name: t('Details'),
                selector: row => {return(<div style={{wordWrap:'break-word',padding:'10px',whiteSpace:'normal',width:'auto'}}>{row.details}</div>)}
            },
            {
                name: t('Uploaded'),
                selector:row => `${followdate(row.uploadDate)}`
            },
            {
                name: t('Action'),
                selector: 'actions',
                cell: row => <><div><button onClick={() => ((organization && userDetails && parseInt(organization) === userDetails.orgId) || (organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_cm')) || userDetails.role.includes('dynamic_admin')) ? downloadPDF(row.fileId, row.fileName) : ''}><FaDownload title={t("Download")} size="20px" className="text-gray-600 dark:text-gray-300" /></button></div>&nbsp;&nbsp;&nbsp;&nbsp;
                    <div><button onClick={() => ((organization && userDetails && parseInt(organization) === userDetails.orgId) || (organization && userDetails && userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_cm')) || userDetails.role.includes('dynamic_admin')) ? deletePdf(row.fileId) : ''}><FaTrashAlt title={t("Delete")} size="20px" className="text-red-600 dark:text-gray-300" /></button></div>
                </>,
            }
        ]
        // .map(c => {
        //     if (c.name !== undefined && columnsSelected.find(e => e.name === c.name)) c.omit = columnsSelected.find(e => e.name === c.name).active
        //     return c
        // })
    }, [activeQuickAction, columnsSelected, uploadList, organization, t, userDetails]) // eslint-disable-line  
    useLayoutEffect(() => {
        setColumnsSelected(colums.filter(c => c.name !== undefined && c.optionalColumn !== false).map(c => ({ name: c.name, active: c.initialActive || false })))
    }, []) // eslint-disable-line    
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return uploadList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [filter,uploadList])

    // const data = [{casedetails}]

    const uploadFile = (v) => {
        const fileType = v ? v.type.split('/')[0]:'';
        if (fileType === 'video') {
            dispatch(setAlert({ title: t('Warning'), subtitle: t('video file cannot be allowed'), type: 'warning', active: true }))
            profileForm.resetForm();
            setUploadButton(false);
            return ;
        }
        if(v===undefined){
            setUploadButton(false)
            profileForm.resetForm()
        }
        if(v.name){
            setFileName(v.name)
            setUploadButton(true)
        }
        let result = validateFileType(v.size)
        if(result === undefined){
            base64Conversion(v).then((value)=>{
                setFile(value)
            })  
        }
     }
     const downloadPDF = (fileId,fileName) => {
        setDownloadFileName(fileName)
         API.get(`cases/${props}/files/${fileId}`).then(res=>{
             setDownloadPopup(true)
             setDownloadFile(res.data.params)
         }).catch(err=>{
             console.log(err)
         })
    }
    const downloadFiles = () =>{
        const linkSource = downloadFile;
        const downloadLink = document.createElement("a");
        const fileName = downloadFileName;
    
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setDownloadPopup(false)
    }
    const base64Conversion = v => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(v);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error); 
    })


    const submitForm = ()=>{
        let details = {
            file : file,
            fileName :fileName,
            details: profileForm.values.details,
            caseId: props,
            uploadDate: '',
        }
        API.post(`cases/${props}/files`,details).then(res=>{
            if(res.status === 200){
                dispatch(setAlert({title:t('Success'),subtitle:t('File uploaded successfully'),active:true,type:'success'}))
                setRender(render+1)
                setUploadButton(false)
            }
        }).catch(e=>{
            console.log(e)
        }).finally(profileForm.resetForm())
    }

    const deletePdf = (value)=>{
        setDeletePopup(true)
        setFileId(value)
    }

    const deleteFile = ()=>{
        API.delete(`cases/-/files/${fileId}`).then(res=>{
            if(res.status === 200){
                dispatch(setAlert({title:t('Success'),subtitle:t('File deleted successfully'),active:true,type:'success'}))
                setRender(render+1)
                setDeletePopup(false)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    return (
        <>{
            loading ? "" :
            ((organization && userDetails && userDetails.orgId === parseInt(organization) && (userDetails.caremanagerId === CmId)) || (organization && userDetails && organization !== userDetails.orgId && (userDetails.caremanagerId === CmId) && userDetails.role.includes('dynamic_cm')) || userDetails.role.includes('dynamic_admin')||userDetails.role.includes('admin') ) ? 
            <div className="max-w-5xl">
                <FormCard title={t("Uploads")}>
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-2">
                    <FieldInput ariaLabel={'Choose File'} loading={formLoading} type="file" label={t("File")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('file')} fieldProps={profileForm.getFieldProps('file')} fieldHelper={profileForm.getFieldHelpers('file')} edit={editForm} dataLoaded={true} onChange={v => uploadFile(v)} />
                    <FieldInput ariaLabel={'File Details'} type="textarea" loading={formLoading} label={t("Details")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('details')} fieldProps={profileForm.getFieldProps('details')} edit={editForm} dataLoaded={true} />
                </div>
                {
                    uploadButton  ? 
                    <div className="flex justify-end mt-3">
                    <button disabled={formLoading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={profileForm.submitForm}>{t("Upload")}</button>
                </div>:''
                }
            </FormCard>
        </div> : ''
        }  
        <div>
           {uploadList.length > 0 ? <FormCard injectClass="mt-12">
            {
                    loading ? <DatatableLoader/> : <DataTable injectClass="mt-12"
                    columns={colums}
                    data={filteredcasedetails} noHeader
                    theme={localStorage.getItem('theme') === 'dark' ? "defaultDark" : "defaultLight"}pagination onChangePage={val => setPage(val)} paginationDefaultPage={page} paginationComponentOptions={{ rowsPerPageText: 'Items per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                />
                }
            </FormCard>:loading === false ? <FormCard>
            <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>
                </FormCard> : ''
            }
            {
                createPortal(<Dialog title={t("Download File")} showDialog={downloadPopup} onClose={() => setDownloadPopup(false)}>
                     <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t(t("Are you sure you want to download this file?"))} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setDownloadPopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => downloadFiles()}>{t("Yes")}</button>
                    </div>
                </div>
                </Dialog>, document.body)
            }
            {
                createPortal(<Dialog title={t("Delete File")} showDialog={deletePopup} onClose={() => setDeletePopup(false)}>
                     <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t(t("Are you sure you want to delete this file?"))} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setDeletePopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => deleteFile()}>{t("Yes")}</button>
                    </div>
                </div>
                </Dialog>, document.body)
            }
        </div>
    </>
    )
}


export default UploadsFile