
import { useEffect, useState } from "react"
import { FaInfoCircle } from 'react-icons/fa'
import FormCard from "../../../components/snippets/FormCard"
import { motion } from 'framer-motion'
import { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../../data/dataTableTheme'
import { AiFillPrinter } from "react-icons/ai"
import PageLoader from "../../CaseDetails/PageLoader";
import { summaryData } from "../../../data/dummy/summary";
import { createPortal } from "react-dom"
import Dialog from "../../../components/snippets/Dialog"
import FlowText from "../../../components/snippets/FlowText"
import { setAlert } from "../../../store/theme/actions"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import axios from "axios"

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

// const initialFormValues = {
//     otherUsefullInfo:{
//         availabilityFamily: '',
//         formalServices: '',
//         specialCircumstances: '',
//         summFour: '',
//         summOne: '',
//         summThree: '',
//         summTwo: ''
//     }
// }

const SummaryData = ({ assessmentId }) => {

    // const [editForm] = useState(false)
    const [loading, setLoading] = useState(true)

    const [summary, setSummary] = useState([false]);
    const [firstIcon, setFirstIcon] = useState(false);
    const [secondIcon, setSecondIcon] = useState(false);
    const [thirdIcon, setThirdIcon] = useState(false);
    const [fourthIcon, setFourthIcon] = useState(false);
    const [downloadPopup, setDownloadPopup] = useState(false)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')
    let protocolType = localStorage.getItem('tcareProtocolEnabled')

    useEffect(() => {
        setLoading(true)
        setSummary(summaryData)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/assessments/${assessmentId}/summary`,{
            headers: {
                'Authorization': localStorage.getItem('token'), 
                Accept: "application/json",
    
              }
        }).then(res => {
            if (res.status === 200) {
                setSummary(res.data.body)
                setLoading(false)
            }
        }).catch(e => {
            console.log(e)
        })
    }, [])// eslint-disable-line

    const downloadFiles = () => {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}organizations/${orgId}/assessments/${assessmentId}/summary`,{
            headers: {
                'Authorization': localStorage.getItem('token'), 
                Accept: "",
    
              }
        }).then(res => {
            if (res.status === 200) {
                const linkSource = `data:application/pdf;base64,${res.data.body}`;
                const downloadLink = document.createElement("a");
                const fileName = "TCE-Summary.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                setDownloadPopup(false)
            }else{
                dispatch(setAlert({title:t('Error'),subtitle:t('Something went wrong'),active:true,type:'error'}))
                setDownloadPopup(false)
            }
        }).catch(err=>{
            dispatch(setAlert({title:t('Error'),subtitle:t('Something went wrong'),active:true,type:'error'}))
            setDownloadPopup(false)
        })
    }

    return (
        <div className={'flex-1'}>
            {
                loading ? <PageLoader /> :
                    <div className="pl-3">
                        <div className={'flex flex-1 justify-between'}>
                            <div className="flex gap-1 justify-end items-center bg-gradient-to-tr from-tcolor to-blue-400 px-2 py-0.5 rounded text-[10px] cursor-pointer hover:ring-2 hover:ring-offset-2 hover:ring-tcolor hover:ring-offset-gray-200 dark:hover:ring-offset-ldark text-white" onClick={() => setDownloadPopup(true)}>
                                <AiFillPrinter size="12px" /><span>{t("Print")}</span>
                            </div>
                        </div>
                        <div className="mt-1 mb-5 pr-8 overflow-y-auto height_less_topbar">
                            {
                                summary.cgStatus !== null ?
                                    <FormCard title={t("1. Caregiver Status")}>
                                        <div className="grid gap-10 grid-cols-1 sm:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2">
                                            <div class="flex mb-4 space-x-4">
                                                <div title={summary.cgMsg.caregiverPhaseMsg} class="w-1/2 px-7 underline" >{t("Caregiver Phase")}:</div><br></br>
                                                <div class="w-1/2" className="answer-1">
                                                    {summary.cgStatus.caregiverPhase}
                                                </div>
                                            </div>
                                            <div class="flex mb-4 space-x-4">
                                                <div title={summary.cgMsg.intentionPlaceMsg} class="w-1/2 px-6 underline" >{t("Intention to Place")}:</div><br></br>
                                                {/* <div class="w-1/2" className="answer-1">
                                                    {summary.cgStatus.intentionToPlace}
                                                </div> */}
                                                <div  class="w-1/2">
                                       {
                                            summary.cgStatus.intentionToPlace === "0" ?
                                            <div className="answer-4">{t("No")}</div>:
                                            summary.cgStatus.intentionToPlace === "1" ?
                                            <div className="answer-3">{t("Yes")}</div>:''
                                        }
                                       </div>
                                            </div>
                                            {/* <div class="flex mb-4 space-x-10">
                                                <div title={summary.cgMsg.caregiverMsgType} class="w-1/2 px-7 underline">{t("Caregiver Type")}:</div><br></br>
                                                <div class="w-1/2" className="answer-8">
                                                    {summary.cgMsg.caregiverType}
                                                </div>
                                            </div> */}
                                        </div>
                                    </FormCard> : ''
                            }
                            <div>
                                <FormCard>
                                    <div class="flex mb-4 flex-row ... ">
                                        <div class="w-1/3 px-2">
                                            <FormCard title={t("2. Caregiver Needs")}>&nbsp;&nbsp;
                                                <motion.div>
                                                    <div class="flex mb-4">
                                                        <div title={summary.cgEmotionalNeedsMsg.cgRelationshipBurdenMsg} class="w-1/2 underline">{t("Relationship Burden")}:</div>
                                                        <div class="w-1/2 px-8">
                                                            {
                                                                summary.cgEmotionalNeeds.cgRelationBurden === "1" ? <div className="answer-4">{t("Low")}</div> :
                                                                    summary.cgEmotionalNeeds.cgRelationBurden === "2" ? <div className="answer-2">{t("Medium")}</div> :
                                                                        summary.cgEmotionalNeeds.cgRelationBurden === "3" ? <div className=" answer-3">{t("High")}</div> : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="flex mb-4">
                                                        <div title={summary.cgEmotionalNeedsMsg.cgObjectiveBurdenMsg} class="w-1/2 underline">{t("Objective Burden")}:</div>
                                                        <div class="w-1/2 px-8">
                                                            {
                                                                summary.cgEmotionalNeeds.cgObjectiveBurden === "1" ? <div className="answer-4">{t("Low")}</div> :
                                                                    summary.cgEmotionalNeeds.cgObjectiveBurden === "2" ? <div className="answer-2">{t("Medium")}</div> :
                                                                        summary.cgEmotionalNeeds.cgObjectiveBurden === "3" ? <div className="answer-3">{t("High")}</div> : ''
                                                            }
                                                        </div>

                                                    </div>
                                                    <div class="flex mb-4">
                                                        <div title={summary.cgEmotionalNeedsMsg.cgStressBurdenMsg} class="w-1/2 underline">{t("Stress Burden")}:</div>
                                                        <div class="w-1/2 px-8">
                                                            {
                                                                summary.cgEmotionalNeeds.cgStressBurden === "1" ?
                                                                    <div className="answer-4">{t("Low")}</div> :
                                                                    summary.cgEmotionalNeeds.cgStressBurden === "2" ?
                                                                        <div className="answer-2">{t("Medium")}</div> :
                                                                        summary.cgEmotionalNeeds.cgStressBurden === "3" ?
                                                                            <div className="answer-3">{t("High")}</div> : ''
                                                            }
                                                        </div>

                                                    </div>
                                                    <div class="flex mb-4">
                                                        <div title={summary.cgEmotionalNeedsMsg.cgUpliftMsg} class="w-1/2 underline">{t("Uplifts")}:</div>
                                                        <div class="w-1/2 px-8">
                                                            {
                                                                summary.cgEmotionalNeeds.cgUplifts === "1" ?
                                                                    <div className="answer-4">{t("Low")}</div> :
                                                                    summary.cgEmotionalNeeds.cgUplifts === "2" ?
                                                                        <div className="answer-2">{t("Medium")}</div> :
                                                                        summary.cgEmotionalNeeds.cgUplifts === "3" ?
                                                                            <div className="answer-3">{t("High")}</div> : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="flex mb-4">
                                                        <div title={summary.cgEmotionalNeedsMsg.cgDepressionMsg} class="w-1/2 underline">{t("Depression")}:</div>
                                                        <div class="w-1/2 px-8">
                                                            {
                                                                summary.cgEmotionalNeeds.cgDepression === "1" ?
                                                                    <div className="answer-4">{t("Low")}</div> :
                                                                    summary.cgEmotionalNeeds.cgDepression === "2" ?
                                                                        <div className="answer-2">{t("Medium")}</div> :
                                                                        summary.cgEmotionalNeeds.cgDepression === "3" ?
                                                                            <div className="answer-3">{t("High")}</div> : ''
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="flex mb-4">
                                                        <div title={summary.cgEmotionalNeedsMsg.cgIdentityDiscrepMsg} class="w-1/2 underline">{t("Identity Discrepancy")}:</div>
                                                        <div class="w-1/2 px-8">
                                                            {
                                                                summary.cgEmotionalNeeds.cgIdentityDiscrep === "1" ?
                                                                    <div className="answer-4">{t("Low")}</div> :
                                                                    summary.cgEmotionalNeeds.cgIdentityDiscrep === "2" ?
                                                                        <div className="answer-2">{t("Medium")}</div> :
                                                                        summary.cgEmotionalNeeds.cgIdentityDiscrep === "3" ?
                                                                            <div className="answer-3">{t("High")}</div> : ''
                                                            }
                                                        </div>

                                                    </div>
                                                    <div class="flex mb-4">
                                                        <div class="w-1/2"></div>
                                                        <div class="w-1/2 px-8">
                                                            {

                                                            }
                                                        </div>

                                                    </div>
                                                </motion.div>
                                            </FormCard>
                                        </div>
                                        <div class="w-1/3 px-2">
                                            <FormCard title={t("3. Care Receiver Needs")}>&nbsp;&nbsp;
                                                {protocolType === 'true'&& summary.crNeeds.crAdl === 0 ? '':<div class="flex mb-4">
                                                    <div title={summary.crNeedsMsg.crAdlMsg} class="w-1/2 underline">{t("ADL")}:</div>
                                                    <div class="w-1/2 px-8">
                                                        {
                                                            summary.crNeeds.crAdl === 1 ?
                                                                <div className="answer-4">{t("Low")}</div> :
                                                                summary.crNeeds.crAdl === 2 ?
                                                                    <div className="answer-2">{t("Medium")}</div> :
                                                                    summary.crNeeds.crAdl === 3 ?
                                                                        <div className="answer-3">{t("High")}</div> : ''
                                                        }
                                                    </div>
                                                </div>}
                                                {protocolType === 'true'&& summary.crNeeds.crIadl === 0 ? '':<div class="flex mb-4">
                                                    <div title={summary.crNeedsMsg.crIadlMsg} class="w-1/2 underline">{t("IADL")}:</div>
                                                    <div class="w-1/2 px-8">
                                                        {
                                                            summary.crNeeds.crIadl === 1 ?
                                                                <div className="answer-4">{t("Low")}</div> :
                                                                summary.crNeeds.crIadl === 2 ?
                                                                    <div className="answer-2">{t("Medium")}</div> :
                                                                    summary.crNeeds.crIadl === 3 ?
                                                                        <div className="answer-3">{t("High")}</div> : ''
                                                        }
                                                    </div>
                                                </div>}
                                                <div class="flex mb-4">
                                                    <div title={summary.crNeedsMsg.crProblemBehaviourMsg} class="w-1/2 underline">{t("Problem Behaviors")}:</div>
                                                    <div class="w-1/2 px-8">
                                                        {
                                                            summary.crNeeds.crProblemBehavior === 1 ?
                                                                <div className="answer-4">{t("Low")}</div> :
                                                                summary.crNeeds.crProblemBehavior === 2 ?
                                                                    <div className="answer-2">{t("Medium")}</div> :
                                                                    summary.crNeeds.crProblemBehavior === 3 ?
                                                                        <div className="answer-3">{t("High")}</div> : ''
                                                        }
                                                    </div>
                                                </div>
                                                {protocolType === 'true'&& !summary.crNeeds.memoryIssue ? '':<div class="flex mb-4">
                                                    <div class="w-1/2 underline">{t("Memory")}:</div><br></br>
                                                    <div class="w-1/2 px-8" ><div className="answer-1">
                                                        {
                                                            summary.crNeeds.memoryIssue
                                                        }
                                                    </div>

                                                    </div>
                                                </div>}
                                                <div class="flex mb-4">
                                                    <div class="w-1/2"></div><br></br>
                                                    <div class="w-1/2 px-8">
                                                        {

                                                        }
                                                    </div>
                                                </div>
                                                <div class="flex mb-4">
                                                    <div class="w-1/2"></div><br></br>
                                                    <div class="w-1/2 px-8">
                                                        {

                                                        }
                                                    </div>
                                                </div>
                                                <div class="flex mb-4">
                                                    <div class="w-1/2"></div><br></br>
                                                    <div class="w-1/2 px-8">
                                                        {

                                                        }
                                                    </div>
                                                </div>
                                            </FormCard>

                                        </div>
                                        <div class="w-1/3 px-2">
                                            <FormCard title={t("4. Caregiver Obligations")}>&nbsp;&nbsp;
                                                <div class="flex mb-4 justify-between">
                                                    <div class="w-1/2 underline">{t("Employment")}:</div>
                                                    <div><div className="answer-8" >{summary.employment}</div>
                                                    </div>
                                                </div>
                                                <div class="flex mb-4 justify-between">
                                                    <div class="underline">{t("Personal Care Hours")}:</div>
                                                    <div><div className="answer-1"> {summary.cgObligations.personalCareHrs}</div>

                                                    </div>
                                                </div>
                                                <div class="flex mb-4 justify-between">
                                                    <div class="underline">{t("Household Care Hours")}:</div>
                                                    <div><div className="answer-1">{summary.cgObligations.householdCareHrs}</div>
                                                    </div>
                                                </div>
                                                <div class="flex mb-4 justify-between">
                                                    <div class="underline">{t("Other Assistance Hours")}:</div>
                                                    <div><div className="answer-1"> {summary.cgObligations.otherAssistanceHrs}</div>

                                                    </div>
                                                </div>
                                                <div class="flex mb-4 justify-between">
                                                    <div class="underline">{t("Total Dependents")}:</div>
                                                    <div><div className="answer-1"> {summary.cgObligations.totalDependents}</div>

                                                    </div>
                                                </div>
                                                <div class="flex mb-4">
                                                    <div class="w-1/2"></div><br></br>
                                                    <div>
                                                        {

                                                        }
                                                    </div>
                                                </div>
                                            </FormCard>
                                        </div>
                                    </div>
                                </FormCard>

                            </div>
                            <FormCard title={t("Questionnaire")} >
                                <div tabIndex={0}>
                                    <div> {t("1. Does caregiver accurately understand care receiver's level of need?")} <button aria-label="info"  onClick={() => setFirstIcon(!firstIcon)} ><FaInfoCircle size="20px" /></button></div><br></br>
                                    <div>
                                        {
                                            firstIcon ? <span >{t("Questions to consider when answering")}
                                                <ul><br></br>
                                                    <li>{t("Does caregiver have accurate knowledge about the care receiver’s medical condition?")}</li>
                                                    <li>{t("Does caregiver accurately attribute symptoms to disease?")}</li>
                                                    <li>{t("Does caregiver understand type and amount of help or supervision needed?")}</li>
                                                </ul></span> : ''
                                        }
                                    </div>
                                    {
                                        summary.otherUsefullInfo.summOne === "1" ? <div class="border p-2 border-blue-500 focus:border-blue-500 ...">{t("Yes")}</div> :
                                            summary.otherUsefullInfo.summOne === "2" ? <div class="border p-2 border-blue-500 focus:border-blue-500 ...">{t("No,care recipient has higher needs")} </div> :
                                                summary.otherUsefullInfo.summOne === "3" ? <div class="border p-2 border-blue-500 focus:border-blue-500 ...">{t("No,care recipient has lower needs")} </div> : ''

                                    }
                                </div>&nbsp;
                                <div tabIndex={0}>
                                    <div>{t("2. Currently is caregiver able to provide necessary care in a safe manner?")} <button aria-label="info" onClick={() => setSecondIcon(!secondIcon)} ><FaInfoCircle size="20px" /></button></div><br></br>
                                    <div>
                                        {
                                            secondIcon ? <span >{t("Is the caregiver physically, emotionally and financially able to provide adequate and safe care?")} &nbsp;
                                                <ul>&nbsp;
                                                    <li>{t("How long has he/she been providing care?")}</li>
                                                    <li>{t("Does caregiver accurately attribute symptoms to disease?")}</li>
                                                    <li>{t("His/her medical condition?")}</li>
                                                    <li>{t("What other obligations does he/she have? Employment? Other dependents?")}</li>
                                                    <li>{t("Evidence of depression?")}</li>
                                                    <li>{t("Evidence of or concerns about substance abuse?")}</li>
                                                    <li>{t("Concerns about or evidence of abuse?")}</li>
                                                    <li>{t("Did he/she express concerns about relationship?")}</li>
                                                    <li>{t("Concerns about personal safety?")}</li>
                                                    <li>{t("Does he/she have necessary knowledge and skills to provide care?")}</li>
                                                    <li>{t("Does he/she have confidence or sense of mastery?")}</li>
                                                </ul></span> : ''
                                        }
                                    </div>
                                    {
                                        summary.otherUsefullInfo.summTwo === "1" ? <div class="border p-2 border-blue-500 focus:border-blue-500 ...">{t("Yes")}</div> :
                                            summary.otherUsefullInfo.summTwo === "2" ? <div class="border p-2 border-blue-500 focus:border-blue-500 ...">{t("No")}</div> : ''
                                    }
                                </div>&nbsp;
                                <div tabIndex={0}>
                                    <div>{t("3. Are there services/resources available to the caregiver that would enable the caregiver to provide care in a safe manner?")} <button aria-label="info" onClick={() => setThirdIcon(!thirdIcon)}><FaInfoCircle size="20px" /></button></div><br></br>
                                    <div>
                                        {
                                            thirdIcon ? <span >{t("What personal strengths and resources does the caregiver have to draw upon to assist with care responsibilities?")}

                                                <ul><br></br>
                                                    <li>{t("Are there educational programs or services available to enhance the skills or knowledge of the caregiver?")}</li>
                                                    <li>{t("Is he or she likely to seek and accept help from informal sources?")}</li>
                                                    <li>{t("Is he or she likely to seek or accept help from formal sources?")}</li>
                                                    <li>{t("Can he/she afford to purchase necessary help?")}</li>
                                                    <li>{t("Is he/she likely to be eligible for means-tested services or resources that are available through public or private programs?")}</li>
                                                </ul></span> : ''
                                        }
                                    </div>
                                    {
                                        summary.otherUsefullInfo.summThree === "1" ? <div class="border p-2 border-blue-500 focus:border-blue-500 ...">{t("Yes")}</div> :
                                            summary.otherUsefullInfo.summThree === "2" ? <div class="border p-2 border-blue-500 focus:border-blue-500 ...">{t("No")}</div> : ''
                                    }
                                </div>&nbsp;
                                <div tabIndex={0}>
                                    <div>{t("4. Is there evidence that would indicate that the caregiver should be encouraged to seek help from a health professional for his/her health condition?")} <button aria-label="info" onClick={() => setFourthIcon(!fourthIcon)}><FaInfoCircle size="20px" /></button></div><br></br>
                                    <div>
                                        {
                                            fourthIcon ? <span >{t("What personal strengths and resources does the caregiver have to draw upon to assist with care responsibilities?")}
                                                <ul><br></br>
                                                    <li>{t("Are there educational programs or services available to enhance the skills or knowledge of the caregiver?")}</li>
                                                    <li>{t("Is he or she likely to seek and accept help from informal sources?")}</li>
                                                    <li>{t("Is he or she likely to seek or accept help from formal sources?")}</li>
                                                    <li>{t("Can he/she afford to purchase necessary help?")}</li>
                                                    <li>{t("Is he/she likely to be eligible for means-tested services or resources that are available through public or private programs?")}</li>
                                                </ul></span> : ''
                                        }
                                    </div>
                                    {
                                        summary.otherUsefullInfo.summFour === "1" ? <div class="border p-2 border-blue-500 focus:border-blue-500 ...">{t("Yes")}</div> :
                                            summary.otherUsefullInfo.summFour === "2" ? <div class="border p-2 border-blue-500 focus:border-blue-500 ...">{t("Possibly")}</div> :
                                                summary.otherUsefullInfo.summFour === "3" ? <div class="border p-2 border-blue-500 focus:border-blue-500 ...">{t("No")}</div> : ''
                                    }
                                </div>
                            </FormCard>

                        </div>
                    </div>

            }
            {
                createPortal(<Dialog title={t("Download File")} showDialog={downloadPopup} onClose={() => setDownloadPopup(false)}>
                    <div className="py-2 w-full">
                        <div className="px-4 mt-2">
                            <FlowText text={t("Are you sure you want to download this summary form?")} />
                        </div>
                        <div className="flex justify-between mt-8 mx-2">
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDownloadPopup(false)}>{t("No")}</button>
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => downloadFiles()}>{t("Yes")}</button>
                        </div>
                    </div>
                </Dialog>, document.body)
            }
        </div>

    )
}

export default SummaryData