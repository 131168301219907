import { useCallback, useMemo, useState, useEffect } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'

import { datatableTheme } from '../../data/dataTableTheme'
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom"
import { FaEdit, FaPhoneAlt } from 'react-icons/fa'
import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import { motion } from 'framer-motion'
import API from '../../axios/API'
import AddButton from '../../components/snippets/AddButton'
import { useTranslation } from 'react-i18next'
import FlowText from '../../components/snippets/FlowText'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const Organizationlist = () => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [organizaitonList, setOrganizationList] = useState([]);
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation()
    const organization = useSelector(state => state.auth.organization)
    const userDetails = useSelector(state => state.auth.userDetails)
    const config = {
        headers: { 'Authorization': localStorage.getItem('token') }
    }
    useEffect(() => {
        setLoading(true);
        API.get(`organizations`, config).then((res) => {
            setOrganizationList(res.data);
        }).catch(e => {
            // console.log(e)
        }).finally(() => setLoading(false))
    }, [])// eslint-disable-line

    const colums = useMemo(() => {
        return [
            { name: t('Organization Name'), selector: 'organizationName', grow: 1, sortable: true },
            { name: t('City'), selector: 'organizationAddress.city', grow: 1, sortable: true },
            // { name: t('Phone'), selector: 'phone', grow: 1, sortable: true },
            {
                name: t('Phone'), cell: row => {
                    return <div data-private="lipsum" style={{ display: 'flex', fontSize: '13px', marginTop: '15px' }}>{row.phone === "" || row.phone === null ? '' : <a href={`tel: +1${row.phone.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor p-2 rounded-lg text-white" title={t('Call')}>
                        <FaPhoneAlt size="10px" style={{ margin: 'auto' }} />
                    </a>}&nbsp;&nbsp;<span>{row.phone}</span></div>
                }, grow: 1, sortable: true
            },
            {
                name: t('Action'), button: true, cell: row => <Link to={{
                    pathname: `/editOrganization/${row.organizationId}`,
                }}><motion.span className="flex my-2 justify-center cursor-pointer group relative text-white">
                        <FaEdit size="20px" className="text-gray-600 dark:text-gray-300" />
                        <span className="absolute bg-ddark left-full text-sm whitespace-nowrap ring-2 ring-gray-500 py-1 px-2 rounded-xl hidden group-hover:inline-block text-gray-300">{t("Edit")}</span>
                    </motion.span></Link>
            },
            // {name: 'Action', button: true, cell: row => <Link to={`/caregiver/${row.id}`}><FaEye size="20px" className="text-gray-600 dark:text-gray-300" /></Link>}
        ]
    }, [])// eslint-disable-line
    const SubHeader = useMemo(() => (
        <div className="flex items-center">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} />
            {(organization && userDetails && userDetails.orgId === parseInt(organization)) ? <AddButton to="/addneworganization" title={t("Add New Organization")} /> : ''}
        </div>
    ), [filter]) // eslint-disable-line
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return organizaitonList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [organizaitonList, filter])// eslint-disable-line
    const ExpandComponent = useCallback(row => {
        return (

            <div className="bg-bluegray-300 dark:bg-ldark" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>

        )
    }, [])
    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role', 'gridcell')
            })
        }
    })

    return (
        <div>
            {
                loading ?
                    <DatatableLoader /> :
                    <div className=" mt-4 p-2 bg-bluegray-200 dark:bg-ddark rounded-lg">
                            <DataTable title="casedetails" columns={colums} data={filteredcasedetails} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} noDataComponent={t('There are no records to display')} />
                            {/* // : <div style={{ textAlign: 'center', justifyContent: 'center' }}><FlowText text={t('There are no records to display')} /></div> */}
                        
                    </div>
            }
        </div>
    )
}

export default Organizationlist