import { useTranslation } from 'react-i18next'
import { FaCheck } from 'react-icons/fa'

const AssessmentPages = ({ pages, page, onChange, showTitle= false }) => {

    const { t } = useTranslation()
    const triggerClick = (c) => {
        if (typeof onChange === 'function') onChange(c)
    }
    return (
        <div className="flex justify-between relative w-full mr-5">
            <div className="w-full h-0.5 mt-3 absolute bg-tcolor"></div>
            {
                pages.map((c, i) => (
                    <div key={i} className="flex items-center transition-all duration-200">
                        <div className={`group relative ring-4 ${i + 1 === page ? 'ring-tcolor bg-bluegray-100 dark:bg-mainbg' : 'bg-tcolor ring-bluegray-100 dark:ring-mainbg hover:scale-110'} py-[6px] px-[6.5px] rounded-full text-black z-10 transform transition-all duration-500 bg-tcolor`} onClick={() => triggerClick(i + 1)}>
                            {
                                c.completed ?
                                    <FaCheck size="12px" className={`${i + 1 === page ? 'text-green-500' : 'text-white'}`} />
                                :
                                i + 1 === page ?
                                    <FaCheck size="16px" className={`text-gray-400 dark:text-gray-400 ring-tcolor`} />
                                :
                                    <span className="w-3 h-3"></span>
                            }
                            { showTitle &&
                                <div className={`hidden group-hover:block overflow-hidden absolute top-[26px] ${(i > pages.length / 2)  ? 'right-3' : ''} whitespace-nowrap z-10 mx-1 px-3 ring-2 ring-tcolor text-tcolor dark:text-white rounded-xl bg-bluegray-100 dark:bg-mainbg text-[9px]`}>
                                    {t(c.title)}
                                </div>
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default AssessmentPages