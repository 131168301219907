import { withRouter } from "react-router"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { MdAccountCircle } from "react-icons/md"
// import { FaUserEdit } from 'react-icons/fa'
import { useDispatch ,useSelector} from "react-redux"
import FlowText from "../../components/snippets/FlowText"
// import Dialog from "../Dialog"
// import EditCaregiver from '../../../pages/Dialogs/Editcaregiver'
// import EditCarereceiver from '../../../pages/Dialogs/Editcarereceiver'
// import RelationshipSelector from "../../../pages/CaseDetails/RelationshipSelector"
// import {FaStickyNote,FaEye } from "react-icons/fa"
// import API from "../../../axios/API"
import { setAlert } from "../../store/theme/actions";
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import ScreenerDemographicLoader from "./ScreenerDemographicLoader"
// import DemoNotes from "../../../pages/Dialogs/DemoNotes"
// import ViewNotes from "../../../pages/Dialogs/ViewNotes"
import { AiOutlineLink } from "react-icons/ai"


const ScreenerDemographic = ({ caseId }) => {
    const [demographic, setDemographic] = useState({ caregiver: [], carereceiver: [] })
    // const [race,setRace] = useState('')
    // const [showCaregiverForm, setShowCaregiverForm] = useState(false)
    // const [showCarerecieverForm, setShowCarereceiverForm] = useState(false)
    // const [editNotesForm, setEditNotesForm] = useState(false)
    // const [viewNotesForm, setViewNotesForm] = useState(false)
    // const [setShowEditRelationship] = useState(false)
    const demographiccount = useSelector(state => state.auth.demographiccount)
    const changerelationShipCount = useSelector(state =>state.auth.changerelationShipCount)
    const [run] = useState(false)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    let history = useHistory();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}intake/cases/${caseId}/demographic`)
            .then(res => {
                if (res.status === 200) {
                    res.data.body.caregiver.race = []
                    if(res.data.body.caregiver.white === true){
                        res.data.body.caregiver.race.push('white')
                    }if(res.data.body.caregiver.black === true){
                        res.data.body.caregiver.race.push('black or african american')
                    }if(res.data.body.caregiver.hispanic === true){
                        res.data.body.caregiver.race.push('hispanic or latin american')
                    }if(res.data.body.caregiver.asian === true){
                        res.data.body.caregiver.race.push('asian')
                    }if(res.data.body.caregiver.nativeAmerican === true){
                        res.data.body.caregiver.race.push('american indian or alaska native')
                    }if(res.data.body.caregiver.pacificIslander === true){
                        res.data.body.caregiver.race.push('native hawaiian or pacific islander')
                    }if(res.data.body.caregiver.middleEastern === true){
                        res.data.body.caregiver.race.push('middle eastern or north african')
                    }if(res.data.body.caregiver.otherRace === true){
                        res.data.body.caregiver.race.push('some other race/ethnicity')
                    }if(res.data.body.caregiver.refusedRace === true){
                        res.data.body.caregiver.race.push('refused')
                    }

                    setDemographic(res.data.body)
                    setLoading(false)
                } else {
                    dispatch(setAlert({ title: t('unauthorized'), subtitle: t('unauthorized_to_view'), active: true, type: 'warning' }))
                    history.push('/')
                }
            })
            .catch(err => {
                console.log(err)
            }).finally(() => setLoading(false))
    }, [run,changerelationShipCount ,demographiccount]) // eslint-disable-line
    // const reRun = () => {
    //     setRun(!run)
    //     // setShowCaregiverForm(false)
    // }

    // const editcaregiver = () => {
    //     setShowCaregiverForm(true)
    // }

    // const editcarereceiver = () => {
    //     setShowCarereceiverForm(true)
    // }
    // const editrelationship = () => {
    //     setShowEditRelationship(true)
    // }
    // const  editnotes = () => {
    //     setEditNotesForm(true)
    // }
    // const  viewnotes = () => {
    //     setViewNotesForm(true)
    // }
    return (
        <>
            {
                loading ? <ScreenerDemographicLoader /> :
                 <div className="flex-1 bg-tcolor rounded-tr-lg bg-opacity-90 min-w-[300px] -ml-8 flex-grow-0 overflow-auto pb-10">
                    <div className="mt-5 ml-4">
                        <div className="text-lg font-medium text-gray-200 dark:text-gray-300 underline flex items-center">
                            <MdAccountCircle className="mr-1" />
                            {t('Caregiver')}
                            {/* <FaUserEdit size="20px" className="ml-2 cursor-pointer bg-bluegray-500 bg-opacity-80 rounded-full py-0.5 px-1" title={t('Edit Caregiver')} onClick={() => editcaregiver()} /> */}
                        </div>
                        <div className="mt-2">
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200">{t("FirstName")} :</div>
                                <div className="text-sm text-white px-4">
                                    <FlowText force={true}  text={demographic.caregiver.firstName}>
                                    </FlowText>
                                </div>
                            </div>
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200">{t("LastName")} :</div>
                                <div className="text-sm text-white px-4">
                                    <FlowText force={true} text={demographic.caregiver.lastName}>
                                    </FlowText>
                                </div>
                            </div>
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200">{t("Phone")} :</div>
                               {
                                   demographic.orgContact &&  
                                   <a href={`tel: +1${demographic.caregiver.phone?.toString().replace(/-/g, '')}`} className={`flex items-center text-sm text-white px-8 ${(demographic.caregiver.phone && demographic.caregiver.phone !== '') ? '' : 'pointer-events-none'}`}>
                                   { (demographic.caregiver.phone && demographic.caregiver.phone !== '') && <AiOutlineLink size="13px" className="mr-0.5" /> }
                                   <FlowText force={true} text={demographic.caregiver.phone}>
                                   </FlowText>
                               </a>
                               }
                               {
                                   !demographic.orgContact &&
                                   <FlowText force={true} className="text-sm text-white px-4" text={demographic.caregiver.phone}>
                                   </FlowText>
                               }
                            </div>
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200">{t("Email")} :</div>
                                <a href={`mailto: ${demographic.caregiver.email}`} className={`flex items-center text-sm text-white px-4 ${(demographic.caregiver.email&& demographic.caregiver.email !== '') ? '' : 'pointer-events-none'}`}>
                                    { (demographic.caregiver.email&& demographic.caregiver.email !== '') && <AiOutlineLink size="13px" className="mr-0.5" /> }
                                    <FlowText force={true} text={(demographic.caregiver.email)?demographic.caregiver.email:'---'}>
                                    </FlowText>
                                </a>
                            </div>
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200">{t("Date of Birth")} :</div>
                                <div className="text-sm text-white px-2">
                                    <FlowText force={true} text={`${demographic.caregiver.dobMonth!==0 ? demographic.caregiver.dobMonth : ''}-${demographic.caregiver.dobDay !==0 ? demographic.caregiver.dobDay : ''}-${demographic.caregiver.dobYear !==0 ? demographic.caregiver.dobYear : ''}`}>
                                    </FlowText>
                                </div>
                            </div>
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200">{t("Race")} </div>
                                <div className="text-sm text-white ">
                                    {
                                        demographic.caregiver.race.map((c,i) =>{
                                           return <FlowText force={true} text={`${c} ${(demographic.caregiver.race.length === i+1) ? '' : ','} `}>
                                    </FlowText>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default withRouter(ScreenerDemographic)