import React, { useState } from 'react'
import API from '../axios/API'
import { withRouter } from 'react-router'
import { motion } from 'framer-motion'
import FlowText from '../components/snippets/FlowText'
import DarkModeSwitch from '../components/snippets/DarkModeSwitch'
import {  TextAnimateXRev } from '../commons/anims'
import LogoImg from '../components/LogoImg'
import { useDispatch, useSelector } from 'react-redux'
import { setLoggedIn, setUserDetails, setUser, setOrganization,setUserRoles } from '../store/auth/actions'
import { setAlert } from '../store/theme/actions'
import LanguageSelector from '../components/LanguageSelector'
import { useTranslation } from 'react-i18next'
import Dialog from '../components/snippets/Dialog'
import ForgotPasswordModal from '../pages/ForgotPassword'
import { useHistory } from "react-router-dom";
import ForgotUserName from '../pages/ForgotUsername'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { useEffect } from 'react'
import azureLogo from '../images/azure-active-directory-seeklogo.com.svg'
import LogRocket from 'logrocket';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

var loginRedirectTimeout = null;

const Login = () => {
    // const [loginData] = useState({username: '', password: '', environment: process.env.REACT_APP_DEFAULT_ORG})
    const [loading, setLoading] = useState(true)
    // const [formDirty, setFormDirty] = useState(false)
    const [chkLogin, setChkLogin] = useState(true)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false)
    const [forgotUsernameModal, setForgotUsernameModal] = useState(false)
    const language = useSelector(state => state.theme.language)
    const zone = new Date().toTimeString().slice(9).split(' ')[0]
    const setIsLoggedIn = value => {
        dispatch(setLoggedIn(value))
    }

    const privateFieldNames = [
        'firstName',
        'lastName',
        'emailAddress',
        'phone', 
        'Authorization'
      ];
   
      
      const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(privateFieldNames);
      

   
    // const setData = (name, value) => setLoginData({...loginData, [name]: value})

    // const loginValid = () => Object.keys(loginData).every(c => loginData[c] !== null && loginData[c] !== '')

    // const submitLogin = () => {
    //     if(loading) return
    //     // setFormDirty(true)
    //     if(loginValid()) {
    //         setLoading(true)
    //         API.post('login/validate', {username: loginData.username, pass: loginData.password, lang: language}).then(res => {
    //             if(res.data.orgId) {
    //              localStorage.setItem('token',res.headers.authorization)
    //              localStorage.setItem('userDetails',JSON.stringify(res.data))
    //             //  let details = JSON.parse(localStorage.getItem('userDetails'))
    //             //  let env = details.env.toUpperCase()
    //                 let userDetails = JSON.parse(atob(localStorage.getItem('token').split('.')[1]));
    //                 dispatch(setUserDetails(userDetails))
    //                 dispatch(setOrganization(userDetails.orgId))
    //                 dispatch(setUser(res.data))
    //                 setIsLoggedIn(true)
    //                 dispatch(setAlert({title:'Success',subtitle:`You have successfully logged into TCARE`,type:'success',active:true}))
    //                 history.push("/")
    //                 // setSessionData(res.data)
    //             } else {
    //                 setIsLoggedIn(false)
    //                 dispatch(setAlert({title: 'Invalid Credentials', subtitle: res.data.errorMessage, active: true, type:'error'}))
    //             }
    //         }).catch(e => {
    //             setIsLoggedIn(false)
    //             dispatch(setAlert({title: 'Invalid Credentials', subtitle: 'Please check Credentials', active: true, type:'error'}))
    //         }).finally(() => {
    //             setLoading(false)
    //         });
    //     } else {
    //         dispatch(setAlert({title: 'Required Fields are Empty', subtitle: 'Please fill all fields', active: true, type:'warning'}))
    //     }
    // }

    // const [tab, setTab] = useState(0)
    const history = useHistory();
    // const handleClick = ()=> {
    //     history.push("/reset");
    //   }

    const { instance, accounts, inProgress } = useMsal();
    const initAzureLogin = () => {
        if(loading) return
        setLoading(true)
        setChkLogin(false)
        instance.loginRedirect(loginRequest)
        .then((res) => {
            setChkLogin(true)
        })
        .catch(e => {
            console.log(e);
            setChkLogin(true)
        })
        .finally(() => {
            setChkLogin(true)
        })
    }
    useEffect(() => {
        if(inProgress === 'none') setLoading(false)
        if(accounts.length === 0 || !chkLogin) return
        setLoading(true)
        loginRequest.account = instance.getAccountByHomeId(accounts[0].homeAccountId)
        instance.acquireTokenSilent(loginRequest)
            .then(response => {
                localStorage.setItem('token', `Bearer ${response.accessToken}`)
                let pastToken = localStorage.getItem('orgid') && localStorage.getItem('orgid') !== null ? localStorage.getItem('orgid') : null
                API.get(`../../whoami/${language}/${zone}`).then(res => {
                    if(res.data.userId && res.status === 200) {
                        let tokenUserDetails = res.data
                        let parentRole =[]
                        let childRole = []
                        const dynamicRoleRegex = /dynamic_/
                            tokenUserDetails.roles.map((c)=>{
                                if(c.match(dynamicRoleRegex)){
                                    childRole.push(c)
                                }else{
                                    parentRole.push(c)
                                }
                            })
                        // "iat": tokenUserDetails.attributes.iat,
                        let userDetails = {"email": tokenUserDetails.email,"sub": tokenUserDetails.userName, "role": tokenUserDetails.roles, "caremanagerId": tokenUserDetails.caremanagerId, "language": tokenUserDetails.lang,"iat": tokenUserDetails.attributes.iat,  "orgId": tokenUserDetails.orgId, authType: 'sso',"env": tokenUserDetails.env,"parentRole":parentRole,"childRole":childRole,'bulkservice':tokenUserDetails.bulkServiceUsage}
                // let userDetails = {"sub": "soorath", "role": ["caremanager"], "caremanagerId": 2, "language": 'en', "iat": 121212122, "orgId": 1}
                        API.get(`organizations/${tokenUserDetails.orgId}/organizations`).then(res => {
                            let luserDetails = { "caremanagerId": tokenUserDetails.caremanagerId, "orgId": tokenUserDetails.orgId, "roles": tokenUserDetails.roles, "cmfirstName": tokenUserDetails.cmfirstName, "cmlastName": tokenUserDetails.cmlastName, "orgName": res.data.find(c => c.organizationId === tokenUserDetails.orgId).organizationName, "schedulingEnabled": tokenUserDetails.schedulingEnabled, "reportEnabled": tokenUserDetails.reportEnabled, "env": tokenUserDetails.env, "lang": tokenUserDetails.lang, "childOrg": res.data, "childOrgEnabled": tokenUserDetails.childOrgEnabled, "releaseBannerEnabled": tokenUserDetails.releaseBannerEnabled, "releaseId": tokenUserDetails.releaseId, "subIdEnabled": tokenUserDetails.subIdEnabled, "samsIdEnabled": tokenUserDetails.samsIdEnabled, "auntBerthaInd": tokenUserDetails.auntBerthaInd,"orgCaseNotesDisable":'',"noCaregiver":'' }
                            localStorage.setItem('userDetails' ,JSON.stringify(luserDetails))
                            dispatch(setUserRoles(userDetails.role.join(',')))
                            localStorage.setItem('orgid',pastToken && pastToken !== null ? pastToken : userDetails.orgId)
                            dispatch(setUser(luserDetails))

                            dispatch(setUserDetails(userDetails))
                            dispatch(setUserRoles(userDetails.role.join(',')))
                            dispatch(setOrganization(pastToken && pastToken !== null ? pastToken : userDetails.orgId))
                            setIsLoggedIn(true)
                            dispatch(setAlert({title:t('Success'),subtitle:`${t('You have successfully logged into TCARE')} ${tokenUserDetails.env}`,type:'success',active:true}))
                            // testlog()
                           
                            LogRocket.identify(userDetails.email, {
                                name:userDetails.sub,
                                email:userDetails.email
                                // Any additional fields
                              })
                              LogRocket.init('0eoqnq/tcare-assist', {
                                network: {
                                    requestSanitizer,
                                    responseSanitizer,
                                },
                                dom: {
                                    privateAttributeBlocklist: ['data-hide-this']
                                  },
                            });
                                
                            //  LogRocket.init('uibbsf/tcare5');
                            // history.push("/")
                            if(tokenUserDetails.roles && ((tokenUserDetails.roles.length === 1 && tokenUserDetails.roles.includes('superadmin')) || (tokenUserDetails.roles.length === 1 && tokenUserDetails.roles.includes('developer')))){
                                history.push('/swagger')
                            }else{
                                history.push('/')
                            }
                        }).catch(e => {
                            console.log(e)
                        })
                        
                    } else {
                        setIsLoggedIn(false)
                        dispatch(setAlert({title: t('Invalid Credentials'), subtitle: res.data.errorMessage, active: true, type:'error'}))
                    }
                }).catch(err => {
                    if (err.response.status === 401) {
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        instance.logoutRedirect({
                            // postLogoutRedirectUri: "/",
                            // mainWindowRedirectUri: "/"
                        });
                        setIsLoggedIn(false)
                    }
                    // } else {
                    //     setIsLoggedIn(false)
                    // dispatch(setAlert({title: t('Invalid Credentials'), subtitle: 'Please check Credentials', active: true, type:'error'}))
                    // }
                }).finally(() => {
                        setLoading(false)
                    });
                // .catch(e => {
                //     console.log(e)
                //     setIsLoggedIn(false)
                //     dispatch(setAlert({title: t('Invalid Credentials'), subtitle: 'Please check Credentials', active: true, type:'error'}))
                // }).finally(() => {
                //     setLoading(false)
                // });
            })
            .catch(err => {
                console.log('err', err)
                setLoading(false)
            });
    }, [accounts, chkLogin, inProgress])// eslint-disable-line  

    useEffect(() => {
        if(!loading && inProgress === "none") {
            loginRedirectTimeout = setTimeout(() => {
                initAzureLogin()
            }, 1000);
        }

        return () => {
            clearTimeout(loginRedirectTimeout)
        }
    }, [loading, inProgress])// eslint-disable-line  

    return (
        <div className="min-h-screen flex justify-center items-center flex-col bg-blue-50 dark:bg-ddark overflow-hidden">
            <motion.div className="fixed left-8 top-5 flex justify-between items-center right-8" variants={ TextAnimateXRev } initial="hidden" animate="visible">
                <LogoImg size="200px" />
                <div className="flex">
                    <span className="mr-5">
                        <LanguageSelector injectPopupClass="-right-0 z-10 dark:bg-mdark" originX="right" originY="top" />
                    </span>
                    <DarkModeSwitch />
                </div>
            </motion.div>
            <div>
                <div className="text-3xl text-gray-500 dark:text-gray-300 font-medium mb-3">
                    <FlowText text={t('Sign in to TCARE')} />
                </div>
                { loading || true ? 
                    <div className="mt-6 flex justify-center">
                        <svg className="animate-spin -ml-1 mr-3 h-10 w-10 opacity-70 text-tcolor dark:text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>
                :
                    <button type="button" className={`flex justify-between items-center w-80 mt-8 mb-4 relative bg-gradient-to-tr from-tcolor to-blue-300 text-lg text-white px-4 py-2 rounded-md hover:ring-2 hover:ring-offset-2 hover:ring-offset-gray-200 dark:hover:ring-offset-ldark hover:ring-tcolor hover:shadow-xl transition-all duration-300 align-middle outline-none focus:outline-none font-bold ${ loading ? 'opacity-50 animate-pulse' : ''}`} onClick={ initAzureLogin }>
                        <img width="40px" src={ azureLogo } alt="Azure AD Logo" aria-label='Azure AD Logo' />
                        <div>
                            {t("Continue with Azure-AD")}
                        </div>
                        <div></div>
                    </button>
                }
            </div>
            {/* <motion.div className="bg-indigo-50 dark:bg-ldark ring-1 ring-indigo-100 dark:ring-mdark shadow-lg flex flex-col overflow-hidden items-center p-6 rounded-2xl relative w-[400px] sm:w-[600px]" variants={ wrapPopAnim } initial="hidden" animate="visible">
                <img src={ BgImage } alt="Bg" className="absolute left-0 bottom-5 opacity-5" />
                <div className="text-3xl text-tcolor font-medium mb-3 flex justify-end content-center w-full px-4">
                    <FlowText text={t('login')} />
                    
                </div>
                <motion.div variants={ PopInAnim } className="w-full">
                    <TextField AppendIcon={ FaUserAlt } label={t("Email")} name="username" injectClass="mt-4" initialValue={ loginData.username } setValue={ (val) => setData('username', val) } type="text" loading={ loading } formDirty={ formDirty } onEnter={ submitLogin } />
                </motion.div>
                <motion.div variants={ PopInAnim } className="w-full">
                    <TextField AppendIcon={ FaLock } label={t("Password")} name="password" injectClass="mt-4" initialValue={ loginData.password } setValue={ (val) => setData('password', val) } type="password" loading={ loading } formDirty={ formDirty } onEnter={ submitLogin } />
                </motion.div>
                <motion.div variants={ TextAnimateY }>
                    <Button injectClass={ `mt-8 mb-4 w-80 ${loading ? 'animate-pulse' : ''}` } onClick={ submitLogin } loading={ loading }>
                    {t("LogIn")}
                    </Button>
                </motion.div>   
                <motion.div variants={ TextAnimateY } className="z-10">
                    <button onClick={()=>setForgotPasswordModal(true)} style={{color:'#0399DB',fontSize:'15px',fontWeight:'bold'}}>{t("Forgot Password")}</button>&nbsp;|&nbsp;
                    <button onClick={()=>setForgotUsernameModal(true)} style={{color:'#0399DB',fontSize:'15px',fontWeight:'bold'}}>{t("Forgot Username")}</button>
                </motion.div>
                <div>
                    <div className="h-1 bg-black"></div>
                </div>
            </motion.div> */}
            <div>
            <Dialog title={t("Forgot Password")} showDialog={forgotPasswordModal} onClose={() => setForgotPasswordModal(false)}>
                <ForgotPasswordModal onClose={()=>setForgotPasswordModal(false)}/>
            </Dialog>
            <Dialog title={t('Forgot Username')} showDialog={forgotUsernameModal} onClose={() => setForgotUsernameModal(false)}>
                <ForgotUserName onClose={()=>setForgotUsernameModal(false)}/>
            </Dialog>
            </div>
        </div>
    )
}

export default withRouter(Login)
