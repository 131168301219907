import React from "react";
import Demographic from "../../../components/snippets/Assessment/Demographic";
import SummaryData from "./SummaryData";



const SummaryReadonly = ({match}) => {
    // const quesWrap = useRef(null)
    return (
        <div className="h-full">
            <div className="h-full flex">
                <Demographic caseId={match.params.caseId} />
                <div className="flex-1 flex">
                    <SummaryData assessmentId={match.params.assessmentId}/>
                </div>
            </div>
        </div>
    )
}

export default SummaryReadonly