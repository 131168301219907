import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { MdCheck } from 'react-icons/md'
import FlowText from "../../components/snippets/FlowText"
import API from '../../axios/API'

import { AnimatePresence, motion } from 'framer-motion'
import ChangeRelationship from "../Dialogs/ChangeRelationship"
import { createPortal } from "react-dom"
import Dialog from "../../components/snippets/Dialog"
import FieldInput from "../../components/snippets/FieldInput"
import { useFormik } from "formik"
import * as Yup from 'yup'
import { useTranslation } from "react-i18next"
import ChangeProtocol from "../Dialogs/ChangeProtocol"




const initialFormValues = {
    otherRelationship : ''
}

const ProtocolSelector = ({ injectPopupClass, originX, originY, props, caseId }) => {
    
    const [showSelector, setShowSelector] = useState(false)
    const [protocol, setProtocol] = useState([])
    const [protocols] = useState(props)
    const [openChangeRelation, setOpenChangeRelation] = useState(false)
    const [editData, setEditData] = useState()
    const [openOtherBox, setOpenOtherBox] = useState(false)
    const { t } = useTranslation()
    const hideShowSelector = () => setShowSelector(false);
    let lang = localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))


    useEffect(() => {
        if(menuItems && menuItems !== '' && menuItems !== null && menuItems !== undefined){
            if (menuItems.protocol && menuItems.protocol.length > 0 && menuItems.protocol !== undefined) {
                setProtocol(menuItems.protocol.map((c) => ({ text: c.name, value: c.value })))
            } else {
                API.get(`menus/protocol?lang=${lang}`).then((protocolResp) => {
                    setProtocol(protocolResp.data.body.map((c) => ({ text: c.name, value: c.value })))
                })
            }
        }else{
            API.get(`menus/protocol?lang=${lang}`).then((protocolResp) => {
                setProtocol(protocolResp.data.body.map((c) => ({ text: c.name, value: c.value })))
            })
        }
    }, [])
    const menuAnim = useMemo(() => ({
        hidden: {
            scale: 0,
            opacity: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            originX: originX,
            originY: originY
        }
    }), [originX, originY])

    const relation = useMemo(() => {
        let l = protocol.filter(c => c.name === protocols)
        return l.length > 0 ? l[0].name : props
    }, [protocol, protocols, props])

    useLayoutEffect(() => {
        if (showSelector) {
            setTimeout(() => {
                window.addEventListener('click', hideShowSelector)
            }, 100)
        } else {
            window.removeEventListener('click', hideShowSelector)
        }
        return () => window.removeEventListener('click', hideShowSelector)
    }, [showSelector])


    const editRelationship = (c) => {
        if (c !== undefined){
                setEditData({ newValue: c, oldValue: protocols, caseId: caseId })
                setOpenChangeRelation(true)
            }
    }
    const cancelModal =()=>{
        otherValueForm.resetForm()
        setOpenOtherBox(false)
    }
    console.log(protocol)
    return (
        <div className="relative">
            <div className="cursor-pointer" onClick={() => setShowSelector(true)} title={t("Protocol")}>
                {/* <span className="absolute text-[9px] leading-none -top-3 -right-2.5 bg-tcolor text-white px-1 py-1 rounded-full">{relation}</span> */}
                {relation !== null ? <FlowText text={`(${relation})`} />:''}
            </div>
            <AnimatePresence>
                {showSelector &&
                    <motion.div variants={menuAnim} initial="hidden" animate="visible" exit="hidden" className={`absolute bg-bluegray-500 dark:bg-ldark py-2 w-48 rounded-lg text-left ${injectPopupClass}`} style={{ zIndex: 10, height: '140px', overflowY: 'auto' }}>
                        <div className="text-xs text-white pl-2">{t("Protocol")}</div>
                        <div className="pt-2">
                            {
                                protocol.map(c => (
                                    <div key={c.value} className="py-1 pl-4 pr-2 hover:bg-bluegray-600 text-white text-sm cursor-pointer flex items-center justify-between" onClick={() => editRelationship(c)}>
                                        <span>{c.text}</span>
                                        {(protocols === c.text) && <MdCheck className="text-green-300" />}
                                        
                                    </div>
                                ))
                            }
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
            {
                createPortal(<Dialog title={t("Protocol change")} showDialog={openChangeRelation} onClose={() => setOpenChangeRelation(false)}>
                    <ChangeProtocol onClose={() => setOpenChangeRelation(false)} props={editData} />
                </Dialog>, document.body)
            }
           
        </div>
    )
}

export default ProtocolSelector