import { Link } from "react-router-dom"
// import { FaPlus } from "react-icons/fa"

const AssessmentFavFormButton = ({ injectClass, edit = false, to, onClick, title ,disabled = false }) =>{
    const trigger = () => {
        if (typeof onClick === 'function') onClick()
    }
    return (
        <button disabled={disabled} className={` ${(title==="Create New Assessment") ? 'pl-2 pr-2' : 'pl-2 pr-2'} ${edit ? 'bg-blue-500 dark:bg-blue-200' : 'bg-blue-400 dark:bg-blue-500'}  transition-all duration-200  text-black dark:ring-offset-mainbg ring-blue-50 dark:ring-mainbg outline-none focus:outline-none ${injectClass}`} onClick={trigger}>
            <span className="whitespace-nowrap flex items-center" >
                {/* <FaPlus className="mr-3" /> */}
                   {title}
            </span>
        </button>
    )
}

export default AssessmentFavFormButton