import React from "react";
import ScreenerDemographic from './ScreenerDemographic'
// import { useRef } from 'react';
import ScreenerAssessment from './ScreenerAssessment'
import { withRouter } from "react-router";

const IntakeScreener = ({match,location,history}) => {
    // const quesWrap = useRef(null)
    return (
        <div className="min-h-screen flex flex-col bg-blue-50 dark:bg-ddark overflow-hidden p-10">
            <div className="h-full flex">
            <ScreenerDemographic caseId={history.location.state.caseId}/>
                <div className="flex-1 flex">
                    <ScreenerAssessment caseId={history.location.state.caseId} asmtId={history.location.state.assessmentId}/>
                </div>
            </div>
        </div>
    )
}

export default withRouter(IntakeScreener)