import axios from "axios"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

const Swagger = () => {

    const [specs, setSpecs] = useState(null)
    const history = useHistory()
    const userDetails = useSelector(state => state.auth.userDetails)

    const base_url = process.env.REACT_APP_API_BASE_URL.split('api/')[0]

    const intercept = (req) => {
        return {
            ...req,
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }
    }

    useEffect(() => {
        if(!(userDetails.role.includes("superadmin") || userDetails.role.includes("developer"))) history.push("/")
    }, [userDetails, history]) //eslint-disable-line

    useEffect(() => {
        axios.get(`${base_url}api-docs/`, {
            headers: {
                'Authorization': localStorage.getItem('token'),
                'Accept': 'application/json'
            }
        })
            .then((res) => {
                let data = {...res.data}
                data.servers[0].url = data.servers[0]?.url.replace("http://", "https://")
                setSpecs({...data})
            })
            .catch(err => {
                if (err) {
                    console.log(err)
                    history.push('/')
                }
            })
    }, [base_url, history]) //eslint-disable-line

    return (
        <div className="bg-bluegray-100 rounded">
            {
                specs !== null && <SwaggerUI spec={specs} requestInterceptor={intercept} />
            }
        </div>
    )
}

export default Swagger