import { FaUserAlt } from 'react-icons/fa'
import CarereceiverEdit from '../../pages/Carereceiver/Carereceiver-Edit'
// import ClientEdit from '../../pages/Client/Client-Edit'

export const CarereceiverRoutes = [
    {
        title: 'Carereceiver',
        ItemIcon: FaUserAlt,
        link: '/carereceiver/:carereceiver_id',
        component: CarereceiverEdit,
        saveData: true,
        multiWindow: false
    }
    // {
    //     title: 'Clients',
    //     ItemIcon: FaUserAlt,
    //     link: '/carereceiver/:carereceiver_id',
    //     component: ClientEdit,
    //     saveData: true,
    //     multiWindow: false
    // }
]