
import React, {} from 'react'

const ResourceOptionField = ({ edit, loading, options = [], type, value, setValue }) => {

    const chgValue = (e) => {
        if (typeof setValue === 'function' && edit) {
            if (type === 'option') {
                setValue(e) 
            }
        }
    }

    return (
        <div className={`relative px-2 py-1 rounded flex flex-wrap gap-4 items-center ${loading ? 'animate-pulse' : ''}`}>
            {
                [...options].map(c => (
                    <div key={c.answerId} className={`flex items-center text-sm py-1 px-3 rounded-lg ring-1 ring-gray-400 dark:ring-gray-600 ${loading ? 'hover:cursor-wait' : 'hover:cursor-pointer'} ${c.answerId === value ? 'bg-tcolor text-white' : ''}`} onClick={() => chgValue(c.answerId)}>
                        {c.answerLable}
                    </div>
                ))
            }
        </div>
    )
}

export default ResourceOptionField
