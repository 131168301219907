
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import API from '../../axios/API'
import * as Yup from 'yup';
// import FieldSelect from '../../components/snippets/FieldSelect'
import FieldInput from '../../components/snippets/FieldInput'
import { AnimatePresence, motion } from 'framer-motion';
import { PopInAnim } from '../../commons/anims';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../store/theme/actions';
import { setCloseCaseRender,refreshTrigger} from  '../../store/auth/actions';
import { useTranslation } from "react-i18next"
import CloseSelect from '../../components/snippets/CloseSelect';
import { useHistory } from 'react-router-dom'
const initialFormValues = {
    closedReasonId: '',
    secondaryReasonId: ''
}


const EnhancedClosedDialog = ({ onClose,caseIds }) => {

    const [closeReason, setCloseReason] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [inputEnable, setInputEnable] = useState(false)
    const render = useSelector(state =>state.auth.closeCaseRender)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')
    const history = useHistory()
    let lang = localStorage.getItem('language')
    const [enhance,setEnhance] =useState([])
    const [secondryReason,setSecondryReason] = useState([])
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if(value === undefined || value === '') return true
            let r = /^[^-\s][a-zA-Z_0-9\s-]+$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'),t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    
    useEffect(() => {
        let details = {
            caseId: caseIds,
        }
        reasonForm.values.closedReasonId= ''
        API.get(`organizations/menus/enhancedCloseReason?lang=${lang}`,details).then(res => {
            setCloseReason(res.data.body.map(c => ({ text: c.primaryReason, value: c.primaryReasonId })));
            setEnhance(res.data.body)
            setDataLoaded(true)
        }).catch(e => {
            console.log(e)
        })
    }, []) // eslint-disable-line

    const reasonFormSchema = Yup.object().shape({
        closedReasonId: Yup.string().required(t("Primary Reason is a required field")),
        secondaryReasonId: Yup.string().required(t("Secondary Reason is a required field")).nullable()
    })

    const reasonForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: reasonFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            submitForm()
        }
    })

    const reasonSelect = (val) => {
        let providerValidation = false;
        reasonForm.values.closedReasonId = val
       enhance.map((c,i)=> {
            if(c.primaryReasonId === val){
                setSecondryReason(c.options.map(e => ({text:e.secondaryReason,value:e.secondaryReasonId})))
                c.options.forEach(element => {
                    if(element.secondaryReasonId === reasonForm.values.secondaryReasonId) {
                        providerValidation=true;
                        reasonForm.setFieldValue('secondaryReasonId', reasonForm.values.secondaryReasonId);
                    }else{
                        reasonForm.setFieldValue('secondaryReasonId', null);
                    }
                });
            } return null;
        });
    }

    
    const submitForm = () => {
            let details = {
                caseId: caseIds,
                closedReasonId: reasonForm.values.closedReasonId,
                secondaryReasonId: reasonForm.values.secondaryReasonId,
                enhancedCloseCaseReason: true,
                otherClosedReason: ""
            } 
            let pageRefresh=true;
            API.post(`/cases:close?orgId=${orgId}`,details).then(res=>{
                    dispatch(setAlert({title:t('Success'),subtitle:t('Case Closed Successfully'),type:'success',active:true}))
                    dispatch(setCloseCaseRender(render+1));
                    dispatch(refreshTrigger(pageRefresh))
                    onClose();   
                
            }).catch(e=>{
                console.log(e)
            }).finally(()=>onClose)
    }

    return (
        <div className="py-2 w-full">
            <div className="px-4 mt-2">
            <CloseSelect ariaLabel={'Primary Reason'} label={t("Primary Reason")} fieldMeta={reasonForm.getFieldMeta('closedReasonId')} fieldHelper={reasonForm.getFieldHelpers('closedReasonId')} fieldProps={reasonForm.getFieldProps('closedReasonId')} options={closeReason} edit={true} dataLoaded={dataLoaded} onChange={reasonSelect} /><br></br>
            <CloseSelect ariaLabel={'Secondary Reason'} label={t("Secondary Reason")} fieldMeta={reasonForm.getFieldMeta('secondaryReasonId')} fieldHelper={reasonForm.getFieldHelpers('secondaryReasonId')} fieldProps={reasonForm.getFieldProps('secondaryReasonId')} options={secondryReason}  edit={true} dataLoaded={dataLoaded} />
                {/* <FieldSelect label={t("Reason")} fieldMeta={reasonForm.getFieldMeta('reason')} fieldHelper={reasonForm.getFieldHelpers('reason')} fieldProps={reasonForm.getFieldProps('reason')} options={closeReason} edit={true} position="bottom" dataLoaded={dataLoaded} onChange={reasonSelect} /> */}
            </div>
            <div className="flex justify-between mt-8 mx-2">
                <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-700 dark:text-red-300 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t("Cancel")}</button>
                <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => reasonForm.submitForm()}>{t("Close Case")}</button>
            </div>
        </div>
    )

}

export default EnhancedClosedDialog