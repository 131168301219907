import { useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdTranslate, MdCheck } from 'react-icons/md'
import { AnimatePresence, motion } from 'framer-motion'
import {setLanguages} from '../store/theme/actions'
import { useDispatch, useSelector } from 'react-redux'
import API from '../axios/API'
import { useHistory } from "react-router-dom"
import { setLoggedIn, setOrganization, setUserDetails,setUserRoles } from "../store/auth/actions"
import { useEffect } from 'react'




const LanguageChange = ({injectPopupClass, originX, originY,onFocus, selectCallBack , closeLanguage}) => {

    let history = useHistory()
    const [showSelector, setShowSelector] = useState(false)
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    // const language = useSelector(state => state.theme.language)
    // const language = useSelector(state =>state.auth.setSpanish)
    const languages = useSelector(state => state.theme.languages)
    const userRoles = useSelector(state=>state.auth.userRoles)
    const zone = new Date().toTimeString().slice(9).split(' ')[0]
    const setLanguage = lang => {
        // dispatch(setLanguages(lang))
        i18n.changeLanguage(lang)
        API.get(`../../whoami/${lang}/${zone}`).then(res => {
            if(res.data.userId && res.status === 200) {
                let tokenUserDetails = res.data
                let parentRole =[]
                let childRole = []
                const dynamicRoleRegex = /dynamic_/
                    tokenUserDetails.roles.map((c)=>{
                        if(c.match(dynamicRoleRegex)){
                            childRole.push(c)
                        }else{
                            parentRole.push(c)
                        }
                    })
                let userDetails = {"email":tokenUserDetails.email,"sub": tokenUserDetails.userName, "role": tokenUserDetails.roles, "caremanagerId": tokenUserDetails.caremanagerId, "language": tokenUserDetails.lang,"iat": tokenUserDetails.attributes.iat, "orgId": tokenUserDetails.orgId, authType: 'sso',"env": tokenUserDetails.env,"parentRole":parentRole,"childRole":childRole}
                // let userDetails = {"sub": "soorath", "role": ["caremanager"], "caremanagerId": 2, "language": 'en', "iat": 121212122, "orgId": 1}
                API.get(`organizations/${tokenUserDetails.orgId}/organizations`).then(res => {
                    let luserDetails = { "caremanagerId": tokenUserDetails.caremanagerId, "orgId": tokenUserDetails.orgId, "roles": tokenUserDetails.roles, "cmfirstName": tokenUserDetails.cmfirstName, "cmlastName": tokenUserDetails.cmlastName, "orgName": res.data.find(c => c.organizationId === tokenUserDetails.orgId).organizationName, "schedulingEnabled": tokenUserDetails.schedulingEnabled, "reportEnabled": tokenUserDetails.reportEnabled, "env": tokenUserDetails.env, "lang": tokenUserDetails.lang, "childOrg": res.data, "childOrgEnabled": tokenUserDetails.childOrgEnabled, "releaseBannerEnabled": tokenUserDetails.releaseBannerEnabled, "releaseId": tokenUserDetails.releaseId, "subIdEnabled": tokenUserDetails.subIdEnabled, "samsIdEnabled": tokenUserDetails.samsIdEnabled, "auntBerthaInd": tokenUserDetails.auntBerthaInd }
                    localStorage.setItem('userDetails' ,JSON.stringify(luserDetails))
                    if(((tokenUserDetails.roles && (tokenUserDetails.roles.length === 1 && tokenUserDetails.roles.includes('superadmin'))) || (tokenUserDetails.roles.length === 1 && tokenUserDetails.roles.includes('developer')))){
                        history.push('/swagger')
                    }
                    dispatch(setUserRoles(userDetails.role.join(',')))
                    dispatch(setUserDetails(userDetails))
                    dispatch(setUserRoles(userDetails.role.join(',')))
                    dispatch(setOrganization(pastToken && pastToken !== null ? pastToken : userDetails.orgId))
                    localStorage.setItem('orgid',pastToken && pastToken !== null ? pastToken : userDetails.orgId)
                    dispatch(setLoggedIn(true))
                }).catch(e => {
                    console.log(e)
                }).finally(() => {
                    setIsLoggedChecked(true)
                })
            }
            history.push("/")
            dispatch(setLanguages(lang))
        })
        // API.post(`language/${lang}`).then(res => {
        //     history.push("/")
        //     // window.location.reload()
        //     dispatch(setLanguages(lang))
        // })
        .catch(e => {
            console.log(e)
        })
    }
    const hideShowSelector = () => setShowSelector(false)
    const menuAnim = useMemo(() => ({
        hidden: {
            scale: 0,
            opacity: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            originX: originX,
            originY: originY
        }
    }), [originX, originY])
    const langCode = useMemo(() => {
        let l = languages.filter(c => c.code === i18n.language)
        return l.length > 0 ? l[0].key : languages[0].key
    }, [i18n.language, languages])
    useLayoutEffect(() => {
        if(showSelector) {
            setTimeout(() => {
                window.addEventListener('click', hideShowSelector)
            }, 100)
        } else window.removeEventListener('click', hideShowSelector)

        return () => window.removeEventListener('click', hideShowSelector)
    }, [showSelector])
    const accessibilityFixs = (data) => {
        setLanguage(data);
        setShowSelector(false);
    }

    useEffect(()=>{
        if(showSelector){
            if(typeof selectCallBack == 'function') selectCallBack('language')
        }
    },[showSelector])

    useEffect(()=>{
        if(closeLanguage){
            setShowSelector(false)
        }
    },[closeLanguage])

    return (
        <div className="relative">
            <div className="cursor-pointer relative" tabIndex="0" onKeyDown={(e) => e.key === 'Enter' && setShowSelector(!showSelector)} onClick={() => setShowSelector(true)}  title={t('Select Language')}>
                <span className="absolute text-[10px] leading-none -top-3 -right-2.5 bg-tcolor text-black px-1 py-1 rounded-full">{langCode}</span>
                <MdTranslate size="20px" className="text-gray-800 dark:text-white" />
            </div>
            <AnimatePresence>
                { showSelector &&
                    <motion.div variants={ menuAnim } initial="hidden" animate="visible" exit="hidden" className={`absolute bg-bluegray-500 dark:bg-ldark py-2 w-48 rounded-lg text-left ${injectPopupClass}`}>
                        <div className="text-xs text-gray-300 pl-2">Language</div>
                        <div className="pt-2">
                            {
                                languages.map(c => (
                                    <div key={c.code} tabIndex="0" className="py-1 pl-4 pr-2 hover:bg-bluegray-600 text-white text-sm cursor-pointer flex items-center justify-between"  onKeyDown={(event) => {event.key === 'Enter' && accessibilityFixs(c.code)}} onClick={() => setLanguage(c.code)} onFocus={onFocus} >
                                        <span>{c.title}</span>
                                        { i18n.language === c.code && <MdCheck className="text-green-300" /> }
                                    </div>
                                ))
                            }
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}

export default LanguageChange