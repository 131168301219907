import { SET_LOGGEDIN, SET_REFRESH, SET_USER_DETAILS, SET_LOAD_ALL_CASE, SET_USER, SET_ORGANIZATION, SET_CHILDORGTRANSFERRENDER, SET_CLOSECASERENDER, SET_TRANSFERCASERENDER, SET_NOTESACTION, SET_RELATIONSHIPCOUNT,SET_REOPENCASECOUNT, SET_ASSOCIATECOUNT,SET_DEMOGRAPHIC,SET_CHANGERELATIONSHIPCOUNT,SET_PROFILE, SET_SPANISH,SET_ASSIGNCASERENDER,SET_ONLY_NUMBER,SET_USERROLES,SET_ONLY_NUMBER1, SET_ENABLE_ALL_SEARCH,SET_TRIGGER_SUBID_SEARCH,SET_SERVICEUSAGE} from './constants'
import authStore from './store'

const authReducers = (state = authStore, action) => {
    switch (action.type) {
        case SET_LOGGEDIN:
            return { ...state, isLoggedIn: action.payload }
        case SET_USER_DETAILS:
            return { ...state, userDetails: action.payload }
        case SET_USER:
            return { ...state, user: action.payload }
        case SET_ORGANIZATION:
            return { ...state, organization: action.payload }
        case SET_CLOSECASERENDER:
            return { ...state, closeCaseRender: action.payload }
        case SET_ASSIGNCASERENDER:
             return { ...state, assignCaseRender: action.payload }
        case SET_TRANSFERCASERENDER:
            return { ...state, transferCaseRender: action.payload }
        case SET_CHILDORGTRANSFERRENDER:
            return { ...state, childOrgTransferRender: action.payload }
        case SET_NOTESACTION:
            return { ...state, notesAction: action.payload }
        case SET_RELATIONSHIPCOUNT:
            return {...state,relationShipCount : action.payload}
        case SET_CHANGERELATIONSHIPCOUNT:
            return {...state,changerelationShipCount : action.payload}
        case SET_REOPENCASECOUNT:
            return {...state,reopencaseCount : action.payload}  
        case SET_ASSOCIATECOUNT:
            return {...state,associatecount : action.payload}
        case SET_DEMOGRAPHIC:
            return {...state, demographiccount : action.payload}
        case SET_PROFILE:
            return {...state, profileCount : action.payload}    
        case SET_SPANISH:
            return {...state, languagespan:action.payload}
        case SET_ONLY_NUMBER : 
            return  {...state,onlyNumber: action.payload}
        case SET_ONLY_NUMBER1 : 
            return  {...state,onlyNumber1: action.payload}    
        case SET_USERROLES:
            return {...state,userRoles: action.payload}
        case SET_LOAD_ALL_CASE:
            return {...state,loadAllCase:action.payload}
        case SET_ENABLE_ALL_SEARCH:
            return {...state,enableAllSerach: action.payload}
        case SET_TRIGGER_SUBID_SEARCH:
            return{...state,triggersubSearch:action.payload}
        case SET_REFRESH:
            return{...state,pageRefresh:action.payload}
        case SET_SERVICEUSAGE:
            return{...state,serviceUsage:action.payload}
        default:
            return state
    }
}

export default authReducers
