import { useTranslation } from "react-i18next"
import { MdAccountCircle } from "react-icons/md"

const ScreenerDemographicLoader = () => {

    const { t } = useTranslation()

    return (
        <div className="flex-1 bg-tcolor rounded-tr-lg bg-opacity-90 min-w-[300px] -ml-8 flex-grow-0 overflow-auto pb-10">
            <div className="mt-5 ml-4">
                <div className="text-lg font-medium text-gray-200 dark:text-gray-300 underline flex items-center">
                    <MdAccountCircle className="mr-1" />
                    {t('Caregiver')}
                </div>
                <div className="mt-2">
                    <div className="flex items-center py-0.5">
                        <div className="mr-2 text-xs text-gray-200">{t("FirstName")}</div>
                        <div className="text-sm text-white bg-gray-300 h-1 w-32 rounded animate-pulse">
                        </div>
                    </div>
                    <div className="flex items-center py-0.5">
                        <div className="mr-2 text-xs text-gray-200">{t("LastName")}</div>
                        <div className="text-sm text-white bg-gray-300 h-1 w-32 rounded animate-pulse">
                        </div>
                    </div>
                    <div className="flex items-center py-0.5">
                        <div className="mr-2 text-xs text-gray-200">{t("Phone")}</div>
                        <div className="text-sm text-white bg-gray-300 h-1 w-32 rounded animate-pulse">
                        </div>
                    </div>
                    <div className="flex items-center py-0.5">
                        <div className="mr-2 text-xs text-gray-200">{t("Email")}</div>
                        <div className="text-sm text-white bg-gray-300 h-1 w-32 rounded animate-pulse">
                        </div>
                    </div>
                    <div className="flex items-center py-0.5">
                        <div className="mr-2 text-xs text-gray-200">{t("Date of Birth")}</div>
                        <div className="text-sm text-white bg-gray-300 h-1 w-32 rounded animate-pulse">
                        </div>
                    </div>
                    <div className="flex items-center py-0.5">
                        <div className="mr-2 text-xs text-gray-200">{t("Race")}</div>
                        <div className="text-sm text-white bg-gray-300 h-1 w-32 rounded animate-pulse">
                        </div>
                    </div>
                </div>
                {/* <div className="mt-5">
                    <div className="text-lg font-medium text-gray-200 dark:text-gray-300 underline flex items-center">
                        <MdAccountCircle className="mr-1" />
                        {t('Carereceiver')}
                    </div>
                    <div className="mt-2">
                        <div className="flex items-center py-0.5">
                            <div className="mr-2 text-xs text-gray-200">FirstName</div>
                            <div className="text-sm text-white bg-gray-300 h-1 w-32 rounded animate-pulse">
                            </div>
                        </div>
                        <div className="flex items-center py-0.5">
                            <div className="mr-2 text-xs text-gray-200">LastName</div>
                            <div className="text-sm text-white bg-gray-300 h-1 w-32 rounded animate-pulse">
                            </div>
                        </div>
                        <div className="flex items-center py-0.5">
                            <div className="mr-2 text-xs text-gray-200">Subscriber Id</div>
                            <div className="text-sm text-white bg-gray-300 h-1 w-32 rounded animate-pulse">
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default ScreenerDemographicLoader