import { SET_LOGGEDIN, SET_USER_DETAILS, SET_USER, SET_ORGANIZATION, SET_LOAD_ALL_CASE,SET_CLOSECASERENDER, SET_TRANSFERCASERENDER, SET_CHILDORGTRANSFERRENDER, SET_NOTESACTION, SET_RELATIONSHIPCOUNT,SET_REOPENCASECOUNT,SET_ASSOCIATECOUNT,SET_DEMOGRAPHIC ,SET_CHANGERELATIONSHIPCOUNT,SET_PROFILE,SET_SPANISH,SET_ASSIGNCASERENDER,SET_ONLY_NUMBER, SET_USERROLES,SET_ONLY_NUMBER1,SET_ENABLE_ALL_SEARCH,SET_TRIGGER_SUBID_SEARCH,SET_REFRESH,SET_SERVICEUSAGE} from "./constants"
export const setLoggedIn = value => {
    if(!value) {
        localStorage.removeItem('token')
    }
    return {
        type: SET_LOGGEDIN,
        payload: value
    }
}
export const setUserDetails = value => {
    return {
        type: SET_USER_DETAILS,
        payload: value
    }
}
export const setUser = value => {
    return{
        type:SET_USER,
        payload:value
    }
}

export const setCloseCaseRender = value =>{
    return{
        type:SET_CLOSECASERENDER,
        payload:value
    }
}

export const setAssignCaseRender = value =>{
    return{
        type:SET_ASSIGNCASERENDER,
        payload:value
    }
}

export const setTransferCaseRender = value =>{
    return{
        type:SET_TRANSFERCASERENDER,
        payload:value
    }
}

export const setChildOrgRender = value =>{
    return{
        type:SET_CHILDORGTRANSFERRENDER,
        payload:value
    }
}

export const setNotesAction = value =>{
    return{
        type:SET_NOTESACTION,
        payload :value
    }
}
export const setRelationshipCount = value =>{
    return{
        type:SET_RELATIONSHIPCOUNT,
        payload : value
    }
}
export const setChangeRelationshipCount = value =>{
    return{
        type:SET_CHANGERELATIONSHIPCOUNT,
        payload : value
    }
}
export const setReopencasecount = value =>{
    return{
        type:SET_REOPENCASECOUNT,
        payload : value
    }
}
export const setAssociatecount = value =>{
    return{
        type: SET_ASSOCIATECOUNT,
        payload : value
    }
}
export const setDemographic = value =>{
    return{
        type: SET_DEMOGRAPHIC,
        payload : value
    }
}
export const setProfileCount = value =>{
    return{
        type: SET_PROFILE,
        payload : value
    }
}
export const setSpanish = value =>{
   return{
       type: SET_SPANISH, 
       payload: value
    }

}
export const refreshTrigger = value =>{
    return{
        type: SET_REFRESH, 
        payload: !value
     }
 }
export const setUserRoles = value =>({type: SET_USERROLES, payload: value})
export const setOrganization = value => ({type: SET_ORGANIZATION, payload: value})

export const setOnlyNumber = value =>({type:SET_ONLY_NUMBER,payload: value})
export const setOnlyNumber1 = value =>({type:SET_ONLY_NUMBER1,payload: value})

export const setLoadAllCase = value=>({type:SET_LOAD_ALL_CASE, payload: value})

export const setEnableSearch = value => ({type:SET_ENABLE_ALL_SEARCH, payload: value})
export const setTriggerSubidSearch = value => ({type:SET_TRIGGER_SUBID_SEARCH,payload: value})
export const setServiceUsages = value => ({type:SET_SERVICEUSAGE,payload: value})