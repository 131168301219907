import { FormikProvider, useFormik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import * as Yup from 'yup'
import { setAlert } from "../../store/theme/actions"
import API from '../../axios/API'
import FormCard from "../../components/snippets/FormCard"
// import FieldInput from '../../components/snippets/FieldInput'
import { datatableTheme } from '../../data/dataTableTheme'
import DataTable, { createTheme } from 'react-data-table-component'
import { FaEdit, FaTrash } from "react-icons/fa"
import {FiAirplay,FiDownload} from "react-icons/fi"
import Dialog from "../../components/snippets/Dialog"
import NotesDialog from '../Dialogs/NotesDialog'
import { useSelector } from "react-redux"
import { setNotesAction } from "../../store/auth/actions"
import DatatableLoader from "../../components/snippets/DatatableLoader"
import { createPortal } from "react-dom"
import FlowText  from "../../components/snippets/FlowText"
import { useTranslation } from "react-i18next"
import 'moment-timezone';
import moment from 'moment';
import AddFormButton from "../../components/snippets/AddFormButton"
import IconEmpty from "../../components/snippets/IconEmpty"
import { QuillEditor } from "../../components/snippets/QuillEditor"
import FieldInput from "../../components/snippets/FieldInput"
import axios from 'axios'

const initialValues = {
    description: '',
    date:''
}

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)



const CaseNotes = ({ props }) => {

    const [tableLoading, setTableLoading] = useState(false)
    const [editForm, setEditForm] = useState(false)
    const [dataLoaded] = useState(true)
    const [notes, setNotes] = useState([])
    const [editData, setEditData] = useState()
    const dispatch = useDispatch()
    const [notesModal, setNotesModal] = useState(false)
    const { themeMode } = useSelector(state => state.theme)
    const [page, setPage] = useState(1)
    const [noteAction,setNoteAction] = useState(0)
    const notesAction = useSelector(state =>state.auth.notesAction)
    const [deletePopup,setDeletePopup] = useState(false)
    // const [hideColumn,setHideColumn] = useState(false)
    const [notesId,setNotesId] = useState()
    const {t} = useTranslation()
    const organization = useSelector(state =>state.auth.organization)
    const userDetails  = useSelector(state => state.auth.userDetails)
    let orgId = localStorage.getItem('orgid')

    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            // let r = /^[^-\s]/g
            // finding html tag contains the content
            let r = /<[^>]+>\s*[^<\s\r\n][^<]*<\/[^>]+>/
            if(value === '<p><br></p>') return false
            return r.test(value)
        })
    })
    useEffect(() => {
        setTableLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}cases/${props}/notes`,{
            headers: {
                'Authorization': localStorage.getItem('token'), 
                'Accept': 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                setNotes(res.data.body)
                setTableLoading(false)
            }
            if(userDetails.role.length === 1 && userDetails.role.includes('referrer')){
                // setHideColumn(true)
            }
        }).catch(e => {
            console.log(e)
        }).finally()
    }, [notesAction,noteAction,props, userDetails.role])

    const notesFormSchema = Yup.object().shape({
        description: Yup.string().validateDescription().required(t("Notes is a required field")),
        date:(userDetails.role.includes('admin') || userDetails.role.includes('dynamic_admin') || userDetails.role.includes('caremanager') || userDetails.role.includes('dynamic_cm')) ? Yup.string().required("Date is required") : Yup.string().nullable()
    })

    const notesForm = useFormik({
        initialValues: initialValues,
        validationSchema: notesFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        validateOnChange:true,
        validateOnBlur:true,
        onSubmit: values => {
            addNotes()
        }
    })

    const addNotes = () => {
        let details =
        {
            "notes": notesForm.values.description.toString(),
            "date":notesForm.values.date && notesForm.values.date.toString().length > 0 ?  notesForm.values.date.toString().replace('/','-').replace('/','-').slice(0,10) + " " + new Date().toString().slice(16,24) : new Date().toLocaleDateString('en-GB').split('/').reverse().join('-')+ " " + new Date().toString().slice(16,24)
        }
        API.post(`cases/${props}/notes?orgId=${orgId}`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t("Notes saved successfully"), type: "success", active: true }))
                dispatch(setNotesAction(notesAction + 1))
                setNoteAction(noteAction+1)
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            } else {

            }
        }).catch(e => {
            console.log(e)
        }).finally(() => {
            notesForm.resetForm()
        })
    }
    const toggleFormEdit = () => {
        if (editForm) notesForm.submitForm()
        if (notesForm.isValid || !editForm) setEditForm(!editForm)
    }

    const columns = [
        {
            name: t('Date'),
            selector: row => `${followdate(row.date)}`,
            width: '250px'
        },
        {
            name: t('Created By'),
            selector: 'createdBy',
            width: '250px'
        },
        {
            name: t('Notes'),
            selector: row => { return (<div style={{ display: 'flex' }}> {row.systemGenerated === true ? <button><FiAirplay size='20px' /></button> : <button><IconEmpty /></button>}&nbsp; &nbsp;<span className="ql-editor" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} dangerouslySetInnerHTML={{ __html: row.description }}></span></div>) },

            wrap: true,
            width:'400px'
        },
        {
            name: t('Action'), button: true, grow: 1, width: '200px', cell: row => <>
                {((!row.systemGenerated) && (row.createdById === userDetails.caremanagerId)) ? <button onClick={() => ((userDetails.orgId === parseInt(organization) && (userDetails.role.includes('caremanager') || userDetails.role.includes('admin') || userDetails.role.includes('screener') || userDetails.role.includes('referrer')) || 
                (userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_cm') || userDetails.role.includes('dynamic_admin') || userDetails.role.includes('dynamic_scr') || userDetails.role.includes('dynamic_ref'))))) ? openNotesDialog(row) : ''}><FaEdit title={t("Edit")} style={{ marginLeft: '10', marginRight: '10' }} size="20px" className="text-gray-600 dark:text-gray-300" /></button> : ''}
                {((organization && userDetails && userDetails.orgId === parseInt(organization) && (!row.systemGenerated) && userDetails.role.includes('admin')) || ((!row.systemGenerated) && (row.createdById === userDetails.caremanagerId)) || organization && userDetails && userDetails.orgId !== parseInt(organization) && (!row.systemGenerated) && userDetails.role.includes('dynamic_admin')) ? <button onClick={() => ((userDetails.orgId === parseInt(organization) && (userDetails.role.includes('caremanager') || userDetails.role.includes('admin') || userDetails.role.includes('screener') || userDetails.role.includes('referrer')) || 
                (userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_cm') || userDetails.role.includes('dynamic_admin') || userDetails.role.includes('dynamic_scr') || userDetails.role.includes('dynamic_ref'))))) ? openDeletePopup(row) : ''}><FaTrash title={t("Delete")} style={{ marginLeft: '10', marginRight: '10' }} size="20px" className="text-gray-600 dark:text-gray-300" /></button> : ''}
            </>
        }
    ]

    const followdate =(value) => {
        if(value !== null && value){
            let timestamp = new Date(value).getTime();
            let tz = moment.tz.guess()
            let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
            //     let followupdate = new Date(value)
            // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
        }else{
            return ''
        }
    }

    const openNotesDialog = (c) => {
        if (c !== undefined)
            setEditData({caseId:props,notes:c})
        setNotesModal(true)
    }

    const deleteNotes = () => {
        API.delete(`cases/${props}/notes/${notesId}?orgId=${orgId}`).then(res => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('Notes deleted successfully'), type: 'success', active: true }))
                setNoteAction(noteAction+1)
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            } else {

            }
        }).catch(e => {
            console.log(e)
        }).finally(()=>setDeletePopup(false))
    }
    const openDeletePopup = (values)=>{
        setNotesId(values.notesId)
        setDeletePopup(true)
    }

    const downloadNotes = ()=>{
        try{
            axios.get(`${process.env.REACT_APP_API_BASE_URL}cases/${props}/notes`,{
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    'Accept': ''
                }
            }).then((res)=>{
                const linkSource = `data:application/pdf;base64,${res.data.body}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "TCE-Care CaseNotes.pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Case Notes download successfully'), type: 'success', active: true }))
            })
        }catch(err){
            console.log(err)
        }
    }
    return (
        <div>
            <FormCard title={t("Notes")}>
                {/* {((organization && userDetails && userDetails.orgId === parseInt(organization))|| (organization && userDetails && organization !== userDetails.orgId && userDetails.role.includes('dynamic_cm')) || userDetails.role.includes('dynamic_ref') || userDetails.role.includes('dynamic_admin')) ? :''} */}
                <AddFormButton injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={() => {setEditForm(false);notesForm.resetForm()}}/>
                {/* <FieldInput ariaLabel={'Type a Note Text Area'} type="textarea" label={t("Type a note")} fieldMeta={notesForm.getFieldMeta('description')} fieldProps={notesForm.getFieldProps('description')} edit={editForm} dataLoaded={dataLoaded} /> */}
                <FormikProvider value={notesForm}>
                    {editForm && (userDetails.role.includes('admin') || userDetails.role.includes('dynamic_admin') || userDetails.role.includes('caremanager') || userDetails.role.includes('dynamic_cm')) &&  <div className="w-full flex flex-row justify-end my-1"><FieldInput injectClass={'w-64'} type="date" ariaLabel={'Notes Calendar'} label={"Calendar"} fieldMeta={notesForm.getFieldMeta('date')} fieldHelper={notesForm.getFieldHelpers('date')} fieldProps={notesForm.getFieldProps('date')} edit={true} dataLoaded={true}/></div>}
                    <QuillEditor name="description" ariaLabel={'Type a Note Text Area'} type="textarea" label={t("Type a note")} fieldMeta={notesForm.getFieldMeta('description')} fieldProps={notesForm.getFieldProps('description')} edit={editForm} dataLoaded={dataLoaded} />
                </FormikProvider>
            </FormCard>
            {
                notes.length > 0 ?
                    <FormCard title={t("Notes List")}>
                        <div className="w-full flex flex-row justify-end">
                            <button className="flex gap-1  items-center py-0.5 px-4 ml-2 text-sm rounded bg-tcolor hover:bg-opacity-80 text-black cursor-pointer" onClick={() =>downloadNotes()}>
                                        <FiDownload role="img" title="Download Notes"/> {t('Download')}
                                    </button>
                        </div>
                        {
                            tableLoading ?
                                <DatatableLoader />
                                :
                                <DataTable injectClass="mt-12"
                                    columns={columns}
                                    data={notes}  noHeader
                                    theme={localStorage.getItem('theme') === 'dark' ? "defaultDark" : "defaultLight"}pagination onChangePage={val => setPage(val)} paginationDefaultPage={page} paginationComponentOptions={{ rowsPerPageText: 'Items per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                                 />
                        }
                    </FormCard> : ''
            }
            {
                createPortal(<Dialog title={t("Update Note")} showDialog={notesModal} onClose={() => setNotesModal(false)}>
                    <NotesDialog onClose={() => setNotesModal(false)} props={editData} type={'update'} />
                </Dialog>, document.body)
            }
            {
                createPortal(<Dialog title={t("Delete Note")} showDialog={deletePopup} onClose={() => setDeletePopup(false)}>
                    <div className="py-2 w-full">
                        <div className="px-4 mt-2">
                            <FlowText text={t("Are you sure you want to delete this notes")} />
                        </div>
                        <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setDeletePopup(false)}>{t("No")}</button>
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => deleteNotes()}>{t("Yes")}</button>
                        </div>
                    </div>
                </Dialog>, document.body)
            }
        </div>
    )
}

export default CaseNotes