import { FaBell, FaCheck, FaExclamationTriangle, FaTimes } from "react-icons/fa"
import { AnimatePresence, motion } from 'framer-motion'
import { useEffect } from "react"

const AlertAnim = {
    hidden: {
        y: '-100vh',
        opacity: 0
    },
    visible: {
        y: 20,
        opacity: 1
    }
}

const TimerAnim = {
    hidden: {
        width: '92%'
    },
    visible: {
        width: 0,
        transition: {
            duration: 5
        }
    }
}

const AlertStyle = {
    minWidth: '400px'
}

var timeout = null

// error, warning, success
const Alert = ({ title, subtitle, active, setActive, type="error" }) => {

    const toggleActive = () => {
        clearTimeout(timeout)
        if(typeof setActive == 'function') setActive(!active)
    }
    useEffect(() => {
        if(active) timeout = setTimeout(() => toggleActive(), 5000)
    }, [active]) // eslint-disable-line

    return (
        <AnimatePresence>
            { active && 
                <div className="fixed top-5 flex justify-center left-[50%] transform -translate-x-1/2 z-[2000]">
                    <motion.div className={`ring-2 ${type === 'error' ? 'ring-red-700 dark:ring-red-700' : type === 'success' ? 'ring-green-700 dark:ring-green-700' : type === 'warning' ? 'ring-yellow-700 dark:ring-yellow-700' : ''} bg-indigo-50 dark:bg-ldark text-gray-600 dark:text-gray-300 flex py-2 pl-4 pr-3 rounded-2xl justify-between items-center shadow-xl`} variants={ AlertAnim } initial="hidden" animate="visible" exit="hidden" style={ AlertStyle }>
                        <motion.div className={`absolute ${type === 'error' ? 'bg-red-700 dark:bg-red-700' : type === 'success' ? 'bg-green-700 dark:bg-green-700' : type === 'warning' ? 'bg-yellow-700 dark:bg-yellow-700' : ''} right-0 mx-4 z-50 rounded-full`} variants={ TimerAnim } style={{height: '3px', top: '-3px'}}></motion.div>
                        <div className="flex items-center">
                            {
                                type === 'error' ? <FaExclamationTriangle className={`text-red-700 text-xl`} />
                                : type === 'success' ? <FaCheck className={`text-green-700 text-xl`} />
                                : type === 'warning' ? <FaBell className={`text-yellow-700 dark:text-yellow-700 text-xl`} />
                                : null
                            }
                            <div className="ml-4 font-medium text-left">
                                <div>{ title }</div>
                                <div className={`${type === 'error' ? 'text-red-700 dark:text-red-300' : type === 'success' ? 'text-green-700 dark:text-green-300' : type === 'warning' ? 'text-yellow-700 dark:text-yellow-300' : ''} text-xs font-bold`}>{ subtitle }</div>
                            </div>
                        </div>
                        <div className="mx-1">
                            <FaTimes className="cursor-pointer" onClick={ toggleActive } />
                        </div>
                    </motion.div>
                </div>
            }
        </AnimatePresence>
    )
}

export default Alert