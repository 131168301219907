import { useEffect, useLayoutEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { withRouter } from "react-router"
import API from "../axios/API"
// import API from "../axios/API"
import IntroLoader from "../components/IntroLoader"

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { setLoggedIn, setOrganization, setUserDetails,setUserRoles } from "../store/auth/actions"

let authcheckinterval = null


const Auth = ({ children, history }) => {

    const isLoggedIn = useSelector(state =>  state.auth.isLoggedIn)
    const [isLoggedChecked, setIsLoggedChecked] = useState(false)
    const language = useSelector(state=>state.theme.language)
    const languages = useSelector(state=>state.theme.languages)
    const userRoles = useSelector(state=>state.auth.userRoles)
    const zone = new Date().toTimeString().slice(9).split(' ')[0]
    // const organization = useSelector(state => state.auth.organization)

    const dispatch = useDispatch()

    const { instance, accounts, inProgress } = useMsal();

    const authSSO = () => {
        if(accounts.length <= 0) {
            dispatch(setLoggedIn(false))
            setIsLoggedChecked(true)
            return
        }
        let lang = localStorage.getItem('language')
        if(lang === undefined || languages.find(c => c.code === lang) === undefined) {
            lang = language
        }
        loginRequest.account = instance.getAccountByHomeId(accounts[0].homeAccountId)
        instance.acquireTokenSilent(loginRequest)
            .then(response => {
                localStorage.setItem('token', `Bearer ${response.accessToken}`)
                let pastToken = localStorage.getItem('orgid') && localStorage.getItem('orgid') !== null ? localStorage.getItem('orgid') : null
                API.get(`../../whoami/${lang}/${zone}?orgId=${pastToken}`).then(res => {
                    if(res.data.userId && res.status === 200) {
                        let tokenUserDetails = res.data
                        let parentRole =[]
                        let childRole = []
                        const dynamicRoleRegex = /dynamic_/
                            tokenUserDetails.roles.map((c)=>{
                                if(c.match(dynamicRoleRegex)){
                                    childRole.push(c)
                                }else{
                                    parentRole.push(c)
                                }
                            })
                            localStorage.setItem('bulkService',tokenUserDetails.bulkServiceUsage)
                        let userDetails = {"email":tokenUserDetails.email,"sub": tokenUserDetails.userName, "role": tokenUserDetails.roles, "caremanagerId": tokenUserDetails.caremanagerId, "language": tokenUserDetails.lang,"iat": tokenUserDetails.attributes.iat, "orgId": tokenUserDetails.orgId, authType: 'sso',"env": tokenUserDetails.env,"parentRole":parentRole,"childRole":childRole,'bulkservice':tokenUserDetails.bulkServiceUsage}
                        // let userDetails = {"sub": "soorath", "role": ["caremanager"], "caremanagerId": 2, "language": 'en', "iat": 121212122, "orgId": 1}
                        API.get(`organizations/${tokenUserDetails.orgId}/organizations`).then(res => {
                            let luserDetails = { "caremanagerId": tokenUserDetails.caremanagerId, "orgId": tokenUserDetails.orgId, "roles": tokenUserDetails.roles, "cmfirstName": tokenUserDetails.cmfirstName, "cmlastName": tokenUserDetails.cmlastName, "orgName": res.data.find(c => c.organizationId === tokenUserDetails.orgId).organizationName, "schedulingEnabled": tokenUserDetails.schedulingEnabled, "reportEnabled": tokenUserDetails.reportEnabled, "env": tokenUserDetails.env, "lang": tokenUserDetails.lang, "childOrg": res.data, "childOrgEnabled": tokenUserDetails.childOrgEnabled, "releaseBannerEnabled": tokenUserDetails.releaseBannerEnabled, "releaseId": tokenUserDetails.releaseId, "subIdEnabled": tokenUserDetails.subIdEnabled, "samsIdEnabled": tokenUserDetails.samsIdEnabled, "auntBerthaInd": tokenUserDetails.auntBerthaInd,'bulkservice':tokenUserDetails.bulkServiceUsage }
                            localStorage.setItem('userDetails' ,JSON.stringify(luserDetails))
                            if(((tokenUserDetails.roles && (tokenUserDetails.roles.length === 1 && tokenUserDetails.roles.includes('superadmin'))) || (tokenUserDetails.roles.length === 1 && tokenUserDetails.roles.includes('developer')))){
                                history.push('/swagger')
                            }
                            dispatch(setUserRoles(userDetails.role.join(',')))
                            dispatch(setUserDetails(userDetails))
                            dispatch(setUserRoles(userDetails.role.join(',')))
                            dispatch(setOrganization(pastToken && pastToken !== null ? pastToken : userDetails.orgId))
                            localStorage.setItem('orgid',pastToken && pastToken !== null ? pastToken : userDetails.orgId)
                            dispatch(setLoggedIn(true))
                        }).catch(e => {
                            console.log(e)
                        }).finally(() => {
                            setIsLoggedChecked(true)
                        })
                    }
                }).catch(e => {
                    dispatch(setLoggedIn(false))
                    setIsLoggedChecked(true)
                })
            }).catch(e => {
                dispatch(setLoggedIn(false))
                setIsLoggedChecked(true)
            })
    }

    const refreshToken = () => {
        let token = localStorage.getItem('token')
        if(accounts.length <= 0 || token === null || token === undefined || token === '') {
            return
        }
        let userDetails = JSON.parse(atob(token.split('.')[1]));
        if(userDetails.exp - Math.floor(Date.now()/1000) < 1000) {
            loginRequest.account = instance.getAccountByHomeId(accounts[0].homeAccountId)
            instance.acquireTokenSilent(loginRequest)
                .then(response => {
                    localStorage.setItem('token', `Bearer ${response.accessToken}`)
                })
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            refreshToken()
        }, 1000 * 60)

        return () => {
            clearInterval(interval)
        }
    }, [accounts])// eslint-disable-line

    const chkLoggedIn = () => {
        let token = localStorage.getItem('token')
        if(!token && isLoggedIn) {
            dispatch(setLoggedIn(false))
        } else if(token && token.split('.').length > 1) {
            let userDetails = JSON.parse(atob(token.split('.')[1]));
            if(userDetails.email) {
                authSSO()
            } else {
                dispatch(setUserDetails(userDetails))
                dispatch(setOrganization(userDetails.orgId))
                dispatch(setLoggedIn(true))
                setIsLoggedChecked(true)
            }
        } else {
            setIsLoggedChecked(true)
        }
    }

    useEffect(() => {
        if(!isLoggedIn) {
            chkLoggedIn()
            if(history.location.pathname.includes('/molina/molina-tx'))history.push('/molina/molina-tx')
            else if(history.location.pathname.includes('/screener/intake'))history.push('/screener/intake')
            else if(history.location.pathname.includes('/screener/score'))history.push('/screener/score')
            else if((history.location.pathname.includes('/reset/') === false)&&(history.location.pathname !== '/login')) history.push('/login')
        } else if(history.location.pathname === '/login') history.push('/')
    }, [isLoggedIn]) // eslint-disable-line

    useEffect(() => {
      authcheckinterval = setInterval(()=>{
       let roledata = localStorage.getItem('userDetails')
       if(userRoles === null)  return
       if(roledata){
        roledata =JSON.parse(roledata)
        if(roledata.roles.join(',') !== userRoles){
           window.location = '/'
        }
       }
      },2000)
      return () =>  {clearInterval(authcheckinterval)}
    },[userRoles])

    useLayoutEffect(() => {
        chkLoggedIn()
    }, [accounts, inProgress]) // eslint-disable-line

    return (
        <>
            {
                isLoggedChecked ? <> { children } </> : <IntroLoader />
            }
        </>
    )
}

export default withRouter(Auth)