import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import * as Yup from 'yup'
import { setAlert } from "../../store/theme/actions"
import API from '../../axios/API'
import FormCard from "../../components/snippets/FormCard"
// import FavFormButton from '../../components/snippets/FavFormButton'
// import FieldInput from '../../components/snippets/FieldInput'
import { datatableTheme } from '../../data/dataTableTheme'
import DataTable, { createTheme } from 'react-data-table-component'
// import { FaToggleOn,FaToggleOff } from "react-icons/fa"
import Dialog from "../../components/snippets/Dialog"
import NotesDialog from '../Dialogs/NotesDialog'
import { useSelector } from "react-redux"
import { setNotesAction } from "../../store/auth/actions"
import DatatableLoader from "../../components/snippets/DatatableLoader"
import { createPortal } from "react-dom"
import FlowText  from "../../components/snippets/FlowText"
import { useTranslation } from "react-i18next"
// import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import AddFormButton from "../../components/snippets/AddFormButton"
import CampaignInput from "../../components/snippets/CampaignInput"
import Switch from "../../components/snippets/Switch"

const initialValues = {
    description: ''
}

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)



const Campaign = ({ props }) => {

    const [tableLoading, setTableLoading] = useState(false)
    const [editForm, setEditForm] = useState(false)
    const [dataLoaded] = useState(true)
    const [notes, setNotes] = useState([])
    const [editData] = useState()
    const dispatch = useDispatch()
    const [notesModal, setNotesModal] = useState(false)
    const { themeMode } = useSelector(state => state.theme)
    const [noteAction,setNoteAction] = useState(0)
    const notesAction = useSelector(state =>state.auth.notesAction)
    const [deletePopup,setDeletePopup] = useState(false)
    const [notesId] = useState()
    const {t} = useTranslation()
    const organization = useSelector(state =>state.auth.organization)
    const userDetails  = useSelector(state => state.auth.userDetails)
    let orgId = localStorage.getItem('orgid')
    const [page, setPage] = useState(1)
    // const [switchActive,setSwitchActive] = useState(false)

    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    useEffect(() => {
        setTableLoading(true)
        API.get(`organizations/${orgId}/campaignID`).then(res => {
            if (res.status === 200) {
                setNotes(res.data)
                setTableLoading(false)
            }
        }).catch(e => {
            console.log(e)
        }).finally()
    }, [notesAction,noteAction, orgId])

    const notesFormSchema = Yup.object().shape({
        description: Yup.string().matches(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/,t("Campaign should be alpha-numeric")).length(24,t("It should have 24 alpha-numeric characters")).required(t("Campaign is a required field")),
    })

    const notesForm = useFormik({
        initialValues: initialValues,
        validationSchema: notesFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            addNotes()
        }
    })

    const addNotes = () => {
        let details =
        {
        "campaignId":notesForm.values.description,
        "orgId":orgId,
        "createdBy":''
        }
        API.post(`organizations/addCampaignId`, details).then((res) => {
            if(res.status === 200 && res.data.params === false){
                dispatch(setAlert({title:t('Warning'),subtitle:res.data.message,type:'warning',active:true}))
            }else if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t("Campaign Added successfully"), type: "success", active: true }))
                dispatch(setNotesAction(notesAction + 1))
                setNoteAction(noteAction+1)
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            } else {

            }
        }).catch(e => {
            console.log(e)
        }).finally(() => {
            notesForm.resetForm()
        })
    }
    const toggleFormEdit = () => {
        if (editForm) notesForm.submitForm()
        if (!editForm) setEditForm(!editForm)
    }
    const toggleFormCancel =()=>{
        setEditForm(false)
        notesForm.resetForm()
    }

    const setCampaign = (value,orgCamp,campaignId) => {
        let details = {
            "active":value,
            "orgCampaignId":orgCamp,
            "campaignId":campaignId,
            "orgId":orgId
          }
          API.patch(`/organizations/activateCampaign`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: res.data.message, type: "success", active: true }))
                dispatch(setNotesAction(notesAction+1))
            }
        }).catch(e => {
            console.log(e)
        })
    }

    const columns = [
        // {
        //     name: 'S.no',
        //     cell:(row, index) =>index +1,
        //     sortable: true,
        //     grow :0
        // },
        {
            name: t('Campaign URL'),
            selector: 'campaignUrl',
            width:'550px',
            wrap: true
            // row => {return(<div style={{wordWrap:'break-word',padding:'10px',whiteSpace:'normal',width:'auto'}}>{row.description}</div>)}
        },
        {
            name: t('Created By'),
            selector: 'createdBy', 
        },
        // {
        //     name: t('Updated At'),
        //     selector: row => `${followdate(row.createdAt)}`,
           
        // },
        {
            name: t('Updated At'),
            selector: row => `${Modifydate(row.modifiedAt)}`,
          
        },
        {
            name:t('Active/InActive'), button: true,
            cell: row => <>
            {/* <button><FaToggleOn size="30px" title={t('Active')} className="text-blue-600 dark:text-blue-300" /></button>
            <button><FaToggleOff size="30px" title={t('DeActive')} className="text-grey-400 dark:text-grey-300" /></button> */}
            <Switch value={row.active} setValue={value =>setCampaign(value,row.orgCampaignId,row.campaignId)} />
            </>
        }
        ,
        // {
        //     name: t('Action'), button: true, grow: 1, cell: row => <>
        //         <button onClick={() => ((organization && userDetails && userDetails.orgId === organization) || (userDetails.role.length === 2 && userDetails.role.includes('caremanager') && userDetails.role.includes('dynamic_cm')) || userDetails.role.includes('dynamic_ref') || userDetails.role.includes('dynamic_admin')) ? openNotesDialog(row) : ''}><FaEdit title={t("Edit")} style={{marginLeft:'10',marginRight:'10'}} size="20px" className="text-gray-600 dark:text-gray-300" /></button>
        //         {(row.createdById === userDetails.caremanagerId) ?<button onClick={() => ((organization && userDetails && userDetails.orgId === organization) || (userDetails.role.length === 2 && userDetails.role.includes('caremanager') && userDetails.role.includes('dynamic_cm')) || userDetails.role.includes('dynamic_ref') || userDetails.role.includes('dynamic_admin')) ? openDeletePopup(row) : ''}><FaTrash  title={t("Delete")} style={{marginLeft:'10',marginRight:'10'}} size="20px" className="text-gray-600 dark:text-gray-300" /></button>:''}
        //     </>,
        // }
    ]
    
    // const followdate =(value) => {
    //     if(value !== null && value){
    //         let timestamp = new Date(value).getTime();
    //     let tz = moment.tz.guess()
    //     let tzabbr = moment.tz.zone(tz).abbr(timestamp)
    //         let followupdate = new Date(value)
    //     return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
    //     }else{
    //         return ''
    //     }
    //  }
     const Modifydate =(value) => {
        if(value !== null && value){
            let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
        //     let followupdate = new Date(value)
        // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
        }else{
            return ''
        }
     }

    // const openNotesDialog = (c) => {
    //     if (c !== undefined)
    //         setEditData({caseId:props,notes:c})
    //     setNotesModal(true)
    // }

    const deleteNotes = () => {
        API.delete(`cases/${props}/notes/${notesId}?orgId=${orgId}`).then(res => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('Notes deleted successfully'), type: 'success', active: true }))
                setNoteAction(noteAction+1)
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            } else {

            }
        }).catch(e => {
            console.log(e)
        }).finally(()=>setDeletePopup(false))
    }
    // const openDeletePopup = (values)=>{
    //     setNotesId(values.notesId)
    //     setDeletePopup(true)
    // }
    return (
        <div>
            <FormCard title={t("Add Campaign")}>
                {((organization && userDetails && userDetails.orgId === parseInt(organization))|| (organization && userDetails && organization !== userDetails.orgId && userDetails.role.includes('dynamic_admin'))) ? <AddFormButton injectClass="absolute -top-5 right-5" edit={editForm} handleClick={toggleFormEdit} handleCancel={toggleFormCancel}/>:''}
                <CampaignInput ariaLabel={'Campaign Textarea'} type="textarea" label={t("Type a Campaign")} fieldMeta={notesForm.getFieldMeta('description')} fieldProps={notesForm.getFieldProps('description')} edit={editForm} dataLoaded={dataLoaded} ml={true} length={'24'} />
            </FormCard>
           
                    <FormCard title={t("Campaign List")}>
                        {
                            tableLoading ?
                                <DatatableLoader />
                                :
                                <DataTable injectClass="mt-12"
                                    columns={columns}
                                    data={notes} noHeader
                                    theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"}
                                    pagination
                                    onChangePage={val => setPage(val)} paginationDefaultPage={page}
                                    paginationComponentOptions={{ rowsPerPageText: 'Items per page', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                                />
                        }
                    </FormCard> 
            {
                createPortal(<Dialog title={t("Update Note")} showDialog={notesModal} onClose={() => setNotesModal(false)}>
                    <NotesDialog onClose={() => setNotesModal(false)} props={editData} type={'update'} />
                </Dialog>, document.body)
            }
            {
                createPortal(<Dialog title={t("Delete Note")} showDialog={deletePopup} onClose={() => setDeletePopup(false)}>
                     <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Are you sure you want to delete this notes")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setDeletePopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => deleteNotes()}>{t("Yes")}</button>
                    </div>
                </div>
                </Dialog>, document.body)
            }
        </div>
    )
}

export default Campaign