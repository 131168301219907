import { useCallback, useMemo, useState,useEffect, useRef} from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
// import { caregivers } from "../../data/dummy/caregiversData"
import { datatableTheme } from '../../data/dataTableTheme'
import { useSelector } from 'react-redux'
// import { Link } from "react-router-dom"
import { FaUserEdit ,FaPhoneAlt,FaEnvelope} from 'react-icons/fa'
import InlineTextField from '../../components/snippets/InlineTextField'
import API from '../../axios/API'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import NumberFormat from 'react-number-format'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import FlowText from '../../components/snippets/FlowText'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const Clienlist = () => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const { userDetails } = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false);
    const [caregiversList, setCaregiversList] = useState([])
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [perPage, setPerPage] = useState(10);
    const [sizePage] = useState([10]);
    const [totalRows, setTotalRows] = useState(0);
    const [column,setColumn] = useState()
    const [sortDirection,setSortDirection] = useState()
    const [triggerAll, setTriggerAll] = useState(false)
    const organization = useSelector(state => state.auth.organization)
    const { t } = useTranslation()
    let history = useHistory()
    let orgId = localStorage.getItem('orgid')
    const prevPage = useRef('');
    const [statusFilter, setStatusFilter] = useState()
    const [records,setRecords] = useState(false)
    useEffect(() => {
        
        setLoading(true);
        setCaregiversList([])
        var pageView = sessionStorage.getItem('pageView');
        if (pageView) {
            prevPage.current = pageView
            setPage(parseInt(pageView));
            sessionStorage.removeItem('pageView');
            if (prevPage.current === 1 || page === 1) {
                let apiPath = `page=${prevPage.current || page}&size=${perPage}`
                API.get(`organizations/${orgId}/care-managers/${userDetails.caremanagerId}/clients?${apiPath}`).then((res) => {
                    setTotalRows(res.data.page.totalElements)
                    setCaregiversList(res.data._embedded.immutableCaremanagerResponseList)
                    setLoading(false)
                }).catch(e => {
                    console.log(e)
                }).finally(() => setLoading(false))
            }
        } else {
            prevPage.current = page
            let apiPath = filter&& filter !== undefined ? `page=${prevPage.current}&size=${perPage}&search=${filter}`:`page=${prevPage.current}&size=${perPage}`
            API.get(`organizations/${orgId}/care-managers/${userDetails.caremanagerId}/clients?${apiPath}`).then((res) => {
                setTotalRows(res.data.page.totalElements)
                setCaregiversList(res.data._embedded.immutableCaremanagerResponseList)
                setLoading(false)
            }).catch(e => {
                console.log(e)
            }).finally(() => setLoading(false))
        }

    }, [organization, page,triggerAll])// eslint-disable-line

    const toggleFormEdit = (value,caseid) => {
        sessionStorage.setItem('pageView', prevPage.current.toString());
        history.push({pathname:`/client/${value}`,state:{caseId: caseid,editMode:true,service:false}})        
    }

    const colums = useMemo(() => {
        return [
            { name: t('Client Name'), selector:'clientName', cell: row =><div data-private="lipsum" data-tag="allowRowEvents"> { row.firstName !== null ?row.firstName:'' } { row.lastName !== null ?row.lastName:'' }</div>, sortable: true,wrap: true, sortField: 'SORT_CLIENT_NAME' },
            { name: t('Caremanager Name'), selector:'caremangerName', cell: row => `${ row.cmFirstName !== null ?  row.cmFirstName :''} ${ row.cmLastName !== null ? row.cmLastName:'' }`, sortable: true, hide: 'sm',wrap: true, sortField: 'SORT_CAREMANAGER_NAME' },
            { name: t('Care Receiver SubscriberID'), selector: 'subscriberId', sortable: true, hide: 'sm' ,wrap: true, initialActive: true, sortField: 'SORT_SUBSCRIBER_ID' },
            { name: t('County'), selector: 'county', sortable: true,wrap: true, sortField: 'SORT_CARE_RECEIVER_COUNTY'},
            // { name: 'Phone', selector:row=>`${numberFormatting(row.phone)}`, sortable: true, hide: 'md'},
            // {name : t('Phone'), selector:row=>{return(<NumberFormat className={`pb-1.5 bg-transparent pr-10 w-full outline-none`} value={row.phone} format="###-###-#####" displayType={'text'}/>)}, sortable:true, hide:'md'},
            {name:t('Phone'),selector:row=>{return <div data-private="lipsum" style={{display:'flex',fontSize:'13px',marginTop:'15px'}}>{row.phone === "" || row.phone === null ? '':<a href={`tel: +1${row.phone.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor p-2 rounded-lg text-white" title={t('Call')}>
            <FaPhoneAlt size="10px" style={{margin:'auto'}}/>
            </a>} &nbsp;&nbsp;<NumberFormat data-tag="allowRowEvents" value={row.phone} format="###-###-#####" displayType={'text'}/></div>},sortable: true,wrap: true, sortField: 'SORT_PHONE'},
            // { name: t('Email'), selector: 'email', sortable: true, hide: 'md' },
            {name:t('Email'),cell:row=>{return <div data-private="lipsum" style={{display:'flex',fontSize:'13px',marginTop:'15px'}}>{row.email === "" || row.email === null ? '' :<a href={`mailto: ${row.email}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Mail')}>
            <FaEnvelope size="10px" style={{margin:'auto'}} />
        </a>}&nbsp;&nbsp;<span data-tag="allowRowEvents">{row.email}</span></div>},grow:1,sortable: true,wrap: true, sortField: 'SORT_EMAIL'},
            { name: t('Action'), button: true,cell: row => <button title={t("Edit")} onClick={() => toggleFormEdit(row.pplId,row.caseId) }><FaUserEdit size="20px" className="text-gray-600 dark:text-gray-300" /></button> }
        ]
    }, [t]) // eslint-disable-line
    const SubHeader = useMemo(() => (
        <div className="flex items-center">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} onEnter={() => searchRecords()} onClick={() => searchRecords()} onClose={() => closeFilter()} />
            {/* <AddButton to="/addCase" title="Add New Caregiver" /> */}
        </div>
    ), [filter]) // eslint-disable-line
    const filteredcasedetails = useMemo(() => {
        let filterSplit = filter.toString().toLowerCase().split(' ')
        return caregiversList.filter(c => filter === '' || filter === null || filterSplit)
    }, [caregiversList, filter])
    const ExpandComponent = useCallback(row => {
        return (
            <div className="bg-bluegray-300 dark:bg-ldark py-2 px-3" key={row.data.id}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm">{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>
        )
    }, [])
    useEffect(() => {
        const element = document.getElementById('column-undefined');
        if(element!=null){
            console.log(element.setAttribute('id','Email Header'))
        }
        // console.log("element", element)
        // if (element) {
        //     element.forEach((e) => {
        //         console.log("element e", e)
        //         e.setAttribute("role", "gridcell")  
        //         const node = document.createAttribute("role");
        //         node.value = "gridcell";
        //         e.attributes.setNamedItem(node)
        //         e.setAttribute('role','gridcell')
        //     })
        // }
    })

    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })

    const closeFilter = () => {
        setPage(1)
        setStatusFilter()
        setColumn()
        setSortDirection()
        // changeStatus('All Status')
        setTriggerAll(!triggerAll)
        setRecords(false)
    }

    const closeFilterStatus = () => {
        setRecords(false)
        setPage(1)
        setStatusFilter()
        setColumn()
        setSortDirection()
        setFilter('')       
        setTriggerAll(!triggerAll)
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        setCaregiversList([])
        setPerPage(sizePage);
        let apiPath = `page=${page}&size=${newPerPage}`
        API.get(`organizations/${orgId}/care-managers/${userDetails.caremanagerId}/clients?${apiPath}`).then((res) => {
            if (res.status === 200) {
                setTotalRows(res.data.page.totalElements)
                setPerPage(res.data.page.size)
                setCaregiversList(res.data._embedded.immutableCaseList)
            }
        })
        setLoading(false);
        // sizeHandling(newPerPage)
    };

    if ((!loading && history.location.state === undefined && (filteredcasedetails.length >= 10 || caregiversList.length >= 10)) || (!loading && (filteredcasedetails.length >= 10 || caregiversList.length >= 10))) {
        if (page > 1) {
            // document.getElementById('pagination-next-page')?.removeAttribute('disabled')
            document.getElementById('pagination-first-page')?.removeAttribute('disabled')
            document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
                setPage(1)
            })
        }
        document.getElementById('pagination-next-page')?.addEventListener(('click'), () => {
            setPage(page + 1)
        })
        if (page > 1) {
            document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
            document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
                setPage(page - 1)
            })
        }
    }  else if (!loading && page > 1 && filteredcasedetails.length < 10 && caregiversList.length < 10) {
        document.getElementById('pagination-previous-page')?.removeAttribute('disabled')
        document.getElementById('pagination-previous-page')?.addEventListener(('click'), () => {
            setPage(page - 1)
        })
        document.getElementById('pagination-first-page')?.removeAttribute('disabled')
        document.getElementById('pagination-first-page')?.addEventListener(('click'), () => {
            setPage(1)
        })
    } else if (filteredcasedetails.length < 10 && caregiversList.length < 10 && !loading && history.location.state === undefined) {
        document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    }else {
        document.getElementById('pagination-next-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-first-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-last-page')?.setAttribute('disabled', true)
        document.getElementById('pagination-previous-page')?.setAttribute('disabled', true)
    }

    const searchRecords = () => {
        if (filter.length > 0) {
            if (statusFilter !== 'All Status' && statusFilter !== undefined) {
                API.get(`organizations/${orgId}/care-managers/${userDetails.caremanagerId}/clients?page=1&size=${perPage}&status=${statusFilter}&search=${filter}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        setCaregiversList([])
                        setCaregiversList(res.data._embedded.immutableCaremanagerResponseList)
                        setTotalRows(res.data.page.totalElements)
                    }else{
                        setRecords(true)
                        setCaregiversList([])
                    }
                })
            } else{
                API.get(`organizations/${orgId}/care-managers/${userDetails.caremanagerId}/clients?page=1&size=${perPage}&search=${filter}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        // setPage(1)
                        setCaregiversList([])
                        setCaregiversList(res.data._embedded.immutableCaremanagerResponseList)
                        setTotalRows(res.data.page.totalElements)
                    }else{
                        setRecords(true)
                        setCaregiversList([])
                    }
                })
            }
        } 
        else  {
            if(statusFilter && statusFilter !== 'All Status'){
                let Apipath = statusFilter ? `page=1&size=${perPage}&status=${statusFilter}` : `page=${page}&size=${perPage}`
                API.get(`organizations/${orgId}/care-managers/${userDetails.caremanagerId}/clients?${Apipath}`).then((res) => {
                    if (res.status === 200 && res.data._embedded) {
                        setPage(1)
                        setCaregiversList(res.data._embedded.immutableCaremanagerResponseList)
                        setTotalRows(res.data.page.totalElements)
                    }else{
                        setRecords(true)
                        setCaregiversList([])
                    }
                })
            }
          
        }
    }
 
    const handleSort = async (column, sortDirection) => {
        setColumn(column.sortField)
        setSortDirection(sortDirection.toUpperCase())
        let apiPath = `page=1&size=${perPage}&sort=${column.sortField}`;        
        API.get(`organizations/${orgId}/care-managers/${userDetails.caremanagerId}/clients?${apiPath},${sortDirection.toUpperCase()}`).then((res) => {
            if (res.status === 200) {
                setCaregiversList(res.data._embedded.immutableCaremanagerResponseList)
                setTotalRows(res.data.page.totalElements)
            }
        })
        //   setData(remoteData);
    };

    return (
        <div className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg relative">
            {
                loading ? 
                <DatatableLoader /> :
                <DataTable title="Carereceiver List" columns={colums} data={filteredcasedetails} onRowClicked={filteredcasedetails => {
                    history.push({pathname:`/client/${filteredcasedetails.pplId}`,state:{caseId:filteredcasedetails.pplId}})
                    sessionStorage.setItem('pageView', prevPage.current.toString());
                 }} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination paginationServer={true} noHeader highlightOnHover selectableRowsHighlight persistTableHead subHeader onSort={handleSort} 
                    sortServer paginationRowsPerPageOptions={sizePage} paginationTotalRows={totalRows} onChangeRowsPerPage={handlePerRowsChange} subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} paginationComponentOptions={{ rowsPerPageText: 'Items per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }} noDataComponent={t('There are no records to display')}/>
                    // <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>
                    
            }
           {records ?<div className='flex justify-center'> <span onClick={() => closeFilterStatus()}><button aria-label="Back to All Cases" className='bg-tcolor flex px-6 py-0.5 mr-3 mt-3 items-center text-sm'>{t("Click here to Client Lists")}</button> </span></div> : ''}

        </div>
    )
}
 
export default Clienlist