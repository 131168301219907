
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import API from '../../axios/API'
import * as Yup from 'yup';
// import FieldSelect from '../../components/snippets/FieldSelect'
import FieldInput from '../../components/snippets/FieldInput'
import { AnimatePresence, motion } from 'framer-motion';
import { PopInAnim } from '../../commons/anims';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../store/theme/actions';
import { setCloseCaseRender,refreshTrigger} from  '../../store/auth/actions';
import { useTranslation } from "react-i18next"
import CloseSelect from '../../components/snippets/CloseSelect';
import { useHistory } from 'react-router-dom'
const initialFormValues = {
    reason: '',
    otherReason: ''
}


const ClosedCaseDialog = ({ onClose,caseIds }) => {

    const [closeReason, setCloseReason] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [inputEnable, setInputEnable] = useState(false)
    const render = useSelector(state =>state.auth.closeCaseRender)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')
    const history = useHistory()
    let lang = localStorage.getItem('language')
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if(value === undefined || value === '') return true
            let r = /^[^-\s][a-zA-Z_0-9\s-]+$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'),t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    
    useEffect(() => {
        let details = {
            caseId: caseIds,
        }
        API.post(`menus/close-case-reasons?lang=${lang}`,details).then(res => {
            setCloseReason(res.data.body.map(c => ({ text: c.name, value: c.value })));
            setDataLoaded(true)
        }).catch(e => {
            console.log(e)
        })
    }, []) // eslint-disable-line

    const reasonFormSchema = Yup.object().shape({
        reason: Yup.string().required(t("Reason is a required field")),
        otherReason: Yup.string().validateDescription().min(5,t("Reason should be min 5 character.")).max(250,t("Reason should be max 250 character")).nullable()
    })

    const reasonForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: reasonFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            submitForm()
        }
    })

    const reasonSelect = (values) => {
        if (values === 9) {
            setInputEnable(true)
        } else {
            setInputEnable(false)
            reasonForm.setValues({...reasonForm,reason:values,otherReason : ''})

        }
    }

    
    const submitForm = () => {
        if((reasonForm.values.reason === 9) && !reasonForm.values.otherReason){
            dispatch(setAlert({title:t('Warning'),subtitle:t('Other Reason is empty'),type:'warning',active:true}))
        }else{
            let details = {
                caseId: caseIds,
                closedReasonId: reasonForm.values.reason,
                otherClosedReason: reasonForm.values.otherReason
            }
            let pageRefresh=true;
            API.post(`/cases:close?orgId=${orgId}`,details).then(res=>{
                if(history.location.state?.state?.subId!=="" && history.location.state?.state?.subId!== undefined){
                    API.get(`organizations/${orgId}/cases:search?subId="${history.location.state?.state?.subId}"`).then((res)=>{
                        if(res.status === 200){ 
                            history.push({state:{state:{list:res.data.body,from:"GeneralSearch"}}})   
                            dispatch(setAlert({title:t('Success'),subtitle:t('Case Closed Successfully'),type:'success',active:true}))
                            dispatch(setCloseCaseRender(render+1));
                            dispatch(refreshTrigger(pageRefresh))
                            onClose();            
                        }
                    })
                }else{
                    dispatch(setAlert({title:t('Success'),subtitle:t('Case Closed Successfully'),type:'success',active:true}))
                    dispatch(setCloseCaseRender(render+1));
                    dispatch(refreshTrigger(pageRefresh))
                    onClose();   
                }
                
            }).catch(e=>{
                console.log(e)
            }).finally(()=>onClose)
        }
    }

    return (
        <div className="py-2 w-full">
            <div className="px-4 mt-2">
            <CloseSelect ariaLabel={'Reason'} label={t("Reason")} fieldMeta={reasonForm.getFieldMeta('reason')} fieldHelper={reasonForm.getFieldHelpers('reason')} fieldProps={reasonForm.getFieldProps('reason')} options={closeReason} edit={true} dataLoaded={dataLoaded} onChange={reasonSelect} />
                {/* <FieldSelect label={t("Reason")} fieldMeta={reasonForm.getFieldMeta('reason')} fieldHelper={reasonForm.getFieldHelpers('reason')} fieldProps={reasonForm.getFieldProps('reason')} options={closeReason} edit={true} position="bottom" dataLoaded={dataLoaded} onChange={reasonSelect} /> */}
                <AnimatePresence>
                    {
                        inputEnable &&
                        <motion.div variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" className="mt-4">
                            <FieldInput ariaLabel={"Type a reason"} type="textarea" label={t("Type a reason")} autoComplete='off' fieldMeta={reasonForm.getFieldMeta('otherReason')} fieldProps={reasonForm.getFieldProps('otherReason')} edit={true} dataLoaded={dataLoaded} />
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
            <div className="flex justify-between mt-8 mx-2">
                <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-700 dark:text-red-300 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t("Cancel")}</button>
                <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => reasonForm.submitForm()}>{t("Save")}</button>
            </div>
        </div>
    )

}

export default ClosedCaseDialog