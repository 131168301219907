import { useFormik } from "formik"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import * as Yup from 'yup'
import { setAlert } from "../../store/theme/actions"
import API from '../../axios/API'
import { datatableTheme } from '../../data/dataTableTheme'
import DataTable, { createTheme } from 'react-data-table-component'
import { FaEdit, FaTrash } from "react-icons/fa"
import Dialog from "../../components/snippets/Dialog"
import NotesDialog from '../Dialogs/NotesDialog'
import { useSelector } from "react-redux"
import { setNotesAction } from "../../store/auth/actions"
import DatatableLoader from "../../components/snippets/DatatableLoader"
import { createPortal } from "react-dom"
import FlowText  from "../../components/snippets/FlowText"
import { useTranslation } from "react-i18next"
import Dialognotes from "../../components/snippets/Dailognotes"
// import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import axios from "axios"

const initialValues = {
    description: ''
}

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)



const ViewNotes = ({ caseId ,onClose}) => {

    const [tableLoading, setTableLoading] = useState(false)
    const [notes, setNotes] = useState([])
    const [editData, setEditData] = useState()
    const dispatch = useDispatch()
    const [notesModal, setNotesModal] = useState(false)
    const { themeMode } = useSelector(state => state.theme)
    const [noteAction,setNoteAction] = useState(0)
    const notesAction = useSelector(state =>state.auth.notesAction)
    const userDetails  = useSelector(state => state.auth.userDetails)
    const organization = useSelector(state =>state.auth.organization)
    const [deletePopup,setDeletePopup] = useState(false)
    const [notesId,setNotesId] = useState()
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')

    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'), t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    useEffect(() => {
        setTableLoading(true)
        axios.get(`${process.env.REACT_APP_API_BASE_URL}cases/${caseId}/notes`,{
            headers: {
                'Authorization': localStorage.getItem('token'), 
                'Accept': 'application/json'
            }
        }).then(res => {
            if (res.status === 200) {
                setNotes(res.data.body)
                setTableLoading(false)
            }
        }).catch(e => {
            console.log(e)
        }).finally()
    }, [notesAction,noteAction, caseId])

    const notesFormSchema = Yup.object().shape({
        description: Yup.string().validateDescription().required(t("Notes is a required field")),
    })

    const notesForm = useFormik({
        initialValues: initialValues,
        validationSchema: notesFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            addNotes()
        }
    })

    const addNotes = () => {
        let details =
        {
            "notes": notesForm.values.description
        }
        API.post(`cases/${caseId}/notes?orgId=${orgId}`, details).then((res) => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t("Notes saved successfully"), type: "success", active: true }))
                dispatch(setNotesAction(notesAction + 1))
                setNoteAction(noteAction+1)
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            } else {

            }
        }).catch(e => {
            console.log(e)
        }).finally(() => {
            notesForm.resetForm()
        })
    }
    // const toggleFormEdit = () => {
    //     if (editForm) notesForm.submitForm()
    //     if (notesForm.isValid || !editForm) setEditForm(!editForm)
    // }

    const columns = [
        {
            name: t('Date'),
            selector: row => ` ${followdate(row.date)}`,
            grow:1
        },
        {
            name: t('Created By'),
            selector: 'createdBy', 
            wrap: true
        },
        {
            name: t('Notes'), center: true,grow:1,
            selector: row => { return (<div className="ql-editor" style={{ wordWrap: 'break-word', padding: '10px', whiteSpace: 'normal', width: '100%' }} dangerouslySetInnerHTML={{ __html: row.description }}></div>) }
        },
        {
            name: t('Action'), button: true, grow: 1, width: '200px', cell: row => <>
                {((!row.systemGenerated) && (row.createdById === userDetails.caremanagerId)) ? <button onClick={() => ((userDetails.orgId === parseInt(organization) && (userDetails.role.includes('caremanager') || userDetails.role.includes('admin') || userDetails.role.includes('screener')) || 
                (userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_cm') || userDetails.role.includes('dynamic_admin') || userDetails.role.includes('dynamic_scr'))))) ? openNotesDialog(row) : ''}><FaEdit title={t("Edit")} style={{ marginLeft: '10', marginRight: '10' }} size="20px" className="text-gray-600 dark:text-gray-300" /></button> : ''}
                {((organization && userDetails && userDetails.orgId === parseInt(organization) && (!row.systemGenerated) && userDetails.role.includes('admin')) || ((!row.systemGenerated) && (row.createdById === userDetails.caremanagerId))) ? <button onClick={() => ((userDetails.orgId === parseInt(organization) && (userDetails.role.includes('caremanager') || userDetails.role.includes('admin') || userDetails.role.includes('screener')) || 
                (userDetails.orgId !== parseInt(organization) && (userDetails.role.includes('dynamic_cm') || userDetails.role.includes('dynamic_admin') || userDetails.role.includes('dynamic_scr'))))) ? openDeletePopup(row) : ''}><FaTrash title={t("Delete")} style={{ marginLeft: '10', marginRight: '10' }} size="20px" className="text-gray-600 dark:text-gray-300" /></button> : ''}
            </>
        }
        // {
        //     name: t('Action'), button: true, grow: 1, cell: row => <>
        //         <button onClick={() => openNotesDialog(row)}>
                    
                    
        //             <FaEdit title={t("Edit")} style={{marginLeft:'10',marginRight:'10'}} size="20px" className="text-gray-600 dark:text-gray-300" /></button>
        //         {(row.createdById === userDetails.caremanagerId) ? <button onClick={() => openDeletePopup(row)}><FaTrash title={t("Delete")} style={{marginLeft:'10',marginRight:'10'}} size="20px" className="text-gray-600 dark:text-gray-300" /></button>:''}
        //     </>,
        // }
    ]

    const followdate =(value) => {
        if(value !== null && value){
            let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
        //     let followupdate = new Date(value)
        // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
        }else{
            return ''
        }
     }

    const openNotesDialog = (c) => {
        if (c !== undefined)
            setEditData({caseId:caseId,notes:c})
        setNotesModal(true)
    }

    const deleteNotes = () => {
        API.delete(`cases/${caseId}/notes/${notesId}?orgId=${orgId}`).then(res => {
            if (res.status === 200) {
                dispatch(setAlert({ title: t('Success'), subtitle: t('Notes deleted successfully'), type: 'success', active: true }))
                setNoteAction(noteAction+1)
                // onClose();;
            } else if (res.status === 400) {
                dispatch(setAlert({ title: t('Error'), subtitle: 'Something went wrong', type: 'error', active: true }))
            } else {

            }
        }).catch(e => {
            console.log(e)
        }).finally(()=>setDeletePopup(false))
    }
    const openDeletePopup = (values)=>{
        setNotesId(values.notesId)
        setDeletePopup(true)
    }
    return (
        <div style={{ maxHeight: '60vh', overflow:"auto" }}>
          
            {
                notes.length > 0 ?
                    <div className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg ">
                        {
                            tableLoading ?
                                <DatatableLoader />
                                :
                                <DataTable injectClass="mt-12"
                                    columns={columns}
                                    data={notes} noHeader
                                    theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"}
                                    style={{width: '900px'}}
                                    
                                />
                        }
                    </div>: <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('No records to display')}/></div>
                        
            }
            {
                createPortal(<Dialognotes title={t("Update Note")} showDialog={notesModal} onClose={() => setNotesModal(false)}>
                    <NotesDialog onClose={() => setNotesModal(false)} props={editData} type={'update'} />
                </Dialognotes>, document.body)
            }
            {
                createPortal(<Dialog title={t("Delete Note")} showDialog={deletePopup} onClose={() => setDeletePopup(false)}>
                     <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t(`Are you sure you want to delete this notes`)} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setDeletePopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => deleteNotes()}>{t("Yes")}</button>
                    </div>
                </div>
                </Dialog>, document.body)
            }
        </div>
    )
}

export default ViewNotes