import { useState } from "react"
import Tab from "../../components/snippets/Tabs/Tab"
import TabGroup from "../../components/snippets/Tabs/TabGroup"
import TabButtonGroup from "../../components/snippets/Tabs/TabButtonGroup"
import Info from "./Info"
import TabButton from "../../components/snippets/Tabs/TabButton"
import ChangePassword from "./ChangePassword"
import Notifications from "./Notifications"
import { useSelector } from "react-redux"
import { useTranslation } from 'react-i18next'



const Notification = () => {

    const [tab, setTab] = useState(0)
    const userDetails = useSelector(state => state.auth.userDetails)
    const organization = useSelector(state => state.auth.organization)
    const { t } = useTranslation()
    return (
        <div>
            <div className="text-center">
                <TabButtonGroup>
                    <TabButton index={0} value={tab} setValue={setTab}>
                       {t("Profile")}
                    </TabButton>
                    {/* <TabButton index={1} value={tab} setValue={setTab}>
                       {t("Change Password")}
                    </TabButton> */}
                  {
                      
                     ((userDetails.orgId === parseInt(organization)) && (userDetails.role.includes('admin') || userDetails.role.includes('caremanager'))) || ((userDetails.orgId !== parseInt(organization)) && (userDetails.role.includes('dynamic_admin') || userDetails.role.includes('dynamic_cm'))) ? <TabButton index={2} value={tab} setValue={setTab}>
                      {t("Notification")}
                   </TabButton> :   ''
                  }
                  {/* {
                      ((userDetails.orgId !== parseInt(organization) && userDetails.role.includes('screener')&&userDetails.role.length===1)) || (((userDetails.role.includes('referrer'))&&userDetails.role.length===1) || ((userDetails.role.length === 2 && userDetails.role.includes('screener') && userDetails.role.includes('referrer'))) || ((userDetails.role.length === 2 && userDetails.role.includes('referrer') && userDetails.role.includes('dynamic_ref')))
                      || (userDetails.orgId !== parseInt(organization) && userDetails.role.length === 2 && userDetails.role.includes('admin') && userDetails.role.includes('dynamic_ref')))
                      ? '' :   <TabButton index={2} value={tab} setValue={setTab}>
                     {t("Notification")}
                  </TabButton>
                  } */}
                </TabButtonGroup>
            </div>
            <TabGroup value={tab}>
                <Tab index={0} value={tab}>
                    <Info />
                </Tab>
                <Tab index={1} value={tab}>
                    <ChangePassword />
                </Tab>
                <Tab index={2} value={tab}>
                    <Notifications />
                </Tab>
            </TabGroup>
        </div>
    )
}

export default Notification