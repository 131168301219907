import { FaSave } from 'react-icons/fa'
import {FiPlus} from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

const AddFormButton = ({injectClass, edit=false, handleClick, handleCancel}) => {
    const { t } = useTranslation()
    
    return (
        <div className={`${injectClass}`}>
            <button className={`h-8 w-8 ${edit ? 'bg-bluegray-500 dark:bg-bluegray-200' : 'bg-bluegray-400 dark:bg-bluegray-500'} transition-all duration-200 rounded-full ring-4 ring-offset-2 ring-offset-blue-50 dark:ring-offset-mainbg ring-blue-50 dark:ring-mainbg outline-none focus:outline-none`} onClick={handleClick} title={edit ? t('Save') : t('Add')}>
                { !edit && <FiPlus size="16px" className="m-auto text-white" /> }
                { edit && <FaSave size="16px" className="m-auto text-white dark:text-gray-800" /> }
            </button>
            { (edit && typeof handleCancel === 'function') &&
                <button className={`h-8 w-8 ${edit ? 'bg-bluegray-500 dark:bg-bluegray-200' : 'bg-bluegray-400 dark:bg-bluegray-500'} transition-all duration-200 rounded-full ring-4 ring-offset-2 ring-offset-blue-50 dark:ring-offset-mainbg ring-blue-50 dark:ring-mainbg outline-none focus:outline-none ml-2`} onClick={handleCancel} title={t('Close')}>
                    { edit && <MdClose size="16px" className="m-auto text-white dark:text-gray-800" /> }
                </button>
            }
        </div>
    )
}

export default AddFormButton