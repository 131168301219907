import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Dialog from "../../components/snippets/Dialog"
import Tab from "../../components/snippets/Tabs/Tab"
import TabButton from "../../components/snippets/Tabs/TabButton"
import TabButtonGroup from "../../components/snippets/Tabs/TabButtonGroup"
import TabGroup from "../../components/snippets/Tabs/TabGroup"
import AddService from "./AddService"
import AdHoc from "./AdHoc"
import FindHelp from "./FindHelp"
import SearchService from "./SearchService"
// import { useSelector } from "react-redux"

const AdditionalServiceSelector = ({ active, adhoc, onClose, onAdd,onAddCategoryId,careplanId }) => {
    const [title, setTitle] = useState('Search')
    const [tab, setTab] = useState(0)
    const { t } = useTranslation()
    // const userDetails = useSelector(state=>state.auth.userDetails)
    const auntberthInd =JSON.parse(localStorage.getItem('userDetails'))
    
    useEffect(() => {
        setTitle('Search')
        setTab(0)
    }, [active])
    
    return (
        <Dialog showDialog={active} title={title} onClose={onClose} injectClass="min-w-[70vw]">
            <div className="flex flex-col gap-4 w-full">
                    <div>
                        <div className="flex justify-center">
                            <TabButtonGroup>
                                <TabButton index={0} value={tab} setValue={val => setTab(val)}>
                                    {t('Search')}
                                </TabButton>
                                <TabButton index={1} value={tab} setValue={val => setTab(val)}>
                                    {t('Add Resource')}
                                </TabButton>
                                <TabButton index={2} value={tab} setValue={val => setTab(val)}>
                                    {t('CG Specific')}
                                </TabButton>
                                { auntberthInd.auntBerthaInd === false ? '' : <TabButton index={3} value={tab} setValue={val => setTab(val)}>
                                    {t('findhelp')}
                                </TabButton>
                                   }
                            </TabButtonGroup>
                        </div>
                        <div>
                            <TabGroup value={tab}>
                                <Tab index={0} value={tab}>
                                    <SearchService additional={true} onAdd={val => onAdd(val)} careplanId={careplanId}   />
                                </Tab>
                                <Tab index={1} value={tab}>
                                    <AddService additional={true}  onAdd={val => onAdd(val)} careplanId={careplanId}   />
                                </Tab>
                                <Tab index={2} value={tab}>
                                    <AdHoc  additional={true}  onAdd={val => onAdd(val)}  careplanId={careplanId}/>
                                </Tab>
                                <Tab index={3} value={tab}>
                                    <FindHelp additional={true} onAdd={val => onAdd(val)}  careplanId={careplanId}   />
                                </Tab>
                            </TabGroup>
                        </div>
                    </div>
            </div>
        </Dialog>
    )
}

export default AdditionalServiceSelector