import { useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {MdInfoOutline} from 'react-icons/md'
import { AnimatePresence, motion } from 'framer-motion'
import { useHistory } from "react-router-dom";
// import { setAlert } from '../store/theme/actions'
// import { useDispatch } from 'react-redux'


const languages = [
    {
        title: 'English',
        code: 'en'
    },
    {
        title: 'Spanish',
        code: 'es'
    }
]

const ReportSelector = ({injectPopupClass, originX, originY, onFocus, selectCallBack}) => {

    const [showSelector, setShowSelector] = useState(false)
    const { i18n } = useTranslation()
    // const dispatch = useDispatch()
    // const setLanguage = lang => i18n.changeLanguage(lang)
    const hideShowSelector = () => setShowSelector(false)
    const { t } = useTranslation()
    const history = useHistory();
    const menuAnim = useMemo(() => ({
        hidden: {
            scale: 0,
            opacity: 0
        },
        visible: {
            scale: 1,
            opacity: 1,
            originX: originX,
            originY: originY
        }
    }), [originX, originY])
        useMemo(() => {
        let l = languages.filter(c => c.code === i18n.language)
        return l.length > 0 ? l[0].code : languages[0].code
    }, [i18n.language])//eslint-disable-line
    useLayoutEffect(() => {
        if(showSelector) {
            if(typeof selectCallBack == 'function') selectCallBack('report')
            setTimeout(() => {
                window.addEventListener('click', hideShowSelector)
            }, 100)
        } else window.removeEventListener('click', hideShowSelector)
        return () => window.removeEventListener('click', hideShowSelector)
    }, [showSelector])
    return (
        <div className="relative">
            <div className="cursor-pointer relative" tabIndex="0" onKeyDown={(event)=> event.key === 'Enter' && setShowSelector(!showSelector)} onClick={() => setShowSelector(true)} title={t('Info')} onFocus={onFocus}>
                {/* <span className="absolute text-[10px] leading-none -top-3 -right-2.5 bg-tcolor text-white px-1 py-1 rounded-full">{langCode}</span> */}
                <MdInfoOutline size="20px" className="text-gray-800 dark:text-white" />
            </div>
            <AnimatePresence>
                { showSelector &&
                    <motion.div variants={ menuAnim } initial="hidden" animate="visible" exit="hidden" className={`absolute bg-bluegray-500 dark:bg-ldark py-2 w-48 rounded-lg text-left ${injectPopupClass}`}>
                        {/* <div className="text-xs text-gray-300 pl-2">Language</div> */}
                        {/* <div className="pt-2">
                            <div className="text-xs text-gray-300 pl-2 hover:bg-bluegray-600 text-white text-sm cursor-pointer flex items-center justify-between"  onClick={()=>dispatch(setAlert({title:t('Warning'),subtitle:t('Work in progress'),type:'warning',active:true}))}>{t("Help & Support")}</div>
                            
                        </div> */}
                        <div className="pt-2">
                            <div className="text-xs text-gray-300 pl-2 hover:bg-bluegray-600 text-white text-sm cursor-pointer flex items-center justify-between" tabIndex="0" onKeyDown={(event)=> {event.key === 'Enter' && history.push('/releasenotes');setShowSelector(false)}}  onClick={()=>history.push('/releasenotes')}>{t("Release Notes")}</div>      
                        </div>
                        {/* <div className="pt-2">
                           
                           <div className="text-xs text-gray-300 pl-2 hover:bg-bluegray-600 text-white text-sm cursor-pointer flex items-center justify-between"  onClick={()=>dispatch(setAlert({title:'Warning',subtitle:'Work in progress',type:'warning',active:true}))} >Reports</div>
                           
                       </div>
                       <div className="pt-2">
                           
                           <div className="text-xs text-gray-300 pl-2 hover:bg-bluegray-600 text-white text-sm cursor-pointer flex items-center justify-between"  onClick={()=>dispatch(setAlert({title:'Warning',subtitle:'Work in progress',type:'warning',active:true}))} >Child Org Report</div>
                           
                       </div> */}
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}

export default ReportSelector