import { useTranslation } from 'react-i18next'
import { RiVipLine } from "react-icons/ri";

const VipCase = ({injectClass, disabled=false}) => {
    const { t } = useTranslation()

    return (
        <div className={`${injectClass}`}>
            <button disabled={disabled} className={` bg-gray-200 dark:bg-black `}  title={t('VIP')}>
                { < RiVipLine size="23px" className="m-auto text-green-500" /> }
            </button>
        </div>
    )
}

export default VipCase