import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useMemo } from "react"
import { tableSettingsAnim } from "../../commons/anims"
import {  setAlert } from "../../store/theme/actions"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

const valueStat = {}
const PopoverMenu = ({ active, onClose, children=valueStat, title, origin }) => {

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const closeMenu = () => {
        document.body.removeEventListener('click', closeMenu)
        if(active && typeof onClose === 'function') onClose()
    }
    useEffect(() => {
        if(active) {
            if(children.filter(x =>x === undefined || x === '').length === 5){
                onClose()
                dispatch(setAlert({title:t('Warning'),subtitle:t('Please select an active case'), type: 'warning', active: true }))
            }
            setTimeout(() => {
                document.body.removeEventListener('click', closeMenu)
                document.body.addEventListener('click', closeMenu)
            }, 200)
        } else document.body.removeEventListener('click', closeMenu)

        return () => document.body.removeEventListener('click', closeMenu)
    })
    const anim = useMemo(() => {
        let a = tableSettingsAnim
        switch (origin) {
            case 'topright':
                a.visible = {...a.visible, originX: 'left', originY: 'bottom'}
                break
            case 'topleft':
                a.visible = {...a.visible, originX: 'right', originY: 'bottom'}
                break
            case 'bottomright':
                a.visible = {...a.visible, originX: 'left', originY: 'top'}
                break
            case 'bottomleft':
                a.visible = {...a.visible, originX: 'left', originY: 'top'}
                break
            default:
                break
        }
        return a
    }, [origin])

    return (
        <AnimatePresence>
            { active && 
                <motion.div variants={anim} initial="hidden" animate="visible" exit="hidden" className={`absolute flex items-start flex-col text-gray-700 dark:text-gray-200 bg-bluegray-300 dark:bg-ldark py-2 z-[2] rounded w-64 shadow-xl ${active.position === 'bottom' ? 'top-full' : 'bottom-full'}`}>
                    <div className="text-[10px] ml-2 mb-2 text-gray-600 dark:text-gray-300 font-bold">{title}</div>
                    <div className="w-full">
                        {children}
                    </div>
                </motion.div>
            }
        </AnimatePresence>
    )
}

export default PopoverMenu