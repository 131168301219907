import { useEffect, useState,useLayoutEffect } from 'react'
import { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from "formik"
import * as Yup from 'yup';
import API from '../../axios/API'
import { setAlert } from '../../store/theme/actions'
import { setDemographic } from '../../store/auth/actions'
import axios from 'axios'
import { useTranslation } from "react-i18next"
import CloseSelect from '../../components/snippets/CloseSelect'
import FieldDate from '../../components/snippets/FieldDate'
import { useHistory } from 'react-router-dom'





createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)
const initialFormValues = {

    "caregiver":
     {
        'dischargeDate': null,
        'enlistmentDate': null,
        'rankId':0,
        'serviceId':0
    },
    "careReceiver":
    {
        'dischargeDate': null,
        'enlistmentDate': null,
        'rankId':0,
       ' serviceId':0
    }
}
const DavServiceDialog = ({props,cgId,crId,resource,onClose }) => {

    // const [loading, setLoading] = useState(true)
    let history = useHistory()
    const [formLoading, setFormLoading] = useState(false)
    const [editForm] = useState(true)
    const [dataLoaded] = useState(true)
    const dispatch = useDispatch()
    const [formData, setFormData] = useState(initialFormValues)
    const demographiccount = useSelector(state => state.auth.demographiccount)
    const [militaryService,setMilitaryService] = useState([])
    const [militaryRank,setMilitaryRank] = useState([])
    const {t} = useTranslation()
    let orgId = localStorage.getItem('orgid')


   
    

    
    useEffect(()=>{
        API.get(`menus/militaryService`).then((militaryresp) => {
                    setMilitaryService(militaryresp.data.body.militaryService.map(c => ({ text: c.name, value: c.value })))
                    setMilitaryRank(militaryresp.data.body.militaryRank.map(c => ({ text: c.name, value: c.value })))
                })
        
    },[]) //eslint-disable-line

    useEffect(() => {
        // setLoading(true)
        axios.all([API.get(`organizations/${orgId}/cases/${props}/militaryService`)]).then(axios.spread((userResp) => {
            if (userResp.status === 200) {
                if(userResp.data.body.caregiver.length > 0 && (userResp.data.body.caregiver[0] === undefined || userResp.data.body.caregiver[0].enlistmentDate === null)){
                    userResp.data.body.caregiver[0].enlistmentDate =''
                }
                if(userResp.data.body.caregiver.length > 0 && (userResp.data.body.caregiver[0] === undefined || userResp.data.body.caregiver[0].dischargeDate === null)){
                    userResp.data.body.caregiver[0].dischargeDate = ''
                }
                if(userResp.data.body.careReceiver.length > 0 && (userResp.data.body.careReceiver[0] === undefined || userResp.data.body.careReceiver[0].enlistmentDate === null)){
                    userResp.data.body.careReceiver[0].enlistmentDate = ''
                }
                if(userResp.data.body.careReceiver.length > 0 && (userResp.data.body.careReceiver[0] === undefined || userResp.data.body.careReceiver[0].dischargeDate === null)){
                    userResp.data.body.careReceiver[0].dischargeDate = ''
                }
                setFormData({...formData,caregiver:userResp.data.body.caregiver[0],careReceiver:userResp.data.body.careReceiver[0]})
                // setLoading(false)
                setFormLoading(false)
            }
        }))
    }, [])// eslint-disable-line
   

    const profileFormSchema = Yup.object().shape({
        caregiver:Yup.object({
        dischargeDate: Yup.date(),
        enlistmentDate: Yup.date(),
        rankId:Yup.string(),
        serviceId:Yup.string()
        }),
        careReceiver:Yup.object({
            dischargeDate: Yup.date(),
            enlistmentDate: Yup.date(),
            rankId:Yup.string(),
            serviceId:Yup.string()
            })
    })

    const profileForm = useFormik({
        initialValues: formData,
        validationSchema: profileFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
                setFormLoading(true)
                updateForm()
                setFormLoading(false)
        }
    })

      
        const Saveclient =()=>{
            // setNextButtonDisable(true)
            profileForm.submitForm()
        }

        const Skipclient=()=>{
            if(resource){
                dispatch(setAlert({ title: t('Success'), subtitle: t('DAV skipped successfully'), active: true, type: 'success' }))
                history.push(`/case/${props}/resourceUtlization`)
            }else{
                let details ={
                    "screener":0
                }
                API.post(`organizations/${orgId}/cases/${props}/assessments`,details).then(res => {
                    if (res.status === 200) {
                        history.push(`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}`)
                        dispatch(setAlert({ title: t('Success'), subtitle: t('Post Assessment created successfully'), type: 'success', active: true }))
                        onClose()
                    }
                }).catch(err => {
                    if (err.response.status === 406) {
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        history.push(`/casedata/${caseId}`)
                        onClose()        
                    }
                    console.log(err)
                })
                dispatch(setDemographic(demographiccount + 1))
            }
        }
    const updateForm = () => {
        let details = {
        "caregiver":[{
        'dischargeDate':  profileForm.values.caregiver !== undefined &&  profileForm.values.caregiver.dischargeDate ?  profileForm.values.caregiver.dischargeDate : null,
        'enlistmentDate': profileForm.values.caregiver !== undefined && profileForm.values.caregiver.enlistmentDate ? profileForm.values.caregiver.enlistmentDate : null,
        'rankId':profileForm.values.caregiver !== undefined ? profileForm.values.caregiver.rankId :0,
        'serviceId':profileForm.values.caregiver !== undefined ? profileForm.values.caregiver.serviceId :0,
        'cgId':profileForm.values.caregiver !== undefined ? cgId :0,
        "caseId":profileForm.values.caregiver !== undefined ? props:0,
        'cgType':profileForm.values.caregiver !== undefined ? true :false
    }],
        "careReceiver":[{
        'dischargeDate':profileForm.values.careReceiver !== undefined && profileForm.values.careReceiver.dischargeDate !== undefined ? profileForm.values.careReceiver.dischargeDate :null,
        'enlistmentDate': profileForm.values.careReceiver !== undefined && profileForm.values.careReceiver.enlistmentDate !== undefined ? profileForm.values.careReceiver.enlistmentDate : null,
        'rankId':profileForm.values.careReceiver !== undefined ? profileForm.values.careReceiver.rankId :0,
        'serviceId': profileForm.values.careReceiver !== undefined ? profileForm.values.careReceiver.serviceId:0,
        'crId':profileForm.values.careReceiver !== undefined ? crId :0,
        'caseId':profileForm.values.careReceiver !== undefined ? props:0,
        'crType':profileForm.values.careReceiver !== undefined ? true:false
    }]
        
        }
        API.patch(`organizations/${orgId}/cases/${props}/updateMilitaryService`, details).then((res) => {
            if (res.status === 200 && resource) {
                dispatch(setAlert({ title: t('Success'), subtitle: res.data.message, active: true, type: 'success' }))
                history.push(`/case/${props}/resourceUtlization`)
                onClose()
            }else{
                dispatch(setAlert({ title: t('Success'), subtitle: res.data.message, active: true, type: 'success' }))
                let details ={
                    "screener":0
                }
                API.post(`organizations/${orgId}/cases/${props}/assessments`,details).then(res => {
                    if (res.status === 200) {
                        history.push(`/case/${res.data.body.caseId}/assessment/${res.data.body.assessmentId}`)
                        dispatch(setAlert({ title: t('Success'), subtitle: t('Post Assessment created successfully'), type: 'success', active: true }))
                        onClose()
                    }
                }).catch(err => {
                    if (err.response.status === 406) {
                        dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                        history.push(`/casedata/${caseId}`)
                        onClose()        
                    }
                    console.log(err)
                })
                dispatch(setDemographic(demographiccount + 1))
            }
        }).catch(err => {
            if (err.response.status === 406) {
                dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
               
            } else {
                dispatch(setAlert({ title: t('Error'), subtitle: t('Something went wrong'), type: 'error', active: true }))
            }
        }).finally(() => onClose)
    }

    const setCgDate=(val)=>{
        let cgEn = new Date(val.value)
        let cgDis =profileForm.values !== undefined && profileForm.values.caregiver !== undefined && profileForm.values.caregiver.dischargeDate !== undefined && new Date(profileForm.values.caregiver.dischargeDate)
        if(cgEn > cgDis){
            profileForm.values.caregiver.dischargeDate = ''
        }
    }
    const setCrDate=(val)=>{
        let cgEn = new Date(val.value)
        let cgDis = profileForm.values !== undefined && profileForm.values.careReceiver !== undefined && profileForm.values.careReceiver.dischargeDate !== undefined && new Date(profileForm.values.careReceiver.dischargeDate)
        if(cgEn > cgDis){
            profileForm.values.careReceiver.dischargeDate = ''
        }
    }

    return (
        <div className="py-2 w-full">
            <div className="px-4 mt-2">
                <span className="text-lg font-medium text-gray-600 dark:text-white">{t('Caregiver')} :</span>
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 mt-1">
                <CloseSelect label={t("Service Branch")} fieldMeta={profileForm.getFieldMeta('caregiver.serviceId')} fieldHelper={profileForm.getFieldHelpers('caregiver.serviceId')} fieldProps={profileForm.getFieldProps('caregiver.serviceId')} edit={editForm} options={militaryService} position="top" dataLoaded={dataLoaded} />
                <CloseSelect label={t("Rank")} fieldMeta={profileForm.getFieldMeta('caregiver.rankId')} fieldHelper={profileForm.getFieldHelpers('caregiver.rankId')} fieldProps={profileForm.getFieldProps('caregiver.rankId')} edit={editForm} options={militaryRank} position="top" dataLoaded={dataLoaded} />
                <FieldDate type="date" label={t("Date of Enlistment")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('caregiver.enlistmentDate')} fieldProps={profileForm.getFieldProps('caregiver.enlistmentDate')} fieldHelper={profileForm.getFieldHelpers('caregiver.enlistmentDate')} edit={editForm} dataLoaded={dataLoaded} onChange={setCgDate(profileForm.getFieldProps('caregiver.enlistmentDate'))} />
                <FieldDate type="date" minDate={profileForm.values !== undefined && profileForm.values.caregiver !== undefined && profileForm.values.caregiver.enlistmentDate !== undefined && new Date(profileForm.values.caregiver.enlistmentDate)} label={t("Date of Discharge")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('caregiver.dischargeDate')} fieldProps={profileForm.getFieldProps('caregiver.dischargeDate')} fieldHelper={profileForm.getFieldHelpers('caregiver.dischargeDate')} edit={editForm} dataLoaded={dataLoaded} />
                 </div>
            </div>
            <div className="px-4 mt-2">
                <span className="text-lg font-medium text-gray-600 dark:text-white">{t('Care Receiver')} :</span>
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4 mt-1">
                <CloseSelect label={t("Service Branch")} fieldMeta={profileForm.getFieldMeta('careReceiver.serviceId')} fieldHelper={profileForm.getFieldHelpers('careReceiver.serviceId')} fieldProps={profileForm.getFieldProps('careReceiver.serviceId')} edit={editForm} options={militaryService} position="top" dataLoaded={dataLoaded} />
                <CloseSelect label={t("Rank")} fieldMeta={profileForm.getFieldMeta('careReceiver.rankId')} fieldHelper={profileForm.getFieldHelpers('careReceiver.rankId')} fieldProps={profileForm.getFieldProps('careReceiver.rankId')} edit={editForm} options={militaryRank} position="top" dataLoaded={dataLoaded} />
                <FieldDate type="date"  label={t("Date of Enlistment")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careReceiver.enlistmentDate')} fieldProps={profileForm.getFieldProps('careReceiver.enlistmentDate')} fieldHelper={profileForm.getFieldHelpers('careReceiver.enlistmentDate')} edit={editForm} dataLoaded={dataLoaded} onChange={setCrDate(profileForm.getFieldProps('careReceiver.enlistmentDate'))} />
                <FieldDate type="date" minDate={profileForm.values !== undefined && profileForm.values.careReceiver !== undefined && profileForm.values.careReceiver.enlistmentDate !== undefined && new Date(profileForm.values.careReceiver.enlistmentDate)} label={t("Date of Discharge")} autoComplete='off' fieldMeta={profileForm.getFieldMeta('careReceiver.dischargeDate')} fieldProps={profileForm.getFieldProps('careReceiver.dischargeDate')} fieldHelper={profileForm.getFieldHelpers('careReceiver.dischargeDate')} edit={editForm} dataLoaded={dataLoaded} />
                 </div>
            </div>
            {editForm &&
                <div className="flex justify-between mt-7 mx-5">
                    <button className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white" onClick={onClose}>{t("Cancel")}</button>
                    <div className='flex'>
                    <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-bluegray-700 hover:bg-opacity-80 text-white"  onClick={() => Skipclient()}>{t("Skip To Assessment")}</button>
                    <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white"  onClick={() => Saveclient()}>{t("Save")}</button>

                    </div>
                </div>
            }
        </div>
    )
}

export default DavServiceDialog