import { useCallback, useMemo, useState ,useEffect} from 'react'
import DataTable, { createTheme } from 'react-data-table-component'

import { datatableTheme } from '../../data/dataTableTheme'
import { useDispatch, useSelector } from 'react-redux'
// import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { FaUserMinus,FaPhoneAlt,FaEnvelope } from 'react-icons/fa'
import InlineTextField from '../../components/snippets/InlineTextField'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import {setAlert} from '../../store/theme/actions'
import FlowText from '../../components/snippets/FlowText'
import API from '../../axios/API'
import Dialog from '../../components/snippets/Dialog'
createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const RemovedAdminList = () => {

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [removedusersList, setRemovedUsersList] = useState([]);
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(false);

    const [reviveuser,setReviveUser] = useState([])
    const dispatch = useDispatch()
    const [pplId,setPplId] = useState()
    const [revivePopup,setRevivePopup] = useState(false)
    const { t } = useTranslation()
    let orgId = localStorage.getItem('orgid')
    const organization = useSelector(state => state.auth.organization)
    useEffect(() => {
        setLoading(true);
        API.get(`organizations/${organization}/users?status=removed`).then((res) => {
            setRemovedUsersList(res.data._embedded.immutableUserResponseList);
        }).catch(e => {
            console.log(e)
        }).finally(()=>setLoading(false))
    },[reviveuser,organization])// eslint-disable-line

    const reviveUser = ()=>{
        API.patch(`users/${pplId}:revive?orgId=${orgId}`).then(res=>{
            setReviveUser(res);
            if(res.status === 200){
                dispatch(setAlert({title:'Success',subtitle:'User Revived Successfully',type:'success',active:true}))
            }else if(res.data.status === false){
                dispatch(setAlert({title:'Error',subtitle:res.data.errorMessage,type:'Error',active:true}))
            }else if(res.status === 404){
                dispatch(setAlert({title:'Error',subtitle:'User not found',type:'Error',active:true}))
            }
        }).catch(e=>{
            console.log(e)
        }).finally(()=>setRevivePopup(false))
    }

    const colums = useMemo(() => {
        return [
            { name: t('Name'), selector:row => `${row.firstName} ${row.lastName}`, grow: 1, sortable: true },
            { name: t('User Name'), selector: 'userName', grow:1,sortable: true },
            // { name: t('Phone'), selector: 'phone',grow:1, sortable: true },
            // { name: t('E-Mail'), selector: 'email',grow:1, sortable: true },
            {name:t('Phone'),selector:'phone', cell: row=>{return <div  data-private="lipsum" style={{display:'flex',fontSize:'13px',marginTop:'15px'}}>{row.phone === "" || row.phone === null ? '':<a href={`tel: +1${row.phone.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor p-2 rounded-lg text-white" title={t('Call')}>
            <FaPhoneAlt size="10px" style={{margin:'auto'}}/>
            </a>}&nbsp;&nbsp;<span>{row.phone}</span></div>},grow:1,sortable: true},
            // { name: t('E-Mail'), selector: 'email',grow:1, sortable: true },
            {name:t('E-Mail'),cell:row=>{return <div style={{display:'flex',fontSize:'13px',marginTop:'15px'}}><a href={`mailto:${row.email}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Mail')}>
            <FaEnvelope size="10px" style={{margin:'auto'}} />
        </a>&nbsp;&nbsp;<span>{row.email}</span> </div>},grow:1,sortable: true},
            {name: t('Action'), button: true,grow:1, cell: row => <button onClick={()=>openRevivePopup(row.pplId)}><FaUserMinus size="20px" title={t("Revive")} className="text-red-600 dark:text-red-300" /></button>},
        ]
    }, [])// eslint-disable-line
    const SubHeader = useMemo(() => (
        <div>
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} />
        </div>
    ), [filter]) // eslint-disable-line
    const filteredcasedetails = useMemo(() => {
        let filterSplit =filter.toString().toLowerCase().split(' ')
        return removedusersList.filter(c => filter === '' || filter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [removedusersList,filter])// eslint-disable-line
    const ExpandComponent = useCallback(row => {
        return (
            
            <div className="bg-bluegray-300 dark:bg-ldark" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>
     
        )
    }, [])
    const openRevivePopup=(values)=>{
        setPplId(values)
        setRevivePopup(true)
    }
    useEffect(() => {
        const element = document.querySelectorAll('[aria-label="Expand Row"]');
        if (element) {
            element.forEach((e) => {
                e.setAttribute('role','gridcell')
            })
        }
    })

    return (
        <div>
            {
                loading ? 
                <DatatableLoader /> :filteredcasedetails.length>0?
                <DataTable title="casedetails" columns={colums} data={filteredcasedetails} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader highlightOnHover  selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)} paginationDefaultPage={page} expandableRows={screenSize < 3} expandableRowsComponent={<ExpandComponent />} noDataComponent={t('There are no records to display')} />:
                <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>

            }
            <Dialog title={t("Revive User")} showDialog={revivePopup} onClose={() => setRevivePopup(false)}>
                <div className="py-2 w-full">
                    <div className="px-4 mt-2">
                        <FlowText text={t("Are you sure you want to revive this user")} />
                    </div>
                    <div className="flex justify-between mt-8 mx-2">
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={()=>setRevivePopup(false)}>{t("No")}</button>
                        <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => reviveUser()}>{t("Yes")}</button>
                    </div>
                </div>
            </Dialog>

        </div>
    )
}

export default RemovedAdminList