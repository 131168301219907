const CareplanLoader = () => {

    return (
        <div>
            <div className="flex flex-col items-end text-lg font-bold">
                <div className="flex items-center gap-2">
                    <span className="text-gray-500 dark:text-gray-400">Care Plan for </span><div className="h-2 w-32 bg-gray-400 rounded-lg animate-pulse"></div>
                </div>
                <div className="flex items-center gap-2">
                    <span className="text-gray-500 dark:text-gray-400">Caring for </span><div className="h-2 w-32 bg-gray-400 rounded-lg animate-pulse"></div>
                </div>
            </div>
            <div className="flex flex-col gap-4 mt-6">
                <div className="form-wrap py-4 m-0">
                    <div className="h-2 w-1/4 bg-gray-400 rounded-lg animate-pulse">
                    </div>
                    <div className="flex flex-col gap-5 mt-6 ml-1">
                        <div className="flex items-center gap-2 font-semibold h-2 w-3/4 bg-gray-400 rounded-lg animate-pulse"></div>
                        <div className="flex items-center gap-2 font-semibold h-2 w-1/3 bg-gray-400 rounded-lg animate-pulse"></div>
                        <div className="flex items-center gap-2 font-semibold h-2 w-3/4 bg-gray-400 rounded-lg animate-pulse"></div>
                    </div>
                </div>
                <div className="form-wrap py-4 m-0">
                    <div className="h-2 w-1/4 bg-gray-400 rounded-lg animate-pulse">
                    </div>
                    <div className="flex flex-col gap-5 mt-6 ml-1">
                        <div className="flex items-center gap-2 font-semibold h-2 w-3/4 bg-gray-400 rounded-lg animate-pulse"></div>
                        <div className="flex items-center gap-2 font-semibold h-2 w-1/3 bg-gray-400 rounded-lg animate-pulse"></div>
                        <div className="flex items-center gap-2 font-semibold h-2 w-3/4 bg-gray-400 rounded-lg animate-pulse"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CareplanLoader