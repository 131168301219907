import React, {useState } from 'react'
import Button from '../components/snippets/Button'
import { useHistory, withRouter } from 'react-router'
import { motion } from 'framer-motion'
import BgImage from '../images/undraw_Meeting_re_i53h.svg'
import FlowText from '../components/snippets/FlowText'
import DarkModeSwitch from '../components/snippets/DarkModeSwitch'
import { PopInAnim, TextAnimateXRev, TextAnimateY, wrapPopAnim } from '../commons/anims'
import LogoImg from '../components/LogoImg'
import { useDispatch } from 'react-redux'
import LanguageSelector from '../components/LanguageSelector'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import axios from 'axios'
import { setAlert } from '../store/theme/actions'
import * as Yup from 'yup'
import FieldInput from '../components/snippets/FieldInput'

Yup.addMethod(Yup.string, 'validatePassword', function () {
    return this.test('Password must be atleast 8 characters with 1 uppercase, 1 numeric character & 1 special character', "Password must be atleast 8 characters with 1 uppercase, 1 numeric character & 1 special character", (value) => {
        if (value === undefined || value === '') return true
        let r = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/g
        return r.test(value)
    })
})

const initalFollowupValue = {
    userName : '',
    password : '',
    confirmPassword : ''
}

const ResetPassword = ({match}) => {    
    const [loading] = useState(false)
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const resetFormSchema = Yup.object().shape({
        userName : Yup.string().required("UserName is required"),
        password : Yup.string().validatePassword().required("Password is required field"),
        confirmPassword : Yup.string().validatePassword().required("Password is required field")
    })

    const resetForm = useFormik({
        initialValues:initalFollowupValue,
        validateOnMount : true,
        validationSchema : resetFormSchema,
        enableReinitialize : true,
        onSubmit : values =>{
            if(values.password !== values.confirmPassword){
                dispatch(setAlert({title:'Warning',subtitle:'Password and confirm password is not matched',type:'warning',active:true}))
            }else{
                submitForm()
            }
        }
    })

    const submitForm = ()=>{
        let details = {
            token : match.params.token,
            pass : resetForm.values.password,
            userName : resetForm.values.userName
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}users:reset-password`,details).then((res)=>
        {
                if(res.data.params === 'finished'){
                    dispatch(setAlert({title:'Success',subtitle:'Password Changed Successfully',type:'success',active:true}))
                    history.push('/login')
                }else{
                    dispatch(setAlert({title:'Error',subtitle:'Something went wrong',type:'error',active:true}))
                }
            }).catch(err=>{
                dispatch(setAlert({title:'Error',subtitle:'Something went wrong',type:'error',active:true}))
            })
        // axios.post(`${process.env.REACT_APP_API_BASE_URL}user/resetPassword`,details).then((res)=>{
        //     if(res.data.status === true){
        //         dispatch(setAlert({title:'Success',subtitle:'Password Changed Successfully',type:'success',active:true}))
        //         history.push('/login')
        //     }else{
        //         dispatch(setAlert({title:'Error',subtitle:'Something went wrong',type:'error',active:true}))
        //     }
        // }).catch(err=>{
        //     dispatch(setAlert({title:'Error',subtitle:'Something went wrong',type:'error',active:true}))
        // })
    }

    return (
        <div className="min-h-screen flex justify-center items-center flex-col bg-blue-50 dark:bg-ddark overflow-hidden">
            <motion.div className="fixed left-8 top-5" variants={ TextAnimateXRev } initial="hidden" animate="visible">
                <LogoImg size="200px" />
            </motion.div>
            <motion.div className="bg-indigo-50 dark:bg-ldark ring-1 ring-indigo-100 dark:ring-mdark shadow-lg flex flex-col overflow-hidden items-center p-6 rounded-2xl relative w-[400px] sm:w-[600px]" variants={ wrapPopAnim } initial="hidden" animate="visible">
                <img src={ BgImage } alt="Reset Password Bg Logo" aria-label='Reset Password Bg Logo' className="absolute left-0 bottom-5 opacity-5" />
                <div className="text-3xl text-tcolor font-medium mb-3 flex justify-between content-center w-full px-4">
                    <FlowText text={t('Reset Your Password')} />
                    <div className="flex">
                        <span className="mr-5">
                            <LanguageSelector injectPopupClass="-right-0 z-10 dark:bg-mdark" originX="right" originY="top" />
                        </span>
                        <DarkModeSwitch />
                    </div>
                </div>
                <motion.div variants={ PopInAnim } className="w-full mb-2 mt-2">
                    {/* <TextField AppendIcon={ FaUserAlt } label="Username" name="username" injectClass="mt-4" initialValue={ resetPasswordData.userName } setValue={ (val) => setData('userName', val) } type="text" loading={ loading } formDirty={ formDirty } /> */}
                    <FieldInput ariaLabel='Username' loading={false} label={t('Username')} fieldMeta={resetForm.getFieldMeta('userName')} fieldHelper={resetForm.getFieldHelpers('userName')} fieldProps={resetForm.getFieldProps('userName')} dataLoaded={true} edit={true}/>
                </motion.div>
                <motion.div variants={ PopInAnim } className="w-full mb-2 mt-2">
                    {/* <TextField AppendIcon={ FaLock } label="New Password" name="password" injectClass="mt-4" initialValue={ resetPasswordData.password } setValue={ (val) => setData('password', val) } type="password" loading={ loading } formDirty={ formDirty }  /> */}
                    <FieldInput ariaLabel='Password' loading={false} type="password" label={t('Password')} fieldProps={resetForm.getFieldProps('password')} fieldHelper={resetForm.getFieldHelpers('password')} fieldMeta={resetForm.getFieldMeta('password')} dataLoaded={true} edit={true}/>
                </motion.div>
                <motion.div variants={ PopInAnim } className="w-full mb-2 mt-2">
                    {/* <TextField AppendIcon={ FaLock } label="Confirm Password" name="confirmPassword" injectClass="mt-4" initialValue={ resetPasswordData.confirmPassword } setValue={ (val) => setData('confirmPassword', val) } type="password" loading={ loading } formDirty={ formDirty }  /> */}
                    <FieldInput ariaLabel='Confirm Password' loading={false} type="password" label={t('Confirm Password')} fieldProps={resetForm.getFieldProps('confirmPassword')} fieldHelper={resetForm.getFieldHelpers('confirmPassword')} fieldMeta={resetForm.getFieldMeta('confirmPassword')} dataLoaded={true} edit={true}/>
                </motion.div>
                <motion.div variants={ TextAnimateY }>
                    <Button injectClass={ `mt-8 mb-4 w-80 ${loading ? 'animate-pulse' : ''}` }  onClick={()=> resetForm.submitForm() } loading={ loading }>
                     {t("Submit")} 
                    </Button>
                </motion.div>   
            </motion.div>
           {/* <div>
                <IntroLoader />
            </div> */}
        </div>
    )
}

export default withRouter(ResetPassword)
