import { AnimatePresence, motion } from "framer-motion"

export const tabAmin = {
    hidden: {
        opacity: 0,
        height: 0
    },
    visible: {
        opacity: 1,
        height: 'auto',
        transition: {
            when: 'beforeChildren',
            staggerChildren: 0.1
        }
    }
}

const Tab = ({ children, index, value }) => {
    return (
        <div className={`flex-shrink-0 flex-grow-0 w-32 px-2`} style={{flexBasis: '100%'}}>
            <AnimatePresence>
                {
                    index === value &&
                    <motion.div variants={tabAmin} initial="hidden" animate="visible" exit="hidden">
                        {children}
                    </motion.div>
                }
            </AnimatePresence>
        </div>
    )
}

export default Tab