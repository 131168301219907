import { MdDashboard, MdSettings } from 'react-icons/md'
import {AiFillFileAdd} from 'react-icons/ai'
import { SiSwagger } from 'react-icons/si'
import { ImUserPlus } from 'react-icons/im'

export const HomeMenu = [
    {
        TitleIcon: AiFillFileAdd,
        title: 'Add New',
        subtitle: 'Add New',
        // extendSidebar : false,
        // link:'/referrer',
        roles: [],
        sidebar: true
    },
    {
        TitleIcon: MdDashboard,
        title: 'Cases',
        subtitle: 'Cases',
        sidebar: true,
        roles: []
    },
    
    {
        TitleIcon: MdSettings,
        title: 'Details',
        subtitle: 'Details',
        sidebar: true,
        roles: []
    },

    {
        TitleIcon: ImUserPlus,
        title: 'Subscriber Details',
        subtitle: 'Subscriber Id',
        sidebar: true,
        roles: []
    },

    {
        TitleIcon: SiSwagger,
        title: "Swagger",
        subtitle: "Swagger",
        sidebar: true,
        roles: []
    }
   
    // {
    //     TitleIcon: BiWindows,
    //     title: 'Details',
    //     subtitle: 'Case Details',
    //     sidebar: true
    // }
]