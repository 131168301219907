
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import API from '../../axios/API'
import * as Yup from 'yup';

import FieldInput from '../../components/snippets/FieldInput'
import { AnimatePresence, motion } from 'framer-motion';
import { PopInAnim } from '../../commons/anims';
import { useDispatch, useSelector } from 'react-redux';
// import { setAlert } from '../../store/theme/actions';
import { setCloseCaseRender} from  '../../store/auth/actions';
import { useTranslation } from "react-i18next"
// import CloseSelect from '../../components/snippets/CloseSelect';
import ReassessSelect from '../../components/snippets/ReassessSelect';
import { useHistory } from 'react-router-dom'
const initialFormValues = {
    reason: '',
    otherReason: '',
    pcspDate:''
}


const Reassessments = ({ onClose,props,assess,type,crDetail,subId }) => {
    const [closeReason, setCloseReason] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)
    const [inputEnable] = useState(false)
    const render = useSelector(state =>state.auth.closeCaseRender)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    let history = useHistory()
    let orgId = localStorage.getItem('orgid')
    let pcsp = localStorage.getItem('pcsp')
    const userDetails = useSelector(state => state.auth.userDetails)
    let lang = localStorage.getItem('language')
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if(value === undefined || value === '') return true
            let r = /^[^-\s][a-zA-Z_0-9\s-]+$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'),t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })
    
    useEffect(() => {
        API.get(`/orgnaizations/${orgId}/menus/assessment/${assess}/reassessment-reason?lang=${lang}`).then(res => {
            setCloseReason(res.data.body.map(c => ({ text: c.name, value: c.value })));
            setDataLoaded(true)
        }).catch(e => {
            console.log(e)
        })
    }, [assess])// eslint-disable-line

    
    const reasonFormSchema = Yup.object().shape({
        reason: Yup.string().required(t("Reason is a required field")),
        // otherReason: Yup.string().validateDescription().min(5,t("Reason should be min 5 character.")).max(250,t("Reason should be max 250 character")).nullable()
        pcspDate: pcsp === 'true' ? Yup.date().required(t("Date Of Associated PCSP is a required field")): ''
    })

    const reasonForm = useFormik({
        initialValues: initialFormValues,
        validationSchema: reasonFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            submitForm()
        }
    })

    // const reasonSelect = (values) => {
    //     if (values === 110) {
    //         setInputEnable(true)
    //     } else {
    //         setInputEnable(false)
    //         reasonForm.setValues({...reasonForm,reason:values,otherReason : ''})

    //     }
    // }

    
    const submitForm = () => {
            let details =  {
                "reassessmentReason" :reasonForm.values.reason,
                "pcspDate":reasonForm.values.pcspDate ? reasonForm.values.pcspDate.toString().replace('/','-').replace('/','-').slice(0,10) : null,
            }
            if(crDetail?.personId) {
                history.push({ pathname: `/createCase/${props}/carereceiver/${crDetail.personId}/caregiver/0/${userDetails?.caremanagerId}`, state: {crDetails:crDetail,assDetail:details,cgNew:true}})          
            }else if(crDetail === undefined || crDetail.length === 0 ){
                if(assess === 0){
                    history.push({ pathname: `/addCase`,state:{subscriberId:subId,cgNew:true,assDetail:details}})
                    //history.push({ pathname: `/createCase/0/carereceiver/0/caregiver/0/${userDetails?.caremanagerId}`, state: {crDetails:crDetail,assDetail:details,cgNew:true}})
                }else {            
                    API.patch(`/assessments/${assess}/reassessmentReason`,details).then(res=>{
                        // dispatch(setAlert({title:t('Success'),subtitle:t('Assessment Reason Created Successfully'),type:'success',active:true}))
                        history.push(`/case/${props}/assessment/${assess}`)
                        dispatch(setCloseCaseRender(render+1));
                        onClose();
                    }).catch(e=>{
                        console.log(e)
                    }).finally(()=>onClose)
                }
            }
            
    }

    const closedReason = () => {
        if(type === 'Rescreen'){
            history.push(`/casedata/${props}`)
            dispatch(setCloseCaseRender(render+1));
            onClose();
        }else{
            dispatch(setCloseCaseRender(render+1));
            onClose();
        }
       
    }
    return (
        <div className="py-2 w-full">
            <div className="px-4 mt-2">
            <ReassessSelect ariaLabel={'Select Reason'} label={t('Select Reason')} fieldMeta={reasonForm.getFieldMeta('reason')} fieldHelper={reasonForm.getFieldHelpers('reason')} fieldProps={reasonForm.getFieldProps('reason')} options={closeReason} edit={true} dataLoaded={dataLoaded} />
                {/* <FieldSelect label={t("Reason")} fieldMeta={reasonForm.getFieldMeta('reason')} fieldHelper={reasonForm.getFieldHelpers('reason')} fieldProps={reasonForm.getFieldProps('reason')} options={closeReason} edit={true} position="bottom" dataLoaded={dataLoaded} onChange={reasonSelect} /> */}
                <AnimatePresence>
                    {
                        inputEnable &&
                        <motion.div variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" className="mt-4">
                            <FieldInput ariaLabel={t("Type a reason")} type="textarea" label={t("Type a reason")} autoComplete='off' fieldMeta={reasonForm.getFieldMeta('otherReason')} fieldProps={reasonForm.getFieldProps('otherReason')} edit={true} dataLoaded={dataLoaded} />
                        </motion.div>
                    }
                </AnimatePresence>
                {pcsp === 'true' ? <FieldInput ariaLabel={t("Date of Associated PCSP")} type="date" label={t("Date of Associated PCSP")} autoComplete='off' fieldMeta={reasonForm.getFieldMeta(`pcspDate`)} fieldProps={reasonForm.getFieldProps(`pcspDate`)} fieldHelper={reasonForm.getFieldHelpers(`pcspDate`)} edit={true} dataLoaded={dataLoaded} /> : ''}
            </div>
            <div className="flex justify-between mt-8 mx-2">
                <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-700 dark:text-red-300 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={closedReason}>{t("Cancel")}</button>
                <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => reasonForm.submitForm()}>{t("Save")}</button>
            </div>
        </div>
    )

}

export default Reassessments