const ActionsLoader = () => {
    return (
        [1,2].map(c => (
            <div key={c} className="mb-8">
                <div className="h-4 w-36 mt-3 rounded-lg animate-pulse bg-gray-300"></div>
                {
                    [1,2].map(e => (
                        <div key={e} className="mt-3 w-full rounded-lg bg-gray-200 p-2 flex items-center">
                            <div className="h-10 w-12 rounded-full animate-pulse bg-gray-400"></div>
                            <div className="w-full ml-4">
                                <div className="h-3 w-full mt-3 rounded-lg animate-pulse bg-gray-400"></div>
                                <div className="h-3 w-1/2 mt-3 rounded-lg animate-pulse bg-gray-400"></div>
                            </div>
                        </div>
                    ))
                }
            </div>
        ))
    )
}

export default ActionsLoader