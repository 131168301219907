import { useMemo } from "react"
import { useSelector } from "react-redux"
import DataTable, { createTheme } from 'react-data-table-component'
import { datatableTheme } from '../../data/dataTableTheme'

createTheme('defaultLight', datatableTheme.default)
createTheme('defaultDark', datatableTheme.dark)

const DatatableLoader = () => {

    const { themeMode } = useSelector(state => state.theme)

    const TitleLoading = useMemo(() => 
        <div className="w-56 h-3 rounded-lg animate-pulse bg-gray-400 dark:bg-gray-600 cursor-wait"></div>
    , [])
    const HeaderLoading = useMemo(() => <div className="w-36 h-3 rounded-lg animate-pulse bg-gray-400 dark:bg-gray-600 cursor-wait"></div>, [])
    const CellLoading = useMemo(() => <div className="w-36 h-2 rounded animate-pulse bg-gray-400 dark:bg-gray-600 cursor-wait"></div>, [])
    const colums = useMemo(() => {
        return [
            { name: HeaderLoading, selector: 'Action', cell: () => CellLoading },
            { name: HeaderLoading, selector: 'Action', cell: () => CellLoading },
            { name: HeaderLoading, selector: 'Action', cell: () => CellLoading },
            { name: HeaderLoading, selector: 'Action', cell: () => CellLoading },
            { name: HeaderLoading, selector: 'Action', cell: () => CellLoading }
        ]
    }, []) // eslint-disable-line
    const data = useMemo(() => {
        return Array.apply('', Array(10)).map(() => ({ 'Action': '12' }))
    }, [])

    return (
        <div>
            <DataTable title={TitleLoading} columns={colums} data={data} theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination persistTableHead />
        </div>
    )
}

export default DatatableLoader 