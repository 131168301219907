import { motion } from "framer-motion"
import { TextAnimateY } from "../../commons/anims"
import {MdAssessment} from 'react-icons/md'
import { useHistory } from 'react-router-dom';
// import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';

const Action = ({ action }) => {
    let history = useHistory();

    const lastdat =(value)=>
    {if(value !== null && value){
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
        //      let followupdate = new Date(value)
        //  return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
         }else{
             return ''
         }
      }

    return (
        <motion.div variants={TextAnimateY} className={`group my-3 p-2 rounded-xl transition-all duration-100 cursor-pointer hover:ring-2 text-textBlack dark:text-gray-200 `}>
            <div className={`flex justify-between items-center mr-2`}>
                <div className="flex items-center">
                    <div className="group-hover:animate-pulse">
                        <MdAssessment size="30px" />
                    </div>
                    <div className="ml-3">
                        <div><span className="font-bold text-sm hover:underline decoration-2 ..." onClick={()=>history.push(`/casedata/${action.id}`)}>{action.id}</span>, <span className="text-red-700 dark:text-red-300 text-xs">{action.caregiverName}</span></div>
                        <div className="text-red-700 dark:text-red-300 text-xs">{action.status}</div>
                    </div>
                </div>
                <div className="text-xs font-bold">
                    {lastdat(action.schedule)}
                </div>
            </div>
        </motion.div>
    )
}

export default Action