import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { MdAccountCircle, MdNotes } from "react-icons/md"
import { FaUserEdit,FaPhoneAlt,FaEnvelope, FaUserFriends } from 'react-icons/fa'
import { useDispatch ,useSelector} from "react-redux"
import FlowText from "../FlowText"
import Dialog from "../Dialog"
import EditCaregiver from '../../../pages/Dialogs/Editcaregiver'
import EditCarereceiver from '../../../pages/Dialogs/Editcarereceiver'
import RelationshipSelector from "../../../pages/CaseDetails/RelationshipSelector"
import {FaStickyNote,FaEye } from "react-icons/fa"
import API from "../../../axios/API"
import { setAlert } from "../../../store/theme/actions";
import { useHistory } from 'react-router-dom';
import DemographicLoader from "./DemographicLoader"
import DemoNotes from "../../../pages/Dialogs/DemoNotes"
import ViewNotes from "../../../pages/Dialogs/ViewNotes"
import { AiOutlineLink } from "react-icons/ai"
import Dialognotes from "../Dailognotes"
import { useLocation } from "react-router-dom/cjs/react-router-dom"


const Demographic = ({ caseId, handleEditor }) => {
    const [demographic, setDemographic] = useState({ caregiver: [], carereceiver: [] })
    // const [race,setRace] = useState('')
    const [showCaregiverForm, setShowCaregiverForm] = useState(false)
    const [showCarerecieverForm, setShowCarereceiverForm] = useState(false)
    const [editNotesForm, setEditNotesForm] = useState(false)
    const [viewNotesForm, setViewNotesForm] = useState(false)
    // const userDetails = useSelector(state => state.auth.userDetails)
    // const [setShowEditRelationship] = useState(false)
    const demographiccount = useSelector(state => state.auth.demographiccount)
    const changerelationShipCount = useSelector(state =>state.auth.changerelationShipCount)
    const [run] = useState(false)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    let history = useHistory();
    const [loading, setLoading] = useState(true)
    let birthyear = localStorage.getItem('birthyear')
    const [birthyear1,setBirthYear1] = useState(false)
    let orgId = localStorage.getItem('orgid')
    const userDetails = useSelector(state=>state.auth.userDetails)
    const organization = useSelector(state => state.auth.organization)
    // let prgName = localStorage.getItem('prgname')
    let orgCaseNotesDisable = localStorage.getItem('orgCaseNotesDisable')
    const [isDraggableEditor, setIsDraggableEditor] = useState(false)
    const [isMaximize, setIsMaximize] = useState(false)
    const currentLocation = useLocation()
    let orgDraggableEditor = localStorage.getItem('orgDraggableEditor') || ''
    let orgEthnicityEnable = localStorage.getItem('orgEthnicity')
    const [orgRaceEnable,setOrgRaceEnabl] = useState(false)

    useEffect(() => {
        if(birthyear === 'true'){
            setBirthYear1(true)
           }
           if(orgEthnicityEnable === 'true'){
            setOrgRaceEnabl(true)
        }
        setLoading(true)
        API.get(`organizations/${orgId}/cases/${caseId}/demographic`)
            .then(res => {
                if (res.status === 200) {
                    res.data.body.caregiver.race = []
                    if( orgEthnicityEnable === 'true' && res.data.body.caregiver.ethnicity !== undefined &&  res.data.body.caregiver.ethnicity !== null && res.data.body.caregiver.ethnicity.toString().length > 0){
                         res.data.body.caregiver.race.push( res.data.body.caregiver.ethnicity)
                    }
                    if(res.data.body.caregiver.white === true){
                        res.data.body.caregiver.race.push(t('White'))
                    }if(res.data.body.caregiver.black === true){
                        res.data.body.caregiver.race.push(t('Black or african american'))
                    }if(res.data.body.caregiver.hispanic === true){
                        res.data.body.caregiver.race.push(t('Hispanic or Latino'))
                    }if(res.data.body.caregiver.asian === true && orgEthnicityEnable !== 'true'){
                        res.data.body.caregiver.race.push(t('Asian'))
                    }if(res.data.body.caregiver.nativeAmerican === true){
                        res.data.body.caregiver.race.push(t('American indian or alaska native'))
                    }if(res.data.body.caregiver.pacificIslander === true && orgEthnicityEnable !== 'true'){
                        res.data.body.caregiver.race.push(t('Native hawaiian or pacific islander'))
                    }if(res.data.body.caregiver.middleEastern === true){
                        res.data.body.caregiver.race.push(t('Middle eastern or north african'))
                    }if(res.data.body.caregiver.otherRace === true){
                        res.data.body.caregiver.race.push(t('Some other race/ethnicity'))
                    }if(res.data.body.caregiver.refused === true){
                        res.data.body.caregiver.race.push(t('Refused'))
                    }

                    setDemographic(res.data.body)
                    setLoading(false)
                } else {
                    dispatch(setAlert({ title: t('unauthorized'), subtitle: t('unauthorized_to_view'), active: true, type: 'warning' }))
                    history.push('/')
                }
            })
            .catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    history.push(`/casedata/${caseId}`)

                }
            }).finally(() => setLoading(false))
    }, [run,changerelationShipCount ,demographiccount]) // eslint-disable-line
    // const reRun = () => {
    //     setRun(!run)
    //     // setShowCaregiverForm(false)
    // }

    const editcaregiver = () => {
        setShowCaregiverForm(true)
    }

    const editcarereceiver = () => {
        setShowCarereceiverForm(true)
    }
    // const editrelationship = () => {
    //     setShowEditRelationship(true)
    // }
    const editnotes = () => {
        const page_path = currentLocation.pathname.split('/')
        {/* *** orgDraggableEditor - false on Amerigroup TN and United Healthcare TN **** */}
        if (page_path[1] === 'case' && page_path[3] === 'assessment' && orgDraggableEditor === 'true') {
            handleEditor(true)
            setIsDraggableEditor(true)
            setEditNotesForm(true)
        } else {
            setIsDraggableEditor(false)
            setEditNotesForm(true)
        }
    }
    const handleResize = () => {
        setIsMaximize(prev => !prev)
    }
    const  viewnotes = () => {
        setViewNotesForm(true)
    }
    return (
        <>
            {
                loading ? <DemographicLoader /> : <div className="flex-1 bg-gray-600 dark:bg-stale-300 rounded-tr-lg bg-opacity-90 min-w-[300px] -ml-8 flex-grow-0 overflow-auto pb-10">
                    <div className="mt-5 ml-4">
                        <div className="text-lg font-medium text-gray-200 dark:text-gray-300 flex items-center flex justify-start mb-2">
                            <h1 style={{color:'white'}}> {t("Case #")} - <button style={{textDecoration:'underline',fontWeight:'800'}} onClick={()=>history.push(`/casedata/${caseId}`)}>{caseId}</button></h1>
                        </div>
                        <div className="text-lg font-medium text-gray-200 dark:text-gray-300 underline flex items-center">
                            <MdAccountCircle className="mr-1" />
                            {t('Caregiver')}
                            <FaUserEdit size="20px" className="ml-2 cursor-pointer bg-bluegray-500 bg-opacity-80 rounded-full py-0.5 px-1" title={t('Edit Caregiver')} onClick={() => editcaregiver()} />
                        </div>
                        <div className="mt-2">
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200 flex-shrink w-16">{t("First Name")} :</div>
                                <div className="flex items-center text-sm text-white px-5" data-private="lipsum">
                                    <FlowText force={true}  text={demographic.caregiver.firstName}>
                                    </FlowText>
                                </div>
                            </div>
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200 w-20" >{t("Last Name")} :</div>
                                <div className="flex items-center text-sm text-white px-1 w-24" data-private="lipsum">
                                    <FlowText force={true} text={demographic.caregiver.lastName}>
                                    </FlowText>
                                </div>
                            </div>
                            <div className="flex items-center py-0.5" data-private="lipsum">
                                <div className="mr-2 text-xs text-gray-200 flex-shrink w-24" >{t("Phone")} :</div>
                                {
                                    demographic.caregiver.phone &&
                                    <span className={`flex items-center text-sm text-white flex-shrink w-80 px-7 ${(demographic.caregiver.phone && demographic.caregiver.phone !== '' && demographic.caregiver.phone !== null) ? '' : 'pointer-events-none'}`}>
                                         { (demographic.caregiver.phone && demographic.caregiver.phone !== '')  }
                                        <FlowText force={true} text={demographic.caregiver.phone}>
                                   </FlowText>&nbsp;
                                   <a href={`tel: +1${demographic.caregiver.phone.toString().replace(/-/g, '')}`} className="cursor-pointer bg-bluegray-500 bg-opacity-80  text-white p-2 rounded-lg" title={t('Call')}>
                                     <FaPhoneAlt size="16px" />
                                 </a>
                                    </span>
                                   
                               }
                               {/* {
                                   demographic.caregiver.orgContact &&  
                                   <a href={`tel: +1${demographic.caregiver.phone?.toString().replace(/-/g, '')}`} className={`flex items-center text-sm text-white px-8 ${(demographic.caregiver.phone && demographic.caregiver.phone !== '' && demographic.caregiver.phone !== null) ? '' : 'pointer-events-none'}`}>
                                   { (demographic.caregiver.phone && demographic.caregiver.phone !== '') && <AiOutlineLink size="13px" className="mr-0.5" /> }
                                   <FlowText force={true} text={demographic.caregiver.phone}>
                                   </FlowText>
                               </a>
                               
                               } */}   
                            </div>
                            <div className="flex items-center py-0.5" data-private="lipsum">
                                <div className="mr-1 text-xs text-gray-200 flex-shrink w-20 " >{t("Email")} :</div>
                            <div className="flex  text-sm text-white">

                                {
                                    demographic.caregiver.email &&demographic.caregiver.email &&
                                    <span className={`flex items-center  text-sm text-white w-44 px-2  ${(demographic.caregiver.email && demographic.caregiver.email !== '' && demographic.caregiver.email !== null) ? '' : 'pointer-events-none'}`}>
                                         { (demographic.caregiver.mail && demographic.caregiver.email !== '') && <AiOutlineLink size="13px" className="mr-0.5" /> }
                                        <FlowText force={true} text={demographic.caregiver.email}>
                                   </FlowText>&nbsp;
                                   
                                   <a href={`mailto: ${demographic.caregiver.email} `} className="cursor-pointer bg-bluegray-500 bg-opacity-80  text-white p-2 rounded-lg" title={t('Mail')}>
                                     <FaEnvelope size="16px" />
                                 </a>
                                    </span>}
                                    </div>
                               </div>
                            {/* <div className="flex items-center py-0.5" data-private="lipsum">
                                <div className="mr-1 text-xs text-gray-200">{t("Email")} :</div>
                                {
                                <span className={`flex items-center text-sm text-white px-7 ${(demographic.caregiver.email&& demographic.caregiver.email !== '') ? '' : 'pointer-events-none'}`}>
                                    { (demographic.caregiver.email&& demographic.caregiver.email !== '') && <AiOutlineLink size="13px" className="mr-0.5" /> }
                                    <FlowText force={true} text={(demographic.caregiver.email)?demographic.caregiver:'---'}>
                                    </FlowText>&nbsp;
                                <a href={`mailto: ${demographic.caregiver.email} `} 
                                className="cursor-pointer bg-bluegray-500 bg-opacity-80  text-white p-2 rounded-lg" title={t('Mail')}>
                                                <FaEnvelope size="16px" />
                                            </a>   </span>}
                            </div> */}
                            
                            <div className="flex items-center py-0.5">
                              {birthyear1 ?  <div className="mr-2 text-xs text-gray-200 w-20">{t("Date of Birth")} :</div>:
                              <div className="mr-2 text-xs text-gray-200 w-20">{t("Year")} :</div>}
                                <div className="flex items-center text-sm text-white px-1 w-24">
                                    <FlowText force={true} text={`${birthyear1 &&demographic.caregiver.dobYear !==0 ? demographic.caregiver.dobMonth +'-' + demographic.caregiver.dobDay +'-'+ demographic.caregiver.dobYear : demographic.caregiver.dobYear !==0 ? demographic.caregiver.dobYear : ''}`}>
                                    </FlowText>
                                </div>
                            </div>
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200 flex-shrink w-24">{t("Race/Ethnicity")} :</div>
                            <div className="flex flex-wrap text-sm text-white flex-shrink w-44 px-1" data-private="lipsum">
                            {demographic.caregiver.race.length !== 0 
                                    && 
                                        demographic.caregiver.race.map((c,i) =>{
                                           return <FlowText force={true} text={`${c} ${(demographic.caregiver.race.length === i+1) ? '' : ','} `}>
                                    </FlowText>
                                        })
                                    }
                            </div>
                            </div>
                            {demographic.caregiver.otherAsianText && orgRaceEnable && <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200 w-20" >{t("Other Asian")} :</div>
                                <div className="flex items-center text-sm text-white px-1 w-24" data-private="lipsum">
                                    <FlowText force={true} text={demographic.caregiver.otherAsianText}>
                                    </FlowText>
                                </div>
                            </div>}
                           {demographic.caregiver.otherNativeText && orgRaceEnable && <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200 w-18" >{t("Other Native Hawaiian")} :</div>
                                <div className="flex items-center text-sm text-white px-1 w-24" data-private="lipsum">
                                    <FlowText force={true} text={demographic.caregiver.otherNativeText}>
                                    </FlowText>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="mt-5 ml-4">
                        <div className="text-lg font-medium text-gray-200 dark:text-gray-300 underline flex items-center">
                            <MdAccountCircle className="mr-1" />
                            {t("Care Receiver")}
                            <FaUserEdit size="20px" className="ml-2 cursor-pointer bg-bluegray-500 bg-opacity-80 rounded-full py-0.5 px-1" title={t('Edit Care Receiver')} onClick={() => editcarereceiver()} />
                        </div>
                        <div className="mt-2">
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200 flex-shrink w-16">{t("First Name")} :</div>
                                <div className="text-sm text-white flex-shrink w-44 px-5" data-private="lipsum">
                                    <FlowText force={true} text={demographic.careReceiver.firstName}>
                                    </FlowText>
                                </div>
                            </div>
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200 w-20">{t("Last Name")} :</div>
                                <div className="text-sm text-white px-1" data-private="lipsum">
                                    <FlowText force={true} text={demographic.careReceiver.lastName}>
                                    </FlowText>
                                </div>
                            </div>
                            <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200 w-20">{t("Subscriber Id")} :</div>
                                <div className="text-sm text-white px-1">
                                    <FlowText force={true} text={(demographic.careReceiver.subscriberId)?demographic.careReceiver.subscriberId:'---'}>
                                    </FlowText>
                                </div>
                            </div><br></br>
                            <div className="flex items-center py-0.5 ">
                                <div className="mr-2 text-gray-200 text-lg font-medium text-gray-200 dark:text-gray-300 underline flex items-center">
                                <FaUserFriends className="mr-1"/> {t('Relationship')}

                                </div>
                                </div>
                                <div className="flex items-center py-0.5">
                                <div className="mr-2 text-xs text-gray-200"> {t("Caring For")} :</div>
                                <div className="flex items-center text-sm px-5" data-private="lipsum">
                                <RelationshipSelector props={demographic.careReceiver.relationship} caseId={caseId} /></div></div><br></br>

                               { ((userDetails.orgId === parseInt(organization) && userDetails.parentRole.length === 1 && userDetails.parentRole.includes('screener')) || (userDetails.orgId !== parseInt(organization) && userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_scr'))) && orgCaseNotesDisable === 'true' ? '' : <div className="text-lg font-medium text-gray-200 dark:text-gray-300 underline flex items-center">
                                    <MdNotes className="mr-1" />{t('Notes')}</div>}<br></br>
                               { ((userDetails.orgId === parseInt(organization) && userDetails.parentRole.length === 1 && userDetails.parentRole.includes('screener')) || (userDetails.orgId !== parseInt(organization) && userDetails.childRole.length === 1 && userDetails.childRole.includes('dynamic_scr'))) && orgCaseNotesDisable === 'true' ? '' :<div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 cursor-pointer px-6">
                                <FaStickyNote size="20px" title={t("Add Note")} color={'#fff'} onClick={() => editnotes()} />
                                <FaEye size="20px" title={t("View Notes")} color={'#fff'} onClick={() => viewnotes()} />
                                {/* <FaPhoneAlt size="20px" title="Call" color={'#fff'} /> */}
                            </div>}
                        </div>
                    </div>
                    <Dialog showDialog={showCaregiverForm} title={t('Edit Caregiver')} onClose={() => setShowCaregiverForm(false)}>
                        <EditCaregiver details={demographic.caregiver} onClose={() => setShowCaregiverForm(false)} />
                    </Dialog>
                    <Dialog showDialog={showCarerecieverForm} title={t('Edit Care Receiver')} onClose={() => setShowCarereceiverForm(false)}>
                        <EditCarereceiver details={demographic.careReceiver} onClose={() => setShowCarereceiverForm(false)} />
                    </Dialog>
                    <Dialognotes showDialog={editNotesForm} title={t('Notes')} onClose={() => {setEditNotesForm(false);setIsMaximize(false);setIsDraggableEditor(false);if(typeof handleEditor === 'function') handleEditor(false)}} isDraggableEditor={isDraggableEditor} onResize={()=>handleResize()} isMaximize={isMaximize}>
                        <DemoNotes  onClose={() => {setEditNotesForm(false);setIsMaximize(false);setIsDraggableEditor(false);if(typeof handleEditor === 'function') handleEditor(false)}} caseId={caseId} isDraggableEditor={isDraggableEditor} isMaximize={isMaximize} />
                    </Dialognotes>
                    <Dialognotes showDialog={viewNotesForm} title={t('View Notes')} onClose={() => setViewNotesForm(false)}>
                        <ViewNotes  onClose={() => setViewNotesForm(false)} caseId={caseId} />
                    </Dialognotes>
                </div>
            }
        </>
    )
}

export default Demographic