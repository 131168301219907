import FlowText from "../../components/snippets/FlowText"
import { useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { motion } from "framer-motion"
import { useEffect } from "react"
import { FaArrowLeft, FaArrowRight, FaCheck } from "react-icons/fa"
import AssessmentTextField from "../../components/snippets/Assessment/AssessmentTextField"
import AssessmentOptionField from "../../components/snippets/Assessment/AssessmentOptionField"
import AssessmentPages from "../../components/snippets/Assessment/AssessmentPages"
import AssessmentLoader from "./AssessmentLoader"
import { setAlert } from "../../store/theme/actions"
import { useHistory } from "react-router"
import { useFormik } from "formik"
import * as Yup from 'yup'
import AssessmentOptionChip from '../../components/snippets/Assessment/AssessmentOptionChip'
import { createPortal } from "react-dom"
import Dialog from "../../components/snippets/Dialog"
import axios from "axios"
import FieldInput from "../../components/snippets/FieldInput"
import Button from "../../components/snippets/Button"
import FieldSelect from "../../components/snippets/FieldSelect"
const initialFormValues = {
    disease: []
}

const initialFormValue = {
    careReceiver: {
        firstName: '',
        lastName: '',
        // dateOfBirth: '',
        relationshipId: '',
        // address: {
        //     addressLine1: '',
        //     addressLine2: '',
        //     addressLine3: '',
        //     city: '',
        //     postalCode: '',
        //     state: ''
        // },
        subscriberId: ''
    }
}

const ScreenerAssessment = ({ asmtId, screenerId, caseId }) => {

    const quesWrap = useRef(null)
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(true)
    const [setPageData] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18])
    const [page, setPage] = useState(1)
    const [previous, setPrevious] = useState(false)
    // const themeMode = useSelector(state => state.theme)
    const [textEnable, setTextEnable] = useState(false)
    const [textEnable1, setTextEnable1] = useState(false)
    const [unansweredCount, setUnansweredCount] = useState()
    const [answeredCount, setAnsweredCount] = useState()
    const [answers, setAnswers] = useState([])
    const history = useHistory()
    const dispatch = useDispatch()
    // const organization = useSelector(state => state.auth.organization)
    const [pageList] = useState([{ id: 1, completed: false }, { id: 2, completed: false }, { id: 3, completed: false }, { id: 4, completed: false }, { id: 5, completed: false }, { id: 6, completed: false }, { id: 7, completed: false }, { id: 8, completed: false }, { id: 9, completed: false }, { id: 10, completed: false }, { id: 11, completed: false }, { id: 12, completed: false }, { id: 13, completed: false }, { id: 14, completed: false }, { id: 15, completed: false }, { id: 16, completed: false }, { id: 17, completed: false }])
    const [multiSelect, setMultiSelect] = useState(false)
    const [otherDisease, setOtherDisease] = useState([])
    const [diseaseString, setDiseaseString] = useState('')
    const [diseases] = useState([])
    // const roles = useSelector(state => state.auth.userDetails?.role)
    const [downloadData] = useState('')
    const [downloadPopup, setDownloadPopup] = useState(false)
    // const userDetails = useSelector(state => state.auth.userDetails)
    const [otherDiseaseDetails, setOtherDiseaseDetails] = useState('')
    const [createCase, setCreateCase] = useState(false)
    const [formLoading] = useState(false)
    const [dataLoaded] = useState(true)
    // const [states, setStates] = useState([])
    // const [gender, setGender] = useState([])
    // const [raceEthnicity, setRaceEthnicity] = useState([])
    // const [languages, setLanguage] = useState([])
    const [relationShip, setRelationship] = useState([])
    let lang = localStorage.getItem('language')

    const diseaseFormSchema = Yup.object().shape({
        disease: Yup.array(),
    })

    const diseaseForm = useFormik({
        initialValues: initialFormValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: diseaseFormSchema,
    })

    const screenerFormSchema = Yup.object().shape({
        // caregiver: Yup.object({
        //     language: Yup.string(),
        //     firstName: Yup.string().required("FirstName is required field"),
        //     lastName: Yup.string().required("LastName is required field"),
        //     preferredName: Yup.string(),
        //     phoneNumber: Yup.string().required("Phone number is required field"),
        //     email: Yup.string(),
        //     dateOfBirth: Yup.date().required("Date of Birth is required field"),
        //     address: Yup.object({
        //         addressLine1: Yup.string(),
        //         addressLine2: Yup.string(),
        //         addressLine3: Yup.string(),
        //         city: Yup.string(),
        //         postalCode: Yup.string().required("PostalCode is a required field"),
        //         state: Yup.string().required("State is a required field")
        //     }),
        //     gender: Yup.string(),
        //     race: Yup.array()
        // }),
        careReceiver: Yup.object({
            firstName: Yup.string().nullable().max(50).required("FirstName is the required field"),
            lastName: Yup.string().required("Lastname is required field"),
            // dateOfBirth: Yup.string().required("Date of birth is required field"),
            relationshipId: Yup.string().required("Relationship is required field"),
            // address: Yup.object({ postalCode: Yup.string().required("PostalCode is required field") }),
            subscriberId: Yup.string().required("Subscriber id is a required field")
        })
    })


    const screenerForm = useFormik({
        initialValues: initialFormValue,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema: screenerFormSchema,
        onSubmit: values => {
            createScreenerCase()
        }
    })

    const createScreenerCase = () => {
        let details = {
            careReceiver: screenerForm.values.careReceiver,
            organizationId:2,
            caseId : caseId,
            assessmentId : asmtId
            // careReceiver: [screenerForm.values.careReceiver]
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}intake`, details).then(res => {
            if (res.status === 200) {
                history.push({pathname:'/screener/score',state:res.data.body})
                // history.push(`/screener/intake/assessment/${res.data.body.assessmentId}/case/${res.data.body.caseId}`)
                // history.push({ pathname: '/screener/intake', state: { assessmentId: res.data.body.assessmentId, caseId: res.data.body.caseId } })
            }
        })
    }

    useEffect(() => {
        axios.all([axios.get(`${process.env.REACT_APP_API_BASE_URL}menus/states?lang=${lang}`), axios.get(`${process.env.REACT_APP_API_BASE_URL}menus/genders?lang=${lang}`), axios.get(`${process.env.REACT_APP_API_BASE_URL}menus/races?lang=${lang}`), axios.get(`${process.env.REACT_APP_API_BASE_URL}menus/languages?lang=${lang}`), axios.get(`${process.env.REACT_APP_API_BASE_URL}menus/relationships?lang=${lang}`)]).then(axios.spread((statesResp, gendersResp,
            raceResp, languageResp, relationshipResp) => {
            // setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
            // setGender(gendersResp.data.body.map(c => ({ text: c.name, value: c.key })))
            // setRaceEthnicity(raceResp.data.body.map(c => ({ text: c.name, value: c.value })))
            // setLanguage(languageResp.data.body.map(c => ({ text: c.name, value: c.key })))
            setRelationship(relationshipResp.data.body.map(c => ({ text: c.name, value: c.value })))
        }))
    }, [])

    // useEffect(() => {
    //     API.post(`assessments/diseases`).then(res => {
    //         if (res.status === 200) {
    //             setDiseases(res.data.body.map(c => ({ text: c.diseaseName, value: c.diseaseName })))
    //         }
    //     })
    // }, [])



    useEffect(() => {
        setLoading(true)
        if (page === 17) {
            setCreateCase(true)
            setLoading(false)
        } else {
            axios.get(`${process.env.REACT_APP_API_BASE_URL}intake/assessments/${asmtId}/pages/${page + 2}/questions?screener=1&orgId=3`).then(res => {
                setAnswers([])
                setAnsweredCount(0)
                setUnansweredCount(0)
                setMultiSelect(false)
                if ((res.data.body.question.length === 0) && !previous) {
                    pageList.map((c, i) => {
                        if (page === c.id) {
                            c.completed = true
                        } return null;
                    })
                    setPage(page + 1)
                } else if ((res.data.body.question.length === 0) && previous) {
                    pageList.map((c, i) => {
                        if (page === c.id) {
                            c.completed = false
                        } return null;
                    })
                    setPage(page - 1)
                } else {
                    setQuestions(res.data.body)
                    let answer = 0;
                    let unanswer = 0;
                    let firstOtherValue = ''
                    res.data.body.question.map((c, i) => {
                        if (c.questionId === 148) {
                            c.answers.forEach((element, i) => {
                                if (element.answerId === 604 && element.selected) {
                                    firstOtherValue = element.otherText
                                    if (firstOtherValue) {
                                        setOtherDiseaseDetails(firstOtherValue)
                                    }
                                    let otherText = element.otherText.split(',')
                                    otherText.map(e => {
                                        c.answers.push({ answerId: e, answerLabel: e, questionId: c.questionId, selected: true })
                                        return null;
                                    })
                                    setMultiSelect(true)
                                }
                            })
                        }
                        if (c.questionType !== "header" && c.questionType !== "multi" && c.questionType !== "text") {
                            c.answers.forEach((element, i) => {
                                if (c.questionId === element.questionId && element.selected) {
                                    initializeAnswers(c.questionId, element.answerId, c.questionType)
                                    c.answered = true
                                    answer = answer + 1
                                    setAnsweredCount(answer)
                                }
                            });
                            unanswer = unanswer + 1
                            setUnansweredCount(unanswer)
                        } else if (c.questionType === "multi") {
                            let stopCount = 0;
                            c.answers.forEach((element, i) => {
                                if (c.questionId === element.questionId && element.selected && stopCount === 0 && element.answerId !== 604) {
                                    answers.push({ "questionId": element.questionId, "answers": [{ "answerId": element.answerId }] })
                                    setAnswers(answers)
                                    c.answered = true
                                    answer = answer + 1
                                    stopCount = stopCount + 1
                                    setAnsweredCount(answer)
                                } else if (c.questionId === element.questionId && element.selected && stopCount > 0) {
                                    initializeAnswers(c.questionId, element.answerId, c.questionType, firstOtherValue)
                                } else if (c.questionId === element.questionId && element.selected && stopCount === 0 && element.answerId === 604) {
                                    answers.push({ "questionId": element.questionId, "answers": [{ "answerId": element.answerId, answerValue: firstOtherValue }] })
                                    setAnswers(answers)
                                    c.answered = true
                                    answer = answer + 1
                                    stopCount = stopCount + 1
                                    setAnsweredCount(answer)
                                }
                            });
                            unanswer = unanswer + 1
                            setUnansweredCount(unanswer)
                        } else if (c.questionType === 'text' && c.textBox) {
                            c.answered = true
                            initializeAnswers(c.questionId, c.textBox, c.questionType)
                            answer = answer + 1;
                            setAnsweredCount(answer)
                            unanswer = unanswer + 1
                            setUnansweredCount(unanswer)
                        } else if (c.questionType === "text" && c.textBox === null) {
                            unanswer = unanswer + 1
                            setUnansweredCount(unanswer)
                        } return null;
                    })
                    setLoading(false)
                }
            }).catch(e => {
                console.log(e)
            })
        }
    }, [page]) // eslint-disable-line

    const initializeAnswers = (questionId, answerId, questionType, otherValue) => {
        if (questionType === 'text') {
            answers.push({ "questionId": questionId, "answers": [{ "answerValue": answerId }] })
            setAnswers(answers)
        } else if (questionType === "multi") {
            answers.map(c => {
                if (c.questionId === questionId) {
                    if (typeof (answerId) === 'string') {
                    } else {
                        if (answerId === 604) {
                            c.answers.push({ "answerId": answerId, answerValue: otherValue })
                            setAnswers(answers)
                        } else {
                            c.answers.push({ "answerId": answerId })
                            setAnswers(answers)
                        }
                    }
                } return null;
            })
            // answers.push({ "questionId": c.questionId, "answers": [{ "answerId": answerValue[answerValue-1] }] })
        } else {
            answers.push({ "questionId": questionId, "answers": [{ "answerId": answerId }] })
            setAnswers(answers)
        }
    }


    const getAnsweredCount = () => {
        let count = 0
        questions.question.map((c, i) => {
            if ((c.questionType !== "headers") && (c.answered === true)) {
                count = count + 1
                setAnsweredCount(count)
            } return null;
        })
    }

    const setField = (id, value) => {
        let method = value.split(',')[1];
        if (method === 'add') {
            otherDisease.push(value.split(',')[0])
            setOtherDisease(otherDisease)
            let output = otherDisease.join(',')
            answers.map(c => {
                if (c.questionId === 148) {
                    c.answers.map(e => {
                        if (e.answerId === 604) {
                            if (otherDiseaseDetails) {
                                e.answerValue = otherDiseaseDetails + ',' + output;
                                setDiseaseString(e.answerValue)
                            } else {
                                e.answerValue = output;
                                setDiseaseString(e.answerValue)
                            }
                        } return null;
                    })
                } return null;
            })
        } else {
            otherDisease.map((c, i) => {
                if (value.split(',')[0] === c) {
                    otherDisease.splice(i, 1)
                } return null;
            })
            let output = otherDisease.join(',')
            answers.map(c => {
                if (c.questionId === 148) {
                    c.answers.map(e => {
                        if (e.answerId === 604) {
                            e.answerValue = output;
                            setDiseaseString(e.answerValue)
                        } return null;
                    })
                } return null;
            })
        }
    }

    const setTextFieldValue = (id, value) => {

        answers.map(c => {
            if (c.questionId === id && id === 175) {
                c.answers[0].answerValue = value
                setAnswers(answers)
            } else if (c.questionId === id && id === 178) {
                c.answers[0].answerValue = value
            } else { }
            return null;
        })
    }
    let diseaseDetails = [];
    const setFieldValue = (id, value) => {
        setPageData(questions.question.map((c, i) => {
            if (c.questionId === id) {
                if (!c.answered) {
                    c.answered = true
                    getAnsweredCount()
                    if (c.questionType === 'radio' || c.questionType === 'select' || c.questionType === 'survey') {
                        if (value === 879 || value === 918) {
                            answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value, "answerValue": '' }] })
                        } else {
                            answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value }] })
                        }
                        c.answers = c.answers.map(e => {
                            if (c.questionId === 175) {
                                if (e.answerId === 879 && value === 879) {
                                    setTextEnable(true)
                                } else if (e.answerId === 880 && value === 880) {
                                    setTextEnable(false)
                                } else { }
                            }
                            if (c.questionId === 178) {
                                if (e.answerId === 918 && value === 918) {
                                    setTextEnable1(true)
                                } else if (e.answerId === 919 && value === 919) {
                                    setTextEnable1(false)
                                } else { }
                            }
                            if (c.questionId === 106) {
                                if (e.answerId !== 424 && value !== 424) {
                                    questions.question.map(c => {
                                        if (c.questionId === 107 || c.questionId === 108) {
                                            c.answers.map((e, i) => {
                                                if (i === 0 || i === c.answers.length - 1) {
                                                    e.selected = false
                                                } return null;
                                            })
                                        } return null;
                                    })
                                }
                            }
                            if (e.answerId === 424 && value === 424) {
                                e.selected = true
                                // answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value }] })
                                questions.question.map(c => {
                                    if (c.questionId === 107 || c.questionId === 108) {
                                        c.answers.map((e, i) => {
                                            c.answered = true
                                            getAnsweredCount()
                                            if (i === 0) {
                                                answers.push({ "questionId": c.questionId, "answers": [{ "answerId": e.answerId }] })
                                                e.selected = true
                                            } else {
                                                e.selected = false
                                            } return null;
                                        })
                                    } return null;
                                })
                            } else if (e.answerId === 972 && value === 972) {
                                e.selected = true
                                // answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value }] })
                                questions.question.map(c => {
                                    if (c.questionId === 107 || c.questionId === 108) {
                                        c.answers.map((e, i) => {
                                            c.answered = true
                                            getAnsweredCount()
                                            if (i === c.answers.length - 1) {
                                                answers.push({ "questionId": c.questionId, "answers": [{ "answerId": e.answerId }] })
                                                e.selected = true
                                            } else {
                                                e.selected = false
                                            } return null;
                                        })
                                        // c.answers[c.answers.length - 1].selected = true
                                    } return null;
                                })
                            } else if (e.answerId === value) {
                                e.selected = true
                            } else {
                                e.selected = false
                            }
                            return e
                        })
                    } else if (c.questionType === 'multi') {
                        if (c.questionId === 148) {
                            let count = 0
                            c.answers.map(e => {
                                if (e.answerId === 604 && e.selected) {
                                    setMultiSelect(true)
                                    let answerValue = value.map(e => {
                                        if (e.selected) {
                                        }
                                        return e.answerId

                                    })
                                    answerValue = answerValue.filter(e => e !== undefined)
                                    answers.push({ "questionId": c.questionId, "answers": [{ "answerId": answerValue[0], answerValue: diseaseString }] })
                                    c.answers = value
                                } else if (e.answerId === 604 && !e.selected) {
                                    setMultiSelect(false)
                                } else {
                                    if (count === 0 && c.questionId === 148 && e.answerId !== 604) {
                                        let answerValue = value.map(e => {
                                            if (e.selected) {
                                            }
                                            return e.answerId

                                        })
                                        answerValue = answerValue.filter(e => e !== undefined)
                                        count = count + 1
                                        answers.push({ "questionId": c.questionId, "answers": [{ "answerId": answerValue[0] }] })
                                        c.answers = value
                                    }
                                } return null;
                            })
                        } else {
                            let answerValue = value.map(e => {
                                if (e.selected) {
                                }
                                return e.answerId

                            })
                            answerValue = answerValue.filter(e => e !== undefined)
                            answers.push({ "questionId": c.questionId, "answers": [{ "answerId": answerValue[0] }] })
                            c.answers = value
                        }
                    } else if (c.questionType === 'text') {
                        if (!value) {
                            c.answered = false
                            c.textBox = value
                        } else {
                            c.textBox = value
                            answers.push({ "questionId": c.questionId, "answers": [{ "answerValue": value }] })
                        }
                    }
                } else {
                    if (c.questionType === 'radio' || c.questionType === 'select' || c.questionType === 'survey') {
                        answers.map((f, i) => {
                            if (f.questionId === c.questionId) {
                                answers.splice(i, 1)
                                answers.push({ "questionId": c.questionId, "answers": [{ "answerId": value }] })
                            } return null;
                        })
                        c.answers = c.answers.map(e => {
                            if (c.questionId === 175) {
                                if (e.answerId === 879 && value === 879) {
                                    setTextEnable(true)
                                } else if (e.answerId === 880 && value === 880) {
                                    setTextEnable(false)
                                } else { }
                            }
                            if (c.questionId === 178) {
                                if (e.answerId === 918 && value === 918) {
                                    setTextEnable1(true)
                                } else if (e.answerId === 919 && value === 919) {
                                    setTextEnable1(false)
                                } else { }
                            }
                            if (c.questionId === 106) {
                                if (e.answerId !== 424 && value !== 424) {
                                    questions.question.map(c => {
                                        if (c.questionId === 107 || c.questionId === 108) {
                                            c.answers.map((e, i) => {
                                                c.answered = false
                                                getAnsweredCount()
                                                // setUnansweredCount(answeredCount - 1)
                                                if (i === 0 || i === c.answers.length - 1) {
                                                    e.selected = false
                                                    answers.map((f, i) => {
                                                        if (e.answerId === f.answers[0].answerId) {
                                                            answers.splice(i, 1)
                                                        } return null;
                                                    })
                                                } return null;
                                            })
                                        } return null;
                                    })
                                }
                            }
                            if (e.answerId === 424 && value === 424) {
                                e.selected = true
                                questions.question.map(c => {
                                    if (c.questionId === 107 || c.questionId === 108) {
                                        c.answers.map((e, i) => {
                                            c.answered = true
                                            getAnsweredCount()
                                            if (i === 0) {
                                                answers.push({ "questionId": c.questionId, "answers": [{ "answerId": e.answerId }] })
                                                e.selected = true
                                            } else {
                                                answers.map((f, i) => {
                                                    if (e.answerId === f.answers[0].answerId) {
                                                        answers.splice(i, 1)
                                                    } return null;
                                                })
                                                e.selected = false
                                            } return null;
                                        })
                                    } return null;
                                })
                            } else if (e.answerId === 972 && value === 972) {
                                e.selected = true
                                questions.question.map(c => {
                                    if (c.questionId === 107 || c.questionId === 108) {
                                        c.answers.map((e, i) => {
                                            if (i === c.answers.length - 1) {
                                                c.answered = true
                                                answers.push({ "questionId": c.questionId, "answers": [{ "answerId": e.answerId }] })
                                                e.selected = true
                                            } else {
                                                answers.map((f, i) => {
                                                    if (e.answerId === f.answers[0].answerId) {
                                                        answers.splice(i, 1)
                                                    } return null;
                                                })
                                                e.selected = false
                                            } return null;
                                        })
                                        // c.answers[c.answers.length - 1].selected = true
                                    } return null;
                                })
                            } else if (e.answerId === value) {
                                e.selected = true
                            } else {
                                e.selected = false
                            }
                            return e
                        })
                    } else if (c.questionType === 'multi') {
                        if (c.questionId === 148) {
                            c.answers.map(e => {
                                if (e.answerId === 604 && e.selected) {
                                    setMultiSelect(true)
                                    let answerValue = value.map(e => {
                                        if (e.selected && e.answerId === 604) {
                                        }
                                        return e.answerId

                                    })
                                    answerValue = answerValue.filter(e => e !== undefined)
                                    let details = []
                                    answerValue.map(c => {
                                        details.push({ answerId: c, answerValue: diseaseString })
                                        return c;
                                    })
                                    let count = 0
                                    if (count === 0) {
                                        answers.map((e, i) => {
                                            if (e.questionId === id) {
                                                e.answers.push(...details)
                                            } return null;
                                        })
                                    }

                                    c.answers = value
                                } else if (e.answerId === 604 && !e.selected) {
                                    setMultiSelect(false)
                                } else {
                                    if (typeof (e.answerId) === 'number') {
                                        let answerValue = value.map(e => {
                                            if (e.selected && e.answerId !== 604) {
                                            }
                                            return e.answerId

                                        })
                                        answerValue = answerValue.filter(e => e !== undefined)
                                        let details = []
                                        answerValue.map((c, i) => {
                                            if (typeof (c) === 'string') {
                                                answerValue.splice(i, 1)
                                            } else {
                                                details.push({ answerId: c })
                                            } return null;
                                        })
                                        let count = 0
                                        if (count === 0) {
                                            answers.map((e, i) => {
                                                if (e.questionId === id) {
                                                    answers.splice(i, 1)
                                                    count = count + 1
                                                    if (details.length === 0) {
                                                        setAnsweredCount(answeredCount - 1)
                                                        c.answered = false
                                                    }
                                                    if (details.length > 0) {
                                                        answers.push({ questionId: e.questionId, answers: details })
                                                    }
                                                } return null;
                                            })
                                        }
                                        c.answers = value
                                    } else {
                                        if (typeof (e.answerId) === 'string') {
                                            if (e.selected) {
                                                diseaseDetails.push(e.answerId)
                                            }
                                            answers.map(e => {
                                                if (e.questionId === 148) {
                                                    e.answers.map((f, i) => {
                                                        if (f.answerId !== 604 && f.answerId === e.answers[i].answerId) {
                                                            e.answers.splice(i, 1)
                                                        } else if (f.answerId === 604) {
                                                            if (diseaseString) {
                                                                setDiseaseString([diseaseString].filter(c => c !== e.answerId))
                                                                setOtherDiseaseDetails([otherDiseaseDetails].filter(c => c !== e.answerId))
                                                                e.answers[i].answerValue = diseaseDetails + ',' + diseaseString
                                                            } else {
                                                                let temp = diseaseDetails.join(',')
                                                                setDiseaseString([diseaseString].filter(c => c !== e.answerId))
                                                                setOtherDiseaseDetails([otherDiseaseDetails].filter(c => c !== e.answerId))
                                                                e.answers[i].answerValue = temp
                                                            }
                                                        }
                                                        return f
                                                    })
                                                    return e
                                                } return null;
                                            })
                                        }
                                    }
                                } return null;
                            })
                        } else {
                            let answerValue = value.map(e => {
                                if (e.selected && e.answerId !== 604) {
                                }
                                return e.answerId

                            })
                            answerValue = answerValue.filter(e => e !== undefined)
                            let details = []
                            answerValue.map(c => {
                                details.push({ answerId: c })
                                return null;
                            })
                            let count = 0
                            if (count === 0) {
                                answers.map((e, i) => {
                                    if (e.questionId === id) {
                                        answers.splice(i, 1)
                                        count = count + 1
                                        if (details.length === 0) {
                                            c.answered = false
                                            getAnsweredCount()
                                        }
                                        if (details.length > 0) {
                                            answers.push({ questionId: e.questionId, answers: details })
                                        }
                                    } return null;
                                })
                            }
                            c.answers = value
                        }
                    } else if (c.questionType === 'text') {
                        if (!value) {
                            answers.map((e, i) => {
                                if (e.questionId === id) {
                                    answers.splice(i, 1)
                                } return null;
                            })
                            setAnsweredCount(answeredCount - 1)
                            c.answered = false
                            c.textBox = value
                        } else {
                            answers.map(e => {
                                if (e.questionId === id) {
                                    e.answers[0].answerValue = value
                                    c.textBox = value
                                } return null;
                            })
                        }
                    }
                }
            }
            return c
        }))
    }

    const previousPage = () => {
        if (page > 1) {
            setPrevious(true)
            setPage(page - 1)
            setUnansweredCount(0)
            setAnsweredCount(0)
            setAnswers([])
            setLoading(true)
        }
    }

    const nextPage = () => {
        setPrevious(false)
        pageList.map((c, i) => {
            if (page === c.id) {
                c.completed = true
            } return null;
        })
        let details = {
            "assessmentId": asmtId,
            "assessmentComplete": false,
            "caseId": caseId,
            questions: answers,
            // orgId: 10065 // sandbox
            orgId: 2// molina
        }
        let charCount = 0
        let loopCount = 0
        answers.map(e => {
            if (e.questionId === 172 || e.questionId === 173 || e.questionId === 30) {
                loopCount = loopCount + 1
                if (/^\d*$/.test(e.answers[0].answerValue)) {
                    charCount = charCount + 1
                }
            } return null;
        })
        if (page === 1 && (loopCount !== charCount)) {
            dispatch(setAlert({ title: 'Warning', subtitle: 'TextField Accept Only Number', type: 'warning', active: true }))
        } else if (page === 2 && (loopCount !== charCount)) {
            dispatch(setAlert({ title: 'Warning', subtitle: 'TextField Accept Only Number', type: 'warning', active: true }))
        } else if (loopCount === charCount) {
            axios.patch(`${process.env.REACT_APP_API_BASE_URL}intake/cases/${caseId}/assessment`, details).then(res => {
                if (res.status === 200) {
                    setUnansweredCount(0)
                    setAnsweredCount(0)
                    setAnswers([])
                    setPage(page + 1);
                    setLoading(true)
                }
            })
        }
    }



    const submitPage = () => {
        setPrevious(false)
        pageList.map((c, i) => {
            if (page === c.id) {
                c.completed = true
            } return null;
        })
        let details = {
            assessmentId: asmtId,
            assessmentComplete: true,
            caseId: caseId,
            questions: answers,
            // orgId: 10065 //sandbox
            orgId: 2// molina
        }
        let count = 0
        let otherSelected = false
        let charCount = 0
        let loopCount = 0
        let textfield = 0
        answers.map(e => {
            if (e.questionId === 175 || e.questionId === 178) {
                loopCount = loopCount + 1
                if (e.answers[0].answerValue !== undefined && e.answers[0].answerValue.length === 0) {
                    textfield = textfield + 1
                }
                if (e.answers[0].answerValue !== undefined && /^\d*$/.test(e.answers[0].answerValue)) {
                    charCount = charCount + 1
                } else if (e.answers[0].answerValue === undefined) {
                    loopCount = loopCount - 1
                }
            } return null;
        })
        answers.map(c => {
            if (c.questionId === 148) {
                c.answers.map(e => {
                    if (e.answerId === 604) {
                        otherSelected = true
                        if (e.answerValue) {
                            count = count + 1
                        } else {
                            dispatch(setAlert({ title: 'Warning', subtitle: 'Either you need to select the disease or enter the disease', type: 'warning', active: true }))
                        }
                    } return null;
                })
            } return null;
        })
        if (textfield === 2) {
            dispatch(setAlert({ title: 'Warning', subtitle: 'TextField required a values', type: 'warning', active: true }))
        } else if (textfield === 1) {
            dispatch(setAlert({ title: 'Warning', subtitle: 'TextField required a values', type: 'warning', active: true }))
        } else if (loopCount !== charCount) {
            dispatch(setAlert({ title: 'Warning', subtitle: "If you clicked 'Yes' for the question 3 and 4 kindly please provide the values in number", active: true, type: 'warning' }))
        } else if ((!otherSelected && count === 0 && charCount === loopCount) || (otherSelected && count > 0 && charCount === loopCount)) {
            axios.patch(`${process.env.REACT_APP_API_BASE_URL}intake/cases/${caseId}/assessment`, details).then(res => {
                if (res.status === 200) {
                    setPage(page + 1)
                } else if (res.status === 500) {
                    dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
                }
            }).catch(err => {
                dispatch(setAlert({ title: 'Error', subtitle: 'Something went wrong', type: 'error', active: true }))
                console.log(err)
            })
        }
    }

    // const printAssessment = (asmtId, page) => {
    //     API.post(`assessments/${asmtId}:printForm`).then(res => {
    //         setDownloadData(`data:application/pdf;base64,${res.data.body}`)
    //         setDownloadPopup(true)
    //     })
    // }
    const downloadFiles = () => {
        const linkSource = downloadData;
        const downloadLink = document.createElement("a");
        const fileName = 'TCE-ASSESSMENT.pdf';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        setDownloadPopup(false)
    }


    return (
        <div className={'flex-1'}>
            {
                loading ? <AssessmentLoader /> :
                    <div className="flex-1 pl-5 h-full bg-bluegray-100 dark:bg-mainbg text-black dark:text-white text-left">
                        <div className="flex justify-end">
                        <div className="flex flex-1 items-center">
                            <AssessmentPages pages={pageList} page={page} />
                        </div>
                    </div>
                        {
                            (page === 3 || page === 11 || page === 13) ? <div ref={quesWrap} className="mt-1 mb-5 overflow-y-auto height_less_topbar -mr-8 pr-8">
                                {
                                    questions.question.map((c, i) => {
                                        if (c.questionType === "radio") {
                                            return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className={`flex justify-between items-center`}>
                                                    <div className="w-11/12 text-sm">
                                                        {c.questionLabel}
                                                    </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                </div>
                                                <div className="mt-4 pb-3">
                                                    <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                </div>
                                                {
                                                    (c.questionId === 175 && textEnable) ? <AssessmentTextField edit={true} type={'number'} value={c.textBox} setValue={val => setTextFieldValue(c.questionId, val)} ml={true} length={'2'} /> : ''
                                                }
                                                {
                                                    (c.questionId === 178 && textEnable1) ? <AssessmentTextField edit={true} type={'number'} value={c.textBox} setValue={val => setTextFieldValue(c.questionId, val)} ml={true} length={'2'} /> : ''
                                                }
                                            </motion.div>
                                        } else if (c.questionType === "text") {
                                            return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className={`flex justify-between items-center`}>
                                                    <div className="w-11/12 text-sm">
                                                        {c.questionLabel}
                                                    </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                </div>
                                                <div className="mt-4 pb-3">
                                                    <AssessmentTextField edit={true} type={'number'} value={c.textBox} setValue={val => setFieldValue(c.questionId, val)} ml={true} length={'2'} />
                                                </div>
                                            </motion.div>
                                        } else if (c.questionType === "select") {
                                            return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className={`flex justify-between items-center`}>
                                                    <div className="w-11/12 text-sm">
                                                        {c.questionLabel}
                                                    </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                </div>
                                                <div className="mt-4 pb-3">
                                                    <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                </div>
                                            </motion.div>
                                        } else if (c.questionType === "multi") {
                                            return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className={`flex justify-between items-center`}>
                                                    <div className="w-11/12 text-sm">
                                                        {c.questionLabel}
                                                    </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                </div>
                                                <div className="mt-4 pb-3">
                                                    <AssessmentOptionField edit={true} type={'multi_option'} value={c.answers} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                </div>
                                                {(multiSelect && c.questionId === 148) ? <div className="mt-4 pb-3">
                                                    <AssessmentOptionChip edit={true} label={'Disease'} type={'option'} fieldHelper={diseaseForm.getFieldHelpers('disease')} fieldMeta={diseaseForm.getFieldMeta('disease')} fieldProps={diseaseForm.getFieldProps('disease')} dataLoaded={true} options={diseases} setAnswer={val => setField(c.questionId, val, 'add')} />
                                                </div> : ''}
                                            </motion.div>
                                        } else if (c.questionType === 'header') {
                                            return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className={`flex justify-between items-center`}>
                                                    <div className="w-11/12 text-sm" style={{ fontWeight: 'bold' }}>
                                                        {c.questionLabel}
                                                    </div>
                                                </div>
                                            </motion.div>
                                        } else if (c.questionType === 'survey') {
                                            return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.questionType === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                <div className={'flex justy-between items-center'}>
                                                    <div className={'w-11/12 text-sm'}>
                                                        {c.questionLabel}
                                                    </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                </div>
                                                <div className={'mt-4 pb-3'}>
                                                    <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                </div>
                                            </motion.div>
                                        } return null;
                                    })
                                }
                            </div> : (!createCase) ?
                                <div ref={quesWrap} className="mt-1 mb-5 overflow-y-auto height_less_topbar -mr-8 pr-8">
                                    {
                                        questions.question.map((c, i) => {
                                            if (c.questionType === "radio") {
                                                return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                    <div className={`flex justify-between items-center`}>
                                                        <div className="w-11/12 text-sm">
                                                            {c.questionLabel}
                                                        </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                    </div>
                                                    <div className="mt-4 pb-3">
                                                        <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                    </div>
                                                    {
                                                        (c.questionId === 175 && textEnable) ? <AssessmentTextField edit={true} type={'number'} value={c.textBox} setValue={val => setTextFieldValue(c.questionId, val)} ml={true} length={'2'} /> : ''
                                                    }
                                                    {
                                                        (c.questionId === 178 && textEnable1) ? <AssessmentTextField edit={true} type={'number'} value={c.textBox} setValue={val => setTextFieldValue(c.questionId, val)} ml={true} length={'2'} /> : ''
                                                    }

                                                </motion.div>
                                            } else if (c.questionType === "text") {
                                                return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                    <div className={`flex justify-between items-center`}>
                                                        <div className="w-11/12 text-sm">
                                                            {c.questionLabel}
                                                        </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                    </div>
                                                    <div className="mt-4 pb-3">
                                                        <AssessmentTextField edit={true} type={'number'} value={c.textBox} setValue={val => setFieldValue(c.questionId, val)} ml={true} length={'2'} />
                                                    </div>
                                                </motion.div>
                                            } else if (c.questionType === "select") {
                                                return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                    <div className={`flex justify-between items-center`}>
                                                        <div className="w-11/12 text-sm">
                                                            {c.questionLabel}
                                                        </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                    </div>
                                                    <div className="mt-4 pb-3">
                                                        <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                    </div>
                                                </motion.div>
                                            } else if (c.questionType === "multi") {
                                                return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                    <div className={`flex justify-between items-center`}>
                                                        <div className="w-11/12 text-sm">
                                                            {c.questionLabel}
                                                        </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                    </div>
                                                    <div className="mt-4 pb-3">
                                                        <AssessmentOptionField edit={true} type={'multi_option'} value={c.answers} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                    </div>
                                                    {(multiSelect && c.questionId === 148) ? <div className="mt-4 pb-3">
                                                        <AssessmentOptionChip edit={true} label={'Disease'} type={'option'} fieldHelper={diseaseForm.getFieldHelpers('disease')} fieldMeta={diseaseForm.getFieldMeta('disease')} fieldProps={diseaseForm.getFieldProps('disease')} dataLoaded={true} options={diseases} setAnswer={val => setField(c.questionId, val)} />
                                                    </div> : ''}
                                                </motion.div>
                                            } else if (c.questionType === 'header') {
                                                return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.type === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                    <div className={`flex justify-between items-center`}>
                                                        <div className="w-11/12 text-sm" style={{ fontWeight: 'bold' }}>
                                                            {c.questionLabel}
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            } else if (c.questionType === 'survey') {
                                                return <motion.div initial="hidden" animate="visible" exit="hidden" className={`mt-3 ${c.questionType === 'heading' ? '' : 'form-wrap py-4'}`}>
                                                    <div>
                                                        <div className={'flex justify-start'}>
                                                            <div className={'flex justy-between items-center'} style={{ width: '35%' }}>
                                                                <div className={'w-11/12 text-sm'}>
                                                                    {c.questionLabel}
                                                                </div><span>{c.answered ? <FaCheck size="12px" className={'text-green-500'} /> : ''}</span>
                                                            </div>
                                                            <div className={'flex flex-1'}>
                                                                <AssessmentOptionField edit={true} type={'option'} value={c.answers.answerValue} options={c.answers} setValue={val => setFieldValue(c.questionId, val)} />
                                                            </div>
                                                        </div>
                                                        {/* <hr className={'mt-5'} style={(themeMode.themeMode === 'light') ? { border: '1px solid grey' } : {}} /> */}
                                                    </div>
                                                </motion.div>
                                            } return null;
                                        })
                                    }
                                </div> :
                                <div ref={quesWrap} className="mt-1 mb-5 overflow-y-auto height_less_topbar pr-10">
                                    <div className="text-black dark:text-white text-left mt-8 grid grid-cols-1 2xl:grid-cols-1 gap-4 m-2">
                                        <div className=" py-4">
                                            <FlowText text="Care Receiver First Name"/>
                                        <FieldInput loading={formLoading}  autoComplete="off" fieldMeta={screenerForm.getFieldMeta('careReceiver.firstName')} fieldProps={screenerForm.getFieldProps('careReceiver.firstName')} fieldHelper={screenerForm.getFieldHelpers('careReceiver.firstName')} edit={true} dataLoaded={dataLoaded} />
                                        </div>
                                        <div className=" py-4"> 
                                            <FlowText text="Care Receiver Last Name"/>
                                            <FieldInput loading={formLoading}  autoComplete="off" fieldMeta={screenerForm.getFieldMeta('careReceiver.lastName')} fieldProps={screenerForm.getFieldProps('careReceiver.lastName')} fieldHelper={screenerForm.getFieldHelpers('careReceiver.lastName')} edit={true} dataLoaded={dataLoaded} />
                                            </div>
                                        <div className=" py-4">
                                            <FlowText text="Relationship"/>
                                        <FieldSelect label={'Relationship'} loading={formLoading} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('careReceiver.relationshipId')} fieldProps={screenerForm.getFieldProps('careReceiver.relationshipId')} fieldHelper={screenerForm.getFieldHelpers('careReceiver.relationshipId')} options={relationShip} edit={true} dataLoaded={dataLoaded} />
                                            </div>
                                        <div className=" py-4">
                                            <FlowText text="Subscriber Id"/>
                                            <FieldInput loading={formLoading} autoComplete="off" fieldMeta={screenerForm.getFieldMeta('careReceiver.subscriberId')} fieldProps={screenerForm.getFieldProps('careReceiver.subscriberId')} fieldHelper={screenerForm.getFieldHelpers('careReceiver.subscriberId')} edit={true} dataLoaded={dataLoaded}/>
                                            </div>
                                    </div>
                                    <div className="flex justify-center mx-4">
                                        <Button injectClass="mt-10 py-1 py-2" onClick={() => screenerForm.submitForm()}>Submit</Button>
                                    </div>
                                </div>
                        }
                       {(page <= 16) ? <div className="flex justify-center mx-4">
                            <div className={`flex items-center rounded-lg text-white px-3 py-1 bg-tcolor ${page > 1 ? 'cursor-pointer hover:ring-2 hover:ring-tcolor hover:ring-offset-2 hover:ring-offset-bluegray-300 dark:hover:ring-offset-ldark' : 'cursor-not-allowed opacity-50'} ml-2 mr-2`} onClick={previousPage}>
                                <FaArrowLeft title="Next" size="15px" />
                                <span className="ml-2 text-sm font-medium">Previous</span>
                            </div>
                            <div className="flex gap-3">
                                {page <= 15 ?
                                    <div className={`flex items-center rounded-lg text-white px-3 py-1 bg-tcolor ${((answeredCount === unansweredCount) && (page <= 15)) ? 'cursor-pointer hover:ring-2 hover:ring-tcolor hover:ring-offset-2 hover:ring-offset-bluegray-300 dark:hover:ring-offset-ldark' : 'cursor-not-allowed opacity-50'} ml-2 mr-2`} onClick={((answeredCount === unansweredCount) && (page <= 15)) ? nextPage : ''}>
                                        <span className="mr-2 text-sm font-medium">Next</span>
                                        <FaArrowRight title="Next" size="15px" />
                                    </div>
                                    : <div className={`flex items-center rounded-lg text-white px-3 py-1 bg-tcolor ${((answeredCount === unansweredCount)) ? 'cursor-pointer hover:ring-2 hover:ring-tcolor hover:ring-offset-2 hover:ring-offset-bluegray-300 dark:hover:ring-offset-ldark' : 'cursor-not-allowed opacity-50'} ml-2 mr-2`} onClick={submitPage}>
                                        <span className="mr-2 text-sm font-medium">Submit</span>
                                        <FaArrowRight title="Next" size="15px" />
                                    </div>
                                }
                            </div>
                        </div>:''}
                    </div>
            }
            {
                createPortal(<Dialog title="Download File" showDialog={downloadPopup} onClose={() => setDownloadPopup(true)}>
                    <div className="py-2 w-full">
                        <div className="px-4 mt-2">
                            <FlowText text={`Are you sure you want to download this Assessment form?`} />
                        </div>
                        <div className="flex justify-between mt-8 mx-2">
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={() => setDownloadPopup(false)}>No</button>
                            <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => downloadFiles()}>Yes</button>
                        </div>
                    </div>
                </Dialog>, document.body)
            }
        </div>
    )
}

export default ScreenerAssessment