import { useTranslation } from "react-i18next"
import FieldInput from "../../components/snippets/FieldInput"
import * as Yup from 'yup'
import { useFormik } from "formik"
import { useEffect, useState } from "react"
import API from "../../axios/API"
import { setAlert } from "../../store/theme/actions"
import { useDispatch } from "react-redux"
import CloseSelect from "../../components/snippets/CloseSelect"
import { useHistory } from "react-router"

const initialValues = {
    template: '',
    content: ''
}



const AdHoc = ({ loading = false, onAdd, serviceId, serviceType, caseId, categoryId, onAddCategoryId, careplanId,additional = false }) => {
    const { t } = useTranslation()
    const [templates, setTemplates] = useState([])
    const [adhoc, setAdhoc] = useState([])
    const dispatch = useDispatch()
    const history = useHistory()

    Yup.addMethod(Yup.string, 'whiteSpace', function () {
        return this.test(t('whiteSpace'),t("Space not allowed at beginning"), (value) => {
            return value === undefined || value.toString().length === 0 || value.toString()[0] !== ' '
        })
    })

    const formSchema = Yup.object().shape({
        template: Yup.string().nullable(),
        content: Yup.string().whiteSpace().required(t("Content is a required field"))
    })
    const form = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            let details = {
                "adhocContent": values.content,
                "serviceId": additional ? 0  : serviceId,
                "adhocTemplate":templates.filter(e => e.value ===  values.template).length > 0 ? templates.filter(e => e.value ===  values.template)[0].text:'',
            }
            API.post(`care-plans/${careplanId}/adhoc`, details).then(res => {
                if (res.status === 200) {
                    let output = { adhocContent: null, adhocTemplate: templates.filter(e => e.value === values.template).length > 0 ? templates.filter(e => e.value === values.template)[0].text:'', comment: null, deleted: false, info: values.content, serviceId: serviceId, selectedServiceId: res.data.params, webLink: null, keyword: null, serviceName: null }
                    onAdd([output])
                    !additional && onAddCategoryId(categoryId)
                }else{
                    dispatch(setAlert({title:t('Error'),subtitle:t('Unable to add Care Plan'),type:'error',active:true}))
                }
            }).catch(err => {
                if (err.response.status === 406) {
                    dispatch(setAlert({ title: t('Warning'), subtitle: err.response.data.errorMessage, type: 'warning', active: true }))
                    history.push(`/casedata/${caseId}`)
                   
                }
            })
        }
    })
    useEffect(() => {
        API.get('resources').then(res => {
            if (res.status === 200) {
                setAdhoc(res.data.body)
                setTemplates(res.data.body.map(c => ({ text: c.adhocTemplate, value: c.adhocId })))
            }
        })
    }, [])


    useEffect(() => {
        form.setValues({ ...form.values, content: adhoc.find(c => c.adhocId === form.values.template)?.adhocContent })
    }, [form.values.template, adhoc]) // eslint-disable-line

    return (
        <div className="flex flex-col gap-4 py-2 my-2">
            <div>
                <CloseSelect ariaLabel={t('Template')} loading={loading} label={t('Template')} autoComplete='off' fieldMeta={form.getFieldMeta('template')} fieldProps={form.getFieldProps('template')} fieldHelper={form.getFieldHelpers('template')} options={templates} edit={true} dataLoaded={true} position="top" />
            </div>
            <div>
                <FieldInput ariaLabel={t('Content')} loading={loading} label={t('Content')} type="textarea" autoComplete='off' fieldMeta={form.getFieldMeta('content')} fieldProps={form.getFieldProps('content')} edit={true} dataLoaded={true} />
            </div>
            <div className="flex justify-end mr-4">
                <button disabled={loading} className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => form.submitForm()}>{t('Add to Care Plan')}</button>
            </div>
        </div>
    )
}

export default AdHoc