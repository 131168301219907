const AssessmentLoader = () => {

    return (
        <div className="flex-1 pl-5 h-full">
            <div>
                <div className="flex justify-between items-center relative w-full mr-5">
                    <div className="w-full h-0.5 absolute bg-gray-400 bg-opacity-40 animate-pulse"></div>
                    {
                        [1,2,3,4].map((c, i) => (
                            <div key={i} className="flex items-center transition-all duration-200">
                                <div className={`group relative ring-4 bg-gray-400 bg-opacity-60 ring-bluegray-100 dark:ring-mainbg hover:scale-110'} py-[6px] px-[6.5px] rounded-full text-black z-10 transform transition-all duration-500 bg-tcolor`}>
                                    <span className="w-3 h-3"></span>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="h-1.5 w-64 mt-4 mb-8 rounded bg-gray-400 animate-pulse"></div>
            {
                [1,2,3,4].map(c => (
                    <div key={c} className="form-wrap py-4 my-4">
                        <div className="h-1.5 w-2/3 mt-2 rounded bg-gray-400 animate-pulse"></div>
                        <div className={`relative px-2 py-1 mt-4 rounded flex flex-wrap gap-4 items-center`}>
                            {
                                [1,2,3].map(e => (
                                    <div key={e} className="h-1 w-48 mt-4 rounded bg-gray-400 animate-pulse"></div>
                                ))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default AssessmentLoader