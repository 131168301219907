import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import API from '../../axios/API'
import { FiSearch } from 'react-icons/fi'
import { PopInAnim, TextAnimateX } from '../../commons/anims'
import {setLoadAllCase, setReopencasecount, setTriggerSubidSearch } from '../../store/auth/actions'
import DataTable from 'react-data-table-component'
import DatatableLoader from "../../components/snippets/DatatableLoader"
import { FaLayerGroup } from 'react-icons/fa'
import QuickAction from "../../components/snippets/QuickAction"
import { setAlert } from "../../store/theme/actions"
import { AnimatePresence, motion } from 'framer-motion'
import InlineTextField from '../../components/snippets/InlineTextField'
import PopoverMenu from '../../components/snippets/PopoverMenu'
import { RiDirectionFill } from 'react-icons/ri'
import Dialog from '../../components/snippets/Dialog'
import ClosedCaseDialog from '../Dialogs/ClosedCaseDialog'
import {MdAssessment,MdSpeakerNotes} from "react-icons/md"
import ReferrerCaseDialog from "../Dialogs/ReferrerCaseDialog"
import {RiInboxArchiveFill} from 'react-icons/ri'
import NoCgDialog from '../Dialogs/NoCaregiverDialog'
import ReassessDialog from '../../components/snippets/ReassesDialog'
import Reassessments from '../Dialogs/Reassessment'
import NotesDialog from '../Dialogs/NotesDialog'
import { Link } from "react-router-dom"
import FlowText from '../../components/snippets/FlowText'

export const SubscriberSearch = ({ history , match}) => {
    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [casedetails, setCaseDetails] = useState([])
    const [selectRows, setSelectedRows] = useState([])
    const { screenSize, themeMode } = useSelector(state => state.theme)
    const [loading, setLoading] = useState(true)
    const [openMultiAction, setOpenMultiAction] = useState(false)
    const organization = useSelector(state => state.auth.organization)
    const notesAction = useSelector(state => state.auth.notesAction)
    const [closeCaseModal, setCloseCaseModal] = useState(false)
    const [noCgAvailableModal, setNoCgAvailableModal] = useState(false)
    const userDetails = useSelector(state => state.auth.userDetails)
    const [activeQuickAction, setActiveQuickAction] = useState({ position: 'bottom', active: null })
    const [columnsSelected, setColumnsSelected] = useState([])
    const caseTable = useRef(null)
    const [statusFilter, setStatusFilter] = useState()
    const [caseId, setCaseId] = useState('')
    const [assessId, setAssessId] = useState('')
    const [reassignreferrerCaseModal, setReassignReferrerCaseModal] = useState(false)
    const closecaseRender = useSelector(state => state.auth.closeCaseRender)
    const transfercaseRender = useSelector(state => state.auth.transferCaseRender)
    const childOrgTransferRender = useSelector(state => state.auth.childOrgTransferRender)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const language = useSelector(state => state.theme.language)
    const reopencount = useSelector(state => state.auth.reopencaseCount)
    let orgId = localStorage.getItem('orgid')
    const roles = useSelector(state => state.auth.userDetails?.role)
    const [hideColumn, setHideColumn] = useState(false)
    const subSearch = useSelector(state => state.auth.triggersubSearch)
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [statusList, setStatusList] = useState([])
    const [triggerAll, setTriggerAll] = useState(false)
    const [column, setColumn] = useState()
    const [sortDirection, setSortDirection] = useState()
    const [subError, setSubError] = useState(false)
    const [subScriberId, setSubscriberId] = useState('')
    const [searchedId, setSearchedId] = useState('')
    const [crName, setCrName] = useState('')
    const [crLastName, setCrLastName] = useState('')
    const [openSearch, setOpenSearch] = useState(true)
    const [pendingCrList, setPendingCrList] = useState([])
    const [pplId, setPplId] = useState()
    const [followupList, setFollowupList] = useState([])
    const [subFilter, setSubFilter] = useState('')
    const [followupFetchedList, setFollowupFetchedList] = useState([])
    const [fetchingFollowupFor, setFetchingFollowupFor] = useState(null)
    const [reassessDialogModal, setReassessDialogModal] = useState(false)
    const [assessDialogModal,setAssessDialogModal] = useState(false)
    const [crDetails, setCrDetails] = useState([])
    const [assessType, setAssessType] = useState('')
    const reopencaseCount = useSelector(state => state.auth.reopencaseCount)
    const [notesDialogModal, setNotesDialogModal] = useState(false)
    let subId = localStorage.getItem('subId')
    const [subreq, setSubReq] = useState(false)
    const [numeric,setNumeric] = useState(false)
    const [alphaNumeric,setAlphaNumeric] = useState(false)

    useEffect (() => {
        if(subId === 'true'){
            setSubReq(true)
        }
    }, [])

    const callSubscriberId = () => {
        setLoading(true);
        let regex = /^[a-zA-Z0-9_]*$/
        let regex1 = /^[0-9]*$/
        if (!regex1.test(subScriberId) && subreq) {
            setLoading(false);
            setNumeric(true)
            setTimeout(() => {
                setNumeric(false)
            }, 3000)
            setCrDetails([])
            setPendingCrList([])
            setCrName("")
            setCrLastName("")
            setCrDetails([])
            setSearchedId()
        } else if (!regex.test(subScriberId)) {
            setLoading(false);
            setAlphaNumeric(true)
            setTimeout(() => {
                setAlphaNumeric(false)
            }, 3000)
            setCrDetails([])
            setPendingCrList([])
            setCrName("")
            setCrLastName("")
            setCrDetails([])
            setSearchedId()
        } else if (subScriberId.length === 9 || subScriberId.length === 14){
            API.get(`/organizations/${orgId}/cases/subscriber/${subScriberId}`).then((res) => {
                if (res.status === 200) {
                    dispatch(setLoadAllCase(false))
                    dispatch(setTriggerSubidSearch(!subSearch))
                   if(res.data.body !== null){
                    res.data.body.history.forEach((e, i) => {
                        res.data.body.history[i].assessDate = assessDate(res.data.body.history[i].assessDate)
                           })
                   }
                    const resData = res.data.body
                    setSearchedId(subScriberId)
                    if(resData!==null){
                        setCrName(resData?.crDetails?.firstName)
                        setCrLastName(resData?.crDetails?.lastName)
                        setCrDetails(resData?.crDetails)
                        setPplId(resData?.crDetails?.personId)
                        setPendingCrList(resData.history)
                    }else{
                        setCrDetails([])
                        setPendingCrList([])
                        setCrName("")
                        setCrLastName("")
                        setCrDetails([])
                    }  
                    setLoading(false)
                    setNumeric(false)
                    setAlphaNumeric(false)
                }
            })
            // setSubError(true)
            // setTimeout(() => {
            //     setSubError(false)
            // }, 3000)
        } else {
            setSubError(true)
            setTimeout(() => {
                setSubError(false)
            }, 3000)
        }
    }

    const setValue = (value) => {
        if (value.target.value.length > 0) {
            if (value.keyCode === 13) {
                setSubscriberId(value.target.value)
                callSubscriberId()
            } else {
                setSubscriberId(value.target.value)
            }
        } else {
            dispatch(setLoadAllCase(true))
            setCrName("")
            setCrLastName("")
            setCrDetails([])
            setPplId()
            setPendingCrList([])
            setSearchedId('')
            setSubscriberId()
        }
    }

    const assessDate =(value) =>{
        if (value) {
            let timestamp = new Date(value).getTime();
            let tz = moment.tz.guess()
            let tzabbr = moment.tz.zone(tz).abbr(timestamp)
            return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr
            //     let followupdate = new Date(value)
            // return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
        } else {
            return value
        }
    }

    const reopenCase = (caseId) => {
        if (caseId !== undefined) {
            API.post(`cases:reopen?orgId=${orgId}`, [caseId]).then(res => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t('Case Reopened Successfully'), type: 'success', active: true }))
                    dispatch(setReopencasecount(reopencaseCount+1))
                    callSubscriberId(subScriberId)
                }
            }).catch(e => {
                console.log(e)
            })
        } else {
            // setCaseid([caseId])
        }
    }

    const QuickAcitonComp = useCallback((row) => {
        let options = 
            (row.status === "Screener in Progress" && row.assessType ===  "Screener Assessment" && (((userDetails.orgId === parseInt(organization)) && userDetails.parentRole.includes('screener')) || ((userDetails.orgId !== parseInt(organization)) && userDetails.childRole.includes('dynamic_scr')))) || (row.status === "Assessment in Progress" && row.assessType ===  "Screener Assessment") ? 
            [
                { icon: MdAssessment, title: t('Continue Screener'), link: `/case/${row.caseId}/assessment/${row.assessId}`},
                { icon: RiInboxArchiveFill, title: t('Close Case'), link: '', onClick: () => openCloseCaseModal(row.caseId) },
                { icon: MdSpeakerNotes, title: t('Add Case Note'), link: '', onClick: () => openAddCaseNotes(row.caseId)},

            ] :
            (row.status === "Closed") ?
            [
                { icon: MdAssessment, title: t('Re-Open'), link: '', onClick: () => reopenCase(row.caseId)},
            ] :
            (row.status === "No Caregiver" || row.status === "Pending Referrer" ) ?
            [
                { icon: RiInboxArchiveFill, title: t('Close Case'), link: '', onClick: () => openCloseCaseModal(row.caseId) },
            ] :
            (row.status === "Pending Assessment" && row.assessType === null)?
            [
                { icon: RiInboxArchiveFill, title: t('Close Case'), link: '', onClick: () => openCloseCaseModal(row.caseId) },
                { icon: MdSpeakerNotes, title: t('Add Case Note'), link: '', onClick: () => openAddCaseNotes(row.caseId)},
            ]:
            (((userDetails.orgId === parseInt(organization)) && userDetails.parentRole.includes('screener')) || ((userDetails.orgId !== parseInt(organization)) && userDetails.childRole.includes('dynamic_scr')))?
            [
                { icon: MdAssessment, title: t('Re-Screen'), link: '', onClick: () => openReassessModal(row.caseId)},
                { icon: RiInboxArchiveFill, title: t('Close Case'), link: '', onClick: () => openCloseCaseModal(row.caseId) },
                { icon: MdSpeakerNotes, title: t('Add Case Note'), link: '', onClick: () => openAddCaseNotes(row.caseId)},
            ]:[
                { icon: RiInboxArchiveFill, title: t('Close Case'), link: '', onClick: () => openCloseCaseModal(row.caseId) },
                { icon: MdSpeakerNotes, title: t('Add Case Note'), link: '', onClick: () => openAddCaseNotes(row.caseId)},
            ]

        return (
            <QuickAction id={row.caseId} active={activeQuickAction} setActive={id => setActiveQuickAction(id)} table={caseTable} options={options} />
        )
    }, [activeQuickAction, pendingCrList])// eslint-disable-line


    const colums = useMemo(() => {
        return [
            {
                cell: row => { return (searchedId ? QuickAcitonComp(row) : '' )  },
                allowOverflow: true,
                button: true,
                width: '56px',
            },
            {
                name: t('Case #'), selector: row => row.caseId,cell: row=> { return ((row.status !== 'No Caregiver' && row.status !=='Pending Referrer' && row.status !== 'Closed') ? <Link to={`/casedata/${row.caseId}`}> {row.caseId} </Link> : (row.status === 'Closed') ? <Link>{row.caseId}</Link> : (row.status === 'No Caregiver')? '' : <Link to={`/createCase/${row.caseId}/carereceiver/${pplId}/caregiver/${row.cgId}/0`}> {row.caseId} </Link> ) }, style: { cursor: "pointer", "& :hover": { textDecoration: "underline" } },sortable: true, wrap: true
             },
            {
               name: t('Caregiver Name'), selector: row => row.cgName,cell:row=>{return ((row.cgId !==0) ? row.cgName : (row.status !== 'No Caregiver') ? row.cgName : (row.status ==='Pending Referrer') ? '': t('No Caregiver Available'))}, sortable: true, wrap: true
            },
            {
                name: t('Caring For'), selector: row => row.relationship, sortable: true, wrap: true
            },
            {
                name: t('Status'), selector: row => row.status,cell:(row)=>row.status !== 'No Caregiver' ? row.status : '' ,sortable: true, wrap: true
            },
            {
                name: t('Email'),
                selector: row => row.email , sortable: true, wrap: true
            },
            { name: t('Phone'), selector: row => row.phone , sortable: true, wrap: true },
            {
                name: t('Last Assess Date'), selector: row => row.assessDate ,  sortable: true, wrap: true
            }
        ]
    }, [activeQuickAction, columnsSelected, pendingCrList]) // eslint-disable-line

    const openCloseCaseModal = (c) => {
        if (c !== undefined)
            setCaseId([c])
        setCloseCaseModal(true)
    }
    const openAddCaseNotes=(c)=>{
        if (c !== undefined)
        setCaseId([c])
        setNotesDialogModal(true)
    }
    const openReassessModal = (c) => {
        let details ={
            "screener":1
        }
        API.post(`organizations/${orgId}/cases/${c}/assessments`,details).then(res => {
            if (res.status === 200) {
                setCaseId([c])
                setAssessId(res.data?.body?.assessmentId)
                setReassessDialogModal(true)       
                //dispatch(setAlert({ title: t('Success'), subtitle: t('Assessment created successfully'), type: 'success', active: true }))
            }
        }).catch(err => {
            console.log(err)
        })
    }
    const openNoCgAvailableModal = (c,assessType) => {
        if (c !== undefined)
            setCaseId([c])
            setAssessType(assessType)
        setNoCgAvailableModal(true)
    }

    const openReassignReferrerModal = (c) => {
        if (c !== undefined)
            setCaseId([c])
        setReassignReferrerCaseModal(true)
    }

    const closeNoCGModal=()=>{
        callSubscriberId(subScriberId)
        setCrDetails([])
        setNoCgAvailableModal(false)
    }

    const closeReferalModal =() =>{
        callSubscriberId(subScriberId)
        setCloseCaseModal(false)
    }
    const SubHeader = useMemo(() => (
        <div className="flex items-center relative">
            <InlineTextField label={t("Search")} value={filter} setValue={setFilter} />
        </div>
    ), [filter, columnsSelected]) // eslint-disable-line

    const filteredFollowupList = useMemo(() => {
        let filterSplit = subFilter.toString().toLowerCase().split(' ')
        return followupList.filter(c => subFilter === '' || subFilter === null || filterSplit.every(i => Object.keys(c).some(j => c[j]?.toString().toLowerCase().includes(i))))
    }, [followupList, subFilter])

    const followupColumns = useMemo(() => {
        return [
            // { name: t('F ollow-Up Id'), center: true, selector: row=>row.rcfId, sortable: true },
            { name: t('Reason'), center: true, selector: row => row.reasonName, sortable: true },
            { name: t('Created by'), center: true, selector: row => row.createdBy, sortable: true },
            { name: t('Notes'), center: true, selector: row => { return (<div style={{ wordWrap: 'break-word', padding: '10px', whiteSpace: 'normal', width: 'auto' }}> {row.notes}</div>) } },
            { name: t('Follow-Up Date'), selector: row => { return (row.followupDate !== null) ? `${followdate(row.followupDate)}` : '' }, sortable: false },
            { name: t('Updated At'), selector: row => `${updatedate(row.createdDate)}`, sortable: false }
        ]
    }, [activeQuickAction, followupFetchedList]) // eslint-disable-line

    const SubHeaderSub = useMemo(() => (
        <div className="flex items-center relative">
            <InlineTextField label={t("Search")} autoFocus={subFilter.toString().length > 0 ? true : false} value={subFilter} setValue={setSubFilter} />
        </div>
    ), [subFilter, followupList])//eslint-disable-line

    const NoFollowup = () => (
        <div className="my-6 text-sm text-gray-500 dark:text-gray-400">
            {t('No Follow-Up to display')}
        </div>
    )

    const addCgNavigation = (crList, crDetail) => {
        if(crList?.length >0 ){
            setAssessDialogModal(true)
            setAssessId(crList[0].assessId)
            setCaseId(crList[0].caseId)
        }else{
            //history.push({ pathname: `/addCase`,state:{subId:subScriberId}})
            setAssessDialogModal(true)
            setAssessId(0)
            setCaseId(0)
        }

        // history.push({ pathname: `/createCase/${crList[0].caseId}/carereceiver/${crDetail.personId}/caregiver/0/0`, state: {cgNew:true}})
    }

    const RowComponent = useCallback(row => {
        return (
            <div className="bg-bluegray-300 dark:bg-ldark py-2 px-3" key={row.data.caseId}>
                {
                    Object.keys(row.data).map(c => (
                        <div className="flex flex-col my-1">
                            <span className="text-xs text-gray-500 dark:text-gray-300">{c.split('_').map(e => (`${e[0].toUpperCase()}${e.slice(1,).toLowerCase()}`)).join(' ')}</span>
                            <span className="text-sm" >{row.data[c]}</span>
                        </div>
                    ))
                }
            </div>
        )
    }, [])

    const updatedate = (value) => {
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr
        // let updatedates = new Date(value)
        // // updatedates = (updatedates.toUTCString().slice(4,21))
        // return `${updatedates.toUTCString().slice(8,11)}${updatedates.toUTCString().slice(4,7)}, ${updatedates.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
    }

    const followdate = (value) => {
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss') + ' ' + tzabbr

    }

    return (
        <>
            <div className="w-full flex px-4 py-2 justify-between items-center h-16">
                <div className="flex justify-end items-center">
                    {roles && (((userDetails.orgId === parseInt(organization) && (userDetails.parentRole.includes('screener') || userDetails.parentRole.includes('referrer')))) || ((userDetails.orgId !== parseInt(organization) && (userDetails.childRole.includes('dynamic_scr') || (userDetails.childRole.includes('dynamic_ref')))))) && <motion.div className="relative mr-5 ml-5 cursor-pointer flex flex-1">
                        {subError && <motion.div className="text-red-500 m-2 text-sm">{t("Please provide a valid subscriber id")}</motion.div>}
                        {numeric && <motion.div className="text-red-500 m-2 text-sm">{t("Subscriber Id should numeric value")}</motion.div>}
                        {alphaNumeric && <motion.div className="text-red-500 m-2 text-sm">{t("It should have nine alpha - numeric values")}</motion.div>}
                        {openSearch && <motion.div variants={TextAnimateX} initial="hidden" animate="visible" className={`bg-transparent flex flex-1 ring-1 dark:ring-white rounded-full`}><input maxLength={userDetails.env ==='MOLINA'?'14':'9'} autoComplete='off' className={'focus:outline-none focus:ring-0 bg-transparent text-gray-600 dark:text-white px-5 py-1'} type="text" id="subscriber" placeholder={t("Search Subscriber Id")} onKeyUp={(ev) => setValue(ev)} onInput={(ev) => setValue(ev)} onChange={(event) => {
                            const modifiedValue = event.target.value.replace(/\s/g, '');
                            event.target.value = modifiedValue;
                        }} />
                            <span className="relative right-2 top-1 text-xl flex">
                                <FiSearch className="text-tcolor dark:text-white ml-2" onClick={() => callSubscriberId(subScriberId)} />
                            </span></motion.div>}
                        {/* <FiSearch className={'text-tcolor dark:text-white m-2'} onClick={() => enableSearch(true)} /> */}
                    </motion.div>}
                </div>
            </div>

            <div>
                {searchedId ? <h3>{t("Subscriber ID")} {searchedId} <b>{crName} {crLastName}</b></h3> : ''}
            </div>
            { searchedId && 
            <div className="p-2 bg-bluegray-200 dark:bg-ddark rounded-lg relative" ref={caseTable}>
            {
                loading ?
                    <DatatableLoader /> :pendingCrList.length>0?
                    <DataTable title="pendingCrList" columns={colums} data={pendingCrList}
                        theme={themeMode === 'dark' ? "defaultDark" : "defaultLight"} pagination noHeader
                        selectableRows={(organization && userDetails && userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin')) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin')) ? true : false}
                        selectableRowsComponentProps={{"role":"columnheader"}}
                        selectableRowsHighlight persistTableHead subHeader subHeaderComponent={SubHeader} onChangePage={val => setPage(val)}
                        paginationDefaultPage={page} paginationComponentOptions={{ rowsPerPageText: 'Items per page:', rangeSeparatorText: 'of', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'All' }}
                        noDataComponent={t('There are no records to display')} />:
                        <div style={{textAlign: 'center',justifyContent: 'center'}}><FlowText text={t('There are no records to display')}/></div>
            }
            {!(roles !== undefined && roles && roles.length === 1 && roles.includes('referrer')) ?
            <button type="button" className={`relative bg-gradient-to-tr from-tcolor to-blue-300 text-sm text-white px-16 py-2 rounded-full hover:ring-2 hover:ring-offset-2 hover:ring-offset-gray-200 dark:hover:ring-offset-ldark hover:ring-tcolor hover:shadow-xl transition-all duration-300 align-middle outline-none focus:outline-none ${loading ? 'opacity-50' : ''}`} onClick={() => addCgNavigation(pendingCrList,crDetails)}>
                {t("ADD CAREGIVER")}
            </button>: "" }
            <button type="button" className={`relative bg-gradient-to-tr from-tcolor to-blue-300 text-sm text-white px-16 py-2 rounded-full hover:ring-2 hover:ring-offset-2 hover:ring-offset-gray-200 dark:hover:ring-offset-ldark hover:ring-tcolor hover:shadow-xl transition-all duration-300 align-middle outline-none focus:outline-none ${loading ? 'opacity-50' : ''}`} onClick={() => openNoCgAvailableModal(crDetails,pendingCrList)}>
                {t("NO AVAILABLE CAREGIVER")}
            </button>
            <AnimatePresence>
                {
                    selectRows.length > 0 &&
                    <motion.button variants={PopInAnim} initial="hidden" animate="visible" exit="hidden" className="flex items-center absolute bottom-6 ml-4 rounded text-white text-xs hover:bg-opacity-80" onClick={() => setOpenMultiAction(true)}>
                        <FaLayerGroup size="18px" className="mr-1 text-black dark:text-white" />
                        {selectRows.length > 0 &&
                            <span className="absolute -top-3 left-3/4 bg-tcolor rounded-lg py-0.5 px-1.5 text-[9px]">{selectRows.length}</span>
                        }
                        <PopoverMenu title={`${t('Action')} (${selectRows.length})`} origin="topright" active={openMultiAction} onClose={() => setOpenMultiAction(false)}>
                            {(selectRows[0].statusId !== 11) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openCloseCaseModal()}>
                                <RiInboxArchiveFill size="16px" className="mr-2" />
                                {t("Close Referrals")}
                            </div> : ''}
                            {/* {selectRows[0].caseStatus !== 'PENDING_REFERRER' ?: ''} */}

                            {
                                ((userDetails.orgId === parseInt(organization) && userDetails.role.includes('admin') && selectRows.every(i => i.referredByName !== null)) || (userDetails.orgId !== parseInt(organization) && userDetails.role.includes('dynamic_admin') && selectRows.every(i => i.referredByName !== null))) ? <div className="pl-4 py-2 w-full flex items-center cursor-pointer hover:text-white hover:bg-gray-500 dark:hover:bg-mdark" onClick={() => openReassignReferrerModal()}>
                                    <RiDirectionFill size="16px" className="mr-2" />
                                    {t("Reassign Referrer")}
                                </div> : ''
                            }
                        </PopoverMenu>
                    </motion.button>
                }
            </AnimatePresence>

            <Dialog title={t("Close Case")} showDialog={closeCaseModal} onClose={() => closeReferalModal()}>
                <ClosedCaseDialog onClose={() => closeReferalModal()} caseIds={caseId} />
            </Dialog>
            <ReassessDialog title={t("Reassessment Reason")} showDialog={reassessDialogModal} onClose={() => setReassessDialogModal(false)} zIndex={false}>
                <Reassessments onClose={() => setReassessDialogModal(false)} assess = {assessId} props = {caseId}/>
            </ReassessDialog>
            <ReassessDialog title={t("Assessment Reason")} showDialog={assessDialogModal} onClose={() => setAssessDialogModal(false)} zIndex={false}>
                <Reassessments onClose={() => setAssessDialogModal(false)} assess = {assessId} props = {caseId} crDetail={crDetails} subId={subScriberId}/>
            </ReassessDialog>
            <Dialog title={t("No Caregiver Available")} showDialog={noCgAvailableModal} onClose={() => closeNoCGModal()} zIndex={false}>
                <NoCgDialog onClose={() =>closeNoCGModal() } caseIds={caseId} crDetail={crDetails} assessType={assessType} subscriberId={subScriberId} refreshTable={() => callSubscriberId}/>
            </Dialog>
            <Dialog title={t("Reassign Referrer")} showDialog={reassignreferrerCaseModal} onClose={() => setReassignReferrerCaseModal(false)}>
                <ReferrerCaseDialog onClose={() => setReassignReferrerCaseModal(false)} caseIds={caseId} />
            </Dialog>
            <Dialog title={t("Add Case Notes")} showDialog={notesDialogModal} onClose={() => setNotesDialogModal(false)}>
                <NotesDialog onClose={() => setNotesDialogModal(false)} props={caseId} type={'create'} />
            </Dialog>
        </div>
            }
        </>

    )
}
