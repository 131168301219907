
import API from '../../axios/API'
import { setAlert } from '../../store/theme/actions'

import { useDispatch, useSelector } from "react-redux"

// import { useState } from "react"

import FlowText from "../../components/snippets/FlowText"
import { setChangeRelationshipCount } from '../../store/auth/actions'
import { useTranslation } from "react-i18next"



const ChangeProtocol = ({ onClose, props }) => {
    const dispatch = useDispatch()

    const  changerelationShipCount = useSelector(state =>state.auth.changerelationShipCount)
    let orgId = localStorage.getItem('orgid')
    const {t} = useTranslation()

    const updateForm = () => {
            let details = {
                "caseId": parseInt(props.caseId),
                "protocolId": props.newValue.value,
                "orgId": parseInt(orgId)
            }
            API.patch('cases/protocol', details).then((res) => {
                if (res.status === 200) {
                    dispatch(setAlert({ title: t('Success'), subtitle: t("Protocol updated successfully"), type: "success", active: true }))
                    dispatch(setChangeRelationshipCount(changerelationShipCount+1))
                    onClose();
                }
            }).catch(e => {
                console.log(e)
            })
    }
    return (
        <div className="py-2 w-full">
            <div className="px-4 mt-2">
                <FlowText text={t(`Are you sure you want to change the Protocol?`)}/>
            </div>
            <div className="flex justify-between mt-8 mx-2">
                <button className="flex items-center relative py-1 px-4 ml-2 rounded text-red-500 font-bold hover:opacity-90 hover:ring-1 hover:ring-red-500" onClick={onClose}>{t("Cancel")}</button>
                <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" onClick={() => updateForm()}>{t("Update")}</button>
            </div>
        </div>
    )
}

export default ChangeProtocol