
import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import API from '../../axios/API'
import * as Yup from 'yup';
// import FieldSelect from '../../components/snippets/FieldSelect'
import FieldInput from '../../components/snippets/FieldInput'
import { AnimatePresence, motion } from 'framer-motion';
import { PopInAnim } from '../../commons/anims';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../store/theme/actions';
import { setCloseCaseRender} from  '../../store/auth/actions';
import { useTranslation } from "react-i18next"
import CloseSelect from '../../components/snippets/CloseSelect';
import { setDemographic } from '../../store/auth/actions';
import FieldDate from '../../components/snippets/FieldDate';
import FieldSelect from "../../components/snippets/FieldSelect"
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa"
import FieldMultiSelect from "../../components/snippets/FieldMultiSelect"



const NoCgDialog = ({ onClose,caseIds, crDetail,assessType,subscriberId }) => {
    const [closeReason, setCloseReason] = useState([])
    const [dataLoaded, setDataLoaded] = useState(true) // revert
    const [inputEnable, setInputEnable] = useState(false)
    const render = useSelector(state =>state.auth.closeCaseRender)
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [formLoading, setFormLoading] = useState(false) // revert
    // const [formData, setFormData] = useState()
    const [cgRefused,setCgRefused] = useState(false)
    const [birthyear1, setBirthYear1] = useState(false)
    const demographiccount = useSelector(state => state.auth.demographiccount)
    const [nextButtonDisable,setNextButtonDisable] = useState(false)
    const [years,setYears] = useState([])
    const [crRefused,setCrRefused] = useState(false)
    const [programName1, setProgramName1]=useState(true)
    const [programName, setProgramName] = useState([])
    const [datePscp, setDatePscp]=useState(true)
    const [zipEnable, setZipEnable] = useState(true)
    const [states, setStates] = useState([])
    const [gender, setGender] = useState([])
    const [relationShip, setRelationship] = useState([])
    const [cgRace,setCgRace] = useState([])
    const [language, setLanguage] = useState([])
    let orgId = localStorage.getItem('orgid')
    let lang = localStorage.getItem('language')
    const menuItems = JSON.parse(localStorage.getItem('tcareMenuItems'))

    const initialFormValues = {
        pcspDate:'',
        address: {
                state: '',
                country: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                postalCode: '',
            },
        phone: '',
        firstName: '',
        lastName: '',
    }

const formData = useMemo(() => {
    if(crDetail?.pcspDate===null){
        crDetail.pcspDate= ""
    }
 return {...initialFormValues,...crDetail.pcspDate,subscriberId } // ...initialFormValues,...crDetail.pcspDate,subscriberId <==> updated ...initialFormValues,...crDetail.pcspDate,subscriberId
    }, [crDetail,subscriberId])
    
    Yup.addMethod(Yup.string, 'onlyAlphabet', function () {
        return this.test(t('onlyAlphabet'), t("Only Alphabets Allowed"), (value) => {
            if(value === undefined || value === '') return true
            let r = /^[^-\s][a-zA-Z_0-9\s-]+$/g
            return r.test(value)
        })
    })
    Yup.addMethod(Yup.string, 'validateDescription', function () {
        return this.test(t('Empty space at begining'),t('Space at beginning is not allowed'), (value) => {
            if (value === undefined || value === '') return true
            let r = /^[^-\s]/g
            return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'validateName', function () {
        return this.test(t('Space is not allowed at the beginning'), t('Space is not allowed at the beginning'), (value) => {
             if (value === undefined || value === '') return true
              let r = /^[^-\s]/g
              return r.test(value)
        })
    })

    Yup.addMethod(Yup.string, 'validatePhoneLength', function () {
        return this.test(t('validPhone'), t("Not a Valid Phone"), (value) => {
            if (value === undefined || value === '') return true
            // return (value.replace(/ /g, '').length === 13 || value.replace(/ /g, '').length === 12)
            return (value.replace(/[- ]/g, '').length === 10)
        })
    })
    
    useEffect(() => {
        if(menuItems !== undefined && menuItems !== null && menuItems !== ''){
            if(menuItems.state && menuItems.state.length > 0 && menuItems.state !== undefined){
                setStates(menuItems.state.map(c => ({ text: c.name, value: c.key })))
            }else{
                API.get(`menus/states?lang=${lang}`).then((statesResp)=>{
                    setStates(statesResp.data.body.map(c => ({ text: c.name, value: c.key })))
                })
            }
        }else{
        API.get(`menus/states?lang=${lang}`).then((res)=>{
             if(res.status===200){    
                setStates(res.data.body.map(c=>({text:c.name,value:c.key})))
             }
            }).catch(err => {
                console.log(err)
            })
        }
    }, []) // eslint-disable-line

    const submitForm = () => {
        let details = {
            person: {
            address: {
                state: addCaregiverForm.values.address.state,
                country: addCaregiverForm.values.address.country,
                addressLine1: addCaregiverForm.values.address.addressLine1,
                addressLine2: addCaregiverForm.values.address.addressLine2,
                city: addCaregiverForm.values.address.city,
                postalCode: addCaregiverForm.values.address.postalCode,
            },
            language: addCaregiverForm.values.language ? addCaregiverForm.values.language : null,
            areaCode: addCaregiverForm.values.areaCode,
            phone: addCaregiverForm.values.phone,
            careManagerId: addCaregiverForm.values.caremanagerId,
            personId: addCaregiverForm.values.personId ? addCaregiverForm.values.personId : 0 ,
            emailAddress: addCaregiverForm.values.emailAddress,
            firstName: addCaregiverForm.values.firstName.trim(),
            lastName: addCaregiverForm.values.lastName.trim(),
            subscriberId: subscriberId ? subscriberId : addCaregiverForm.values.subscriberId,
            dobDay:cgRefused ? 0 : 1,
            hispanic: addCaregiverForm.values.race,
            dobMonth:cgRefused ? 0 : 1,
            dobYear:cgRefused ? 0 : parseInt(addCaregiverForm.values.dobYear ? addCaregiverForm.values.dobYear :0),
            uninsured: addCaregiverForm.values.uninsured,
            maritalStat: addCaregiverForm.values.maritalStat ? addCaregiverForm.values.maritalStat : null,
            pronouns: addCaregiverForm.values.pronouns ? addCaregiverForm.values.pronouns : null,
            relationshipId:addCaregiverForm.values.relationshipId ? addCaregiverForm.values.relationshipId : 0,
            otherRelationship: addCaregiverForm.values.otherRelationship,
            caseId:addCaregiverForm.values.caseId,
            nativeAmerican: addCaregiverForm.values.race,
            pacificIslander: addCaregiverForm.values.race,
            multiRacial: addCaregiverForm.values.multiRacial,
            preferredName: addCaregiverForm.values.preferredName,
            ssn: addCaregiverForm.values.ssn,
            homePhone: addCaregiverForm.values.homePhone,
            gender: addCaregiverForm.values.gender ? addCaregiverForm.values.gender : null,
            employmentId: addCaregiverForm.values.employmentId ? addCaregiverForm.values.employmentId : 0,
            educationId: addCaregiverForm.values.educationId ? addCaregiverForm.values.educationId : 0,
            incomeId: addCaregiverForm.values.incomeId ? addCaregiverForm.values.incomeId : 0,
            white: addCaregiverForm.values.race,
            black: addCaregiverForm.values.race,
            asian: addCaregiverForm.values.race,
            middleEastern: addCaregiverForm.values.race,
            middleName: addCaregiverForm.values.middleName,
            concentToContat: addCaregiverForm.values.phoneAuthorized,
            medicaid: addCaregiverForm.values.medicaid,
            medicare: addCaregiverForm.values.medicare,
            tricare: addCaregiverForm.values.tricare,
            otherInsurer: addCaregiverForm.values.otherInsurer,
            otherRace:  addCaregiverForm.values.race,
            refused:  addCaregiverForm.values.race
        },
        pcspDate: addCaregiverForm.values.pcspDate ? addCaregiverForm.values.pcspDate.toString().replace('/','-').replace('/','-').slice(0,10) : null,
        }
        API.patch('/cases:noCaregiver', details).then(res => {
            if(res.status === 200) {
                dispatch(setAlert({title:'Success',subtitle:'No Caregiver Updated',type:'success',active:true}))
                onClose()
                addCaregiverForm.resetForm();
            }
        })
    }
    const addCaregiverFormSchema = Yup.object().shape({
        firstName: Yup.string().nullable().max(50).validateName().required(t("FirstName is a required field")),
        lastName: Yup.string().nullable().max(50).validateName().required(t("LastName is a required field")),
        phone:Yup.string().validatePhoneLength().required(t("Mobile Number is a required field")),
        // pcspDate:crDetail?.pcspDate ? '' : Yup.date().required(t("Date Of Associated PCSP is a required field")).nullable(),
        pcspDate: Yup.date().required(t("Date Of Associated PCSP is a required field")).nullable(),
        address:Yup.object({
            postalCode:Yup.string().length(5, t('ZIP code should be 5 digits')).matches(/^[0-9]*$/, t("ZIP code should be numeric value")).required(t('Zip code is a required field')),
            addressLine1:Yup.string().validateName().required(t("Address is a required field")),
            city:Yup.string().required(t("City is a required field")),
            state:Yup.string().required(t("State is a required field")),
        })
    })

    const addCaregiverForm = useFormik({
        initialValues: formData,
        validationSchema: addCaregiverFormSchema,
        enableReinitialize: true,
        validateOnMount: true,
        onSubmit: values => {
            // setFormLoading(true)
            submitForm()
            // setFormLoading(false)
        }
    })

    const getAddress = (value,field) => {
        if(value.length ===5){
            API.get(`/intake/zones?zipCode=${value}`).then((res)=>{
                if(res.status === 200){
                    console.log("res" , res )
                    if(field === 'sub'){
                        addCaregiverForm.setValues({...addCaregiverForm.values,...addCaregiverForm.values,address:{...addCaregiverForm.values.address,state:res.data.body.state,city:res.data.body.city,country:res.data.body.country}})
                    }
                }
            })
        }
    }
    
    return (
        <div className="py-2 w-full">
            <div className="px-4 mt-2">
                <div className="grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-4">
                    <FieldInput ariaLabel={"First Name"} hideData={true} loading={formLoading} label={t("First Name")} autoComplete='off' fieldMeta={addCaregiverForm.getFieldMeta('firstName')} fieldProps={addCaregiverForm.getFieldProps('firstName')} edit={true} dataLoaded={dataLoaded}/>
                    <FieldInput ariaLabel={"Last Name"} hideData={true} loading={formLoading} label={t("Last Name")} autoComplete='off' fieldMeta={addCaregiverForm.getFieldMeta('lastName')} fieldProps={addCaregiverForm.getFieldProps('lastName')} edit={true} dataLoaded={dataLoaded}/>
                    <div className="flex items-center gap-2 relative">
                        <FieldInput ariaLabel={"Phone"} hideData={true} type="phone" loading={formLoading} label={t('Phone')} autoComplete='off' fieldMeta={addCaregiverForm.getFieldMeta(`phone`)} fieldProps={addCaregiverForm.getFieldProps(`phone`)} fieldHelper={addCaregiverForm.getFieldHelpers(`phone`)} edit={true} dataLoaded={dataLoaded} />
                        <a href={`tel: +1${addCaregiverForm.getFieldProps('phone').value?.toString().replace(/-/g, '')}`} className="cursor-pointer bg-tcolor text-white p-2 rounded-lg" title={t('Call')}>
                            <FaPhoneAlt size="16px" />
                        </a>
                    </div>
                    {/* {programName1 ? <FieldSelect loading={formLoading} label={t('Reassessment Reason')} fieldMeta={addCaregiverForm.getFieldMeta(`assessmentType`)} fieldHelper={addCaregiverForm.getFieldHelpers(`assessmentType`)} fieldProps={addCaregiverForm.getFieldProps(`assessmentType`)} edit={true} options={programName} dataLoaded={dataLoaded} /> : ''} */}
                    {datePscp ? <FieldInput ariaLabel={"Pcsp Date"} loading={formLoading} type="date" label={t("Date of Associated PCSP")} autoComplete='off' fieldMeta={addCaregiverForm.getFieldMeta(`pcspDate`)} fieldProps={addCaregiverForm.getFieldProps(`pcspDate`)} fieldHelper={addCaregiverForm.getFieldHelpers(`pcspDate`)} edit={true} dataLoaded={dataLoaded} /> : ''}
                    <FieldInput ariaLabel={"Zipcode"} loading={formLoading} label={t('ZIP Code')} autoComplete='off' fieldMeta={addCaregiverForm.getFieldMeta(`address.postalCode`)} fieldProps={addCaregiverForm.getFieldProps(`address.postalCode`)} edit={true} dataLoaded={dataLoaded} ml={true} length={'5'} onChange={(ev)=>getAddress(ev,'sub')} />
                    <FieldInput ariaLabel={"Address"} loading={formLoading} label={t('Address')} autoComplete='off' fieldMeta={addCaregiverForm.getFieldMeta(`address.addressLine1`)} fieldProps={addCaregiverForm.getFieldProps(`address.addressLine1`)} edit={true} dataLoaded={dataLoaded} />
                    <FieldInput ariaLabel={"City"} loading={formLoading} label={t('City')} autoComplete='off' fieldMeta={addCaregiverForm.getFieldMeta(`address.city`)} fieldProps={addCaregiverForm.getFieldProps(`address.city`)} edit={true} dataLoaded={dataLoaded} />
                    <FieldSelect ariaLabel={"STate"} loading={formLoading} label={t('State')} fieldMeta={addCaregiverForm.getFieldMeta(`address.state`)} fieldProps={addCaregiverForm.getFieldProps(`address.state`)} fieldHelper={addCaregiverForm.getFieldHelpers(`address.state`)} options={states} edit={true} dataLoaded={dataLoaded} position="bottom" />
                    <FieldInput ariaLabel={"Subscriber Id"} type="phone" loading={formLoading} label={t('Subscriber ID')} autoComplete='off' fieldMeta={addCaregiverForm.getFieldMeta(`subscriberId`)} fieldProps={addCaregiverForm.getFieldProps(`subscriberId`)} fieldHelper={addCaregiverForm.getFieldHelpers(`subscriberId`)} edit={false} dataLoaded={dataLoaded} />
                </div>
            </div>
            <div className="flex justify-between mt-7 mx-5">
                <button className="flex items-center relative py-1 px-4 ml-2 mr-2 rounded ring-1 bg-red-700 hover:bg-opacity-80 text-white" onClick={onClose}>{t("Cancel")}</button>
                <button className="flex items-center relative py-1 px-4 ml-2 rounded bg-green-700 hover:bg-opacity-80 text-white" disabled={nextButtonDisable} onClick={() => addCaregiverForm.submitForm()}>{t("Acknowledge")}</button>
            </div>
        </div>
    )

}

export default NoCgDialog