import React from "react";
import Demographic from "../../../components/snippets/Assessment/Demographic";
// import { useRef } from 'react';
import AssessmentReadOnly from "./AssessmentReadOnly";

const AssessmentData = ({match}) => {
   
    // const quesWrap = useRef(null)
    return (
        <div className="h-full">
            <div className="h-full flex">
            <Demographic caseId={match.params.caseId} />
                <div className="flex-1 flex">
                    <AssessmentReadOnly asmtId={match.params.assessmentId} caseId={match.params.caseId} screenerId={0}/>
                </div>
            </div>
        </div>
    )
}

export default AssessmentData