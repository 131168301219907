import FlowText from "../../components/snippets/FlowText"
import { FaUserPlus } from 'react-icons/fa'
import { AnimatePresence, motion } from "framer-motion"
import { wrapPopAnim } from "../../commons/anims"
import TopRowData from "./TopRowData"
import Action from './Action'
import Alert from "./Alert"
import { useEffect, useState } from "react"
import ActionsLoader from "./ActionsLoader"
import AlertsLoader from "./AlertsLoader"
import { Link,useHistory } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import API from '../../axios/API'
import Chart from './Chart/Chart'
import { useTranslation } from 'react-i18next'
// import {setLanguages} from '../../store/theme/actions'
import Reminder from "./Reminder"
import {setServiceUsages} from '../../store/auth/actions'

const Dashboard = (props) => {

    const [loaded, setLoaded] = useState(false)
    const roles = useSelector(state => state.auth.userDetails?.role)
    const userDetails = useSelector(state => state.auth.userDetails);
    const organization = useSelector(state => state.auth.organization)
    const [dashboardDetails, setDashboardDetails] = useState()
    const [alerts, setAlerts] = useState([])
    const [reminder,setReminder] = useState([])
    const [actions,setActions] = useState([])
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const language = useSelector(state=>state.theme.language)
    // let orgId = localStorage.getItem('orgid')
    // organizations/${orgId}/care-managers/${userDetails.caremanagerId}/dashboard
    const history = useHistory();
    const render = useSelector(state =>state.auth.serviceUsage)

    useEffect(() => {
        setLoaded(false)
        API.get(`organizations/${organization}/care-managers/${userDetails.caremanagerId}/dashboard`).then(res => {
            if (res.status === 200) {
                setDashboardDetails(res.data.body)      
                localStorage.setItem('refdyad',res.data.body.referralDyadRequired)
                localStorage.setItem('subId',res.data.body.subId) 
                localStorage.setItem('birthyear',res.data.body.dobFullRequired) 
                localStorage.setItem('prgname',res.data.body.programName)
                localStorage.setItem('assessType',res.data.body.assessmentType) 
                localStorage.setItem('reassess',res.data.body.reassessmentReason)
                localStorage.setItem('pcsp',res.data.body.dateOfPCSP) 
                localStorage.setItem('contactCG',res.data.body.contactCG)  
                localStorage.setItem('uploads',res.data.body.orgUpload)  
                localStorage.setItem('campaign',res.data.body.showCampaign)
                localStorage.setItem('ssn',res.data.body.showSSN)
                localStorage.setItem('createScreen',res.data.body.orgScreenerReason)
                localStorage.setItem('Rescreen',res.data.body.orgRescreenerReason)
                localStorage.setItem('disableAssociateCr',res.data.body.disableAssociateCr)
                localStorage.setItem('vipEnable',res.data.body.vipEnabled)
                localStorage.setItem('QARescreen',res.data.body.orgQARescreenDisable)
                localStorage.setItem('orgCaseNotesDisable', res.data.body.orgCaseNotesDisable)
                localStorage.setItem('noCaregiver',res.data.body.noCaregiver)
                localStorage.setItem('blankPdfEnable',res.data.body.blankPdfEnable)
                localStorage.setItem('orgDraggableEditor', res.data.body.orgDraggableEditor)
                localStorage.setItem('nextActionPopup',res.data.body.nextActionPopup)
                localStorage.setItem('tcareProtocolEnabled',res.data.body.tcareProtocolEnabled)
                localStorage.setItem('orgEthnicity',res.data.body.orgEthnicity)
                localStorage.setItem('tcareMenuItems',JSON.stringify(res.data.body.menus))
                localStorage.setItem('bulkService',res.data.body.bulkServiceUsage)
                localStorage.setItem('jobFamily',res.data.body.jobFamilyGroup)
                localStorage.setItem('empType',res.data.body.employeeType)
                localStorage.setItem('military',res.data.body.militaryService)
                localStorage.setItem('enhanceClose',res.data.body.enhancedCloseCaseReason)
                localStorage.setItem('serviceAuthorization',res.data.body.serviceAuthorization)
                localStorage.setItem('serviceUsage',res.data.body.serviceUsage)
                dispatch(setServiceUsages(render+1))
                let pastdue = []
                setAlerts(res.data.body.alert)
                // setReminder(res.data.body.reminder)
                if(res.data.body.passDue.length){
                    pastdue.push({title:t('Past Due'),data:res.data.body.passDue})
                    // setActions(actions)
                }
                if(res.data.body.todayAction.length){
                    pastdue.push({title:t('Today Actions'),data:res.data.body.todayAction})
                    // setActions(actions)
                }
                if(res.data.body.weekAction.length){
                    pastdue.push({title:t('Week Actions'),data:res.data.body.weekAction})
                    // setActions(actions)
                }
                if(res.data.body.upcommingAction.length){
                    pastdue.push({title:t('Upcoming Actions'),data:res.data.body.upcommingAction})
                    // setActions(actions)
                }
                setActions(pastdue)
                let pasdueReminder = []
                if(res.data.body.pastDueReminder.length){
                    pasdueReminder.push({title:t('Past Due Reminder'),data:res.data.body.pastDueReminder})
                    // setActions(actions)
                }
                if(res.data.body.upComingReminder.length){
                    pasdueReminder.push({title:t('Upcoming Reminder'),data:res.data.body.upComingReminder})
                    // setActions(actions)
                }
                setReminder(pasdueReminder)
                setLoaded(true)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [organization,language, t, userDetails.caremanagerId])

    const addCase=()=>{
        history.push({pathname:`/addCase`})
    }
    const referrer=()=>{
        history.push({pathname:`/referrer`})
    }

    return (
        <div className="z-[1] relative">
            {/* {loaded ? } */}
            {/* {loaded ? <TopRowData loaded={loaded} cases={dashboardDetails.allCasesCount} inProgress={dashboardDetails.progressCount} pendingReferral={dashboardDetails.referrerCount} timeToCases={dashboardDetails.timeToCases} /> : <TopRowData loaded={loaded} />} */}
            {loaded ? <Chart details={dashboardDetails}  /> : <TopRowData loaded={loaded} />}
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 mt-7">
                <div>
                    <div className="font-bold text-gray-600 dark:text-blue-300">{t("Next Actions")}</div>
                    <AnimatePresence>
                        {
                            loaded ?
                                <motion.div variants={wrapPopAnim} initial="hidden" animate="visible" className="shadow-lg bg-bluegray-200 dark:bg-ddark p-4 mt-4 rounded-xl">
                                    {
                                        actions.length ? actions.map((c, i) => (
                                            <motion.section tabIndex={0} key={i} className="mb-4">
                                                <div className="text-gray-600 dark:text-blue-300 text-lg font-bold"><FlowText text={c.title} /></div>
                                                <motion.div>
                                                    {
                                                        c.data.map((e, j) => (
                                                            <Action key={j} action={e} />
                                                        ))
                                                    }
                                                </motion.div>
                                            </motion.section>
                                        )) : <FlowText text={t("No Actions")} />
                                    }
                                </motion.div>
                                :
                                <ActionsLoader />
                        }
                    </AnimatePresence>
                </div>
                <div>
                    <div className="font-bold text-gray-600 dark:text-blue-300">{t("Alerts")}</div>
                    <AnimatePresence>
                        {
                            loaded ?
                                <motion.div variants={wrapPopAnim} initial="hidden" animate="visible" className="shadow-lg bg-bluegray-200 dark:bg-ddark p-4 mt-4 rounded-xl">
                                    {
                                        alerts.length ? alerts.map((c, i) => (
                                            <Alert key={i} alert={c} />
                                        )) : <FlowText text={t("No Alerts")} />
                                    }
                                </motion.div>
                                :
                                <AlertsLoader />
                        }
                    </AnimatePresence>
                </div>
                <div>
                    <div className="font-bold text-gray-600 dark:text-blue-300">{t("Reminders")}</div>
                    <AnimatePresence>
                        {
                            loaded ?
                                <motion.div variants={wrapPopAnim} initial="hidden" animate="visible" className="shadow-lg bg-bluegray-200 dark:bg-ddark p-4 mt-4 rounded-xl">
                                    {/* {
                                        reminder.length ? reminder.map((c, i) => (
                                            <Reminder key={i} alert={c} />
                                        )) : <FlowText text={t("No Reminders")} />
                                    } */}
                                      {
                                         reminder.length ? reminder.map((c, i) => (
                                            <motion.section tabIndex={0} key={i} className="mb-4">
                                                <div className="text-gray-600 dark:text-blue-300 text-lg font-bold"><FlowText text={c.title} /></div>
                                                <div>
                                                    {
                                                        c.data.map((e, j) => (
                                                            <Reminder key={j} alert={e} />
                                                        ))
                                                    }
                                                </div>
                                            </motion.section>
                                        )) : <FlowText text={t("No Reminders")} />
                                    }
                                </motion.div>
                                :
                                <AlertsLoader />
                        }
                    </AnimatePresence>
                </div>
                {/* {
                    ((roles !== undefined && roles && roles.length > 0 && roles.includes('caremanager','screener')) || (roles !== undefined && roles && roles.length > 0 && roles[0]==="screener") || (roles !== undefined && roles.includes('screener'))) ?
                        <div>
                            <div className="text-gray-600 dark:text-gray-300 text-lg">
                                {t("Sign up a caregiver into the system")}
                            </div>
                            <button className="mt-3 bg-tcolor px-6 py-2 rounded-xl shadow-lg text-white ring-offset-2 hover:ring-2 hover:ring-tcolor dark:ring-offset-black">
                                <Link to="/addCase" className="whitespace-nowrap flex items-center">
                                    <FaUserPlus className="mr-3" />
                                   {t("Add New")}
                                </Link>
                            </button>
                        </div> : ''
                } */}
                <div style={{ marginTop: '27px' }}>
                    {((userDetails && organization && (userDetails.orgId === parseInt(organization)) && roles.includes('referrer')) || (userDetails && organization && (userDetails.orgId !== parseInt(organization)) && roles.includes('dynamic_ref'))) ? <button className="mt-3 bg-tcolor px-6 py-2 rounded-xl shadow-lg text-black ring-offset-2 hover:ring-2 hover:ring-tcolor dark:ring-offset-black" title={t("Create Referral")}>
                        <span onClick={() => referrer()} className="whitespace-nowrap flex items-center">
                            <FaUserPlus className="mr-3" />
                            {t("Create Referral")}
                        </span>
                    </button> : ''}
                    {((userDetails && organization && (userDetails.orgId === parseInt(organization)) && roles.includes('caremanager')) || (userDetails && organization && (userDetails.orgId !== parseInt(organization)) && roles.includes('dynamic_cm'))) ? <button className="mt-3 bg-tcolor px-6 py-2 rounded-xl shadow-lg text-black ring-offset-2 hover:ring-2 hover:ring-tcolor dark:ring-offset-black" title={t("Enroll Caregiver")}>
                        <span onClick={() => addCase()} className="whitespace-nowrap flex items-center">
                            <FaUserPlus className="mr-3" />
                            {t("Enroll Caregiver")}
                        </span>
                    </button> : ''}

                </div>
             
           </div>
       </div>
    )
}

export default Dashboard