import { FaBell, FaCheck, FaExclamationTriangle } from 'react-icons/fa'
import { motion } from "framer-motion"
import { TextAnimateY } from "../../commons/anims"
// import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const Alert = ({ alert }) => {
    let history = useHistory();
    const lastdat =(value)=>
    {if(value !== null && value){
        let timestamp = new Date(value).getTime();
        let tz = moment.tz.guess()
        let tzabbr = moment.tz.zone(tz).abbr(timestamp)
        return moment(new Date(value)).format('MMM D,YYYY HH:mm:ss')+ ' ' +tzabbr
        //      let followupdate = new Date(value)
        //  return `${followupdate.toUTCString().slice(8,11)}${followupdate.toUTCString().slice(4,7)}, ${followupdate.toUTCString().slice(11,16)} ${moment(value).format("HH:mm:ss")} ${tzabbr}`
         }else{
             return ''
         }
      }
    return (
        <motion.div variants={TextAnimateY} className={`group my-3 p-2 rounded-xl transition-all duration-100 hover:ring-1 cursor-pointer ${alert.priority === 3 ? 'ring-red-500 text-red-400' : alert.priority === 2 ? 'ring-yellow-500 text-yellow-500' : 'ring-green-500 text-green-500'}`}>
            <div className={`flex justify-between items-center mr-2`}>
                <div className="flex items-center">
                    <div className="group-hover:animate-bounce">
                        {alert.priority === 3 && <FaExclamationTriangle size="25px" />}
                        {alert.priority === 2 && <FaBell size="25px" />}
                        {alert.priority === 1 && <FaCheck size="25px" />}
                    </div>
                    <div className="ml-3">
                        <div className="font-bold text-sm" onClick={()=>history.push(`/casedata/${alert.id}`)}>{alert.id}</div>
                        <div className="text-xs">{alert.status}</div>
                    </div>
                </div>
                <div className="text-xs font-bold">
                    {lastdat(alert.time)}
                </div>
            </div>
        </motion.div>
    )
}

export default Alert