
import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import FlowText from './FlowText';
import { FaExclamationTriangle } from 'react-icons/fa';
import { TextAnimateX } from '../../commons/anims';
import { AnimatePresence, motion } from 'framer-motion';

export const QuillEditor = ({ edit, dataLoaded, disabled = false, type = 'textarea', loading = false, label, arialLabel = '', err = false, customErrmsg, fieldMeta, isDraggableEditor = false, isMaximize = false }) => {
    // const [value, setValue] = useState('');
    const { values, setFieldValue, validateField, setFieldTouched } = useFormikContext()
    const input = useRef(null)
    const wrapRef = useRef(null)
    const handleFocus = (ev) => {
        if (input.current !== null && type !== 'phone' && type !== 'price')
            input.current.focus()
    }
    const [showError, setShowError] = useState(false)
    const toggleError = (val) => setShowError(val)

    const modules = {
        toolbar: [
            [{ font: [] }],
            [{ size: ["small", false, "large"] }],

            ["bold", "italic", "underline", "strike"],
            [{ color: [], }],
            [{ background: [] }],
            [
                { list: "ordered" },
                { list: "bullet" },
            ],
            ["link"],
            ["clean"],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    }
    // document.querySelector('.quill').classList.add('relative')
    useEffect(() => {
        const element = document.querySelector('div.ql-toolbar')
        // const container = document.querySelector('div.quill')
        const qlEditor = document.querySelectorAll('div.ql-editor')
        if (element || qlEditor) {
            element && element.classList.add('sticky', 'top-0', 'z-50', 'bg-bluegray-200')
            // container.classList.add('min-h-[150px]','pb-3')
            qlEditor && qlEditor.forEach(e => e.classList.add('max-h-40'))

        }
    }, [edit, loading])

    useEffect(() => {
        function addAccessibility() {
            const query = document.getElementsByClassName('ql-toolbar');
            if (query.length !== 1) {
                // No toolbars found OR multiple which is not what we expect either
                return;
            }

            const toolBar = query[0];

            // apply aria labels to base buttons
            const buttons = toolBar.getElementsByTagName('button');
            for (let i = 0; i < buttons.length; i++) {
                const button = buttons[i];
                const className = button.getAttribute('class').toLowerCase();

                if (className.indexOf('bold') >= 0) {
                    button.setAttribute('aria-label', 'Toggle bold text');
                } else if (className.indexOf('italic') >= 0) {
                    button.setAttribute('aria-label', 'Toggle italic text');
                } else if (className.indexOf('underline') >= 0) {
                    button.setAttribute('aria-label', 'Toggle underline text');
                } else if (className.indexOf('strike') >= 0) {
                    button.setAttribute('aria-label', 'Toggle strike text');
                }
                else if (className.indexOf('link') >= 0) {
                    button.setAttribute('aria-label', 'Attach link text')
                }
                else if (className.indexOf('clean') >= 0) {
                    button.setAttribute('aria-label', 'Clean Format text')
                }
                else if (className.indexOf('blockquote') >= 0) {
                    button.setAttribute('aria-label', 'Toggle blockquote text');
                } else if (className.indexOf('list') >= 0 && button.value === 'ordered') {
                    button.setAttribute('aria-label', 'Toggle ordered list');
                } else if (className.indexOf('list') >= 0 && button.value === 'bullet') {
                    button.setAttribute('aria-label', 'Toggle bulleted list');
                }
            }

            // Make pickers work with keyboard and apply aria labels
            //FIXME: When you open a submenu with the keyboard and close it with the mouse by click somewhere else, the menu aria-hidden value is incorrectly left to `false`
            const pickers = toolBar.getElementsByClassName('ql-picker');
            for (let i = 0; i < pickers.length; i++) {
                const picker = pickers[i];

                const label = picker.getElementsByClassName('ql-picker-label')[0];
                const optionsContainer = picker.getElementsByClassName('ql-picker-options')[0];
                const options = optionsContainer.getElementsByClassName('ql-picker-item');

                label.setAttribute('role', 'button');
                // label.setAttribute('tabindex', '0');
                label.setAttribute('aria-label', 'picker');

                if (i === 0) {
                    // This is our size select box.. Works for us as we only have the one drop box
                    label.setAttribute('aria-label', 'Font Size');
                }

                optionsContainer.setAttribute('aria-hidden', 'true');
                optionsContainer.setAttribute('aria-label', 'submenu');

                for (let x = 0; x < options.length; x++) {
                    const item = options[x];
                    item.setAttribute('tabindex', '0');
                    item.setAttribute('role', 'button');

                    // Read the css 'content' values and generate aria labels
                    const size = window.getComputedStyle(item, ':before').content.replace('"', '');
                    item.setAttribute('aria-label', size);
                    item.addEventListener('keyup', (e) => {
                        if (e.keyCode === 13) {
                            item.click();
                            optionsContainer.setAttribute('aria-hidden', 'true');
                        }
                    });
                }

                label.addEventListener('keyup', (e) => {
                    if (e.keyCode === 13) {
                        label.click();
                        optionsContainer.setAttribute('aria-hidden', 'false');
                    }
                });

            }

        }
        addAccessibility()
    }, [edit, loading])
    return (
        // <ReactQuill className={`pb-1.5 pl-3 bg-transparent pr-10 w-full h-48 outline-none mb-4`} {...fieldProps} aria-label={ariaLabel} theme='snow' value={value} onChange={setValue} />

        <div ref={wrapRef} className={`group flex flex-col ${disabled ? 'bg-gray-300 dark:bg-gray-600' : edit ? 'ring-1 ring-gray-300 dark:ring-gray-600' : ''} ${type === 'date' ? 'cursor-pointer' : ''} px-2 pt-1 rounded-lg focus-within:ring-tcolor dark:focus-within:ring-tcolor transition-all duration-100 ${loading ? 'animate-pulse' : ''} ${isDraggableEditor && isMaximize ? '' : 'max-w-[90vw]'}`} onClick={handleFocus}> {/* max-width:none*/}

            <span className={`${edit ? 'ml-1' : ''} text-xs text-gray-500 dark:text-gray-400`}>
                <FlowText text={label} direction="x" />
            </span>
            <div className={`relative ${isDraggableEditor ? 'z-50' :''}`}>
                {edit && dataLoaded &&

                    // <textarea className={`pb-1.5 pl-3 bg-transparent pr-10 w-full h-48 outline-none`} ref={input} {...fieldProps} aria-label={ariaLabel} />

                    <div className='min-h-52'>
                        <ReactQuill bounds='.quill' modules={modules} value={values.description} className={`pb-1.5 mt-2 bg-transparent w-full h-full mb-2 ${isDraggableEditor ? '' : 'max-h-52'}`} theme='snow'
                            onChange={(v) => {
                                if (v === '<p><br></p>') {
                                    setFieldValue('description', '');
                                }
                                else {
                                    setFieldValue('description', v);
                                }
                                validateField('description')
                                setFieldTouched('description',true)
                            }} 
                            /> {/* max-height:none*/}
                    </div>

                }

                {edit &&
                    <span className={`absolute ${type === 'password' ? 'right-2' : 'right-4'} ${isMaximize ? 'top-14' : 'top-16'} text-xl flex z-50`}>
                        {fieldMeta.error && fieldMeta.touched && <FaExclamationTriangle className="text-red-500 cursor-pointer" onMouseEnter={() => toggleError(true)} onMouseLeave={() => toggleError(false)} />}
                    </span>
                }

                <AnimatePresence>
                    {fieldMeta.error && showError &&
                        <motion.span variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className={`absolute top-14 ${type === 'password' ? 'right-16' : 'right-10'} inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500 z-50`}>
                            {fieldMeta.error}
                        </motion.span>
                    }
                    {err && showError &&
                        <motion.span variants={TextAnimateX} initial="hidden" animate="visible" exit="hidden" className={`absolute -top-1 ${type === 'password' ? 'right-16' : 'right-10'} inline-block bg-gray-300 dark:bg-ldark py-0.5 px-2 rounded text-sm text-red-500`}>
                            {customErrmsg}
                        </motion.span>
                    }
                </AnimatePresence>
            </div>
        </div>
    )
}
