import { motion } from "framer-motion"

const loadingAnim = {
    hidden: {
        x: '-50vw'
    },
    visible: {
        x: '100vw',
        transition: {
            repeat: Infinity,
            repeatType: 'loop',
            duration: 1,
            type: "linear"
        }
    }
}

const Loading = () => {
    return (
        <div className="h-1 w-full fixed top-0 bg-blue-300 dark:bg-opacity-30 z-[1999]">
            <motion.div variants={loadingAnim} initial="hidden" animate="visible" className="w-1/4 h-1 bg-tcolor rounded-full"></motion.div>
        </div>
    )
}

export default Loading