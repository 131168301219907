export const datatableTheme = {
    default: {
        background: {
            default: '#e2e8f0'
        },
        highlightOnHover: {
            default: '#CBD5E1'
        },
        text:{
            secondary: '#000000',

        }
    },
    dark: {
        text: {
            primary: '#ffffff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(0,0,0,.12)'
        },
        background: {
            default: '#161e29'
        },
        context: {
            background: '#E91E63',
            text: '#FFFFFF',
        },
        divider: {
            default: 'rgba(81, 81, 81, 0.3)',
        },
        button: {
            default: '#FFFFFF',
            focus: 'rgba(255, 255, 255, .54)',
            hover: 'rgba(255, 255, 255, .12)',
            disabled: 'rgba(255, 255, 255, .18)',
        },
        sortFocus: {
            default: 'rgba(255, 255, 255, .54)',
        },
        selected: {
            default: '#374151',
            text: '#FFFFFF',
        },
        highlightOnHover: {
            default: '#6B7280',
            text: '#FFFFFF',
        },
        striped: {
            default: 'rgba(0, 0, 0, .87)',
            text: '#FFFFFF',
        },
    }
}
